import { EDIT_CUSTOMER, FETCH_REQUEST } from '../../sharedTypes';
import {
  GET_CUSTOMERS_TENANT, CREATE_CUSTOMER_TENANT, UPDATE_CUSTOMER_TENANT,
  GET_CURRENT_CUSTOMER_TENANT, SOFT_DELETE_CUSTOMER_TENANT,
} from '../../fetchTypes';

export const getCustomersRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CUSTOMERS_TENANT,
  data,
});

export const getCurrentCustomerRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CURRENT_CUSTOMER_TENANT,
  data,
});

export const createCustomerRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_CUSTOMER_TENANT,
  data,
});

export const updateCustomerRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_CUSTOMER_TENANT,
  data,
});

export const softDeleteCustomerRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_CUSTOMER_TENANT,
  data,
});

export const editCustomer = data => ({
  type: EDIT_CUSTOMER,
  data,
});
