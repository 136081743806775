import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../../shared/components/pagination/Pagination';

const CustomersTablePagination = ({
  totalCustomers,
  perPage,
  currentPage,
  updatePagination,
}) => (
  <Pagination
    itemsCount={totalCustomers}
    itemsToShow={perPage}
    pageOfItems={currentPage}
    onChangePage={updatePagination}
  />
);

CustomersTablePagination.propTypes = {
  totalCustomers: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  updatePagination: PropTypes.func.isRequired,
};

export default CustomersTablePagination;
