import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as ProjectActions from '../../../store/actions/tenant/projects';
import Loading from '../../../shared/components/Loading';

const MatterTopbarHeader = ({
  loading, loaded, error, project, getCurrentProject,
}) => {
  const { matterId: projectId } = useParams();

  const loadCurrentProject = () => {
    getCurrentProject({ _id: projectId });
  };

  useEffect(() => {
    loadCurrentProject();
  }, [projectId]);

  const { title: projectTitle, customerId: customer } = project || {};

  const { _id: customerId, name: customerName } = customer || {};

  return (
    <Loading
      loading={loading}
      loaded={!loading}
    >
      <div className="topbar__header-group">
        <Link to={`/matters/${projectId}`}>
          <span className="topbar__header-title">
            { projectTitle || 'AdvoPASS' }
          </span>
        </Link>
        <Link to={`/customers/${customerId}`}>
          <span className="topbar__header-subtitle">
            { customerName || 'John Doe' }
          </span>
        </Link>
      </div>
    </Loading>
  );
};

MatterTopbarHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  project: PropTypes.shape({
    customerId: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }),
  getCurrentProject: PropTypes.func.isRequired,
};

MatterTopbarHeader.defaultProps = {
  error: '',
  project: null,
};

const mapStateToProps = state => ({
  loading: state.projects.loadingCurrent,
  loaded: state.projects.loadedCurrent,
  error: state.projects.errorCurrent,
  project: state.projects.currentProject,
});

const mapDispatchToProps = {
  getCurrentProject: ProjectActions.getCurrentProjectRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatterTopbarHeader);
