import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as ProfileActions from '../../../../store/actions/tenant/profile';

const UnsubscribePrompt = ({
  onHideModal, unsubscribe, modalVisible,
}) => {
  const handleDeleteConfirm = () => {
    unsubscribe();
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('unsubscribe.prompt_title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={modalVisible}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('unsubscribe.prompt_description')}
      </h4>
    </ModalComponent>
  );
};

UnsubscribePrompt.propTypes = {
  onHideModal: PropTypes.func.isRequired,

  modalVisible: PropTypes.bool.isRequired,

  unsubscribe: PropTypes.func.isRequired,
};

UnsubscribePrompt.defaultProps = {

};

const mapDispatchToProps = {
  unsubscribe: ProfileActions.unsubscribeRequest,
};

export default connect(null, mapDispatchToProps)(UnsubscribePrompt);
