import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import { withTranslation } from 'react-i18next';
import LoginForm from '../../../shared/components/form/LoginForm';
import TenantActions from '../../../store/actions/tenant';

const LoginTenant = ({
  loginRequest, authLoading, authError, t,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  const handleSubmit = (data) => {
    loginRequest(data);
    setSubmitted(true);
  };

  useEffect(() => {
    if (!authLoading && authError === 'VERIFYEMAIL' && submitted) {
      history.push('/email_verification');
    }
  });

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> Advo
                <span className="account__logo-accent">PASS</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">{t('login.sign_in')}</h4>
          </div>
          <LoginForm
            onSubmit={handleSubmit}
            loading={authLoading}
          />
        </div>
      </div>
    </div>
  );
};

LoginTenant.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  authLoading: PropTypes.bool.isRequired,
  authError: PropTypes.string,
  t: PropTypes.func.isRequired,
};
LoginTenant.defaultProps = {
  authError: '',
};

const mapStateToProps = state => ({
  authLoading: state.auth.loading,
  authError: state.auth.error,
});

const mapDispatchToProps = { loginRequest: TenantActions.Auth.loginRequest };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(LoginTenant));
