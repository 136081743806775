import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Row, Col } from 'reactstrap';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import throttle from 'lodash.throttle';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import RenderSelectField from '../../../../shared/components/form/Select';
import { ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import SearchBox from '../../../../shared/components/SearchBox';
import FilterSidebar from '../../../../shared/components/filter/FilterSidebar';
import {
  billingTypeFilters, categoryFilters, statusFilters, tenureTypeFilters,
} from '../constants/options';

const sortByOptions = [
  { value: 'title', label: 'matter_options.title' },
  { value: 'category', label: 'matter_options.category' },
  { value: 'status', label: 'matter_options.status' },
  /* { value: 'tenureType', label: 'matter_options.tenure_type' }, */
  { value: 'proposalDate', label: 'matter_options.proposal_date' },
  { value: 'billingType', label: 'matter_options.billing_type' },
];

const dirOptions = [
  { value: 'asc', label: 'project_filter.ascending' },
  { value: 'desc', label: 'project_filter.descending' },
];

class ProjectsFilter extends Component {
  static propTypes = {
    updateFilter: PropTypes.func.isRequired,
    activesFilter: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    billingType: PropTypes.string.isRequired,

    startDateRangeStart: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    startDateRangeEnd: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    createdRangeStart: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    createdRangeEnd: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    keywords: PropTypes.string.isRequired,
    tenureType: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    createdRangeEnd: null,
    createdRangeStart: null,
    startDateRangeEnd: null,
    startDateRangeStart: null,
  };

  constructor(props) {
    super(props);
    this.handleKeywordsChange = throttle(this.handleKeywordsChange, 400);
  }

  handleFilterChange = (event) => {
    const { updateFilter } = this.props;
    updateFilter(event.target.value, event.target.name);
  };

  handleDateChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  handleTypeChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'tenureType');
  };

  handleBillingTypeChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'billingType');
  };

  handleStatusChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'status');
  };

  categoryChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'category');
  }

  handleSortByChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'sortBy');
  };

  handleDirChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'dir');
  };

  handleKeywordsChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  render() {
    const {
      activesFilter,
      status,
      startDateRangeStart,
      startDateRangeEnd,
      createdRangeStart,
      createdRangeEnd,
      billingType,
      keywords,
      tenureType,
      sortBy,
      category,
      dir,
      theme,
      t,
      rtl,
    } = this.props;

    return (
      <FilterSidebar>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.keywords')}</p>
            <SearchBox
              name="keywords"
              defaultValue={keywords}
              onChange={this.handleKeywordsChange}
            />
          </Col>
        </Row>
        {/* <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">Start Date</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'startDate',
              }}
                defaultValue={ startDate}
             />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">End Date</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'endDate',
              }}
                defaultValue={ endDate}
             />
          </Col>
        </Row> */}
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.proposal_date_range')}</p>
            <div className="d-flex sortInput">
              <RenderDatePickerField
                input={{
                  onChange: this.handleDateChange,
                  name: 'startDateRangeStart',
                }}
                defaultValue={startDateRangeStart}
              />
              <RenderDatePickerField
                input={{
                  onChange: this.handleDateChange,
                  name: 'startDateRangeEnd',
                }}
                defaultValue={startDateRangeEnd}
              />
            </div>
          </Col>
        </Row>
        <Row className="tasks-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.creation_date_range')}</p>
            <div className="d-flex sortInput">
              <RenderDatePickerField
                input={{
                  onChange: this.handleDateChange,
                  name: 'createdRangeStart',
                }}
                defaultValue={createdRangeStart}
              />
              <RenderDatePickerField
                input={{
                  onChange: this.handleDateChange,
                  name: 'createdRangeEnd',
                }}
                defaultValue={createdRangeEnd}
              />
            </div>
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.status')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleStatusChange,
                name: 'status',
              }}
              options={statusFilters}
              defaultValue={status || statusFilters[0]}
              isTranslated
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.billing')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleBillingTypeChange,
                name: 'billingType',
              }}
              options={billingTypeFilters}
              defaultValue={billingType || billingTypeFilters[0]}
              isTranslated
            />
          </Col>
        </Row>
        {/*        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.tenure_type')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleTypeChange,
                name: 'type',
              }}
              options={tenureTypeFilters}
              defaultValue={tenureType || tenureTypeFilters[0]}
              isTranslated
            />
          </Col>
        </Row> */}
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.category')}</p>
            <div className="d-flex sortInput">
              <RenderSelectField
                input={{
                  onChange: this.categoryChange,
                  name: 'category',
                }}
                options={categoryFilters}
                defaultValue={category || categoryFilters[0]}
                isTranslated
              />

            </div>
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('project_filter.sort_by')}</p>
            <div className="d-flex sortInput">
              <RenderSelectField
                input={{
                  onChange: this.handleSortByChange,
                  name: 'sortBy',
                }}
                options={sortByOptions}
                defaultValue={sortBy || sortByOptions[0]}
                isTranslated
              />

            </div>
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <div className="d-flex sortInput">
              <RenderSelectField
                input={{
                  onChange: this.handleDirChange,
                  name: 'dir',
                }}
                options={dirOptions}
                defaultValue={dir || dirOptions[0]}
                isTranslated
              />
            </div>
          </Col>
        </Row>
      </FilterSidebar>
    );
  }
}
export default withTranslation('common')(ProjectsFilter);
