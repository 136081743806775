import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import debounce from 'lodash.debounce';

import * as TaskActions from '../../../../store/actions/tenant/tasks';
import { selectFilter } from '../../../../store/reducers/tasks';

import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import RenderSelectField from '../../../../shared/components/form/Select';
import SearchBox from '../../../../shared/components/SearchBox';
import FilterSidebar from '../../../../shared/components/filter/FilterSidebar';
import { activeStatusFilters, billableStatusFilters, statusFilters } from '../constants/options';
import { getTaskTypes } from '../../../../store/api/lookup';


const sortByOptions = [
  { value: '', label: 'task_filter.sort_by_options.no_sort' },
  { value: 'title', label: 'task_filter.sort_by_options.title' },
  { value: 'startDateTime', label: 'task_filter.sort_by_options.start_date' },
  { value: 'duration', label: 'task_filter.sort_by_options.duration' },
  { value: 'created', label: 'task_filter.sort_by_options.creation_date' },
  /* { value: 'activeStatus', label: 'Active Status' },
  { value: 'status', label: 'Status' }, */
];

const dirOptions = [
  { value: 'asc', label: 'task_filter.dir_options.ascending' },
  { value: 'desc', label: 'task_filter.dir_options.descending' },
];

const TasksFilterSidebar = ({
  t, filter, changeTaskFilter, clearTaskFilter,
}) => {
  const {
    'createdRange[0]': createdRangeStart,
    'createdRange[1]': createdRangeEnd,
    'startDateRange[0]': startDateRangeStart,
    'startDateRange[1]': startDateRangeEnd,
    actives, billableStatus, keywords, status, sortBy, dir, taskType,
  } = filter;
  const [taskTypes, setTaskTypes] = useState([]);

  const handleKeywordsChange = useRef(debounce(value => changeTaskFilter({ keywords: value }), 250));

  const searchBox = useRef(null);
  useEffect(() => {
    getTaskTypes().then((taskTypeList) => {
      if (taskTypeList) {
        setTaskTypes([{ value: '', label: t('task_filter.all_task_types') },
          ...taskTypeList
            .map(({ _id, name }) => ({ label: name, value: name }))]);
      }
    });
  }, []);


  const handleDateChange = (value, name) => changeTaskFilter({ [name]: value });

  // eslint-disable-next-line no-console

  return (
    <FilterSidebar>
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_filter.keywords')}</p>
          <SearchBox
            name="keywords"
            value={keywords}
            onChange={handleKeywordsChange.current}
            ref={searchBox}
          />
        </Col>
      </Row>
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_filter.start_date')}</p>
          <div className="d-flex sortInput">
            <RenderDatePickerField
              input={{
                onChange: handleDateChange,
                name: 'startDateRange[0]',
                value: startDateRangeStart,
              }}
              forceValue
            />
            <RenderDatePickerField
              input={{
                onChange: handleDateChange,
                name: 'startDateRange[1]',
                value: startDateRangeEnd,
              }}
              forceValue
            />
          </div>
        </Col>
      </Row>
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_filter.creation_date')}</p>
          <div className="d-flex sortInput">
            <RenderDatePickerField
              input={{
                onChange: handleDateChange,
                name: 'createdRange[0]',
                value: createdRangeStart,
              }}
              forceValue
            />
            <RenderDatePickerField
              input={{
                onChange: handleDateChange,
                name: 'createdRange[1]',
                value: createdRangeEnd,
              }}
              forceValue
            />
          </div>
        </Col>
      </Row>
      {/*
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">Status</p>
          <RenderSelectField
            input={{
              onChange: ({ value }) => changeTaskFilter({ status: value }),
              name: 'status',
            }}
            options={statusFilters}
            defaultValue={status || statusFilters[0]}
          />
        </Col>
      </Row> */}
      {/*      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">Active Status</p>
          <RenderSelectField
            input= {{
              onChange: ({ value }) => changeTaskFilter({
                actives: value,
              }),
              name: 'activeStatus',
            }}
            options={activeStatusFilters}
            defaultValue={actives || activeStatusFilters[0]}
          />

        </Col>
      </Row> */}
      <Row cla ssName="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_form.task_type')}</p>
          <RenderSelectField
            input={{
              onChange: ({ value }) => changeTaskFilter({ taskType: value }),
              name: 'taskType',
            }}
            options={taskTypes}
            defaultValue={taskType || taskTypes[0]}
          />
        </Col>
      </Row>
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_filter.billable_status')}</p>
          <RenderSelectField
            input={{
              onChange: ({ value }) => changeTaskFilter({
                billableStatus: value,
              }),
              name: 'billableStatus',
              value: billableStatus || billableStatusFilters[0],
            }}
            options={billableStatusFilters}
            isTranslated
          />
        </Col>
      </Row>
      <Row className="customers-page__filter-row form">
        <Col>
          <p className="title">{t('task_filter.sort_by')}</p>
          <div className="d-flex sortInput">
            <RenderSelectField
              input={{
                onChange: ({ value }) => changeTaskFilter({ sortBy: value }),
                name: 'sortBy',
                value: sortBy || sortByOptions[0],
              }}
              options={sortByOptions}
              isTranslated
            />
            <RenderSelectField
              input={{
                onChange: ({ value }) => changeTaskFilter({
                  dir: value,
                }),
                name: 'dir',
                value: dir || dirOptions[0],
              }}
              options={dirOptions}
              isTranslated
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-center mt-3">
            <Button
              color="danger"
              onClick={() => {
                if (searchBox.current) {
                  searchBox.current.clearSilent();
                }
                clearTaskFilter();
              }}
              className="icon"
            >
              <p>
                <DeleteIcon /> {t('task_filter.clear_filter')}
              </p>
            </Button>
          </div>
        </Col>
      </Row>
    </FilterSidebar>
  );
};

TasksFilterSidebar.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    'createdRange[0]': PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    'createdRange[1]': PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    'startDateRange[0]': PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    'startDateRange[1]': PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    actives: PropTypes.oneOf(['-1', '0', '1']).isRequired,
    keywords: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['', '100', '101', '103', '200']).isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    taskType: PropTypes.string,
  }).isRequired,
  changeTaskFilter: PropTypes.func.isRequired,
  clearTaskFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  filter: selectFilter(state),
});

const mapDispatchToProps = {
  changeTaskFilter: TaskActions.changeTaskFilter,
  clearTaskFilter: TaskActions.clearTaskFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TasksFilterSidebar));
