/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import { chamberRegistrationNumber, idNumber } from '../../../../lib/FieldWarnings/FieldWarnings';
import RenderTextField from './TextField';
import Loading from '../../../../shared/components/Loading';

class ProfileInfoSettings extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleSubmit, reset, loading, t,
    } = this.props;
    return (
      <form className="material-form ProfileInfoSettings" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label">{t('profile_info.first_name')}</span>
          <Field
            name="fname"
            component={RenderTextField}
            placeholder={t('profile_info.first_name')}
          />
        </div>
        <div>
          <span className="material-form__label">{t('profile_info.last_name')}</span>
          <Field
            name="lname"
            component={RenderTextField}
            placeholder={t('profile_info.last_name')}
          />
        </div>
        <div>
          <span className="material-form__label">{t('profile_info.email')}</span>
          <Field
            name="email"
            component={RenderTextField}
            placeholder={t('profile_info.email_placeholder')}
            type="email"
          />
        </div>
        <div>
          <span className="material-form__label">{t('profile_info.identity_number')}</span>
          <Field
            name="identityNumber"
            component={RenderTextField}
            validate={idNumber}
            placeholder={t('profile_info.identity_holder')}
            type="text"
          />
        </div>
        <div>
          <span className="material-form__label">{t('profile_info.chamber_register')}</span>
          <Field
            name="chamberRegNo"
            component={RenderTextField}
            placeholder={t('profile_info.chamber_register')}
            validate={chamberRegistrationNumber}
            type="number"
          />
        </div>
        <div>
          <span className="material-form__label">{t('profile_info.phone')}</span>
          <Field
            name="phone"
            component={renderPhoneInput}
            defaultCountry="tr"
            type="text"
          />
        </div>
        {/*        <div>
          <span className="material-form__label">Password</span>
          <Field
            name="password"
            component={RenderTextField}
            type="password"
          />
        </div> */}
        <ButtonToolbar className="form__button-toolbar">
          {
            loading ? <Loading />
              : (
                <Button
                  color="primary"
                  type="submit"
                >{t('profile_info.update_info')}
                </Button>
              )
          }
          <Button type="button" onClick={reset} disabled={loading}>
            {t('profile_address.reset')}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'profile_info_settings_form', // a unique identifier for this form
})(withTranslation('common')(ProfileInfoSettings));
