import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import CustomPieChart from './components/CustomPieChart';
import useTaskTypeReport from './hooks/useTaskTypeReport';
import { useCurrentProject, useProject } from '../Projects/hooks';
import ExpenseGaugeChart from './components/ExpenseGaugeChart';
import { displayCurrency } from '../../../shared/helpers';
import { useCustomer } from '../Customers/hooks';
import { useExpenseTeamReport } from '../Expenses/hooks';
import { useExpensePaymentTeamReport } from '../ExpensePayments/hooks';
import { useCombinedRequest } from '../../../shared/hooks';

const ExpenseGaugePanel = ({ t, projectId, customerId }) => {
  const [projectState, reloadProject] = useProject(projectId);
  const [customerState, reloadCustomer] = useCustomer(customerId);

  const expenseTeamReportRequest = useExpenseTeamReport(!projectId && !customerId);
  const expensePaymentTeamReportRequest = useExpensePaymentTeamReport(!projectId && !customerId);

  const [teamReportState, reloadTeamReport] = useCombinedRequest([
    expenseTeamReportRequest,
    expensePaymentTeamReportRequest,
  ], ([expenseResult, expensePaymentResult]) => ({
    totalExpense: (expenseResult || {}).totalPrice || 0,
    totalExpensePayments: (expensePaymentResult || {}).totalPrice || 0,
  }));

  const {
    loading, loaded, error, result,
    // eslint-disable-next-line no-nested-ternary
  } = projectId
    ? projectState
    : customerId
      ? customerState
      : teamReportState;

  // eslint-disable-next-line no-nested-ternary
  const reload = projectId
    ? reloadProject
    : customerId
      ? reloadCustomer
      : reloadTeamReport;

  const {
    title, name, totalExpense, totalExpensePayments, currency,
  } = result || {};

  // eslint-disable-next-line no-nested-ternary
  const panelTitle = projectId
    ? t('gauge_panel.panel_title.by_project', { title })
    : customerId
      ? t('gauge_panel.panel_title.by_customer', { name })
      : t('gauge_panel.panel_title.overall');

  const [collapsed, setCollapsed] = useState(false);

  return (
    <ControlledPanel
      /* title={`Expense Status for ${title || ''}`} */
      title={panelTitle}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      md={12}
      lg={6}
    >
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {
          loaded ? (
            <ExpenseGaugeChart
              totalExpense={totalExpense || 0}
              totalExpensePayments={totalExpensePayments || 0}
              currency={currency}
            />
          ) : null
        }
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <h5>{t('gauge_panel.total_expense_payments')}: {displayCurrency(totalExpensePayments || 0, currency)}</h5>
          <h5>{t('gauge_panel.total_expense')}: {displayCurrency(totalExpense || 0, currency)}</h5>
        </div>
      </Loading>
    </ControlledPanel>
  );
};

ExpenseGaugePanel.propTypes = {
  t: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
};

ExpenseGaugePanel.defaultProps = {
  projectId: '',
  customerId: '',
};

export default withTranslation('common')(ExpenseGaugePanel);
