import auth from './auth';
import profile from './profile';

const fetcherList = [
  auth,
  profile,
];

export default fetchType => fetcherList
  .reduce((currentFetcher, nextFetcher) => currentFetcher || nextFetcher(fetchType), null);
