/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import TopbarHeader from './topbar_with_navigation/TopbarHeader';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
import Customizer from './customizer/Customizer';
import { BasicNotification } from '../../../shared/components/Notification';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  changeFilterSidebarVisibility,
} from '../../../store/actions/shared/sidebar';
import {
  changeThemeToDark,
  changeThemeToLight,
} from '../../../store/actions/shared/theme';
import {
  changeDirectionToRTL,
  changeDirectionToLTR,
} from '../../../store/actions/shared/rtl';
import {
  changeBorderRadius,
  toggleBoxShadow,
  toggleTopNavigation,
} from '../../../store/actions/shared/customizer';
import {
  CustomizerProps,
  SidebarProps,
  ThemeProps,
  RTLProps,
} from '../../../shared/prop-types/ReducerProps';
import FilterSidebar from '../../../shared/components/filter/FilterSidebar';
import TaskModal from '../Tasks/components/TaskModal';
import ProjectModal from '../Projects/components/ProjectModal';
import CustomerModal from '../Customers/components/CustomerModal';
import EmployeeModal from '../Employees/components/EmployeeModal';
import ExpenseModal from '../Expenses/components/ExpenseModal';
import ExpensePaymentModal from '../ExpensePayments/components/ExpensePaymentModal';
import ExtraModal from '../Extras/components/ExtraModal';
import ReportModal from '../Reports/components/ExtraModal';
import BillModal from '../Bills/components/BillModal';

const notification = null;

const showNotification = (rtl) => {
  notification.notice({
    content: (
      <BasicNotification
        title="👋 Welcome to the EasyDev!"
        message="You have successfully registered in the EasyDev. Now you can start to explore the dashboard
                interface with a bunch of components and applications. Enjoy!"
      />
    ),
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: `right-up ${rtl}-support`,
  });
};

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
    profile: PropTypes.shape({
      isTenantAdmin: PropTypes.bool.isRequired,
      isEmployee: PropTypes.bool.isRequired,
      isSubscribed: PropTypes.bool.isRequired,
      isNotSubscribed: PropTypes.bool.isRequired,
      isEnterpriseSubscribed: PropTypes.bool.isRequired,
      isIndividualSubscribed: PropTypes.bool.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { rtl } = this.props;
    /* NotificationSystem.newInstance(
      { style: { top: 65 } },
      n => (notification = n),
    );
    setTimeout(() => showNotification(rtl.direction), 700); */
  }

  componentWillUnmount() {
    if (notification) {
      notification.destroy();
    }
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeFilterSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeFilterSidebarVisibility());
  }

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  changeToRTL = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToRTL());
  };

  changeToLTR = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToLTR());
  };

  toggleTopNavigation = () => {
    const { dispatch } = this.props;
    dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    const { dispatch } = this.props;
    dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    const { dispatch } = this.props;
    dispatch(toggleBoxShadow());
  };

  render() {
    const {
      customizer, sidebar, theme, rtl, profile, location,
    } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const showCustomizer = queryParams.get('cst');


    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--filter-collapse': sidebar.filterCollapse,
      'layout--top-navigation': false,
    });

    const showEmployeeTab = profile.isTenantAdmin;

    return (
      <div className={layoutClass}>
        { showCustomizer ? (
          <Customizer
            customizer={customizer}
            sidebar={sidebar}
            theme={theme}
            rtl={rtl}
            changeSidebarVisibility={this.changeSidebarVisibility}
            toggleTopNavigation={this.toggleTopNavigation}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeToRTL={this.changeToRTL}
            changeToLTR={this.changeToLTR}
            changeBorderRadius={this.changeBorderRadius}
            toggleBoxShadow={this.toggleBoxShadow}
          />
        ) : null}
        <TopbarWithNavigation
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          showEmployeeTab={showEmployeeTab}
        />
        <TopbarHeader />
        {/* <Sidebar
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeSidebarVisibility={this.changeSidebarVisibility}
        /> */}
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          showEmployeeTab={showEmployeeTab}
        />
        <CustomerModal />
        <EmployeeModal />
        <TaskModal />
        <ExpenseModal />
        <ExtraModal />
        <ReportModal />
        <ExpensePaymentModal />
        <ProjectModal />
        <BillModal />
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
    rtl: state.rtl,
    profile: state.profile,
  }))(Layout),
);
