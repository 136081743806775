import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import BillItem from './BillItem';
import billType from '../types/bill';
import ProjectItem from '../../Projects/components/ProjectItem';

class BillsList extends PureComponent {
  static propTypes = {
    bills: PropTypes.arrayOf(billType.isRequired).isRequired,
    totalBills: PropTypes.number.isRequired,
    toggleEditBill: PropTypes.func.isRequired,
    toggleBillStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currency: '',
  };

  render() {
    const {
      bills, toggleEditBill, toggleBillStatus, promptSoftDelete, totalBills, currency, t,
    } = this.props;
    if (totalBills === 0) {
      return (
        <Card>
          <CardBody>
            {t('bill_list.filter_error')}.
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        {bills && bills.map(bill => (
          <Col xs={12} md={6} xl={4} key={bill._id}>
            <BillItem
              bill={bill}
              toggleEditBill={() => toggleEditBill(bill)}
              toggleBillStatus={() => toggleBillStatus(bill)}
              promptSoftDelete={() => promptSoftDelete(bill)}
              currency={currency}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
export default withTranslation('common')(BillsList);
