/* eslint-disable indent */
import React from 'react';
import { toast } from 'react-toastify';
import {
  LOGIN_ADMIN,
  LOGIN_TENANT,
  GET_PROFILE_ADMIN,
  GET_PROFILE_TENANT,
  CREATE_PROJECT_TENANT,
  VERIFY_EMAIL_TENANT,
  RESEND_EMAIL_VERIFICATION_TENANT,
  REGISTER_TENANT,
  RESET_PASSWORD_TENANT,
  RESET_PASSWORD_CONFIRMATION_TENANT,
  EDIT_PROFILE_TENANT,
  EDIT_PROFILE_ADMIN,
  EDIT_PROFILE_PICTURE_TENANT,
  EDIT_PROFILE_PICTURE_ADMIN,
  EDIT_PROFILE_LOGO_TENANT,
  SOFT_DELETE_TASK_TENANT,
  SOFT_DELETE_PROJECT_TENANT,
  SOFT_DELETE_CUSTOMER_TENANT,
  SOFT_DELETE_EXPENSE_TENANT,
  SOFT_DELETE_EXTRA_TENANT,
  SOFT_DELETE_EXPENSE_PAYMENT_TENANT,
  DELETE_PROFILE_PICTURE_TENANT,
  DELETE_PROFILE_LOGO_TENANT,
  UPDATE_EXPENSE_PHOTO_TENANT,
  DELETE_EXPENSE_PHOTO_TENANT,
  UNSUBSCRIBE_TENANT,
} from './fetchTypes';
import { LOG_OUT } from './sharedTypes';
import Translated from '../shared/components/Translated';

export const fetchErrorToasts = ({ fetchType, error, data }) => {
  window.consoleErrorTranslated(error);
  switch (fetchType) {
    case LOGIN_TENANT:
    case LOGIN_ADMIN:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.login.failed_to_login')
            } ${t(({
              VERIFYEMAIL: 'toasts.errors.login.verify_email',
              NOTACTIVE: 'toasts.errors.login.account_needs_activation',
              NOTFOUND: 'toasts.errors.login.account_not_found',
            })[error] || error)}`}
        </Translated>,
      );

    case REGISTER_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.register.failed_to_register')
            } ${t(({
              USEREXISTS: 'toasts.errors.register.user_exists',
            })[error] || error)}`}
        </Translated>,
      );
    case GET_PROFILE_ADMIN:
    case GET_PROFILE_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_load')
            } ${t(error)}`}
        </Translated>,
      );
    case EDIT_PROFILE_TENANT:
    case EDIT_PROFILE_ADMIN:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_update')
            } ${t(error)}`}
        </Translated>,
      );
    case EDIT_PROFILE_PICTURE_TENANT:
    case EDIT_PROFILE_PICTURE_ADMIN:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_update_picture')
            } ${t(error)}`}
        </Translated>,
      );
    case EDIT_PROFILE_LOGO_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_update_logo')
            } ${t(error)}`}
        </Translated>,
      );
    case DELETE_PROFILE_PICTURE_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_delete_picture')
            } ${t(error)}`}
        </Translated>,
      );
    case DELETE_PROFILE_LOGO_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_delete_logo')
            } ${t(error)}`}
        </Translated>,
      );
    case UNSUBSCRIBE_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.profile.failed_to_unsubscribe')
            } ${t(error)}`}
        </Translated>,
      );
    case VERIFY_EMAIL_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.email_verification.failed_to_verify')
            } ${t(error)}`}
        </Translated>,
      );
    case RESEND_EMAIL_VERIFICATION_TENANT:
    case RESET_PASSWORD_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.email_verification.failed_to_send')
            } ${t(error)}`}
        </Translated>,
      );
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.email_verification.failed_to_reset_password')
            } ${t(error)}`}
        </Translated>,
      );
    case UPDATE_EXPENSE_PHOTO_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.expense.failed_to_update_photo')
            } ${t(error)}`}
        </Translated>,
      );
    case DELETE_EXPENSE_PHOTO_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.expense.failed_to_delete_photo')
            } ${t(error)}`}
        </Translated>,
      );
    default:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.generic.failed')
            } ${t(error)}`}
        </Translated>,
      );
    case SOFT_DELETE_PROJECT_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.soft_delete.matter', { matter: data.title || data._id })
            } ${t(error)}`}
        </Translated>,
      );
    case CREATE_PROJECT_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.create.matter', { matter: data.title || data._id })
            } ${t(error)}`}
        </Translated>,
      );
    case SOFT_DELETE_CUSTOMER_TENANT:
      return toast.error(
        <Translated>
          {t => `
            ${t('toasts.errors.soft_delete.customer', { customer: data.name || data._id })
            } ${t(error)}`}
        </Translated>,
      );
    case SOFT_DELETE_TASK_TENANT:
      // HANDLED ELSEWHERE in watchTaskSoftDelete.jsx
      return null;
    case SOFT_DELETE_EXPENSE_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.soft_delete.expense', { expense: data.title || data._id })
            } ${t(error)}`}
        </Translated>,
      );
    case SOFT_DELETE_EXTRA_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.soft_delete.extra', { extra: data.title || data._id })
            } ${t(error)}`}
        </Translated>,
      );
    case SOFT_DELETE_EXPENSE_PAYMENT_TENANT:
      return toast.error(
        <Translated>
          {t => `${t('toasts.errors.soft_delete.expense_payment', { expensePayment: data.title || data._id })
            } ${t(error)}`}
        </Translated>,
      );
  }
};

export const fetchSuccessToasts = ({ fetchType, success, reqData }) => {
  switch (fetchType) {
    case LOGIN_ADMIN:
    case LOGIN_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.logged_in')}
        </Translated>,
      );
    case LOG_OUT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.logged_out')}
        </Translated>,
      );
    case REGISTER_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.registered')}
        </Translated>,
      );
    case VERIFY_EMAIL_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.verified_email')}
        </Translated>,
      );
    case RESEND_EMAIL_VERIFICATION_TENANT:
    case RESET_PASSWORD_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.verification_code_sent')}
        </Translated>,
      );
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.reset_password')}
        </Translated>,
      );
    case EDIT_PROFILE_TENANT:
    case EDIT_PROFILE_ADMIN:
      return toast.success(
        <Translated>
          {t => t('toasts.success.updated_profile')}
        </Translated>,
      );
    case EDIT_PROFILE_PICTURE_TENANT:
    case EDIT_PROFILE_PICTURE_ADMIN:
      return toast.success(
        <Translated>
          {t => t('toasts.success.updated_profile_picture')}
        </Translated>,
      );
    case EDIT_PROFILE_LOGO_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.updated_profile_logo')}
        </Translated>,
      );
    case DELETE_PROFILE_PICTURE_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_profile_picture')}
        </Translated>,
      );
    case DELETE_PROFILE_LOGO_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_profile_logo')}
        </Translated>,
      );
    case UNSUBSCRIBE_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.unsubscribed')}
        </Translated>,
      );
    case UPDATE_EXPENSE_PHOTO_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.updated_expense_photo')}
        </Translated>,
      );
    case DELETE_EXPENSE_PHOTO_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_expense_photo')}
        </Translated>,
      );
    case SOFT_DELETE_PROJECT_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_matter', { matter: reqData.title || reqData._id })}
        </Translated>,
      );
    case CREATE_PROJECT_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.created_matter', { matter: reqData.title || reqData._id })}
        </Translated>,
      );
    case SOFT_DELETE_CUSTOMER_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_customer', { customer: reqData.name || reqData._id })}
        </Translated>,
      );
    case SOFT_DELETE_TASK_TENANT:
      // HANDLED ELSEWHERE in watchTaskSoftDelete.jsx
      return null;
    case SOFT_DELETE_EXPENSE_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_expense', { expense: reqData.title || reqData._id })}
        </Translated>,
      );
    case SOFT_DELETE_EXTRA_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_extra', { extra: reqData.title || reqData._id })}
        </Translated>,
      );
    case SOFT_DELETE_EXPENSE_PAYMENT_TENANT:
      return toast.success(
        <Translated>
          {t => t('toasts.success.deleted_expense_payment', {
            expensePayment: reqData.title || reqData._id,
          })}
        </Translated>,
      );
    default:
      if (success !== 'SUCCESS' && success !== 'CREATED' && success) {
        return toast.success(
          <Translated>
            {t => t(success)}
          </Translated>,
        );
      }
      return null;
  }
};
