import PropTypes from 'prop-types';

export const CustomizerProps = PropTypes.shape({
  squaredCorners: PropTypes.bool,
  withBoxShadow: PropTypes.bool,
  topNavigation: PropTypes.bool,
});

export const SidebarProps = PropTypes.shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
  filterCollapse: PropTypes.bool,
});

export const ThemeProps = PropTypes.shape({
  className: PropTypes.string,
});

export const RTLProps = PropTypes.shape({
  direction: PropTypes.string,
});
