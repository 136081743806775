import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../Loading';

class LoginForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { handleSubmit, loading, t } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('login.email')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder={t('login.email')}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('login.password')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('login.password')}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/reset_password_request">{t('login.forgot_password')}</Link>
          </div>
        </div>
        { loading ? <Loading className="mb-2" />
          : (
            <button className="btn btn-primary account__btn account__btn--small mt-2" type="submit">
              {t('login.sign_in')}
            </button>
          ) }
        <Link className="btn btn-success account__btn account__btn--small" to="/register">
          {t('login.create_account')}
        </Link>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(withTranslation('common')(LoginForm));
