import { FETCH_REQUEST } from '../../sharedTypes';
import {
  RESET_PASSWORD_CONFIRMATION_TENANT, RESET_PASSWORD_TENANT,
} from '../../fetchTypes';

export const resetPassword = data => ({
  type: FETCH_REQUEST,
  fetchType: RESET_PASSWORD_TENANT,
  data,
});

export const resetPasswordConfirmation = data => ({
  type: FETCH_REQUEST,
  fetchType: RESET_PASSWORD_CONFIRMATION_TENANT,
  data,
});
