/* eslint-disable react/sort-comp */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-ios-switch';
import moment from 'moment';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import Button from 'reactstrap/es/Button';
import { withTranslation } from 'react-i18next';


import DeleteIcon from 'mdi-react/DeleteIcon';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import projectType from '../types/project';
import Loading from '../../../../shared/components/Loading';
import { displayCurrency } from '../../../../shared/helpers';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class ExpensesTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    expenses: PropTypes.arrayOf(projectType.isRequired).isRequired,
    totalExpenses: PropTypes.number.isRequired,
    toggleEditExpense: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleExpenseStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
    currency: '',
  };

  get heads() {
    const { t } = this.props;
    return [
    // { key: 'caseName', name: 'Name', sortable: true },

      { key: 'title', name: 'Title', sortable: true },
      {
        key: 'projectId.customerId.name',
        name: `${t('expense_payment_form.customer')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {row.projectId.customerId.name}
          </div>
        ),
      },
      {
        key: 'projectId.title',
        name: `${t('expense_payment_form.project')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {row.projectId.title}
          </div>
        ),
      },
      {
        key: 'appliedDate',
        name: `${t('expense_payment_form.applied_date')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {moment(row.appliedDate).fromNow()}
          </div>
        ),
      },
      {
        key: 'amount',
        name: `${t('expense_payment_form.amount')}`,
        sortable: true,
        formatter: ({ row }) => {
          const { currency } = this.props;
          return (
            <div>
              {displayCurrency(row.amount, row.currency || currency)}
            </div>
          );
        },
      },

      // {
      //   key: 'created',
      //   name: 'Created At',
      //   sortable: true,
      //   formatter: ({ value }) => (
      //     <div>
      //       {`${moment(value).fromNow()}`}
      //     </div>
      //   ),
      // },
      // { key: 'peopleInCharge', name: 'People In Charge', formatter: simpleFormatter },

      // { key: 'taxOffice', name: 'Tax Office', formatter: simpleFormatter },
      {
        key: 'actions',
        name: `${t('expense_payment_form.actions')}`,
        width: 176,
        /* cellClass: 'Expenses-page__details-cell', */
        formatter: ({ row }) => (
          <div>
            <Button
              type="button"
              size="sm"
              className="icon"
              outline
              onClick={() => this.toggleEditExpense(row)}
            >
              <p><SquareEditOutlineIcon /> {t('expense_payment_form.edit')}</p>
            </Button>
            <Button
              type="button"
              size="sm"
              className="icon"
              color="danger"
              onClick={() => this.promptSoftDelete(row)}
            >
              <p><DeleteIcon />{t('expense_payment_form.delete')}</p>
            </Button>
          </div>
        ),
      },
    ];
  }

  toggleEditExpense = (Expense) => {
    const { toggleEditExpense } = this.props;
    toggleEditExpense(Expense);
  };

  promptSoftDelete = (expense) => {
    const { promptSoftDelete } = this.props;
    promptSoftDelete(expense);
  };

  toggleExpenseStatus = (Expense) => {
    const { toggleExpenseStatus } = this.props;
    toggleExpenseStatus(Expense);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, expenses, totalExpenses, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={expenses}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalExpenses === 0 ? (
              <div>
                {t('expense_list.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

export default withTranslation('common')(ExpensesTable);
