import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import {
  Container, Row, Col,
  Nav, NavItem, NavLink, ButtonToolbar, Button,
} from 'reactstrap';
import classNames from 'classnames';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import RenderSelectField from '../../../../shared/components/form/Select';
import renderField from '../../../../shared/components/form/FormField';
import RenderDateTimePickerField from '../../../../shared/components/form/DateTimePicker';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import Loading from '../../../../shared/components/Loading';

import { feeUnits, currencies, defaultCurrencyObject } from '../../Projects/constants/options';
import UploadableImage from '../../../../shared/components/UploadableImage/UploadableImage';

import { getCustomersList, getProjectsList } from '../../../../store/api/lookup';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderTextArea from '../../../../shared/components/form/TextArea';


const baseTasksOptions = [
  { label: 'None', value: null },
];

class ExpensePaymentForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      projectId: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      parentId: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      selectedProject: PropTypes.shape(),
    }).isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    changeFormField: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    customers: [],
    projects: [],
    projectsById: {},
  };


  customerRequired = isRequired('expense_payment_form.customer');

  matterRequired = isRequired('expense_payment_form.matter');

  /* titleRequired = isRequired('Title'); */

  amountRequired = isRequired('expense_payment_form.amount');

  currencyRequired = isRequired('expense_payment_form.currency');

  descriptionRequired = isRequired('expense_payment_form.description');

  componentDidMount() {
    const { initialValues } = this.props;
    const {
      customerId, projectId, selectedProject,
    } = initialValues;
    if (projectId) {
      this.handleProjectSelect(projectId, selectedProject);
    }
    if (customerId) {
      this.handleCustomerSelect(customerId);
    }

    getCustomersList()
      .then((customers) => {
        if (customers) {
          this.setState({
            customers: customers.map(({ _id, name }) => ({ label: name, value: _id })),
          });
        }
      });
  }

  handleCustomerSelect = (customer) => {
    const { value } = customer;
    getProjectsList(value)
      .then((projects) => {
        if (projects) {
          this.setState({
            projects: projects.map(({ _id, title }) => ({ label: title, value: _id })),
            projectsById: projects.reduce((acc, project) => ({ ...acc, [project._id]: project }), {}),
          });
        }
      });
  };

  handleProjectSelect = (project, selectedProject) => {
    const { changeFormField } = this.props;
    const { projectsById } = this.state;
    const { value } = project;
    // eslint-disable-next-line no-param-reassign
    selectedProject = selectedProject || projectsById[value] || {};
  };

  uploadableImageField = (props) => {
    const { t } = this.props;
    const {
      input, uploadable, className, title, placeholderImage, shape,
    } = props;
    const {
      onChange, value, name, meta,
    } = input;
    return (
      <div className={`form__form-group-input-wrap ${className || ''}`}>
        <UploadableImage
          uploadable={uploadable}
          onChange={onChange}
          imageSource={value}
          title={title}
          name={name}
          placeholderImage={placeholderImage}
          shape={shape}

        />
        {meta && meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
      </div>
    );
    /**
     * uploadable: bool (default: false)
     * placeholderImage: string (url/src to default image)
     * placeholderImageElement: react element (default null)
     * imageSource: string (url/src to current image)
     * onChange: function(imageFile):void
     * title: string (default: 'image')
     * shape: 'circle' | 'rounded' | 'square' (default: rounded)
     */
  }

  render() {
    const {
      handleSubmit, close, loading, isNew, t,
    } = this.props;
    const {
      customers, projects,
    } = this.state;
    return (
      <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="mb-3">
                {isNew ? t('expense_payment_form.add') : t('expense_payment_form.edit')}
                {' '}
                {t('expense_payment_form.expense_payment')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_payment_form.customer')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="customerId"
                    component={RenderSelectField}
                    placeholder={t('expense_payment_form.customer')}
                    options={customers}
                    extraOnChange={this.handleCustomerSelect}
                    validate={this.customerRequired}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_payment_form.matter')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="projectId"
                    component={RenderSelectField}
                    placeholder={t('expense_payment_form.matter')}
                    options={projects}
                    extraOnChange={this.handleProjectSelect}
                    validate={this.matterRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">Title</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    placeholder="Title"
                    validate={this.titleRequired}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_payment_form.amount')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="amount"
                    component={renderField}
                    type="number"
                    placeholder={t('expense_payment_form.amount')}
                    validate={this.amountRequired}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.currency')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="currency"
                    component={RenderSelectField}
                    placeholder={t('project_form.currency')}
                    options={currencies}
                    defaultValue={defaultCurrencyObject}
                    type="String"
                    validate={this.currencyRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('expense_payment_form.applied_date')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="appliedDate"
                    component={RenderDatePickerField}
                  />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_payment_form.description')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    component={renderTextArea}
                    type="text"
                    placeholder={t('expense_payment_form.description')}
                    validate={this.descriptionRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Loading loading={loading}>
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('expense_payment_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew
                      ? t('expense_payment_form.add_expense_payment')
                      : t('expense_payment_form.update_expense_payment')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const mapDispatchToProps = {
  changeFormField: (field, value) => change('tenant_expense_payment_form', field, value),
};

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'tenant_expense_payment_form',
  })(withTranslation('common')(ExpensePaymentForm)),
);
