import PropTypes from 'prop-types';

const customer = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  peopleInCharge: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  taxNumber: PropTypes.string,
  taxOffice: PropTypes.string,
  addresses: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  phones: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  isActive: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
  totalExpensePayments: PropTypes.number,
  totalExpense: PropTypes.number,
});

export default customer;
