import React from 'react';
import { useRequest } from '../../../../shared/hooks';

const fixedParams = {
  start: 0,
  limit: 400,
};

const params = { includeAdmin: 1 };

function useEmployeeList() {
  return useRequest(
    data => data.users.users.map(({
      _id, fname, lname, picture, logo,
    }) => ({
      _id, fname, lname, picture, logo,
    })),
    'tenants/employees',
    useRequest.methods.GET,
    params,
  );
}

export default useEmployeeList;
