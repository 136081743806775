import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import ExpenseForm from './ExpenseForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';

class ExpenseModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createExpenseRequest: PropTypes.func.isRequired,
    updateExpenseRequest: PropTypes.func.isRequired,
    editExpense: PropTypes.func.isRequired,
    editingExpense: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingExpense: null,
  };

  handleSubmit = (data) => {
    const payload = {};
    const { createExpenseRequest, updateExpenseRequest, editingExpense } = this.props;
    ['projectId', 'taskId']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value || null;
        }
      });
    ['expenseType', 'currency'].forEach((key) => {
      if (data[key] && typeof data[key] === 'object') {
        payload[key] = data[key].value || '';
      }
    });
    ['amount', 'appliedDate', 'description']
      .forEach((key) => {
        payload[key] = data[key];
      });

    if (payload.description) {
      payload.title = payload.description;
      if (payload.title.length > 40) {
        payload.title = `${payload.title.substr(0, 36)} ...`;
      }
    }

    if (editingExpense._id === 'new') {
      const formData = new FormData();
      if (data.picture) {
        formData.append('picture', data.picture);
      }
      Object.entries(payload)
        .forEach(([key, value]) => {
          if (value) {
            formData.append(key, value);
          }
        });
      createExpenseRequest(formData);
    } else {
      payload._id = editingExpense._id;
      updateExpenseRequest(payload);
    }
  };

  render() {
    const {
      updating, editingExpense, theme, rtl, editExpense,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingExpense}
        toggle={() => editExpense(null)}
        className={modalClasses}
      >
        {editingExpense && (
          <ExpenseForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editExpense(null)}
            initialValues={editingExpense}
            isNew={editingExpense._id === 'new'}
          />
        )}
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.expenses.updating,
  editingExpense: state.expenses.editingExpense,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createExpenseRequest: TenantActions.Expenses.createExpenseRequest,
  updateExpenseRequest: TenantActions.Expenses.updateExpenseRequest,
  editExpense: TenantActions.Expenses.editExpense,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseModal);
