import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line react/prefer-stateless-function
class ProjectsLeftSidebar extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props;
    return (

      <div>
        <h4>{t('projects.page_title')}</h4>
        <p>{t('projects.p1')}</p>
        <p>{t('projects.p2')}</p>
        <p>{t('projects.p3')}</p>
        <p>{t('projects.p4')}</p>
        <p>{t('projects.p5')}</p>
        <p>{t('projects.p6')}</p>
        <p>{t('projects.p7')}</p>
        <p>{t('projects.p8')}</p>
      </div>
    );
  }
}

export default withTranslation('common')(ProjectsLeftSidebar);
