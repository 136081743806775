import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import EmailIcon from 'mdi-react/EmailIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';

import { withTranslation } from 'react-i18next';
import TenantActions from '../../../store/actions/tenant';

import EmailVerificationForm from './components/EmailVerificationForm';

const useQuery = () => new URLSearchParams(useLocation().search);

const EmailVerification = ({
  t, loading, error, verifyEmail,
}) => {
  const query = useQuery();
  const verificationToken = query.get('token');

  useEffect(() => {
    if (verificationToken && !loading && !error) {
      verifyEmail({ token: verificationToken });
    }
  });

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">{t('email_verification_form_index.welcome')}
              <span className="account__logo">
                <span className="account__logo-accent">!</span>
              </span>
            </h3>
            <h4 className="account__subhead">{t('email_verification_form_index.verify_email')}</h4>
          </div>
          <EmailVerificationForm
            loading={loading}
            initialValues={{ token: verificationToken }}
            onSubmit={({ token }) => verifyEmail({ token })}
          />
        </div>
      </div>
    </div>
  );
};

EmailVerification.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  verifyEmail: PropTypes.func.isRequired,
};

EmailVerification.defaultProps = {
  error: '',
};

const mapStateToProps = state => ({
  loading: state.emailVerification.loading,
  error: state.emailVerification.error,
});

const mapDispatchToProps = {
  verifyEmail: TenantActions.EmailVerification.verifyEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation('common')(EmailVerification),
);
