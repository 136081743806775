import admin from './admin';
import tenant from './tenant';

const fetcherList = [
  admin,
  tenant,
];

export const handleFetch = (fetchType, data) => {
  const fetcher = fetcherList.reduce((currentFetcher, nextFetcher) => currentFetcher || nextFetcher(fetchType), null);
  if (!fetcher) {
    throw new Error(`Fetch type, ${fetchType} is not correct!`);
  }

  return fetcher(data)
    .then((response) => {
      if (response.data.success) {
        return response.data.success;
      }
      const err = new Error();
      err.response = response;
      throw err;
    }).catch((error) => {
      const niceError = new Error();
      if (error.response) {
        if (typeof error.response.data === 'object' && error.response.data !== null) {
          if (error.response.data.error) {
            niceError.code = error.response.data.error.code;
            niceError.type = error.response.data.error.type;
            niceError.message = error.response.data.error.message;
          } else {
            niceError.code = error.response.status;
            niceError.type = error.response.statusText;
            niceError.message = error.message;
          }
        } else {
          niceError.code = error.response.status;
          niceError.type = error.response.statusText;
          niceError.message = error.data || 'toasts.errors.generic.server_error';
        }
      } else if (error.request) {
        niceError.code = 500;
        niceError.type = 'ConnectionError';
        niceError.message = 'toasts.errors.generic.connection_error';
      } else {
        niceError.code = 400;
        niceError.type = 'UnknownError';
        niceError.details = error.message;
        niceError.message = `$t(toasts.errors.generic.unknown_error, {"error":"${error.message}"})`;
      }
      throw niceError;
    });
};
