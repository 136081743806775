import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailIcon from 'mdi-react/MailIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../../shared/components/Loading';

const EmailVerificationForm = ({ t, handleSubmit, loading }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">{t('email_verification_form.verification')}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <MailIcon />
        </div>
        <Field
          name="token"
          component="input"
          type="text"
          placeholder="Verification Code"
        />
      </div>
      <div className="account__forgot-password">
        <Link to="/login">{t('email_verification_form.login')}</Link>
        {' | '}
        <Link to="/register">{t('email_verification_form.register')}</Link>
      </div>
    </div>
    {loading ? <Loading className="mb-2" />
      : (
        <button
          className="btn btn-primary account__btn account__btn--small mt-2"
          type="submit"
        >{t('email_verification_form.verify_email')}
        </button>
      )}
    <Link
      className="btn btn-outline-primary account__btn account__btn--small"
      to="/resend_email_verification"
    >{t('email_verification_form.resend_email')}
    </Link>

  </form>
);

EmailVerificationForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'email_verification_form',
})(withTranslation('common')(EmailVerificationForm));
