import { FETCH_ERROR, FETCH_REQUEST, FETCH_SUCCESS } from '../sharedTypes';

const defaultDefaultReducer = state => state;

/**
 *
 * @param initialState
 * @param fetchRequestReducer: Function
 * @param fetchSuccessReducer: Function
 * @param fetchErrorReducer: Function
 * @param defaultReducer: Function
 * @return {Function}
 */
export default (initialState, fetchRequestReducer, fetchSuccessReducer,
  fetchErrorReducer, defaultReducer = defaultDefaultReducer) => (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return fetchRequestReducer(state, action);
    case FETCH_SUCCESS:
      return fetchSuccessReducer(state, action);
    case FETCH_ERROR:
      return fetchErrorReducer(state, action);
    default:
      return defaultReducer(state, action);
  }
};
