import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ExpenseItem from './ExpenseItem';
import expenseType from '../types/project';
import CustomerItem from '../../Customers/components/CustomerItem';

class ExpensesList extends PureComponent {
  static propTypes = {
    expenses: PropTypes.arrayOf(expenseType.isRequired).isRequired,
    totalExpenses: PropTypes.number.isRequired,
    toggleEditExpense: PropTypes.func.isRequired,
    toggleExpenseStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currency: '',
  };

  render() {
    const {
      expenses, toggleEditExpense, toggleExpenseStatus, totalExpenses, currency, promptSoftDelete, t,
    } = this.props;
    if (totalExpenses === 0) {
      return (
        <Card>
          <CardBody>
            {t('extra_expenses_list.filter_error')}.
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        {expenses && expenses.map(expense => (
          <Col xs={12} md={6} xl={4} key={expense._id}>
            <ExpenseItem
              expense={expense}
              toggleEditExpense={() => toggleEditExpense(expense)}
              toggleExpenseStatus={() => toggleExpenseStatus(expense)}
              currency={currency}
              promptSoftDelete={() => promptSoftDelete(expense)}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
export default withTranslation('common')(ExpensesList);
