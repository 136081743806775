import {
  CREATE_EMPLOYEE_TENANT, GET_EMPLOYEES_TENANT, GET_CURRENT_EMPLOYEE_TENANT,
  UPDATE_EMPLOYEE_TENANT, RELOAD_EMPLOYEE_TENANT,
  DELETE_EMPLOYEE_TENANT, DELETE_EMPLOYEE_WITH_CHILDREN_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_EMPLOYEES_TENANT:
      return params => makeFetcher('/tenants/employees', 'GET', params);
    case GET_CURRENT_EMPLOYEE_TENANT:
      return params => makeFetcher(`/tenants/employees/${params._id}`, 'GET');
    case RELOAD_EMPLOYEE_TENANT:
      return data => makeFetcher(`/tenants/employees/${data._id}`, 'GET');
    case CREATE_EMPLOYEE_TENANT:
      return data => makeFetcher('/tenants/employees', 'POST', data);
    case UPDATE_EMPLOYEE_TENANT:
      return ({ _id, ...data }) => makeFetcher(`/tenants/employees/${_id}`, 'POST', data);
    case DELETE_EMPLOYEE_TENANT:
      return data => makeFetcher(`/tenants/employees/${data._id}`, 'DELETE');
    case DELETE_EMPLOYEE_WITH_CHILDREN_TENANT:
      return data => makeFetcher(`/tenants/employees/${data._id}/delete-child`, 'DELETE');
    default:
      return null;
  }
};
