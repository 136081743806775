import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import IOSSwitch from 'react-ios-switch';
import ReloadIcon from 'mdi-react/ReloadIcon';
import Container from 'reactstrap/es/Container';
import Button from 'reactstrap/es/Button';
import { useParams } from 'react-router';
import CaseCard from './CaseCard';
import { caseType } from './caseType';
import * as CaseActions from '../../../../store/actions/tenant/cases';
import Loading from '../../../../shared/components/Loading';

const CaseList = ({
  cases, getCases, addCase, loading, loaded, error, firstActiveIndex, canAppeal, t,
}) => {
  const { matterId } = useParams();
  const fetchCases = () => {
    getCases({ matterId });
  };

  useEffect(() => {
    fetchCases();
  }, [matterId]);
  useEffect(() => {
    if (!loading && loaded && cases.length === 0) {
      addCase();
    }
  }, [cases, loaded, loading]);

  return (
    <Container>
      <Loading loading={loading} loaded={loaded} error={error} retry={fetchCases}>
        <div>
          { canAppeal && (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 16,
          }}
          >
            <Button color="primary" onClick={addCase}>
              <span className="mr-1">
                {t('case_list.appeal')}
              </span>
            </Button>
          </div>
          )}
          <div className="timeline">

            {cases.slice(firstActiveIndex).map((id, index, allCases) => (
              <CaseCard
                id={id}
                key={id}
                canRemove={(index === 0 && allCases.length > 1)}
              />
            ))}
          </div>
        </div>
      </Loading>
    </Container>
  );
};

CaseList.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  addCase: PropTypes.func.isRequired,
  getCases: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  firstActiveIndex: PropTypes.number.isRequired,
  canAppeal: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CaseList.defaultProps = {
  error: '',
};

const mapStateToProps = (state) => {
  const { firstActiveIndex } = state.cases;
  const firstActiveId = state.cases.cases[firstActiveIndex];
  const firstCase = state.cases.casesById[firstActiveId] || {};
  const canAppeal = !!((firstCase.details || {}).isComplete);
  return {
    cases: state.cases.cases,
    loading: state.cases.loading,
    loaded: state.cases.loaded,
    error: state.cases.error,
    firstActiveIndex: state.cases.firstActiveIndex,
    canAppeal,
  };
};

const mapDispatchToProps = {
  addCase: CaseActions.addCase,
  getCases: CaseActions.getCasesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CaseList));
