import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../prop-types/ReducerProps';

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
    heads: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      disablePadding: PropTypes.bool,
      label: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  };

  static defaultProps = {
    onRequestSort: null,
    order: '',
    orderBy: '',
  };

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rtl, heads, onRequestSort,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              className={`material-table__checkbox ${numSelected === rowCount
                && rowCount && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={!!(numSelected === rowCount && rowCount)}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {heads.map(row => (
            <TableCell
              className="material-table__cell material-table__cell--sort material-table__cell-right"
              key={row.id}
              align={rtl.direction === 'rtl' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              {onRequestSort ? (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {row.label}
                </TableSortLabel>
              ) : row.label}
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(MatTableHead);
