import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TopbarMenuLink from './TopbarMenuLink';
import SharedActions from '../../../../store/actions/shared';
import TenantActions from '../../../../store/actions/tenant';
import Loading from '../../../../shared/components/Loading';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    profile: PropTypes.shape({
      loaded: PropTypes.bool.isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      profile: PropTypes.shape({
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
      }),
    }).isRequired,
    logOut: PropTypes.func.isRequired,
    profileRequest: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  componentDidMount() {
    this.maybeReloadProfile();
  }

  componentDidUpdate() {
    this.maybeReloadProfile();
  }

  maybeReloadProfile = () => {
    const { profile, profileRequest } = this.props;
    if (!profile.loaded && !profile.loading && !profile.error) {
      profileRequest();
    }
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { logOut } = this.props;
    logOut();
  };

  render() {
    const { collapse } = this.state;

    const { profile: { loading: profileLoading, profile, error }, t } = this.props;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar align-items-center" onClick={this.toggle}>
          <Loading loading={profileLoading} size="sm">
            <img className="topbar__avatar-img" src={profile.picture || Ava} alt="avatar" />
            <p className="topbar__avatar-name">{profile.fname} {profile.lname}</p>
          </Loading>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title={t('profile_topbar.my_profile')}
              icon="user"
              path="/profile"
              onClick={this.toggle}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title={t('profile_topbar.logout')} icon="exit" path="#" onClick={this.logOut} />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});

const mapDispatchToProps = {
  logOut: SharedActions.Auth.logOut,
  profileRequest: TenantActions.Profile.profileRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopbarProfile));
