import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withTranslation } from 'react-i18next';
import * as Countries from '../../../../lib/Countries';
import RenderSelectField from '../../../../shared/components/form/Select';
import Loading from '../../../../shared/components/Loading';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import renderField from '../../../../shared/components/form/FormField';
import {
  chamberRegistrationNumber, idNumber, isRequired, phoneNumber,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import RenderCheckBoxField from '../../../../shared/components/form/CheckBox';


class RegisterFormTwo extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  fnameRequired = isRequired('register.first_name');

  lnameRequired = isRequired('register.last_name');

  constructor(props) {
    super(props);
    this.state = {
      cityList: [],
      defaultPhoneCodeCountry: 'tr',
    };
    this.countryList = Countries.countriesWithCities.map(countryName => ({
      value: countryName,
      label: countryName,
    }));
    this.countryCodeList = Countries.phoneCodeList
      .map(({ countryName, countryPhoneCode }) => ({
        value: countryPhoneCode,
        label: `${countryName} (${countryPhoneCode})`,
      }));
  }

  selectCountry = (e, { value }) => {
    this.setState({
      cityList: Countries.citiesByCountry(value)
        .map(cityName => ({
          value: cityName,
          label: cityName,
        })),
      defaultPhoneCodeCountry: Countries.countryCodeByCountry(value).toLowerCase(),
    });
  };

  render() {
    const {
      handleSubmit, previousPage, loading, t,
    } = this.props;
    const { cityList, defaultPhoneCodeCountry } = this.state;
    return (
      <form className="form wizard__form" onSubmit={handleSubmit}>
        {/* <h3 className="wizard__title">Please, fill your personal details</h3> */}
        <div className="form__form-group">
          <span className="form__form-group-label required">{t('register.name')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="fname"
              component={renderField}
              type="text"
              placeholder={t('register.first_name')}
              validate={this.fnameRequired}
            />

          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label required">{t('register.last_name')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>

            <Field
              name="lname"
              component={renderField}
              type="text"
              placeholder={t('register.last_name')}
              validate={this.lnameRequired}
            />
          </div>
          <div className="account__have-account">

            <Field
              name="clarification"
              validate={value => (value
                ? undefined
                : t('field_warnings.is_required'))}
              label={(
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://advopass.com/wp-content/uploads/2020/06/advopass-clarification-text.pdf"
                  >
Aydınlatma metnini
                  </a>{' '} okudum, anladım.
                </p>
)}
              component={RenderCheckBoxField}
              className="colored-click"
            />

            {/* <p>{t('register.already_have_an_account')} <Link to="/login">{t('register.login')}</Link></p> */}
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={previousPage}
          >
            {t('register.previous')}
          </Button>
          {loading ? (
            <Loading />
          ) : (
            <Button color="primary" type="submit" className="next">
              {t('register.submit')}
            </Button>
          )}
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'register_tenant_form', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(RegisterFormTwo));
