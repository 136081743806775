import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'bootstrap/dist/css/bootstrap.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../scss/app.scss';
import '../../lib/Toaster';
import Router from './Router';
import store, { dispatch } from '../../store';
import ScrollToTop from './ScrollToTop';
import * as LanguageActions from '../../store/actions/shared/language';
import { config as i18nextConfig, localizeMoment, localizeDatePicker } from '../../translations';
import Headers from './Headers';

let firstLanguageChange = true;

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (lng) => {
    const locale = lng.substr(0, 2).toLowerCase();

    if (firstLanguageChange) {
      // make sure to set it on html before any render so that
      // css text-uppercase will work with correct locale
      // doing with helmet is not fast enough so did it as below
      const html = document.documentElement;
      html.setAttribute('lang', locale);
      firstLanguageChange = false;
    }

    // console.log('languageChanged', lng, locale);
    dispatch(LanguageActions.changeLanguage(locale));
    return Promise.all([
      localizeMoment(locale),
      localizeDatePicker(locale),
    ]);
  })
  .init(i18nextConfig, (err, t) => {
    // for console error only, below isn't reasonable to use in many places.
    window.consoleErrorTranslated = (e, ...args) => console.error(t(e, ...args));
  });

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <ScrollToTop>
              <Fragment>
                {!loaded && (
                  <div className={`load${loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                  </div>
                )}
                <Headers />
                <div>
                  <Router />
                </div>
              </Fragment>
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
