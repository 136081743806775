import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


const TopbarNav = ({ showEmployeeTab, t }) => {
  const [steps, setSteps] = useState([
    {
      target: '.passboard',
      content: 'Bu sekme sizin çalışmalarınızı ve gelirlerinizi takip ettiğiniz sayfadır.',
    },
    {
      target: '.customers',
      content: 'Bu sayfada tüm müvekkillerinizi görebilirsiniz.',
    },
    {
      target: '.matters',
      content: 'Bu ekran altında, ilgili İş ile ilgili tüm detayları görebilirsiniz.',
    },
    {
      target: '.tasks',
      content: 'Bu ekran altinda, gorevlerinizi ekleyebilir ve duzenleyebilirsiniz.',
    },

  ]);
  const [runJoyride, setrunJoyride] = useState(localStorage.getItem('NavigationJoyride') || false);
  useEffect(() => {
    if (runJoyride !== 'false') setrunJoyride(true);
  }, []);
  const handleCallback = (({ action, ...props }) => {
    console.log('props', props);
    console.log(localStorage.getItem('NavigationJoyride'));
    if (action === 'reset') {
      localStorage.setItem('NavigationJoyride', 'false');
    }
  });
  return (
    <nav className="topbar__nav">
      <NavLink className="topbar__nav-link passboard" activeClassName="active" to="/passboard">
        {t('main_sidebar.dashboard')}
      </NavLink>
      <NavLink className="topbar__nav-link customers" activeClassName="active" to="/customers">
        {t('main_sidebar.all_customers')}
      </NavLink>
      <NavLink className="topbar__nav-link matters" activeClassName="active" to="/matters">
        {t('main_sidebar.all_matters')}
      </NavLink>
      <NavLink className="topbar__nav-link tasks" activeClassName="active" to="/tasks">
        {t('main_sidebar.all_tasks')}
      </NavLink>
      {
      showEmployeeTab ? (
        <NavLink className="topbar__nav-link" activeClassName="active" to="/employees">
          {t('main_sidebar.all_employees')}
        </NavLink>
      ) : null
    }
      <Joyride
        callback={handleCallback}
        continuous
        run={runJoyride === true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            backgroundColor: '#004032',
          },
          beaconInner: {
            backgroundColor: '#ffbf00',
          },
          beaconOuter: {
            borderColor: '#ffbf00',
            backgroundColor: '#ffbf0002',
          },
          buttonBack: {
            color: '#004032',
          },
          buttonSkip: {
            backgroundColor: '#004032',
          },

        }}
        locale={{
          back: 'Geri', close: 'Kapat', last: 'Bitir', next: 'İleri', open: 'Göster', skip: 'Geç',
        }}
        scrollToFirstStep
      />
    </nav>
  );
};

TopbarNav.propTypes = {
  showEmployeeTab: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(TopbarNav));
