const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const statuses = [
  { label: 'report_options.statuses.processing', value: 'Processing' },
  { label: 'report_options.statuses.finished', value: 'Finished' },
  { label: 'report_options.statuses.failed', value: 'Failed' },
];

export const statusToLabel = toLabel(statuses, 'Unknown');
/*

const statusColors = [
  { value: '100', label: 'info' },
  { value: '101', label: 'primary' },
  { value: '200', label: 'success' },
];
export const statusToColor = toLabel(statusColors);

export const activeStatusFilters = [
  { value: '0', label: 'All Tasks' },
  { value: '1', label: 'Active Tasks' },
  { value: '-1', label: 'Inactive Tasks' },
];
*/
