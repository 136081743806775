/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import CalendarIcon from 'mdi-react/CalendarIcon';
import EditIcon from 'mdi-react/EditIcon';
import classNames from 'classnames';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import expenseTypes from '../types/project';
import { displayCurrency } from '../../../../shared/helpers';
// import { tenureTypeToLabel, categoryToLabel, billingTypeToLabel } from '../constants/options';

class ExpenseItem extends Component {
  static propTypes = {
    /* actions: PropTypes.shape({
      editexpenses-page: PropTypes.func,
      completeexpenses-page: PropTypes.func,
      deleteexpenses-page: PropTypes.func,
    }).isRequired, */
    expense: expenseTypes.isRequired,
    toggleEditExpense: PropTypes.func.isRequired,
    toggleExpenseStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currency: '',
  };

  constructor(props) {
    super(props);

    const { expense } = this.props;

    /*
    this.state = {
      title: expenses-page.title,
      completed: expenses-page.completed,
      description: expenses-page.description,
      priority: expenses-page.priority,
    };
    */
  }

  handleComplete() {
    const { expense } = this.props;
    // const { checked } = this.state;

    /*
    actions.completeexpenses-page(expenses-page.id);
    this.setState({
      checked: !checked,
    });
     */
  }

  handleDelete() {
    const { expense } = this.props;

    // actions.deleteexpenses-page(expenses-page.id);
  }

  /*
  handleEdit() {
    const { expenses-page, actions } = this.props;

    actions.editexpenses-page(expenses-page.id);
  }

  handleUpdate() {
    const { expenses-page, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updateexpenses-page(expenses-page.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }

 */

  render() {
    /*
    const {
      title, completed, description, priority,
    } = this.state;
    */

    /*
    const priorityColorsClass = classNames({
      'expenses-page__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
     */
    const {
      expense, toggleEditExpense, toggleExpenseStatus, promptSoftDelete, currency, t,
    } = this.props;
    const {
      _id, projectId, taskId, title, expenseType, description, amount, picture, currency: itemCurrency,
    } = expense;


    return (
      <Card>
        <CardBody className="customers-page__item pt-0">
          <button
            className="btn btn-secondary customers-page__delete-btn align-self-right"
            type="button"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: '30px',
              height: '30px',
              alignItems: 'center',
              padding: 'initial',
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#000',
              color: '#000',
            }}
            onClick={toggleEditExpense}
          >
            <EditIcon className="w-100" style={{ color: '#000' }} />
          </button>
          <fieldset style={{ borderWidth: 1, borderColor: '#000', borderStyle: 'solid' }} className="w-100">
            <legend style={{
              width: 'unset',
              whiteSpace: 'nowrap',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
            >
              {title}

            </legend>

            {/* <label htmlFor={`customer_${_id}_is_active_`} className="customers-page__label-checkbox">
              <input
                id={`customer_${_id}_is_active_`}
                type="checkbox"
                className="customers-page__complete-toggle"
                defaultChecked={isActive}
                required
                onClick={toggleCustomerStatus}
              />
              <span className="checkbox-indicator" />
            </label> */}
            <div className="customers-page__info">

              <div className="customers-page__header">
                {/* <h3>{name}</h3> */}
                <div className="customers-page__additional d-block m-auto">

                  <span className="customers-page__priority d-inline-block p-0 ml-1 h5">
                    {projectId.customerId.name}
                  </span>


                </div>
                <div className="customers-page__additional d-block m-auto">

                  <span className="customers-page__priority d-inline-block p-1 m-1 font-weight-bold">
                    {projectId.title}
                  </span>
                  {/* <span className="customers-page__priority d-inline-block p-1 m-1">
                    {isActive ? 'Active' : 'Not Active'}
                  </span> */}

                  <span className="customers-page__due-date d-inline-block p-1 m-1 font-weight-bold">
                    {/* {tenureTypeToLabel(tenureType)} */}
                  </span>
                  {/* <div className="w-100 text-right"> */}
                  {/* <span className="customers-page__due-date d-inline-block p-1 m-1 mt-2">
                      ({customer.name})
                    </span> */}
                  <span className="customers-page__due-date d-inline-block p-1 m-1 mt-2 font-weight-bold">
                    {/* {`${moment(proposalDate).fromNow()}`} <CalendarIcon size="18" /> */}
                  </span>
                  {/* </div> */}
                </div>

              </div>
              <div className="separator" />
              <div className="customers-page__content">
                <div className="customers-page__description customers-page__details my-2">

                  {/* {caseName && (
                    <div>
                      <b>Case Name: </b>
                      <span>{caseName}</span>
                    </div>
                  )
                  }
                  {caseNumber
                    && (
                      <div>
                        <b>Case Number: </b>
                        <span>{caseNumber}</span>
                      </div>
                    )
                  }
                  {meritsNumber
                    && (
                      <div>
                        <b>Merits Number: </b>
                        <span>{meritsNumber}</span>
                      </div>
                    )
                  }
                  {status
                    && (
                      <div>
                        <b>Status: </b>
                        <span>{status}</span>
                      </div>
                    )
                  }
                  {tenureType
                    && (
                      <div>
                        <b>Tenure Type: </b>

                      </div>
                    )
                  } */}


                </div>
                <div className="customers-page__description my-2">
                  {/* <h4>Addresses</h4> */}
                  <ul>

                    <li>
                      {/* <CashIcon size="18" />{' '} */}
                      {t('expense_item.amount')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(amount, itemCurrency || currency)}
                      </span>
                    </li>
                    <li>
                      {t('expense_item.description')}:
                      <span style={{ fontSize: '14px' }}>
                        {description}
                      </span>
                    </li>
                  </ul>
                </div>
                {picture && (
                <div className="customers-page__description my-2">
                  {/* <h4>Addresses</h4> */}
                  <span>{t('expense_item.picture')}: </span>
                  <img src={picture} alt="img" style={{ maxHeight: 200, maxWidth: 150 }} />
                </div>
                )}
              </div>
            </div>
            <div
              className={`my-1 ${''
              }d-flex flex-wrap justify-content-center align-items-center w-100`}
            >
              <Button
                type="button"
                className="icon m-1"
                outline
                onClick={toggleEditExpense}
              >
                <p><SquareEditOutlineIcon /> {t('customer_item.edit')}</p>
              </Button>
              <Button
                type="button"
                color="danger"
                className="icon m-1"
                onClick={promptSoftDelete}
              >
                <p style={{ color: '#fff' }}>
                  <DeleteIcon /> {t('customer_item.delete')}
                </p>
              </Button>
            </div>
          </fieldset>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(ExpenseItem);
