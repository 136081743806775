import { createStore, applyMiddleware, compose } from 'redux';

import createSageMiddleware from 'redux-saga';
// import { persistStore } from 'redux-persist';

import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSageMiddleware();

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [sagaMiddleware];

// export const persistor = persistStore(store);

export const configureStore = (initialState) => {
  const store = createStore(reducers, initialState,
    composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configureStore();

export default store;

export const dispatch = action => store.dispatch(action);
