import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Select from '../../../../shared/components/form/Select';

const SelectFilter = ({
  state, setState, name, options, isTranslated,
  xs, sm, md, lg, xl, className,
}) => (
  <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={className}>
    <Select
      input={{
        value: state,
        name,
        onChange: option => setState(option.value),
      }}
      options={options}
      isTranslated={isTranslated}
    />
  </Col>
);

SelectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  isTranslated: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

SelectFilter.defaultProps = {
  isTranslated: undefined,
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
  className: '',
};

export default SelectFilter;
