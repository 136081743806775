import {
  CREATE_REPORT_TENANT, GET_REPORTS_TENANT,
  UPDATE_REPORT_TENANT, RELOAD_REPORT_TENANT,
  DELETE_REPORT_TENANT, DELETE_REPORT_WITH_CHILDREN_TENANT, SOFT_DELETE_REPORT_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_REPORTS_TENANT:
      return params => makeFetcher('reports', 'GET', params);
    case RELOAD_REPORT_TENANT:
      return data => makeFetcher(`reports/${data._id}`, 'GET');
    case CREATE_REPORT_TENANT:
      return data => makeFetcher('reports', 'POST', data);
    case UPDATE_REPORT_TENANT:
      return ({ _id, ...data }) => makeFetcher(`reports/${_id}`, 'PUT', data);
    case SOFT_DELETE_REPORT_TENANT:
      return ({ _id }) => makeFetcher(`reports/${_id}`, 'PUT', { isActive: false });
    case DELETE_REPORT_TENANT:
      return data => makeFetcher(`reports/${data._id}`, 'DELETE');
    case DELETE_REPORT_WITH_CHILDREN_TENANT:
      return data => makeFetcher(`reports/${data._id}/delete-child`, 'DELETE');
    default:
      return null;
  }
};
