import {
  CHANGE_BORDER_RADIUS,
  TOGGLE_BOX_SHADOW,
  TOGGLE_TOP_NAVIGATION,
} from '../../sharedTypes';

export function changeBorderRadius() {
  return {
    type: CHANGE_BORDER_RADIUS,
  };
}

export function toggleBoxShadow() {
  return {
    type: TOGGLE_BOX_SHADOW,
  };
}

export function toggleTopNavigation() {
  return {
    type: TOGGLE_TOP_NAVIGATION,
  };
}
