import React, { Component } from 'react';
import {
  Col, Container, Row, ButtonToolbar, ButtonGroup, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import EmployeesTable from './components/EmployeeTable';
import EmployeesTablePagination from './components/EmployeesTablePagination';
import EmployeesFilter from './components/EmployeesFilter';
import EmployeeModal from './components/EmployeeModal';
import employeeType from './types/employee';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import SearchBox from '../../../shared/components/SearchBox';
import EmployeeList from './components/EmployeeList';
import EmployeesSidebar from './components/EmployeeSidebar';
import FilterButton from '../../../shared/components/filter/FilterButton';
import FilterSidebar from '../../../shared/components/filter/FilterSidebar';
import EmployeesFilterSidebar from './components/EmployeesFilterSidebar';

class Employees extends React.PureComponent {
  static propTypes = {
    getEmployeesRequest: PropTypes.func.isRequired,
    editEmployee: PropTypes.func.isRequired,
    updateEmployee: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    employees: PropTypes.arrayOf(employeeType).isRequired,
    totalEmployees: PropTypes.number.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  state = {
    activesFilter: 'actives',
    startDate: null,
    endDate: null,
    keywords: '',
    type: '',
    sortBy: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
  };

  componentDidMount() { this.forceReloadEmployee(); }
  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = ['activesFilter', 'startDate', 'endDate', 'keywords',
      'type', 'sortBy', 'dir', 'currentPage', 'perPage']
      .reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadEmployees(force);
  }

  toggleEmployeeStatus = (employee) => {
    const { updateEmployee } = this.props;
    updateEmployee({
      _id: employee._id,
      isActive: !employee.isActive,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editEmployeeModal = (customer) => {
    const { editEmployee } = this.props;
    editEmployee(customer);
  };

  newEmployeeModal = () => {
    const { editEmployee } = this.props;
    editEmployee({
      _id: 'new',
    });
  };

  forceReloadEmployee = () => this.maybeReloadEmployees(true);

  maybeReloadEmployees = (force = false) => {
    const {
      getEmployeesRequest, loading, loaded, error,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter, startDate, endDate, keywords, type, sortBy, dir, currentPage, perPage,
      } = this.state;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.actives = '1';
        else if (activesFilter === 'inactives') filter.inactives = '1';
        else console.error('unknown activesFilter filter in employees', activesFilter);
      }
      if (startDate) filter.startDate = moment(startDate).format('YYYY/MM/DD');
      if (endDate) filter.endDate = moment(endDate).format('YYYY/MM/DD');
      if (keywords) filter.keywords = keywords;
      if (type) filter.type = type;
      if (sortBy) filter.sortBy = sortBy;
      if (dir) filter.dir = dir;
      getEmployeesRequest();
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading, loaded, error, employees, totalEmployees, t, theme, rtl,
    } = this.props;

    const {
      activesFilter, startDate, endDate, keywords, type, sortBy, dir, currentPage, perPage, viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <EmployeesTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadEmployee}
                employees={employees}
                totalEmployees={totalEmployees}
                toggleEditEmployee={this.editEmployeeModal}
                sortBy={sortBy}
                dir={dir}
                updateSort={this.updateSort}
                toggleEmployeetatus={this.toggleEmployeeStatus}
              />
              <EmployeesTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalEmployees={totalEmployees}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      loading, loaded, error, employees, totalEmployees, t, theme, rtl,
    } = this.props;

    const {
      activesFilter, startDate, endDate, keywords, type, sortBy, dir, currentPage, perPage, viewMode,
    } = this.state;

    return (
      <Container className="container__with-filter customers-page">
        <Row>
          <Col sm={3}>
            <h3 className="page-title">{t('employees.page_title')}</h3>
          </Col>
          <Col sm={9} className="d-flex justify-content-end">
            <Button
              color="success"
              className="icon"
              onClick={this.newEmployeeModal}
            >
              <p>
                <AccountPlusIcon /> {t('employees.add_new')}
              </p>
            </Button>
            <FilterButton />
          </Col>
        </Row>
        { this.renderTableView()}
        <EmployeesFilterSidebar
          updateFilter={this.updateFilter}
          activesFilter={activesFilter}
          startDate={startDate}
          endDate={endDate}
          keywords={keywords}
          type={type}
          sortBy={sortBy}
          dir={dir}
          theme={theme}
          rtl={rtl.direction}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.employees.loading,
  loaded: state.employees.loaded,
  error: state.employees.error,
  employees: state.employees.employees,
  totalEmployees: state.employees.totalEmployees,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  getEmployeesRequest: TenantActions.Employees.getEmployeesRequest,
  updateEmployee: TenantActions.Employees.updateEmployeeRequest,
  editEmployee: TenantActions.Employees.editEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Employees));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
