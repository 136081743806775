const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const types = [
  { value: 'corporate', label: 'Corporate' },
  { value: 'personal', label: 'Individual' },
];

export const typesFilter = [
  { value: '', label: 'Any Type' },
  ...types,
];

export const typeToLabel = toLabel(types);

export const subTypes = [
  { value: 'lead', label: 'Lead' },
  { value: 'working', label: 'Working' },
];

export const subTypeToLabel = toLabel(subTypes);

export const statusFilters = [
  { value: '', label: 'All Customers' },
  { value: 'actives', label: 'Active Customers' },
  { value: 'inactives', label: 'Inactive Customers' },
];
