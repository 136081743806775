import {
  CREATE_EXPENSE_PAYMENT_TENANT, GET_EXPENSE_PAYMENTS_TENANT,
  UPDATE_EXPENSE_PAYMENT_TENANT, RELOAD_EXPENSE_PAYMENT_TENANT,
  DELETE_EXPENSE_PAYMENT_TENANT, SOFT_DELETE_EXPENSE_PAYMENT_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_EXPENSE_PAYMENTS_TENANT:
      return params => makeFetcher('expense-payments', 'GET', params);
    case RELOAD_EXPENSE_PAYMENT_TENANT:
      return data => makeFetcher(`expense-payments/${data._id}`, 'GET');
    case CREATE_EXPENSE_PAYMENT_TENANT:
      return data => makeFetcher('expense-payments', 'POST', data);
    case UPDATE_EXPENSE_PAYMENT_TENANT:
      return ({ _id, ...data }) => makeFetcher(`expense-payments/${_id}`, 'PUT', data);
    case SOFT_DELETE_EXPENSE_PAYMENT_TENANT:
      return ({ _id }) => makeFetcher(`expense-payments/${_id}`, 'PUT', { isActive: false, amount: '0' });
    case DELETE_EXPENSE_PAYMENT_TENANT:
      return data => makeFetcher(`expense-payments/${data._id}`, 'DELETE');
    default:
      return null;
  }
};
