import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

const renderPhoneInput = ({
  input, meta, placeholder, defaultCountry, className, t,
}) => (
  <div className="form__form-group-input-wrap">
    <PhoneInput
      {...input}
      autocompleteSearch={false}
      placeholder={placeholder}
      defaultCountry={defaultCountry}
      className={className}
      searchPlaceholder={t('form_fields.phone_input.search_country_code')}
      enableSearchField
      disableAreaCodes
    />
    {meta && meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
  </div>
);

renderPhoneInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultCountry: PropTypes.string,
  className: PropTypes.string,
};

renderPhoneInput.defaultProps = {
  meta: null,
  placeholder: '',
  defaultCountry: '',
  className: '',
};

export default withTranslation('common')(renderPhoneInput);
