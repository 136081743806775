import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import PropTypes from 'prop-types';

class MultiSelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    ]).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  noOptionsMessage = () => {
    const { t } = this.props;
    return t('form_fields.multi_select.no_options');
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const {
      value, name, placeholder, options, t,
    } = this.props;

    return (
      <Select
        isMulti
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        closeOnSelect={false}
        removeSelected={false}
        className="react-select multi-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        noOptionsMessage={this.noOptionsMessage}
      />
    );
  }
}

const RenderMultiSelectField = (props) => {
  const {
    input, meta, options, placeholder, t,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <MultiSelectField
        {...input}
        options={options}
        placeholder={placeholder}
        t={t}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
    </div>
  );
};

RenderMultiSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

RenderMultiSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default withTranslation('common')(RenderMultiSelectField);
