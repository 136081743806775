import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';


const styles = {
  imageHolder: {
    margin: '4px 4px 8px',
    position: 'relative',
    overflow: 'hidden',
  },
  outerContainer: {
    display: 'inline-block',
    position: 'relative',
  },
  imageHolderDashed: {
    marginTop: -2,
    position: 'relative',
    overflow: 'hidden',
    border: '1px dashed #444',
  },
  imageInput: {
    position: 'absolute',
    top: -20,
    left: -20,
    right: -20,
    bottom: -20,
    width: 'calc(100% + 40px)',
    height: 'calc(100% + 40px)',
    zIndex: 1,
    fontSize: 0,
    cursor: 'pointer',
  },
  imagePreview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    margin: 4,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(6px, -6px)',
    padding: '4px 6px 2px',
    color: '#fff',
    fontWeight: '100',
    borderRadius: '50%',
    backgroundColor: '#c53030', // tailwind bg-red-700
    border: '1px solid #9b2c2c', // tailwind bg-red-800
    cursor: 'pointer',
    zIndex: 1,
  },
};

/**
 * uploadable: bool (default: false)
 * clearable: bool (default: false)
 * placeholderImage: string (url/src to default image)
 * placeholderImageElement: react element (default null)
 * imageSource: string (url/src to current image)
 * onChange: function(imageFile):void
 * onClear: function():void
 * title: string (default: 'image')
 * shape: 'circle' | 'rounded' | 'square' (default: rounded)
 */

class UploadableImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imagePreview: null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleImageClear = this.handleImageClear.bind(this);
    this.getShapeBorderRadius = this.getShapeBorderRadius.bind(this);
    this.getImageHolderStyle = this.getImageHolderStyle.bind(this);
    this.getImagePreviewStyle = this.getImagePreviewStyle.bind(this);
    this.renderImageInput = this.renderImageInput.bind(this);
    this.renderImage = this.renderImage.bind(this);
  }

  getShapeBorderRadius() {
    const { shape } = this.props;
    switch (shape) {
      case 'circle':
        return '50%';
      case 'rounded':
        return 8;
      default:
      case 'square':
        return 0;
    }
  }

  getImageHolderStyle() {
    const { uploadable } = this.props;
    let { size } = this.props;
    if (uploadable) {
      size += 2;
    }
    return {
      ...(uploadable ? styles.imageHolderDashed : styles.imageHolder),
      borderRadius: this.getShapeBorderRadius(),
      width: size,
      maxWidth: size,
      minWidth: size,
      height: size,
      maxHeight: size,
      minHeight: size,
    };
  }

  getImagePreviewStyle() {
    return {
      ...styles.imagePreview,
      borderRadius: this.getShapeBorderRadius(),
    };
  }

  handleImageChange(e) {
    e.preventDefault();
    const { onChange } = this.props;
    const reader = new FileReader();
    const imageFile = e.target.files[0];
    // console.log('onch', onChange, imageFile);
    reader.onloadend = () => {
      this.setState({
        imagePreview: reader.result,
      }, () => onChange && onChange(imageFile));
    };
    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  }

  handleImageClear(e) {
    e.preventDefault();
    const { onClear } = this.props;
    this.setState({ imagePreview: null }, () => onClear && onClear());
  }

  renderClearButton() {
    const { clearable, imageSource } = this.props;
    const { imagePreview } = this.state;
    if (!clearable || (!imagePreview && !imageSource)) {
      return null;
    }
    return (
      <button
        type="button"
        style={styles.clearButton}
        onClick={this.handleImageClear}
      >
        X
      </button>
    );
  }

  renderImageInput() {
    const { uploadable, name } = this.props;
    if (!uploadable) {
      return null;
    }
    return (
      <input
        type="file"
        name={name}
        onChange={this.handleImageChange}
        style={styles.imageInput}
        accept="image/*"
      />
    );
  }

  renderImage() {
    const { imagePreview } = this.state;
    const {
      uploadable, imageSource, placeholderImage, placeholderImageElement, title,
    } = this.props;
    const imagePreviewStyle = this.getImagePreviewStyle();
    if (imagePreview || imageSource || placeholderImage) {
      return (
        <img
          src={imagePreview || imageSource || placeholderImage}
          style={imagePreviewStyle}
          alt={title}
          title={title}
        />
      );
    }
    if (uploadable && placeholderImageElement) {
      return (
        <div
          style={imagePreviewStyle}
        >
          {placeholderImageElement}
        </div>
      );
    }
    return null;
  }

  render() {
    const imageHolderStyle = this.getImageHolderStyle();
    return (
      <div style={styles.outerContainer}>
        <div style={imageHolderStyle}>
          {this.renderImageInput()}
          {this.renderImage()}
        </div>
        {this.renderClearButton()}
      </div>
    );
  }
}

UploadableImage.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  shape: PropTypes.oneOf(['circle', 'rounded', 'square']),
  uploadable: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholderImage: PropTypes.string,
  placeholderImageElement: PropTypes.node,
  imageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  title: PropTypes.string,
};

UploadableImage.defaultProps = {
  name: '',
  size: 120,
  shape: 'rounded',
  uploadable: false,
  clearable: false,
  title: 'image',
  placeholderImageElement: <i className="fa fa-upload" />,
  placeholderImage: '',
  imageSource: '',
  onChange: undefined,
  onClear: undefined,
};

export default UploadableImage;
