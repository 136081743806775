import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Switch from 'react-ios-switch';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import AccountArrowLeft from 'mdi-react/AccountArrowLeftIcon';
import Button from 'reactstrap/es/Button';

import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import customerType from '../types/customer';
import Loading from '../../../../shared/components/Loading';
import * as ProjectActions from '../../../../store/actions/tenant/projects';
import { typeToLabel } from '../constants/options';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class CustomersTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    customers: PropTypes.arrayOf(customerType.isRequired).isRequired,
    totalCustomers: PropTypes.number.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    editMatter: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleCustomerStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  get heads() {
    const { t } = this.props;
    return [{
      key: 'name',
      name: `${t('customer_form.name')}`,
      sortable: true,
      formatter: ({ row }) => (
        <div>
          <Link to={`/customers/${row._id}`}>
            <AccountArrowLeft style={{ width: '20px', height: '20px' }} /> {row.name}
          </Link>
        </div>
      ),
    },
    /*    {
      key: 'status',
      name: `${t('customer_form.status')}`,
      sortable: true,
      formatter: ({ row }) => {
        const [updating, setUpdating] = useState();

        return (
          <div>
            <Switch
              checked={updating ? !row.isActive : row.isActive}
              disabled={updating || false}
              onChange={() => {
                setUpdating(true);
                this.toggleCustomerStatus(row);
              }}
            />
          </div>
        );
      },
    }, */
    {
      key: 'type',
      width: 128,
      name: `${t('customer_form.type')}`,
      sortable: true,
      formatter: ({ value }) => (
        <div>
          {t(typeToLabel(value))}
        </div>
      ),
    },
    {
      key: 'created',
      name: `${t('customer_form.created_at')}`,
      sortable: true,
      formatter: ({ value }) => (
        <div>
          {`${moment(value).fromNow()}`}
        </div>
      ),
    },
    { key: 'sector', name: `${t('customer_form.sector')}`, formatter: simpleFormatter },
    { key: 'peopleInCharge', name: `${t('customer_form.people_in_charge')}`, formatter: simpleFormatter },
    { key: 'taxNumber', name: `${t('customer_form.tax_number')}`, formatter: simpleFormatter },
    { key: 'taxOffice', name: `${t('customer_form.tax_office')}`, formatter: simpleFormatter },
    {
      key: 'actions',
      name: `${t('customer_form.actions')}`,
      width: 296,
      /* cellClass: 'customers-page__details-cell', */
      formatter: ({ row }) => (
        <div>
          <Button
            type="button"
            size="sm"
            className="icon"
            outline
            onClick={() => this.toggleEditCustomer(row)}
          >
            <p><SquareEditOutlineIcon /> {t('customer_table.edit')}</p>
          </Button>
          <Button
            type="button"
            size="sm"
            className="icon"
            color="danger"
            onClick={() => this.promptSoftDelete(row)}
          >
            <p><DeleteIcon />{t('customer_table.delete')}</p>
          </Button>
          <Button
            type="button"
            size="sm"
            color="success"
            onClick={() => this.handleAddMatter(row)}
            className="icon m-0"
          >
            <p><AccountPlusIcon /> {t('customer_table.add_matter')}</p>
          </Button>
        </div>
      ),
    }];
  }

  toggleEditCustomer = (customer) => {
    const { toggleEditCustomer } = this.props;
    toggleEditCustomer(customer);
  };

  handleAddMatter = (customer) => {
    const { editMatter } = this.props;
    const { _id, name } = customer;
    editMatter({
      _id: 'new',
      customerId: { value: _id, label: name },
    });
  };

  promptSoftDelete = (customer) => {
    const { promptSoftDelete } = this.props;
    promptSoftDelete(customer);
  };

  toggleCustomerStatus = (customer) => {
    const { toggleCustomerStatus } = this.props;
    toggleCustomerStatus(customer);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, customers, totalCustomers, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={customers}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalCustomers === 0 ? (
              <div>
                {t('customer_table.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

const mapDispatchToProps = {
  editMatter: ProjectActions.editProject,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(CustomersTable));
