import withFetch from './withFetch';
import { RESEND_EMAIL_VERIFICATION_TENANT, VERIFY_EMAIL_TENANT } from '../fetchTypes';

const initialState = {
  loading: false,
  error: undefined,
  resendError: undefined,
  resendLoading: false,
  resendLoaded: false,
};

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case VERIFY_EMAIL_TENANT:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case RESEND_EMAIL_VERIFICATION_TENANT:
      return {
        ...state,
        resendError: undefined,
        resendLoading: true,
        resendLoaded: false,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case VERIFY_EMAIL_TENANT:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case RESEND_EMAIL_VERIFICATION_TENANT:
      return {
        ...state,
        resendError: undefined,
        resendLoading: false,
        resendLoaded: true,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case VERIFY_EMAIL_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESEND_EMAIL_VERIFICATION_TENANT:
      return {
        ...state,
        resendError: action.error,
        resendLoading: false,
        resendLoaded: false,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => state;

export default withFetch(initialState, fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
