import axios from 'axios';

export const makeFetcherBuilder = defaultBaseUrl => async (url, method,
  body = {}, headers = {}, baseURL = defaultBaseUrl, extras = {}) => {
  switch (method.toUpperCase()) {
    case 'GET':
    case 'HEAD':
      return axios({
        url,
        method,
        headers,
        params: body,
        baseURL,
        ...extras,
      });
    case 'POST':
    case 'PUT':
    case 'DELETE':
      return axios({
        url,
        method,
        headers,
        data: body,
        baseURL,
        ...extras,
      });
    default:
      throw new Error('Unknown method in fetcher builder, ', method);
  }
};
