/* eslint-disable no-param-reassign */
export const calculateTotalPrice = (billingType, estimatedHours, fee, capHours, feeAfterCap) => {
  if ((!estimatedHours && estimatedHours !== 0) || !fee) {
    return 0;
  }
  if (typeof estimatedHours === 'string') estimatedHours = parseFloat(estimatedHours);
  if (typeof fee === 'string') fee = parseFloat(fee);
  if (!capHours) capHours = 0;
  if (!feeAfterCap) feeAfterCap = 0;
  if (typeof capHours === 'string') capHours = parseFloat(capHours);
  if (typeof feeAfterCap === 'string') feeAfterCap = parseFloat(feeAfterCap);
  switch (billingType) {
    case 'hourly':
      return capHours
        ? (Math.min(capHours, estimatedHours) * fee) + (Math.max(capHours, estimatedHours) - capHours) * feeAfterCap
        : estimatedHours * fee;
    default:
      return fee + (capHours ? ((Math.max(capHours, estimatedHours) - capHours) * feeAfterCap) : 0);
  }
};

export const selectObjectValueSelector = obj => (typeof obj === 'object' ? obj.value : obj);
