import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as ProjectActions from '../../../../store/actions/tenant/projects';

const MatterDeletePrompt = ({
  matter, onHideModal, softDeleteMatter,
}) => {
  const { _id } = matter || {};
  let { title } = matter || {};
  title = title || _id;
  const handleDeleteConfirm = () => {
    softDeleteMatter(matter);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('matter_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!matter}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('matter_delete_prompt.message')}
      </h4>
      <p>{title}</p>
    </ModalComponent>
  );
};

MatterDeletePrompt.propTypes = {
  matter: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteMatter: PropTypes.func.isRequired,
};

MatterDeletePrompt.defaultProps = {
  matter: null,
};

const mapDispatchToProps = {
  softDeleteMatter: ProjectActions.softDeleteProjectRequest,
};

export default connect(null, mapDispatchToProps)(MatterDeletePrompt);
