import axios from 'axios';
import { TENANT_API_URL } from '../../constants';

export const getCustomersList = () => axios.get(`${TENANT_API_URL}customers/lookup?active=1&start=0&limit=400`)
  .then(response => response.data.success.data)
  .catch(console.error);
export const getEmployeesList = () => axios.get(`${TENANT_API_URL}/tenants/employees`)
  .then(response => response.data.success.data.users.users.map(({ _id, fname }) => ({ _id, fname })))
  .catch(console.error);
export const getProjectsList = customerId => axios.get(`${TENANT_API_URL}customers/${customerId}/projects?active=1`)
  .then(response => response.data.success.data);
export const getTasksList = (projectId, filters) => axios.get(`${TENANT_API_URL}tasks`, {
  params: {
    projectId,
    start: 0,
    limit: 400,
    actives: '1',
    ...filters,
  },
}).then(response => response.data.success.data.tasks /* .map(({ _id, title }) => ({ _id, title })) */);
export const getExtraPaymentsList = (projectId, filters) => axios.get(`${TENANT_API_URL}extras`, {
  params: {
    projectId,
    actives: '1',
    ...filters,
  },
}).then(response => response.data.success.data.extras);
export const getLegalTypes = () => axios.get(`${TENANT_API_URL}legal-categories`)
  .then(response => response.data.success.data.types)
  .catch(console.error);
export const getTaskTypes = () => axios.get(`${TENANT_API_URL}task-categories`)
  .then(response => response.data.success.data.types)
  .catch(console.error);
export const getExpenseTypes = () => axios.get(`${TENANT_API_URL}expense-categories`)
  .then(response => response.data.success.data.types)
  .catch(console.error);
export const getSubscriptionOptions = () => axios.get(`${TENANT_API_URL}subscriptions`)
  .then(response => response.data.success.data.subscriptions)
  .catch(console.error);
