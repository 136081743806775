/* eslint-disable react/no-array-index-key */
import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Bar, BarChart, PieChart, Pie, CartesianGrid, Legend, ResponsiveContainer, Cell, Tooltip,
  XAxis, YAxis, Label, LabelList,
} from 'recharts';
import expenseTypesType from '../types/expenseTypes';
import { getRandomColorFactory } from './colors';
import useRefOnce from '../hooks/useRefOnce';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import { displayCurrency } from '../../../../shared/helpers';

const CustomPieChart = ({
  entries, unit, dataKey, defaultGetColor, t,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (typeof dataKey === 'function') {
      setData(entries.map(entry => ({ ...entry, value: dataKey(entry) })));
    } else {
      setData(entries);
    }
  }, [entries, dataKey]);
  const getColor = defaultGetColor || useRefOnce(getRandomColorFactory);
  const renderCustomizedLabel = useRefOnce(() => renderCustomizedLabelFactory(getColor, unit));
  if (entries.length === 0) {
    return (
      <div>
        <h4 style={{ textAlign: 'center', marginTop: 24 }}>
          {t('custom_pie_chart.no_data')}
        </h4>
      </div>
    );
  }
  return (
    <ResponsiveContainer width="100%" aspect={1.5 - (entries.length) * 0.03}>
      <PieChart>
        <Pie
          data={data}
          nameKey="title"
          dataKey={typeof dataKey === 'function' ? 'value' : dataKey}
          label={renderCustomizedLabel}
          labelLine={{ stroke: '#888' }}
          outerRadius="70%"
        >
          {
            entries.map((entry, index) => (
              <Cell
                key={`cell-${entry.title}-${index}`}
                fill={getColor(index)}
              />
            ))
          }
        </Pie>
        <Tooltip
          formatter={value => displayCurrency(value, unit)}
        />
        <Legend verticalAlign="top" />
      </PieChart>
    </ResponsiveContainer>
  );
};


CustomPieChart.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape().isRequired,
  ).isRequired,
  unit: PropTypes.string,
  dataKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  defaultGetColor: PropTypes.func,
  t: PropTypes.func.isRequired,
};

CustomPieChart.defaultProps = {
  unit: '',
  dataKey: 'value',
  defaultGetColor: undefined,
};

export default withTranslation('common')(CustomPieChart);
