import {
  CHANGE_MOBILE_SIDEBAR_VISIBILITY, CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_FILTER_SIDEBAR_VISIBILITY,
} from '../../sharedTypes';

export function changeSidebarVisibility() {
  return {
    type: CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}

export function changeFilterSidebarVisibility() {
  return {
    type: CHANGE_FILTER_SIDEBAR_VISIBILITY,
  };
}
