import React, { useEffect, useState } from 'react';
import { useRequest } from '../../../../shared/hooks';

const useTaskTypeReport = (date, projectId, customerId) => {
  const { start, end } = date;
  const [params, setParams] = useState();
  useEffect(() => {
    let nextParams;
    if (start && end) {
      nextParams = {
        'dateRange[0]': start.toISOString(),
        'dateRange[1]': end.toISOString(),
      };
      if (projectId) {
        nextParams.projectId = projectId;
      }
      if (customerId) {
        nextParams.customerId = customerId;
      }
    }
    setParams(nextParams);
  }, [start, end, projectId, customerId]);
  return useRequest(
    undefined,
    params ? 'tasks/report' : '',
    useRequest.methods.GET,
    params,
  );
};

export default useTaskTypeReport;
