import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import TenantActions from '../../../store/actions/tenant';

import ResetPasswordConfirmationForm from './components/ResetPasswordConfirmationForm';

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPasswordConfirmation = ({
  t, loading, error, loaded, resetPasswordConfirmation,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const verificationCode = query.get('token');

  const handleSubmit = ({ token, password }) => {
    resetPasswordConfirmation({ token, password });
    setSubmitted(true);
  };

  useEffect(() => {
    if (!loading && !error && loaded && submitted) {
      history.replace('/login');
    }
  });

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> {t('reset_password_confirmation_form_index.advo')}
                <span className="account__logo-accent">{t('reset_password_confirmation_form_index.pass')}</span>
              </span>
            </h3>
            <h4 className="account__subhead">{t('reset_password_confirmation_form_index.reset_pass_con')}</h4>
          </div>
          <ResetPasswordConfirmationForm
            loading={loading}
            initialValues={{ token: verificationCode }}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

ResetPasswordConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  resetPasswordConfirmation: PropTypes.func.isRequired,
};

ResetPasswordConfirmation.defaultProps = {
  error: '',
};

const mapStateToProps = state => ({
  loading: state.resetPassword.confirmLoading,
  error: state.resetPassword.confirmError,
  loaded: state.resetPassword.confirmLoaded,
});

const mapDispatchToProps = {
  resetPasswordConfirmation: TenantActions.ResetPassword.resetPasswordConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ResetPasswordConfirmation));
