import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import MapMarkerRadiusIcon from 'mdi-react/MapMarkerRadiusIcon';
import MoneyIcon from 'mdi-react/MoneyIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import CreditCardClockIcon from 'mdi-react/CreditCardClockIcon';
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import useDurationPriceReport from './hooks/useDurationPriceReport';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import StackedGroupedBarChart from './components/StackedGroupedBarChart';
import CustomPieChart from './components/CustomPieChart';
import useRefOnce from './hooks/useRefOnce';
import { getRandomColorFactory } from './components/colors';
import CheckBoxFilter from './components/CheckboxFilter';
import DurationPriceDateLineChart from './components/DurationPriceDateLineChart';
import timeseriesOptions from './constants/timeseries';
import modeOptions from './constants/modes';
import SelectFilter from './components/SelectFilter';
import durationPriceDateNormalize from './helpers/durationPriceDateNormalizer';
import PieChartGoalGraph from './components/PieChartGoalGraph';
import { getDefaultCurrency } from '../../../shared/helpers';

const date = {
  end: new Date(),
};
date.start = new Date(date.end.getTime() - 30 * 24 * 60 * 60 * 1000);

const groupBy = 'team';
const LastMonthWidget = ({
  projectId, customerId, t,
}) => {
  /* const [date, setDate] = useState({ initialState }); */
  const [timeseries, setTimeseries] = useState('monthly');
  // const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  // const [collapsed, setCollapsed] = useState(false);
  const [{
    loading, loaded, error, result,
  }, reload] = useDurationPriceReport(
    date,
    groupBy,
    projectId,
    customerId,
    timeseries,
  );
  const currency = getDefaultCurrency();
  const entries = useMemo(() => {
    if (loaded) {
      return durationPriceDateNormalize({
        date,
        timeseries,
        results: result,
        fallbackTitle: '',
      });
    }
    return [];
  }, [result]);

  const {
    taskGain, hourlyTaskFee, workedHours, extraGain,
  } = useMemo(() => {
    const entry = entries[0] || {};
    const dataList = entry.data || [];
    const data = dataList[dataList.length - 1] || {};
    const {
      closedBillableTasksPrice = 0,
      openBillableTasksPrice = 0,
      closedExtraPaymentsPrice = 0,
      openExtraPaymentsPrice = 0,
      closedBillableTasksDuration = 0,
      openBillableTasksDuration = 0,
      nonBillableTasksDuration = 0,
    } = data;
    const res = {
      taskGain: closedBillableTasksPrice + openBillableTasksPrice,
      extraGain: closedExtraPaymentsPrice + openExtraPaymentsPrice,
      workedHours: closedBillableTasksDuration + openBillableTasksDuration + nonBillableTasksDuration,
    };
    res.hourlyTaskFee = (res.taskGain + res.extraGain) / (res.workedHours || 1);
    return res;
  }, [entries]);

  return (
    <Row>
      <PieChartGoalGraph
        title={t('graphs_index.last_month_goal.hourly_task_fee')}
        color="#1eae4e"
        format=",.2f"
        current={hourlyTaskFee}
        loading={loading}
        titleIcon="tag"
        unit={`${currency} / ${t('custom_pie_chart.hour')}`}
        bodyIcon={
          <CreditCardClockIcon style={{ fill: '#1eae4e' }} />
        }
      />
      <PieChartGoalGraph
        title={t('graphs_index.last_month_goal.task_gain')}
        color="#d8b00e"
        current={taskGain}
        loading={loading}
        titleIcon="magic-wand"
        unit={currency}
        bodyIcon={
          <MoneyIcon style={{ fill: '#d8b00e' }} />
        }
      />
      <PieChartGoalGraph
        title={t('graphs_index.last_month_goal.worked_hours')}
        color="#ff4861"
        format=",.1f"
        current={workedHours}
        loading={loading}
        titleIcon="clock"
        unit={t('custom_pie_chart.hours')}
        bodyIcon={
          <ClockIcon style={{ fill: '#ff4861' }} />
        }
      />
      <PieChartGoalGraph
        title={t('graphs_index.last_month_goal.extra_gain')}
        color="darkturquoise"
        current={extraGain}
        loading={loading}
        titleIcon="diamond"
        unit={currency}
        bodyIcon={
          <DiamondStoneIcon style={{ fill: 'darkturquoise' }} />
        }
      />
    </Row>
  );
};

LastMonthWidget.propTypes = {
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

LastMonthWidget.defaultProps = {
  projectId: '',
  customerId: '',
};

export default withTranslation('common')(LastMonthWidget);
