import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';


class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard Default" icon="home" route="/passboard" onClick={this.hideSidebar} />
          <SidebarLink
            title="Dashboard E-commerce"
            icon="store"
            route="/dashboard_e_commerce"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard Fitness"
            icon="heart-pulse"
            route="/dashboard_fitness"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard Crypto"
            icon="rocket"
            route="/dashboard_crypto"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard Booking"
            icon="apartment"
            newLink
            route="/dashboard_booking"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Dashboard MobileApp"
            icon="smartphone"
            newLink
            route="/dashboard_mobile_app"
            onClick={this.hideSidebar}
          />
          <SidebarCategory title="Layout" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
