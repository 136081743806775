/* eslint-disable react/sort-comp */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Switch from 'react-ios-switch';
import moment from 'moment';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Button from 'reactstrap/es/Button';

import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import MailIcon from 'mdi-react/MailIcon';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import billType from '../types/bill';
import Loading from '../../../../shared/components/Loading';
import EmailButton from '../../../../shared/components/EmailButton';
import { displayCurrency } from '../../../../shared/helpers';
import { languageOptionToLabel } from '../constants/options';
import { statusToLabel } from '../../Reports/constants/options';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class BillsTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    bills: PropTypes.arrayOf(billType.isRequired).isRequired,
    totalBills: PropTypes.number.isRequired,
    toggleEditBill: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleBillStatus: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
    currency: '',
  };

  get heads() {
    const { t } = this.props;
    return [
      {
        key: 'created',
        width: 96,
        name: `${t('bill_table.created_at')}`,
        formatter: ({ value }) => (
          <div>
            {`${moment(value || new Date()).fromNow()}`}
          </div>
        ),
      },
      {
        key: 'periodStart',
        name: `${t('bill_table.billing_period')}`,
        width: 180,
        formatter: ({ row }) => (
          <div>
            {`${moment(row.periodStart).format('ll')} - ${moment(row.periodEnd).format('ll')}`}
          </div>
        ),
      },
      {
        key: 'billedAmount',
        width: 120,
        name: `${t('bill_table.billed_amount')}`,
        formatter: ({ value }) => {
          const { currency } = this.props;
          return (
            <div>
              {displayCurrency(value, currency)}
            </div>
          );
        },
      },
      {
        key: 'totalTaskHrs',
        width: 128,
        name: `${t('bill_table.total_task_hours')}`,
        formatter: ({ value }) => (
          <div>
            {value} {t('bill_table.hours')}
          </div>
        ),
      },
      {
        key: 'extrasTotal',
        width: 104,
        name: `${t('bill_table.extra_payments')}`,
        formatter: ({ value }) => {
          const { currency } = this.props;
          return (
            <div>
              {displayCurrency(value, currency)}
            </div>
          );
        },
      },
      {
        key: 'taskCost',
        width: 128,
        name: `${t('bill_table.tasks_cost')}`,
        formatter: ({ value }) => {
          const { currency } = this.props;
          return (
            <div>
              {displayCurrency(value, currency)}
            </div>
          );
        },
      },
      {
        key: 'language',
        name: `${t('bill_table.language')}`,
        width: 80,
        formatter: ({ row }) => (
          <div>
            {t(languageOptionToLabel(row.language))}
          </div>
        ),
      },
      {
        key: 'status',
        name: `${t('extra_form.status')}`,
        width: 96,
        formatter: ({ value }) => (
          <div>
            {t(statusToLabel(value))}
          </div>
        ),
      },
      {
        key: 'actions',
        name: `${t('bill_table.actions')}`,
        width: 372,
        /* cellClass: 'Expenses-page__details-cell', */
        formatter: ({ row }) => (
          <div>
            {row.pdfUrl ? (
              <Button
                tag="a"
                href={row.pdfUrl}
                type="button"
                size="sm"
                className="icon mr-1"
                outline
              >
                <p><FileDownloadIcon /> {t('bill_table.download')}</p>
              </Button>
            ) : null }
            <EmailButton
              subject={`Bill for ${moment(row.periodStart).format('DD/MM/YYYY')
              } - ${moment(row.periodEnd).format('DD/MM/YYYY')}`}
              htmlBody={row.htmlOutput}
              type="button"
              size="sm"
              className="icon mr-1"
              outline
            >
              <p><MailIcon /> {t('bill_table.send_mail')}</p>
            </EmailButton>
            <Button
              type="button"
              size="sm"
              className="icon mr-1"
              outline
              onClick={() => this.toggleEditBill(row)}
            >
              <p><SquareEditOutlineIcon />{t('bill_table.edit')}</p>
            </Button>
            <Button
              type="button"
              size="sm"
              className="icon mr-1"
              color="danger"
              onClick={() => this.promptSoftDelete(row)}
            >
              <p><DeleteIcon />{t('bill_table.delete')}</p>
            </Button>
            {/*          <Button
            tag="a"
            type="button"
            size="sm"
            className="icon"
            outline
            onClick={() => {
              const win = window.open('mailto:?subject=Report &html-body=<b>ME</b>');
              win.focus();
            }}
          >
            <p><MailIcon /> Send Mail</p>
          </Button> */}
          </div>
        ),
      },
    /* {
      key: 'actions',
      name: 'Actions',
      /!* cellClass: 'Bills-page__details-cell', *!/
      formatter: ({ row }) => (
        <div>
          <Button
            type="button"
            size="sm"
            className="icon"
            outline
            onClick={() => this.toggleEditBill(row)}
          >
            <p><SquareEditOutlineIcon /> Edit</p>
          </Button>
        </div>
      ),
    }, */
    ];
  }

  toggleEditBill = (Bill) => {
    const { toggleEditBill } = this.props;
    toggleEditBill(Bill);
  };

  promptSoftDelete = (bill) => {
    const { promptSoftDelete } = this.props;
    promptSoftDelete(bill);
  };

  toggleBillStatus = (Bill) => {
    const { toggleBillStatus } = this.props;
    toggleBillStatus(Bill);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, bills, totalBills, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={bills}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalBills === 0 ? (
              <div>
                {t('bill_table.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

export default withTranslation('common')(BillsTable);
