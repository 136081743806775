import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import { Container } from 'reactstrap';
import EmployeeForm from './EmployeeForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';

class EmployeeModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createEmployeeRequest: PropTypes.func.isRequired,
    updateEmployeeRequest: PropTypes.func.isRequired,
    editEmployee: PropTypes.func.isRequired,
    editingEmployee: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingEmployee: null,
  };

  handleSubmit = (data) => {
    console.log(data);
    const payload = { ...data };
    const {
      _id, ...tempArray
    } = payload;
    const { createEmployeeRequest, updateEmployeeRequest, editingEmployee } = this.props;
    if (data.phones) {
      payload.phones = data.phones.map(phone => phone.replace(/[^\d+]+/g, ''));
    }
    ['type', 'subtype']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value;
        }
      });
    if (editingEmployee._id === 'new') {
      createEmployeeRequest(tempArray);
    } else {
      payload._id = editingEmployee._id;
      const {
        fname, lname, identityNumber, phone, email, isActive, isTenantAdmin,
      } = payload;

      updateEmployeeRequest({
        fname, lname, identityNumber, phone, email, isActive, isTenantAdmin, _id: editingEmployee._id,
      });
    }
  };

  render() {
    const {
      updating, editEmployee, editingEmployee, theme, rtl,
    } = this.props;

    const modalClasses = classNames({
      'todo__add-modal': true,
    }, theme.className, `${rtl.direction}-support`);

    return (

      <Modal
        isOpen={!!editingEmployee}
        toggle={() => editEmployee(null)}
        className={modalClasses}
      >
        {editingEmployee && (
          <EmployeeForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editEmployee(null)}
            initialValues={editingEmployee}
            isNew={editingEmployee._id === 'new'}
          />
        )}
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.employees.updating,
  editingEmployee: state.employees.editingEmployee,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createEmployeeRequest: TenantActions.Employees.createEmployeeRequest,
  updateEmployeeRequest: TenantActions.Employees.updateEmployeeRequest,
  editEmployee: TenantActions.Employees.editEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);
