import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import CheckBox from '../../../../shared/components/form/CheckBox';

const CheckBoxFilter = ({
  state, setState, label, name,
}) => (
  <Col xs={12} sm={6}>
    <CheckBox
      input={{
        value: state,
        name,
        onChange: e => (typeof e === 'boolean' ? null : setState(e.target.checked)),
      }}
      label={label}
      className="colored-click"
    />
  </Col>
);

CheckBoxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckBoxFilter;
