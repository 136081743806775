import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row } from 'reactstrap';

import EmailConfirmationCard from './components/EmailConfirmationCard';
import TenantActions from '../../../store/actions/tenant';

const EmailConfirmation = ({ verifyEmail, verifyEmailViewed }) => {
  if (verifyEmail) {
    verifyEmailViewed();
  }
  return (
    <Container>
      <Row>
        <EmailConfirmationCard />
      </Row>
    </Container>
  );
};

EmailConfirmation.propTypes = {
  verifyEmailViewed: PropTypes.func.isRequired,
  verifyEmail: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  verifyEmail: state.register.verifyEmail,
});

const mapDispatchToProps = {
  verifyEmailViewed: TenantActions.Register.verifyEmailViewed,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
