import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {
  Button, ButtonToolbar, Row, Col,
} from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Loading from '../../../../shared/components/Loading';
import RenderSelectField from '../../../../shared/components/form/Select';
import { categories, statuses, tenureTypes } from '../constants/options';

const RenderMaskedField = ({ input, ...props }) => (
  <MaskedInput {...props} {...input} />
);

RenderMaskedField.propTypes = {
  input: PropTypes.shape().isRequired,
};

const ProjectFormInfo = ({ t }) => (
  <Row>
    <Col xs={12} sm={6}>

      <div className="form__form-group">
        <span className="form__form-group-label">{t('project_form.status')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon form__form-group-icon--select">
            <AccountOutlineIcon />
          </div>
          <Field
            name="status"
            component={RenderSelectField}
            placeholder={t('project_form.status')}
            options={statuses}
          />
        </div>
      </div>
    </Col>
    <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('project_form.tenure_type')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon form__form-group-icon--select">
            <AccountOutlineIcon />
          </div>
          <Field
            name="tenureType"
            component={RenderSelectField}
            placeholder={t('project_form.tenure_type')}
            options={tenureTypes}
          />
        </div>
      </div>
    </Col>
  </Row>
);

ProjectFormInfo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'tenant_project_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(ProjectFormInfo));
