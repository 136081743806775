import React from 'react';
import useStateMemo from './useStateMemo';

const useCombinedRequest = (requests, resultsMapper = results => results) => useStateMemo(() => [{
  ...requests.reduce((acc, [{
    loading, loaded, error,
  }]) => ({
    loading: acc.loading || loading,
    loaded: acc.loaded && loaded,
    error: (acc.error && error) ? `${acc.error}\n${error}` : acc.error || error,
  }), {
    loading: false,
    loaded: true,
    error: null,
  }),
  result: resultsMapper(
    requests.map(([{ result }]) => result),
  ),
}, () => Promise.all(
  // eslint-disable-next-line no-unused-vars
  requests.map(([requestState, reloadRequest]) => reloadRequest()),
), requests], requests.map(([requestState]) => requestState));

export default useCombinedRequest;
