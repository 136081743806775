import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../../shared/components/pagination/Pagination';

const BillTablePagination = ({
  totalBills,
  perPage,
  currentPage,
  updatePagination,
}) => (
  <Pagination
    itemsCount={totalBills}
    itemsToShow={perPage}
    pageOfItems={currentPage}
    onChangePage={updatePagination}
  />
);

BillTablePagination.propTypes = {
  totalBills: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  updatePagination: PropTypes.func.isRequired,
};

export default BillTablePagination;
