import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const statuses = [
  { label: 'task_options.not_started', value: '100' },
  { label: 'task_options.in_progress', value: '101' },
  // { value: '102', label: 'Status 102' },
  { label: 'task_options.finished', value: '200' },
];
export const statusFilters = [
  { value: '', label: 'task_options.all_tasks' },
  ...statuses,
];
export const statusToLabel = toLabel(statuses, 'Unknown');

const statusColors = [
  { value: '100', label: 'info' },
  { value: '101', label: 'primary' },
  { value: '200', label: 'success' },
];
export const statusToColor = toLabel(statusColors);

export const activeStatusFilters = [
  { value: '0', label: 'task_options.all_tasks' },
  { value: '1', label: 'task_options.active_tasks' },
  { value: '-1', label: 'task_options.inactive_tasks' },
];

// eslint-disable-next-line no-nested-ternary
export const toBillableStatus = (isClose, isBillable) => (isBillable
  ? isClose ? 'billableClosed' : 'billableOpen' : 'nonBillable');

export const billableStatuses = [
  { value: 'billableOpen', label: 'task_options.billable_open' },
  { value: 'billableClosed', label: 'task_options.billable_closed' },
  { value: 'nonBillable', label: 'task_options.non_billable' },
];

export const billableStatusToLabel = toLabel(billableStatuses, 'Unknown');

const billableStatusColors = [
  { value: 'nonBillable', label: 'info' },
  { value: 'billableOpen', label: 'primary' },
  { value: 'billableClosed', label: 'success' },
];

export const billableStatusToColor = toLabel(billableStatusColors);

export const billableStatusFilters = [
  { value: 'all', label: 'task_options.all' },
  { value: 'billable', label: 'task_options.all_billable' },
  ...billableStatuses,
];
