import PropTypes from 'prop-types';
import { billingTypes } from '../../Projects/constants/options';

const billingProfileType = PropTypes.shape({
  billingType: PropTypes.oneOf(billingTypes.map(({ value }) => value)).isRequired,
  fee: PropTypes.number.isRequired,
  capHours: PropTypes.number.isRequired,
  feeAfterCap: PropTypes.number.isRequired,
});

const bill = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  extras: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  billedAmount: PropTypes.number.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired]).isRequired,
  authorId: PropTypes.string.isRequired,
  billingProfile: billingProfileType.isRequired,
  totalTaskHrs: PropTypes.number.isRequired,
  extrasTotal: PropTypes.number.isRequired,
  taskCost: PropTypes.number.isRequired,
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
});

export { billingProfileType };

export default bill;
