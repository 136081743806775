import PropTypes from 'prop-types';

const project = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  expenseType: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  description: PropTypes.string,
  picture: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
});

export default project;
