import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as ExpenseActions from '../../../../store/actions/tenant/expenses';

const ExpenseDeletePrompt = ({
  expense, onHideModal, softDeleteExpense,
}) => {
  const { _id } = expense || {};
  let { title } = expense || {};
  title = title || _id;
  const handleDeleteConfirm = () => {
    softDeleteExpense(expense);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('expense_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!expense}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('expense_delete_prompt.message')}
      </h4>
      <p>{title}</p>
    </ModalComponent>
  );
};

ExpenseDeletePrompt.propTypes = {
  expense: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteExpense: PropTypes.func.isRequired,
};

ExpenseDeletePrompt.defaultProps = {
  expense: null,
};

const mapDispatchToProps = {
  softDeleteExpense: ExpenseActions.softDeleteExpenseRequest,
};

export default connect(null, mapDispatchToProps)(ExpenseDeletePrompt);
