import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Row, Col,
} from 'reactstrap';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import throttle from 'lodash.throttle';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import RenderSelectField from '../../../../shared/components/form/Select';
import { ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import SearchBox from '../../../../shared/components/SearchBox';
import FilterSidebar from '../../../../shared/components/filter/FilterSidebar';
import { statusFilters, typesFilter } from '../constants/options';

class CustomersFilter extends Component {
  static propTypes = {
    updateFilter: PropTypes.func.isRequired,
    activesFilter: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    keyword: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
  };

  constructor(props) {
    super(props);
    this.handleKeywordChange = throttle(this.handleKeywordChange, 400);
  }

  handleFilterChange = (event) => {
    const { updateFilter } = this.props;
    updateFilter(event.target.value, event.target.name);
  };

  handleDateChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  handleTypeChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'type');
  };

  handleStatusChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'activesFilter');
  };

  handleKeywordChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  render() {
    const {
      activesFilter, startDate, endDate, keyword, type, theme, t, rtl,
    } = this.props;

    return (
      <FilterSidebar>
        <Row className="customers-page__filter-row form">
          <Col xl={2} lg={4} md={6} sm={12}>
            <p className="title">{t('customer_filter.keyword')}</p>
            <SearchBox
              name="keyword"
              defaultValue={keyword}
              onChange={this.handleKeywordChange}
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={12}>
            <p className="title">{t('customer_filter.start_date')}</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'startDate',
                defaultValue: startDate,
              }}
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={12}>
            <p className="title">{t('customer_filter.end_date')}</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'endDate',
                defaultValue: endDate,
              }}
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={12}>
            <p className="title">{t('customer_filter.status')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleStatusChange,
                name: 'status',
              }}
              options={statusFilters}
              defaultValue={activesFilter || statusFilters[0]}
            />
          </Col>
          <Col xl={2} lg={4} md={6} sm={12}>
            <p className="title">{t('customer_filter.type')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleTypeChange,
                name: 'type',
              }}
              options={typesFilter}
              defaultValue={type || typesFilter[0]}
            />
          </Col>
        </Row>
      </FilterSidebar>
    );
  }
}
export default withTranslation('common')(CustomersFilter);
