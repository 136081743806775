import React, { useEffect, useState } from 'react';
import { useRequest } from '../../../../shared/hooks';

const useDurationPriceReport = (date, groupBy, projectId, customerId, timeseries) => {
  const { start, end } = date;
  const [params, setParams] = useState();
  useEffect(() => {
    let nextParams;
    if (start && end && groupBy) {
      nextParams = {
        'moneyDateRange[0]': start.toISOString(),
        'moneyDateRange[1]': end.toISOString(),
        groupBy,
      };
      if (projectId) {
        nextParams.projectId = projectId;
      }
      if (customerId) {
        nextParams.customerId = customerId;
      }
      if (timeseries) {
        nextParams.timeseries = timeseries;
      }
    }
    setParams(nextParams);
  }, [start, end, groupBy, projectId, customerId, timeseries]);
  return useRequest(
    undefined,
    params ? 'projects/report' : '',
    useRequest.methods.GET,
    params,
  );
};

export default useDurationPriceReport;
