import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import Loading from '../../../shared/components/Loading';

import * as ProfileActions from '../../../store/actions/tenant/profile';

/* useEffect(() => {
    if (!loading && !loaded && !error) {
      profileRequest();
    }
  }, [loading, loaded, error, profileRequest]); */
const Profile = ({
  profile: {
    loading, loaded, error, profile, ...metadata
  },
  updateProfilePicture,
  updateProfileLogo,
  deleteProfilePicture,
  deleteProfileLogo,
  profileRequest,
  t,
}) => (
  <Container>
    <div className="profile">
      <Loading loading={loading} loaded={loaded} error={error} retry={profileRequest}>
        <Row>
          <Col md={12} lg={12} xl={6}>
            <Row>
              <ProfileMain
                {...profile}
                metadata={metadata}
                updateProfilePicture={updateProfilePicture}
                updateProfileLogo={updateProfileLogo}
                deleteProfilePicture={deleteProfilePicture}
                deleteProfileLogo={deleteProfileLogo}
              />
              {/* <ProfileCalendar />
              <ProfileTasks /> */}
            </Row>
          </Col>
          <ProfileTabs
            t={t}
          />
        </Row>
      </Loading>
    </div>
  </Container>
);
Profile.propTypes = {
  profile: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    profile: PropTypes.shape().isRequired,
    isEmployee: PropTypes.bool.isRequired,
    isTenantAdmin: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    isNotSubscribed: PropTypes.bool.isRequired,
    isEnterpriseSubscribed: PropTypes.bool.isRequired,
    isIndividualSubscribed: PropTypes.bool.isRequired,
  }).isRequired,
  updateProfilePicture: PropTypes.func.isRequired,
  updateProfileLogo: PropTypes.func.isRequired,
  deleteProfilePicture: PropTypes.func.isRequired,
  deleteProfileLogo: PropTypes.func.isRequired,
  profileRequest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

const mapDispatchToProps = {
  updateProfilePicture: ProfileActions.updateProfilePictureRequest,
  updateProfileLogo: ProfileActions.updateProfileLogoRequest,
  deleteProfilePicture: ProfileActions.deleteProfilePictureRequest,
  deleteProfileLogo: ProfileActions.deleteProfileLogoRequest,
  profileRequest: ProfileActions.profileRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Profile));
