import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import EmailPlusIcon from 'mdi-react/EmailPlusIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import { Button, Row, Col } from 'reactstrap';

const renderEmails = ({ fields, t }) => (
  <>
    {
      fields.map((email, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="form__form-group" key={`customer_address_${index}`}>
          {/* <span className="form__form-group-label">Email #{index + 1}</span> */}
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name={email}
              type="email"
              component="input"
              placeholder={t('customer_form_emails.example')}
            />
            <button
              className="form__form-group-button"
              type="button"
              onClick={() => fields.remove(index)}
            ><CloseIcon />
            </button>
          </div>
        </div>
      ))
    }
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Button type="button" onClick={() => fields.push('')} outline size="sm">
          <EmailPlusIcon />
          {t('customer_form_emails.add_email')}
        </Button>
      </div>
    </div>
  </>
);
renderEmails.propTypes = {
  fields: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

const CustomerFormEmails = ({ t }) => (
  <Row>
    <Col xs={12}>
      <FieldArray
        name="emails"
        component={renderEmails}
        t={t}
      />
    </Col>
  </Row>
);

CustomerFormEmails.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'tenant_customer_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(CustomerFormEmails));
