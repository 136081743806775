import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import useDurationPriceReport from './hooks/useDurationPriceReport';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import StackedGroupedBarChart from './components/StackedGroupedBarChart';
import CustomPieChart from './components/CustomPieChart';
import useRefOnce from './hooks/useRefOnce';
import { getRandomColorFactory } from './components/colors';
import CheckBoxFilter from './components/CheckboxFilter';
import DurationPriceDateLineChart from './components/DurationPriceDateLineChart';
import timeseriesOptions from './constants/timeseries';
import modeOptions from './constants/modes';
import SelectFilter from './components/SelectFilter';
import durationPriceDateNormalize from './helpers/durationPriceDateNormalizer';
import MultiSelectFilter from './components/MultiSelectFilter';
import useStateMemo from '../../../shared/hooks/useStateMemo';
import durationPriceDateSumHelper from './helpers/durationPriceDateSumHelper';

const DurationPriceDatePanel = ({
  title, groupBy, projectId, customerId, fallbackTitle, t,
}) => {
  const [date, setDate] = useState(initialState);
  const [timeseries, setTimeseries] = useState('monthly');
  const [selectedEntries, setSelectedEntries] = useState([]);
  const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  const [collapsed, setCollapsed] = useState(false);
  const [{
    loading, loaded, error, result,
  }, reload] = useDurationPriceReport(
    date,
    groupBy,
    projectId,
    customerId,
    timeseries,
  );
  const entries = useStateMemo(() => {
    if (loaded) {
      return durationPriceDateNormalize({
        date,
        timeseries,
        results: result,
        fallbackTitle: t(fallbackTitle),
      });
    }
    return [];
  }, [result]);

  const [includeClosedBillableTasks, setIncludeClosedBillableTasks] = useState(true);
  const [includeOpenBillableTasks, setIncludeOpenBillableTasks] = useState(true);
  const [includeNonBillableTasks, setIncludeNonBillableTasks] = useState(true);
  const [includeClosedExtraPayments, setIncludeClosedExtraPayments] = useState(true);
  const [includeOpenExtraPayments, setIncludeOpenExtraPayments] = useState(true);
  const [mode, setMode] = useState('averagePricePerHour');

  const { extendedEntries, entryOpts } = useStateMemo(() => {
    const ext = durationPriceDateSumHelper({
      entries,
      includeClosedBillableTasks,
      includeOpenBillableTasks,
      includeClosedExtraPayments,
      includeOpenExtraPayments,
      includeNonBillableTasks,
    });
    ext.sort((a, b) => b.totalPrice - a.totalPrice);
    const opts = ext.map(entry => ({ value: entry.title, label: entry.title }));
    return {
      extendedEntries: ext,
      entryOpts: opts,
    };
  }, [entries, includeClosedBillableTasks, includeOpenBillableTasks,
    includeClosedExtraPayments, includeOpenExtraPayments, includeNonBillableTasks]);

  useEffect(() => {
    if (selectedEntries.length === 0) {
      setSelectedEntries(entryOpts.slice(0, 5));
    }
  }, [entryOpts]);

  const chosenEntries = useStateMemo(() => {
    const selectedSet = new Set();
    selectedEntries.forEach(({ value }) => selectedSet.add(value));
    return extendedEntries.filter(entry => selectedSet.has(entry.title));
  }, [selectedEntries, extendedEntries]);

  return (
    <ControlledPanel
      title={title}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      md={12}
      lg={6}
    >
      <DateFilter
        onChange={onDateChange}
        state={date}
      />
      <Row className="mt-2 form">
        <SelectFilter
          name={`${groupBy}-duration-price-date-timeseries`}
          options={timeseriesOptions}
          setState={setTimeseries}
          state={timeseries}
          isTranslated
        />
        <SelectFilter
          name={`${groupBy}-duration-price-date-mode`}
          options={modeOptions}
          setState={setMode}
          state={mode}
          isTranslated
        />
      </Row>
      <Row className="mt-2 form">
        <MultiSelectFilter
          setState={setSelectedEntries}
          state={selectedEntries}
          options={entryOpts}
          name={`${groupBy}-duration-price-data-entry-select`}
        />
      </Row>
      <Row className="mt-2 form">
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-billable-tasks`}
          label={t('duration_panel.inc_close_task')}
          setState={setIncludeClosedBillableTasks}
          state={includeClosedBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-billable-tasks`}
          label={t('duration_panel.inc_open_task')}
          setState={setIncludeOpenBillableTasks}
          state={includeOpenBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-non-billable-tasks`}
          label={t('duration_panel.inc_non_task')}
          setState={setIncludeNonBillableTasks}
          state={includeNonBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-extra-payments`}
          label={t('duration_panel.inc_close_extra')}
          setState={setIncludeClosedExtraPayments}
          state={includeClosedExtraPayments}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-extra-payments`}
          label={t('duration_panel.inc_open_extra')}
          setState={setIncludeOpenExtraPayments}
          state={includeOpenExtraPayments}
        />
      </Row>
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {
          loaded ? (
            <DurationPriceDateLineChart
              mode={mode}
              entries={chosenEntries}
              startDateRange={date.start}
              endDateRange={date.end}
            />
          ) : null
        }
      </Loading>
    </ControlledPanel>
  );
};

DurationPriceDatePanel.propTypes = {
  title: PropTypes.string.isRequired,
  groupBy: PropTypes.oneOf(['legalType', 'sector', 'client', 'project', 'team']).isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  fallbackTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DurationPriceDatePanel.defaultProps = {
  projectId: '',
  customerId: '',
  fallbackTitle: 'duration_price_chart.unknown',
};

export default withTranslation('common')(DurationPriceDatePanel);
