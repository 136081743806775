/* eslint-disable no-console */
import React, { PureComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Field, reduxForm, change, formValueSelector,
} from 'redux-form';
import {
  Card, CardBody, CardHeader, CardTitle, TabContent, TabPane,
  Container, Row, Col,
  Nav, NavItem, NavLink, ButtonToolbar, Button,
} from 'reactstrap';
import classNames from 'classnames';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import RenderSelectField from '../../../../shared/components/form/Select';
import RenderDateTimePickerField from '../../../../shared/components/form/DateTimePicker';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import Loading from '../../../../shared/components/Loading';
import { statuses } from '../constants/options';
import { feeUnits } from '../../Projects/constants/options';
import renderHourDuration from '../../../../shared/components/form/HourDuration';
import renderField from '../../../../shared/components/form/FormField';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderSwitchInput from '../../../../shared/components/form/Switch';
import { selectObjectValueSelector } from '../../Projects/helpers';
import {
  getCustomersList, getProjectsList, getTasksList, getTaskTypes,
} from '../../../../store/api/lookup';
import renderTextArea from '../../../../shared/components/form/TextArea';


const baseTaskOptions = [
  { label: 'None', value: null },
];

const descriptionRequired = isRequired('task_form.description');
const customerRequired = isRequired('task_form.customer');
const matterRequired = isRequired('task_form.matter');
const taskTypeRequired = isRequired('task_form.task_type');

const TaskForm = ({
  handleSubmit, customerId, projectId, parentId, close, loading, isNew, changeFormField, t,
}) => {
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  useEffect(() => {
    getCustomersList().then((customerList) => {
      if (customerList) {
        setCustomers(customerList
          .map(({ _id, name }) => ({ label: name, value: _id })));
      }
    });
    getTaskTypes().then((taskTypeList) => {
      if (taskTypeList) {
        setTaskTypes(taskTypeList
          .map(({ _id, name }) => ({ label: name, value: name })));
      }
    });
  }, []);
  useEffect(() => {
    if (customerId) {
      getProjectsList(customerId).then((projectList) => {
        if (projectList) {
          setProjects(projectList
            .map(({ _id, title }) => ({ label: title, value: _id })));
        }
      });
    }
  }, [customerId]);
  useEffect(() => {
    if (projectId) {
      getTasksList(projectId).then((taskList) => {
        if (taskList) {
          setTasks([...baseTaskOptions, ...taskList
            .map(({ _id, title }) => ({ label: title, value: _id })),
          ]);
        }
      });
    }
  }, [projectId]);
  return (
    <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className="mb-3">
              {isNew ? t('task_form.add_task') : t('task_form.edit_task')}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('task_form.customer')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--select">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="customerId"
                  component={RenderSelectField}
                  placeholder={t('task_form.customer')}
                  options={customers}
                  validate={customerRequired}
                />
              </div>
            </div>
          </Col>
          <>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('task_form.matter')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="projectId"
                    component={RenderSelectField}
                    placeholder={t('task_form.matter')}
                    options={projects}
                    validate={matterRequired}
                  />
                </div>
              </div>
            </Col>
          </>
          {parentId && (
            <Col xs={12} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('task_form.parent_task')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="parentId"
                    component={RenderSelectField}
                    placeholder={t('task_form.parent_task')}
                    options={tasks}
                    disabled
                  />
                </div>
              </div>
            </Col>
          )}
          <>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('task_form.description')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    component={renderTextArea}
                    type="text"
                    placeholder={t('task_form.description')}
                    validate={descriptionRequired}
                  />
                </div>
              </div>
            </Col>
          </>
          <Col xs={12} sm={parentId ? 5 : 9}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('task_form.task_type')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--select">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="taskType"
                  component={RenderSelectField}
                  placeholder={t('task_form.task_type')}
                  options={taskTypes}
                  isCreatable
                  validate={taskTypeRequired}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('task_form.is_billable')}</span>
              <div className="form__form-group-field">
                <Field
                  name="isBillable"
                  component={renderSwitchInput}
                />
              </div>
            </div>
          </Col>
          {/* <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="status"
                    component={RenderSelectField}
                    placeholder="Status"
                    options={statuses}
                  />
                </div>
              </div>
            </Col> */}
        </Row>
        {/* <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    placeholder="Title"
                  />
                </div>
              </div>
            </Col>
          </Row> */}
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('task_form.date')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
                <Field
                  name="startDateTime"
                  component={RenderDatePickerField}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('task_form.hours')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
                <Field
                  name="duration"
                  component={renderHourDuration}
                  type="text"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Loading loading={loading}>
              <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                <Button type="button" onClick={close}>{t('task_form.close')}</Button>
                <Button color="primary" type="submit" className="next">
                  {isNew ? t('task_form.add_task') : t('task_form.update_task')}
                </Button>
              </ButtonToolbar>
            </Loading>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

TaskForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  projectId: PropTypes.string,
  parentId: PropTypes.string,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  changeFormField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TaskForm.defaultProps = {
  customerId: '',
  projectId: '',
  parentId: '',
};

const taskFormSelector = formValueSelector('tenant_task_form');

const mapStateToProps = state => ({
  customerId: selectObjectValueSelector(
    taskFormSelector(state, 'customerId'),
  ),
  projectId: selectObjectValueSelector(
    taskFormSelector(state, 'projectId'),
  ),
  taskId: selectObjectValueSelector(
    taskFormSelector(state, 'parentId'),
  ),
});

const mapDispatchToProps = {
  changeFormField: (field, value) => change('tenant_task_form', field, value),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'tenant_task_form',
  })(withTranslation('common')(TaskForm)),
);
