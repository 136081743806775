import withFetch from './withFetch';
import {
  GET_PROFILE_ADMIN, GET_PROFILE_TENANT, EDIT_PROFILE_ADMIN, EDIT_PROFILE_TENANT,
  EDIT_PROFILE_PICTURE_TENANT, EDIT_PROFILE_PICTURE_ADMIN, EDIT_PROFILE_LOGO_TENANT,
  DELETE_PROFILE_LOGO_TENANT, DELETE_PROFILE_PICTURE_TENANT, UNSUBSCRIBE_TENANT,
} from '../fetchTypes';
import { LOG_OUT } from '../sharedTypes';

/*
isTenantAdmin is true when profile is loaded and the tenant is admin, false otherwise
isEmployee is true when profile is loaded and tenant is not admin, false otherwise

So until profile is loaded both are false !
(
  Useful when dealing with issues when it is loading, ie:
  employee pages should only be accessible by the admin
  so in the layout we change the showEmployeeTab to isTenantAdmin,
  so while loading 'Employees' tab will also be hidden.
  however in routes we keep the employee routes while it is loading
  by keeping the employee routes when !isEmployee
  otherwise if we used isTenantAdmin to decide keeping employee routes,
  if the admin reloads the page on employees page,
  while profile is loading, admin would be redirected out of employee page.
)


isSubscribed, isNotSubscribed, isIndividualSubscribed, isEnterpriseSubscribed all work the same.
They all start with false values until profile is loaded, when it is loaded they are updated to true/false
 */

const createInitialState = () => ({
  loading: false,
  loaded: false,
  profile: {
    fname: '',
    lname: '',
    picture: '',
    email: '',
    phone: '',
  },
  updating: false,
  updatingLogo: false,
  isTenantAdmin: false,
  isEmployee: false,
  isNotSubscribed: false,
  isSubscribed: false,
  isIndividualSubscribed: false,
  isEnterpriseSubscribed: false,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROFILE_ADMIN:
    case GET_PROFILE_TENANT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case EDIT_PROFILE_ADMIN:
    case EDIT_PROFILE_TENANT:
      return {
        ...state,
        updateError: '',
        updating: true,
      };
    case EDIT_PROFILE_LOGO_TENANT:
    case DELETE_PROFILE_LOGO_TENANT:
      return {
        ...state,
        updatingLogo: true,
        updateLogoError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROFILE_ADMIN:
    case GET_PROFILE_TENANT: {
      const nextState = {
        ...state,
        loading: false,
        loaded: true,
        profile: action.data,
        isTenantAdmin: action.data.isTenantAdmin, //! action.data.parentId,
        isEmployee: !action.data.isTenantAdmin, //! !action.data.parentId,
      };
      let { subscription } = action.data;
      const isSubscribedCancelled = ['CANCELLED', 'CANCELED'].includes((subscription || {}).subscriptionStatus || '');
      if (isSubscribedCancelled) {
        subscription = null;
      }
      return {
        ...nextState,
        isNotSubscribed: !subscription,
        isSubscribed: !!subscription,
        isIndividualSubscribed: !!subscription && !subscription.isEnterprise,
        isEnterpriseSubscribed: !!subscription && subscription.isEnterprise,
      };
    }
    case EDIT_PROFILE_ADMIN:
    case EDIT_PROFILE_TENANT:
      return {
        ...state,
        updating: false,
        profile: {
          ...state.profile,
          ...action.reqData,
          password: null,
        },
      };
    case EDIT_PROFILE_PICTURE_TENANT:
    case EDIT_PROFILE_PICTURE_ADMIN:
      return {
        ...state,
        profile: {
          ...state.profile,
          picture: action.data.picture,
        },
      };
    case EDIT_PROFILE_LOGO_TENANT:
      return {
        ...state,
        profile: {
          ...state.profile,
          logo: action.data.logo,
        },
        updatingLogo: false,
      };
    case DELETE_PROFILE_PICTURE_TENANT:
      return {
        ...state,
        profile: {
          ...state.profile,
          picture: null,
        },
      };
    case DELETE_PROFILE_LOGO_TENANT:
      return {
        ...state,
        profile: {
          ...state.profile,
          logo: null,
        },
        updatingLogo: false,
      };
    case UNSUBSCRIBE_TENANT:
      return {
        ...state,
        loaded: false,
        error: '',
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROFILE_ADMIN:
    case GET_PROFILE_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EDIT_PROFILE_TENANT:
    case EDIT_PROFILE_ADMIN:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case EDIT_PROFILE_LOGO_TENANT:
    case DELETE_PROFILE_LOGO_TENANT:
      return {
        ...state,
        updatingLogo: false,
        updateLogoError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
