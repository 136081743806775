import { formatLocale } from 'd3';

// eslint-disable-next-line import/no-mutable-exports
let defaultCurrency = '₺';

export const getDefaultCurrency = () => defaultCurrency;

let defaultFormatter;

export const updateDefaultCurrency = (currency) => {
  if (currency) {
    defaultCurrency = currency;
  }
  defaultFormatter = formatLocale({
    currency: ['', defaultCurrency],
    thousands: '.',
    grouping: [3],
    decimal: ',',
  });
};

updateDefaultCurrency();

export const displayFormat = (rawValue, format) => {
  let value = rawValue;
  if (typeof value === 'string') {
    value = parseFloat(value.replace(/[^\d.,]/g, ''));
  }
  if (!value || typeof value !== 'number') {
    value = 0;
  }
  return defaultFormatter.format(format)(value);
};

const displayCurrency = (rawValue, currency) => {
  let value = rawValue;
  if (typeof value === 'string') {
    value = parseFloat(value.replace(/[^\d.,]/g, ''));
  }
  if (!value || typeof value !== 'number') {
    value = 0;
  }
  const formatter = currency ? formatLocale({
    currency: ['', currency],
    thousands: '.',
    grouping: [3],
    decimal: ',',
  }) : defaultFormatter;
  return formatter.format('$,.2f')(value);

  /*
    // MY IMPLEMENTATION WITHOUT D3
    let value = rawValue;
    if (typeof value === 'string') {
      value = parseFloat(value.replace(/[^\d.,]/g, ''));
    }
    if (!value || typeof value !== 'number') {
      value = 0;
    }
    let res = (value % 1000).toFixed(2).replace(/\./g, ',');
    while (value >= 1000) {
      if (value % 1000 < 100) {
        res = `0${res}`;
        if (value % 1000 < 10) {
          res = `0${res}`;
        }
      }
      value = Math.floor(value / 1000);
      res = `${value % 1000}.${res}`;
    }
    return `${res}${currency || defaultCurrency}`; */
};

export default displayCurrency;
