import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchIcon from 'mdi-react/SearchIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';

class SearchBox extends PureComponent {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultValue: '',
    onChange: () => {},
    name: 'unnamed-search-box',
  };

  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      value: defaultValue,
    };
  }

  clearSilent = () => {
    this.setState({ value: '' });
  };

  onClear = (e) => {
    e.preventDefault();
    const { onChange, name } = this.props;
    this.setState({ value: '' });
    onChange('', name);
  };

  onInputChange = (e) => {
    const { onChange, name } = this.props;
    const { value } = e.target;
    this.setState({ value });
    onChange(value, name);
  };

  render() {
    const { t } = this.props;
    const { value } = this.state;

    const searchClass = classNames({
      'search-box__search-field': true,
      'search-box__search-field--open': true,
    });

    return (
      <div className="search-box__search">
        <input
          placeholder={t('search_box.placeholder')}
          className={searchClass}
          value={value}
          onChange={this.onInputChange}
        />
        <button className="search-box__btn search-box__search-btn" type="button" onClick={this.onClear}>
          <CloseIcon />
        </button>
      </div>
    );
  }
}


export default withTranslation('common')(SearchBox);
