import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailIcon from 'mdi-react/MailIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../../shared/components/Loading';

class ResetPasswordConfirmationForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { t, handleSubmit, loading } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Onay Kodu</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailIcon />
            </div>
            <Field
              name="token"
              component="input"
              type="text"
              placeholder="Onay Kodu"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Yeni Şifre</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Yeni Şifre"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/login">Giriş</Link>
            {' | '}
            <Link to="/register">Kayıt</Link>
          </div>
        </div>
        { loading ? <Loading className="mb-2" />
          : (
            <button className="btn btn-primary account__btn account__btn--small mt-2" type="submit">
             Şifreyi Güncelle
            </button>
          )}
        <Link className="btn btn-outline-primary account__btn account__btn--small" to="/reset_password_request">
          Güncelleme E-Postasını Yeniden Gönder
        </Link>
      </form>
    );
  }
}

export default reduxForm({
  form: 'reset_password_confirmation_form',
})(withTranslation('common')(ResetPasswordConfirmationForm));
