import { fork, all } from 'redux-saga/effects';
import watchCurrentProject from './watchCurrentProject';
import watchTaskSoftDelete from './watchTaskSoftDelete';

export default function* subscriptions() {
  try {
    yield all([
      watchCurrentProject(),
      watchTaskSoftDelete(),
    ]);
  } catch (error) {
    console.error('Error at subscriptions saga:', error.message);
  }
}
