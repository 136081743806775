const BASE_URL = 'https://api-advo-test.gj2u.com/';
// if (process.env.REACT_APP_ENV === 'next') {
//   BASE_URL = 'https://prod.advopass.com/';
// }
// if (process.env.NODE_ENV === 'development') {
//   // BASE_URL = 'http://34.254.227.66:8080/';
//   BASE_URL = 'https://prod.advopass.com/';
// }
export const ADMIN_API_URL = `${BASE_URL}admin/api/0.0/`;
export const TENANT_API_URL = `${BASE_URL}tenant/api/0.0/`;
export const APP_VERSION = '1.0';
export const ADMIN_MODE = window.location.hostname.startsWith('admin.');
