import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import CustomPieChart from './components/CustomPieChart';
import useExpenseTypeReport from './hooks/useExpenseTypeReport';
import { useCurrentProject, useProject } from '../Projects/hooks';

const ExpenseTypePanel = ({ title, projectId, customerId }) => {
  const [date, setDate] = useState(initialState);
  const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  const [collapsed, setCollapsed] = useState(false);
  const [projectState] = useProject(projectId);
  const { currency } = projectState.result || {};
  const [{
    loading, loaded, error, result,
  }, reload] = useExpenseTypeReport(
    date,
    projectId,
    customerId,
  );


  return (
    <ControlledPanel
      title={title}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      md={12}
      lg={6}
    >
      <DateFilter
        onChange={onDateChange}
        state={date}
      />
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {
          loaded ? (
            <CustomPieChart
              entries={result}
              dataKey="totalPrice"
              unit={currency}
            />
          ) : null
        }
      </Loading>
    </ControlledPanel>
  );
};

ExpenseTypePanel.propTypes = {
  title: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
};

ExpenseTypePanel.defaultProps = {
  projectId: '',
  customerId: '',
};

export default ExpenseTypePanel;
