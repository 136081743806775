import {
  LANGUAGE_CHANGED,
} from '../sharedTypes';

const initialState = {
  language: 'tr',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return { ...action.data };
    default:
      return state;
  }
}

export const selectLanguage = state => state.language.language;
