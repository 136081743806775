import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import BillTable from './components/BillsTable';
import BillTablePagination from './components/BillTablePagination';
import billType from './types/bill';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import BillList from './components/BillsList';
import FilterButton from '../../../shared/components/filter/FilterButton';
import BillDeletePrompt from './components/BillDeletePrompt';

class Bills extends React.PureComponent {
  static propTypes = {
    getBillsRequest: PropTypes.func.isRequired,
    editBill: PropTypes.func.isRequired,
    updateBill: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    bills: PropTypes.arrayOf(billType).isRequired,
    totalBills: PropTypes.number.isRequired,
    editingBill: PropTypes.shape(),
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    projectId: PropTypes.string,
    currentProject: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  static defaultProps = {
    error: '',
    editingBill: null,
    projectId: '',
    currentProject: null,
  };

  state = {
    activesFilter: 'actives',
    startDate: null,
    endDate: null,
    keyword: '',
    tenureType: '',
    sortBy: '',
    keywords: '',
    category: '',
    billingType: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
    deletingBill: null,
  };

  componentDidMount() {
    this.forceReloadBill();
  }
  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = [
      'activesFilter',
      'startDate',
      'endDate',
      'keyword',
      'tenureType',
      'sortBy',
      'startDateRangeStart',
      'startDateRangeEnd',
      'createdRangeStart',
      'createdRangeEnd',
      'billingType',
      'keywords',
      'dir',
      'currentPage',
      'category',
      'perPage',
    ].reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadBills(force);
  }

  toggleBillStatus = (bill) => {
    const { updateBill } = this.props;
    updateBill({
      _id: bill._id,
      isActive: !bill.isActive,
    });
  };

  promptSoftDelete = (deletingBill = null) => {
    this.setState({
      deletingBill,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editBillModal = (bill) => {
    const { currentProject, projectId, editBill } = this.props;
    const { customerId: customer, title: projectTitle } = currentProject || {};
    const { _id: customerId, name: customerName } = customer || {};

    editBill({
      ...bill,
      projectId: { value: projectId, label: projectTitle },
      customerId: { value: customerId, label: customerName },
      tasks: new Set(bill.tasks || []),
      extras: new Set(bill.extras || []),
    });
  };

  newBillModal = () => {
    const { editBill, projectId, currentProject } = this.props;
    const data = {
      _id: 'new',
    };
    if (projectId) {
      const { customerId: customer, title: projectTitle } = currentProject || {};
      const { _id: customerId, name: customerName } = customer || {};
      data.projectId = { label: projectTitle || projectId, value: projectId };
      if (customerId) {
        data.customerId = { label: customerName || customerId, value: customerId };
      }
    }
    editBill(data);
  };

  closeBillModal = () => {
    const { editBill } = this.props;
    editBill(null);
  };

  forceReloadBill = () => this.maybeReloadBills(true);

  maybeReloadBills = (force = false) => {
    const {
      getBillsRequest, loading, loaded, error, projectId,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter,
        startDate,
        endDate,
        tenureType,
        sortBy,
        category,
        dir,
        keywords,
        currentPage,
        perPage,
        startDateRangeStart,
        startDateRangeEnd,
        createdRangeStart,
        createdRangeEnd,
        billingType,
      } = this.state;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.active = '1';
        else if (activesFilter === 'inactives') filter.inactive = '1';
        else {
          console.error(
            'unknown activesFilter filter in bills',
            activesFilter,
          );
        }
      }
      if (projectId) {
        filter.projectId = projectId;
      }
      getBillsRequest(filter);
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading,
      loaded,
      error,
      bills,
      totalBills,
      editingBill,
      t,
      theme,
      rtl,
      currentProject,
    } = this.props;

    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      type,
      startDateRangeStart,
      startDateRangeEnd,
      createdRangeStart,
      createdRangeEnd,
      sortBy,
      dir,
      currentPage,
      perPage,
      viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    const { currency } = currentProject || {};

    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              {/* <BillsFilter
                openNewBillModel={this.newBillModal}
                updateFilter={this.updateFilter}
                activesFilter={activesFilter}
                startDate={startDate}
                endDate={endDate}
                keyword={keyword}
                type={type}
                theme={theme}
                rtl={rtl.direction}
              /> */}
              <BillTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadBill}
                bills={bills}
                totalBills={totalBills}
                toggleEditBill={this.editBillModal}
                sortBy={sortBy}
                dir={dir}
                // startDateRangeStart={startDateRangeStart}
                // startDateRangeEnd={startDateRangeEnd}
                // createdRangeStart={createdRangeStart}
                // createdRangeEnd={createdRangeEnd}
                updateSort={this.updateSort}
                toggleBillStatus={this.toggleBillStatus}
                currency={currency}
                promptSoftDelete={this.promptSoftDelete}
              />
              <BillTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalBills={totalBills}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      loading,
      loaded,
      error,
      bills,
      totalBills,
      editingBill,
      t,
      theme,
      rtl,
    } = this.props;
    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      tenureType,
      sortBy,
      dir,
      billingType,
      category,
      currentPage,
      perPage,
      keywords,
      viewMode,
      deletingBill,
    } = this.state;

    return (
      <Container className="container__with-filter projects-page customers-page">
        <Row>
          <Col sm={6}>
            <h3 className="page-title">{t('bills.page_title')}</h3>
          </Col>
          <Col sm={6} className="d-flex justify-content-end">
            <Button
              color="success"
              className="icon"
              onClick={this.newBillModal}
            >
              <p>
                <AccountPlusIcon /> {t('bills.add_new')}
              </p>
            </Button>
          </Col>
        </Row>
        {this.renderTableView()}
        <BillDeletePrompt
          onHideModal={this.promptSoftDelete}
          bill={deletingBill}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.bills.loading,
  loaded: state.bills.loaded,
  error: state.bills.error,
  bills: state.bills.bills,
  totalBills: state.bills.totalBills,
  editingBill: state.bills.editingBill,
  theme: state.theme,
  rtl: state.rtl,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = {
  getBillsRequest: TenantActions.Bills.getBillsRequest,
  updateBill: TenantActions.Bills.updateBillRequest,
  editBill: TenantActions.Bills.editBill,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Bills));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
