import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as ExpensePaymentActions from '../../../../store/actions/tenant/expensePayments';

const ExpensePaymentDeletePrompt = ({
  expensePayment, onHideModal, softDeleteExpensePayment,
}) => {
  const { _id } = expensePayment || {};
  let { title } = expensePayment || {};
  title = title || _id;
  const handleDeleteConfirm = () => {
    softDeleteExpensePayment(expensePayment);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('expense_payment_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!expensePayment}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('expense_payment_delete_prompt.message')}
      </h4>
      <p>{title}</p>
    </ModalComponent>
  );
};

ExpensePaymentDeletePrompt.propTypes = {
  expensePayment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteExpensePayment: PropTypes.func.isRequired,
};

ExpensePaymentDeletePrompt.defaultProps = {
  expensePayment: null,
};

const mapDispatchToProps = {
  softDeleteExpensePayment: ExpensePaymentActions.softDeleteExpensePaymentRequest,
};

export default connect(null, mapDispatchToProps)(ExpensePaymentDeletePrompt);
