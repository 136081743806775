import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/es/Spinner';
import Button from 'reactstrap/es/Button';
import ReloadIcon from 'mdi-react/ReloadIcon';
import AlertComponent from './Alert';

const Loading = ({
  render, loading, loaded, error, retry, children, size, className, t,
}) => {
  if (loading) {
    return render(
      <div className={`justify-content-center d-flex col ${className}`}>
        <Spinner color="info" size={size} />
      </div>,
    );
    /*
      <div className="justify-content-center d-flex col pb-4">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
      */
  }
  if (error) {
    return render(
      <AlertComponent icon dismissible={false} color="danger" className={className}>
        <>
          <div>
            <p> { t(error) } </p>
          </div>
          { retry && (
          <Button color="warning" className="loading-btn" onClick={retry} outline>
            <span className="mr-1">
              Retry
            </span>
            <ReloadIcon className="icon__lg" />
          </Button>
          ) }
        </>
      </AlertComponent>,
    );
  }
  if (loaded) {
    return render(children);
  }
  return render(null);
  /*
  return (
    <AlertComponent icon dismissible={false} color="danger" className={className}>
      <>
        <div>
          <p> Not Loaded </p>
        </div>
        { retry && (
          <Button color="primary" className="mt-2" onClick={retry}>
            Retry
          </Button>
        ) }
      </>
    </AlertComponent>
  ); */
};

Loading.propTypes = {
  render: PropTypes.func,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  retry: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md']),
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Loading.defaultProps = {
  render: loader => loader,
  loading: true,
  loaded: true,
  error: undefined,
  retry: undefined,
  children: null,
  size: undefined,
  className: '',
};

export default withTranslation('common')(Loading);
