import {
  EDIT_EXTRA, FETCH_REQUEST, CHANGE_EXTRA_FILTER, CLEAR_EXTRA, CLEAR_EXTRA_FILTER,
} from '../../sharedTypes';
import {
  GET_EXTRAS_TENANT, CREATE_EXTRA_TENANT, UPDATE_EXTRA_TENANT,
  RELOAD_EXTRA_TENANT, DELETE_EXTRA_TENANT, SOFT_DELETE_EXTRA_TENANT,
} from '../../fetchTypes';

export const changeExtraFilter = data => ({
  type: CHANGE_EXTRA_FILTER,
  data,
});

export const clearExtraFilter = () => ({
  type: CLEAR_EXTRA_FILTER,
});

export const clearExtras = () => ({
  type: CLEAR_EXTRA,
});

export const getExtrasRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_EXTRAS_TENANT,
  data,
});

export const reloadExtraRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: RELOAD_EXTRA_TENANT,
  data,
});

export const editExtra = data => ({
  type: EDIT_EXTRA,
  data,
});

export const createExtraRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_EXTRA_TENANT,
  data,
});

export const updateExtraRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_EXTRA_TENANT,
  data,
});

export const softDeleteExtraRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_EXTRA_TENANT,
  data,
});

export const deleteExtraRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_EXTRA_TENANT,
  data,
});
