import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DurationPricePanel from './DurationPricePanel';
import DurationPriceDatePanel from './DurationPriceDatePanel';
import ExpenseTypePanel from './ExpenseTypePanel';
import TaskTypePanel from './TaskTypePanel';
import MatterExpenseGaugePanel from './ExpenseGaugePanel';
import LastMonthWidget from './LastMonthWidget';
import ShortDurationPriceDatePanel from './ShortDurationPriceDatePanel';
import FullDurationPriceDatePanel from './FullDurationPriceDatePanel';
import EmployeeContributionPanel from './EmployeeContributionPanel';

const Graphs = ({
  isEmployee, isTenantAdmin, projectId, customerId, t,
}) => (
  <Container className="container__with-filter dashboard">
    <Row>
      <Col xs={6}>
        <h3 className="page-title">{t('dashboard.page_title')}</h3>
      </Col>
    </Row>
    {<LastMonthWidget customerId={customerId} projectId={projectId} />}
    <Row>
      <ShortDurationPriceDatePanel
        title={t('graphs_index.duration_price_date.overall')}
        projectId={projectId}
        customerId={customerId}
      />
      { isTenantAdmin ? (
        <EmployeeContributionPanel
          title={t('graphs_index.duration_price_date.employee')}
          projectId={projectId}
          customerId={customerId}
        />
      ) : null }
    </Row>
    {/* {
      customerId || projectId ? null : (
        <Row>
          <FullDurationPriceDatePanel
            groupBy="team"
            title={t('graphs_index.duration_price_date.overall')}
            fallbackTitle="duration_price_chart.overall"
            noPieChart
          />
        </Row>
      )
    } */}
    {
      (projectId || customerId) ? null : (
        <Row>
          <FullDurationPriceDatePanel
            groupBy="client"
            title={t('graphs_index.duration_price_date.customer')}
            projectId={projectId}
            customerId={customerId}
            noPieChart={!!customerId}
          />
        </Row>
      )
    }
    <Row>
      <TaskTypePanel
        title={t('graphs_index.other.task_type')}
        projectId={projectId}
        customerId={customerId}
      />
    </Row>
    { projectId ? null : (
      <Row>
        <FullDurationPriceDatePanel
          groupBy="legalType"
          title={t('graphs_index.duration_price_date.legal_type')}
          projectId={projectId}
          customerId={customerId}
        />
      </Row>
    )}
    {
      (customerId || projectId) ? null : (
        <Row>
          <FullDurationPriceDatePanel
            groupBy="sector"
            title={t('graphs_index.duration_price_date.sector')}
            projectId={projectId}
            customerId={customerId}
          />
        </Row>
      )
    }
    {
      projectId ? null : (
        <Row>
          <FullDurationPriceDatePanel
            groupBy="project"
            title={t('graphs_index.duration_price_date.matter')}
            projectId={projectId}
            customerId={customerId}
            noPieChart={!!projectId}
          />
        </Row>
      )
    }
    <Row>
      <MatterExpenseGaugePanel
        projectId={projectId}
        customerId={customerId}
      />
      <ExpenseTypePanel
        title={t('graphs_index.other.expense_type')}
        projectId={projectId}
        customerId={customerId}
      />
    </Row>
  </Container>
);

Graphs.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  isTenantAdmin: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Graphs.defaultProps = {
  projectId: undefined,
  customerId: undefined,
};


const mapStateToProps = state => ({
  isEmployee: state.profile.isEmployee,
  isTenantAdmin: state.profile.isTenantAdmin,
});

export default connect(mapStateToProps)(
  withTranslation('common')(Graphs),
);
