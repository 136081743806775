import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import Loading from '../../../shared/components/Loading';

const Profile = ({
  profile: {
    loading, loaded, error, profile,
  },
}) => (
  <Container>
    <div className="profile">
      <Loading loading={loading} loaded={loaded} error={error} size="sm">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain {...profile} />
              <ProfileCalendar />
              <ProfileTasks />
            </Row>
          </Col>
          <ProfileTabs {...profile} />
        </Row>
      </Loading>
    </div>
  </Container>
);

Profile.propTypes = {
  profile: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    profile: PropTypes.shape().isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Profile);
