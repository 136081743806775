import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import CustomerFormInfo from './CustomerFormInfo';
import CustomerFormTax from './CustomerFormTax';
import CustomerFormAddresses from './CustomerFormAddresses';
import CustomerFormEmails from './CustomerFormEmails';
import CustomerFormPhones from './CustomerFormPhones';
import RenderSelectField from '../../../../shared/components/form/Select';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import ProjectFormInfo from '../../Projects/components/ProjectFormInfo';
import ProjectFormTimeBilling from '../../Projects/components/ProjectFormTimeBilling';
import ProjectFormCase from '../../Projects/components/ProjectFormCase';
import Loading from '../../../../shared/components/Loading';
import { subTypes, types } from '../constants/options';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderField from '../../../../shared/components/form/FormField';

class CustomerForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    tab: 'info',
    show: false,
  };

  constructor(props) {
    super(props);
    this.typeRequired = isRequired('customer_form.type');
    this.subTypeRequired = isRequired('customer_form.subtype');
    this.nameRequired = isRequired('customer_form.name');
    this.peopleInChargeRequired = isRequired('customer_form.people_in_charge');
  }

  setTab = (tab, callback) => {
    this.setState({ tab }, callback);
  };

  setShow = () => this.setState(currentState => ({ show: !currentState.show }));

  render() {
    const {
      handleSubmit, close, loading, isNew, t, type,
    } = this.props;
    const { tab, show } = this.state;

    return (
      <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="mb-3">
                {isNew ? t('customer_form.add_customer') : t('customer_form.edit_customer')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('customer_form.type')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="type"
                    component={RenderSelectField}
                    placeholder={t('customer_form.type')}
                    options={types}
                    validate={this.typeRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                {type === 'corporate' ? (
                  <span className="form__form-group-label required">{t('customer_form.title')}</span>
                ) : <span className="form__form-group-label required">{t('customer_form.name')}</span>}
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  {type === 'corporate' ? (
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder={t('customer_form.title')}
                      validate={this.nameRequired}
                    />
                  ) : (
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder={t('customer_form.name')}
                      validate={this.nameRequired}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {type === 'corporate' ? (
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('customer_form.people_in_charge')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="peopleInCharge"
                      component={renderField}
                      type="text"
                      placeholder={t('customer_form.people_in_charge')}
                    /* validate={this.peopleInChargeRequired} */
                    />
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('customer_form.lead_working')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="subtype"
                    component={RenderSelectField}
                    placeholder={t('customer_form.subtype')}
                    options={subTypes}
                    validate={this.subTypeRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Nav className="float-right" style={{ cursor: 'pointer' }}>
                <NavItem>
                  <NavLink onClick={this.setShow}>
                    {show ? `${t('customer_form.hide_details')}` : `${t('customer_form.show_details')}`}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          {show && (
            <Row>
              <Col xs={12}>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'info' })}
                          onClick={() => {
                            this.setTab('info');
                          }}
                        >
                          {t('customer_form.info')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'addresses' })}
                          onClick={() => {
                            this.setTab('addresses');
                          }}
                        >
                          {t('customer_form.addresses')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'phones' })}
                          onClick={() => {
                            this.setTab('phones');
                          }}
                        >
                          {t('customer_form.phones')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'emails' })}
                          onClick={() => {
                            this.setTab('emails');
                          }}
                        >
                          {t('customer_form.emails')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                      <TabPane tabId="info">
                        <CustomerFormInfo />
                      </TabPane>
                      <TabPane tabId="addresses">
                        <CustomerFormAddresses />
                      </TabPane>
                      <TabPane tabId="phones">
                        <CustomerFormPhones />
                      </TabPane>
                      <TabPane tabId="emails">
                        <CustomerFormEmails />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Loading loading={loading}>
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('customer_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew ? t('customer_form.add_customer') : t('customer_form.update_customer')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const selector = formValueSelector('tenant_customer_form');
const mapStateToProps = (state) => {
  let type = selector(state, 'type') || '';
  if (typeof type === 'object') type = type.value || '';
  return {
    type,
  };
};

export default connect(
  mapStateToProps,
)(
  reduxForm({
    form: 'tenant_customer_form',
  })(withTranslation('common')(CustomerForm)),
);
