import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as ProfileActions from '../../../../store/actions/tenant/profile';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import PreSubscriptionForm from './PreSubscriptionForm';

const PreSubscription = (props) => {
  const {
    profile, updating, updateError, updateProfile,
    theme, rtl, t, showPreSubscriptionModal, toggle, onSuccess,
  } = props;
  const prevUpdating = useRef(false);
  const [pristine, setPristine] = useState(true);
  const onSubmit = useCallback((fields) => {
    if (pristine) {
      return onSuccess();
    }
    let { fname, lname, addresses } = profile;
    if (fields.addresses) {
      // eslint-disable-next-line prefer-destructuring
      addresses = fields.addresses;
    }
    if (!addresses) {
      addresses = [];
    }
    if (fields.fname) {
      // eslint-disable-next-line prefer-destructuring
      fname = fields.fname;
    }
    if (fields.lname) {
      // eslint-disable-next-line prefer-destructuring
      lname = fields.lname;
    }

    const payload = {};

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        payload[key] = fields[key];
      }
    });

    ['billingAddress', 'shippingAddress'].forEach((addressKey) => {
      if (payload[addressKey]) {
        const {
          address, city, country, zipCode,
        } = addresses[payload[addressKey]];
        payload[addressKey] = {
          contactName: `${fname} ${lname}`,
          country,
          city,
          address,
          zipCode,
        };
      }
    });

    if (payload.phone) {
      payload.phone = payload.phone.replace(/[^\d+]+/g, '');
    }

    return updateProfile(payload);
  }, [profile, updateProfile, pristine]);

  useEffect(() => {
    const isSuccess = showPreSubscriptionModal && prevUpdating.current && !updating && !updateError;
    prevUpdating.current = updating;
    if (isSuccess) { // successfully updated just now,
      onSuccess();
    }
  }, [updating, onSuccess, showPreSubscriptionModal]);

  if (!profile) return null;
  const {
    fname, lname, email, phone, identityNumber,
    billingAddress, shippingAddress,
  } = profile;
  let { addresses } = profile;
  if (!addresses) addresses = [];

  let billingAddressIndex = '';
  let shippingAddressIndex = '';
  if (billingAddress) {
    addresses.forEach((address, index) => {
      if (billingAddress.address === address.address) {
        billingAddressIndex = `${index}`;
      }
    });
  }
  if (shippingAddress) {
    addresses.forEach((address, index) => {
      if (shippingAddress.address === address.address) {
        shippingAddressIndex = `${index}`;
      }
    });
  }

  const modalClasses = classNames({
    'todo__add-modal': true,
  }, theme.className, `${rtl.direction}-support`);

  const PreSubscriptionFormInitialValues = {
    fname,
    lname,
    identityNumber,
    phone,
    addresses,
    billingAddress: billingAddressIndex,
    shippingAddress: shippingAddressIndex,
  };

  return (
    <Modal
      isOpen={showPreSubscriptionModal}
      toggle={toggle}
      className={modalClasses}
      color="primary"
      size="md"
    >
      {
        showPreSubscriptionModal && (
        <PreSubscriptionForm
          initialValues={PreSubscriptionFormInitialValues}
          onSubmit={onSubmit}
          close={toggle}
          loading={updating}
          setParentPristine={setPristine}
          parentPristine={pristine}
        />
        )
      }
    </Modal>
  );
};

PreSubscription.propTypes = {
  profile: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    picture: PropTypes.string,
    identityNumber: PropTypes.string.isRequired,
    chamberRegNo: PropTypes.string,
    subscriptionReferenceCode: PropTypes.string,
    fname: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    addresses: PropTypes.arrayOf(PropTypes.shape({
      countryName: PropTypes.string.isRequired,
      cityName: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    })),
    subscription: PropTypes.shape(),
  }),
  updating: PropTypes.bool.isRequired,
  updateError: PropTypes.string,
  updateProfile: PropTypes.func.isRequired,
  showPreSubscriptionModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  t: PropTypes.func.isRequired,
};

PreSubscription.defaultProps = {
  profile: null,
  updateError: '',
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  updating: state.profile.updating,
  updateError: state.profile.updateError,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  updateProfile: ProfileActions.updateProfile,
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(PreSubscription),
);
