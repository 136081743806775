import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import CustomerItem from './CustomerItem';
import customerType from '../types/customer';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import Loading from '../../../../shared/components/Loading';
import ProjectItem from '../../Projects/components/ProjectItem';

class CustomerList extends PureComponent {
  static propTypes = {
    customers: PropTypes.arrayOf(customerType.isRequired).isRequired,
    totalCustomers: PropTypes.number.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    toggleCustomerStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      customers, toggleEditCustomer, toggleCustomerStatus, totalCustomers, promptSoftDelete, t,
    } = this.props;

    if (totalCustomers === 0) {
      return (
        <Card>
          <CardBody>
            {t('customer_list.filter_error')}.
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        {customers.map(customer => (
          <Col xs={12} md={6} xl={4} key={customer._id}>
            <CustomerItem
              customer={customer}
              toggleEditCustomer={() => toggleEditCustomer(customer)}
              toggleCustomerStatus={() => toggleCustomerStatus(customer)}
              promptSoftDelete={() => promptSoftDelete(customer)}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
export default withTranslation('common')(CustomerList);
