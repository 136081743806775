import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import theme from './theme';
import sidebar from './sidebar';
import rtl from './rtl';
import customizer from './customizer';
import language from './language';
import auth from './auth';
import emailVerification from './emailVerification';
import resetPassword from './resetPassword';
import profile from './profile';
import register from './register';
import customers from './customers';
import projects from './projects';
import tasks from './tasks';
import cases from './cases';
import expenses from './expenses';
import expensePayments from './expensePayments';
import extras from './extras';
import reports from './reports';
import bills from './bills';
import messages from './messages';
import employees from './employees';

export default combineReducers({
  auth,
  emailVerification,
  resetPassword,
  theme,
  sidebar,
  rtl,
  customizer,
  language,
  form,
  profile,
  register,
  customers,
  projects,
  tasks,
  cases,
  expenses,
  expensePayments,
  extras,
  bills,
  reports,
  messages,
  employees,
});
