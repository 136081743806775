import { FETCH_REQUEST, VERIFY_EMAIL_VIEWED } from '../../sharedTypes';
import { REGISTER_TENANT, VERIFY_EMAIL_TENANT } from '../../fetchTypes';

export const registerRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: REGISTER_TENANT,
  data,
});

export const verifyEmailViewed = () => ({
  type: VERIFY_EMAIL_VIEWED,
});
