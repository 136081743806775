import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {
  Field, reduxForm, formValueSelector, change,
} from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import classNames from 'classnames';
import { connect, useSelector, useDispatch } from 'react-redux';
import RenderSelectField, { extractSelectValue } from '../../../../shared/components/form/Select';
import RenderDatePickerField, { extractDateString } from '../../../../shared/components/form/DatePicker';
import { useCustomerList } from '../../Customers/hooks';
import { useProject, useProjectList } from '../../Projects/hooks';
import Loading from '../../../../shared/components/Loading';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import BillFormTasks from './BillFormTasks';
import BillFormExtras from './BillFormExtras';
import { useOpenBillableTasks, useOpenExtras } from '../hooks';
import BillFormPricing from './BillFormPricing';
import {
  defaultVatOption, defaultWithholdingOption, languageOptions, vatOptions, withholdingOptions,
} from '../constants/options';
import { selectLanguage } from '../../../../store/reducers/language';

const billFormSelector = formValueSelector('tenant_bill_form');
const customerRequired = isRequired('bill_form.customer');
const matterRequired = isRequired('bill_form.matter');
const withholdingRequired = isRequired('bill_form.withholding');
const vatRequired = isRequired('bill_form.vat');
const languageRequired = isRequired('bill_form.language');

const changeBillForm = (field, value) => change('tenant_bill_form', field, value);

const BillForm = ({
  isNew, handleSubmit, loading, close, t,
}) => {
  const {
    customerId, projectId, periodStart, periodEnd, tasks, extras, language, withholding, vat,
    billedAmount, extrasTotal, taskCost,
  } = useSelector(
    state => billFormSelector(state,
      'customerId', 'projectId', 'periodStart', 'periodEnd', 'tasks', 'extras', 'language', 'withholding', 'vat',
      'billedAmount', 'extrasTotal', 'taskCost'),
  );
  const defaultLanguage = useSelector(state => selectLanguage(state));
  const [projectListState, reloadProjectList] = useProjectList(extractSelectValue(customerId));
  const [customerListState, reloadCustomerList] = useCustomerList();
  const [projectState, reloadProject] = useProject(extractSelectValue(projectId));
  const [tab, setTab] = useState('tasks');

  const { currency } = projectState.result || {};

  const customerList = customerListState.loaded
    ? customerListState.result.map(({ _id, name }) => ({ label: name, value: _id })) : [];
  const projectList = projectListState.loaded
    ? projectListState.result.map(({ _id, title }) => ({ label: title, value: _id })) : [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!periodEnd) {
      dispatch(changeBillForm('periodEnd', new Date()));
    }
    if (periodEnd && !periodStart) {
      dispatch(changeBillForm('periodStart', new Date(periodEnd.getTime() - 30 * 24 * 60 * 60 * 1000)));
    }
  }, [periodStart, periodEnd]);

  const [openBillableTaskListState, reloadOpenBillableTaskList] = useOpenBillableTasks(
    extractSelectValue(projectId),
    extractDateString(periodStart),
    extractDateString(periodEnd),
  );

  const [openExtraListState, reloadOpenExtraList] = useOpenExtras(
    extractSelectValue(projectId),
    extractDateString(periodStart),
    extractDateString(periodEnd),
  );

  return (
    <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className="mb-3">
              {isNew ? t('bill_form.add') : t('bill_form.edit')} {t('bill_form.bill')}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('bill_form.customer')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--select">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="customerId"
                  component={RenderSelectField}
                  placeholder={t('bill_form.customer')}
                  options={customerList}
                  validate={customerRequired}
                  isLoading={customerListState.loading}
                  disabled={!isNew}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('bill_form.matter')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--select">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="projectId"
                  component={RenderSelectField}
                  placeholder={t('bill_form.matter')}
                  options={projectList}
                  validate={matterRequired}
                  isLoading={projectListState.loading}
                  disabled={!isNew}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('bill_form.period_start_date')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
                <Field
                  name="periodStart"
                  component={RenderDatePickerField}
                  isClearable={false}
                  disabled={!isNew}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('bill_form.period_end_date')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
                <Field
                  name="periodEnd"
                  component={RenderDatePickerField}
                  isClearable={false}
                  disabled={!isNew}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('bill_form.withholding')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="withholding"
                      component={RenderSelectField}
                      placeholder={t('bill_form.withholding')}
                      options={withholdingOptions}
                      validate={withholdingRequired}
                      defaultValue={defaultWithholdingOption}
                      isTranslated
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('bill_form.vat')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="vat"
                      component={RenderSelectField}
                      placeholder={t('bill_form.vat')}
                      options={vatOptions}
                      validate={vatRequired}
                      defaultValue={defaultVatOption}
                      isTranslated
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('bill_form.language')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="language"
                      component={RenderSelectField}
                      placeholder={t('bill_form.language')}
                      options={languageOptions}
                      validate={languageRequired}
                      defaultValue={defaultLanguage}
                      isTranslated
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Loading
                  loading={projectState.loading}
                  loaded={projectState.loaded}
                  error={projectState.error}
                  retry={reloadProject}
                >
                  <BillFormPricing
                    projectState={projectState}
                    openBillableTaskListState={openBillableTaskListState}
                    openExtraListState={openExtraListState}
                    tasks={tasks}
                    extras={extras}
                    language={extractSelectValue(language)}
                    withholding={extractSelectValue(withholding)}
                    vat={extractSelectValue(vat)}
                    billedAmount={billedAmount}
                    extrasTotal={extrasTotal}
                    taskCost={taskCost}
                    isNew={isNew}
                  />
                </Loading>
              </Col>
            </Row>
          </Col>
        </Row>
        { isNew ? (
          <>
            <Row>
              <Col xs={12}>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'tasks' })}
                          onClick={() => {
                            setTab('tasks');
                          }}
                        >
                      Tasks
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classNames({ active: tab === 'extras' })}
                          onClick={() => {
                            setTab('extras');
                          }}
                        >
                          {t('bill_form.extra_payments')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                      <TabPane tabId="tasks">
                        <Loading
                          loading={openBillableTaskListState.loading}
                          loaded={openBillableTaskListState.loaded}
                          error={openBillableTaskListState.error}
                          retry={reloadOpenBillableTaskList}
                        >
                          <BillFormTasks
                            taskList={openBillableTaskListState.result || []}
                            selectedTasks={tasks}
                            currency={currency}
                          />
                        </Loading>
                      </TabPane>
                      <TabPane tabId="extras">
                        <Loading
                          loading={openExtraListState.loading}
                          loaded={openExtraListState.loaded}
                          error={openExtraListState.error}
                          retry={reloadOpenExtraList}
                        >
                          <BillFormExtras
                            extraList={openExtraListState.result || []}
                            selectedExtras={extras}
                            currency={currency}
                          />
                        </Loading>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col xs={12}>
            <Loading loading={loading}>
              <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                <Button type="button" onClick={close}>{t('bill_form.close')}</Button>
                <Button color="primary" type="submit" className="next">
                  {isNew ? t('bill_form.add_bill') : t('bill_form.update_bill')}
                </Button>
              </ButtonToolbar>
            </Loading>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

BillForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default
reduxForm({
  form: 'tenant_bill_form',
})(withTranslation('common')(BillForm));
