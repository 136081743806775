import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Collapse, Button, ButtonToolbar, ButtonGroup, CardBody,
} from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import * as TaskActions from '../../../../store/actions/tenant/tasks';
import { selectTaskById, selectFilter, filterToParams } from '../../../../store/reducers/tasks';

import Loading from '../../../../shared/components/Loading';
import { mapDurationValue } from '../../../../shared/components/form/HourDuration';
import TaskBillableStatusBadge from './TaskBillableStatusBadge';
import { toBillableStatus } from '../constants/options';
import ModalComponent from '../../../../shared/components/Modal';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';


const mapStateToProps = (state, { taskId }) => {
  const task = selectTaskById(state, taskId);
  const { parentId } = task.details || {};
  return {
    task,
    parent: selectTaskById(state, parentId),
    filter: selectFilter(state),
  };
};

const mapDispatchToProps = {
  getTasks: TaskActions.getTasksRequest,
  reloadTask: TaskActions.reloadTaskRequest,
  editTask: TaskActions.editTask,
};


const TaskTreeItem = connect(mapStateToProps, mapDispatchToProps)(({
  taskId, showProjectTitle, task, parent, filter, getTasks,
  reloadTask, editTask, t, onDeleteButtonClick,
}) => {
  const {
    loading, loaded, error, childrenLoading, childrenLoaded, childrenLoadError,
    totalChildrenTasks, childrenTasks, details, estimatedHrs,
  } = task;

  const {
    parentId, project = {}, startDateTime, duration, isClose, isBillable,
  } = details || {};

  const billableStatus = toBillableStatus(isClose, isBillable);

  let { title, description } = details || {};
  if (!title) {
    title = '';
  }
  if (!description) {
    description = '';
  }
  if (title.length > 40) {
    title = `${title.substr(0, 37)}...`;
  }

  const projectId = project.id || project._id || '';
  const projectTitle = project.title || '';
  const customer = project.customer || project.customerId || {};
  const customerId = customer.id || customer._id || '';
  const customerName = customer.name || '';

  const editSelf = (e) => {
    e.preventDefault();
    e.stopPropagation();
    editTask({
      ...(details || {}),
      customerId: { value: customerId, label: customerName },
      projectId: { value: projectId, label: projectTitle },
      selectedProject: project,
      parentId: { value: parentId || '', label: ((parent || {}).details || {}).title || parentId || 'None' },
    });
  };

  const deleteSelf = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDeleteButtonClick(taskId, description || taskId);
  };

  useEffect(() => {
    if (!loaded && !loading && !error) {
      reloadTask({ _id: taskId });
    }
  });

  const itemHeadClass = classNames({
    'task-tree__item-head': true,
  });

  const date = (startDateTime || duration)
    ? `(${startDateTime ? moment(startDateTime).format('LL') : ''
    }${startDateTime && duration ? ' - ' : ''
    }${duration ? `${duration} ${t('task_tree.hours')}` : ''})` : '';

  return (
    <Loading
      loading={loading}
      loaded={loaded}
      error={error}
      retry={() => {
        reloadTask({ _id: taskId });
      }}
    >
      <div className={itemHeadClass}>
        <div className="task-tree__item-head-title-block">
          <TaskBillableStatusBadge
            billableStatus={billableStatus}
            className="mt-1"
          />
          {showProjectTitle && (
            <>
              <Link to={`/customers/${customerId}`}>
                <h4 className="subhead">
                  {customerName}
                </h4>
              </Link>
              <Link to={`/matters/${projectId}`}>
                <h4 className="subhead">
                  {projectTitle}
                </h4>
              </Link>
            </>
          )}
          <Link to={`/tasks/${taskId}`}>
            <h5>
              {title}
            </h5>
          </Link>
        </div>
        <h6 className="subhead mx-1 d-none d-md-block" style={{ marginTop: 1 }}>
          {date}
        </h6>
        {isClose ? null : (
          <ButtonToolbar className="my-0 mx-1">
            <ButtonGroup
              className="btn-group--icons m-0"
              dir="ltr"
            /* intentionally left ltr as reactstrap applies border-radius styles directly to first/last children */
            >
              <Button color="primary" className="m-0 p-1" onClick={editSelf}>
                <PencilIcon />
              </Button>
              <Button color="danger" className="m-0 p-1" onClick={deleteSelf}>
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        )}
      </div>
      {/* <pre>
        { JSON.stringify(details) }
      </pre> */}
    </Loading>
  );
});

TaskTreeItem.propTypes = {
  taskId: PropTypes.string.isRequired,
  showProjectTitle: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  /* task: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    childrenLoading: PropTypes.bool.isRequired,
    childrenLoaded: PropTypes.bool.isRequired,
    childrenLoadError: PropTypes.string,
    totalChildrenTasks: PropTypes.number.isRequired,
    childrenTasks: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    details: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      hasChild: PropTypes.bool,
      isActive: PropTypes.bool,
      status: PropTypes.oneOf([100, 101, 102, 200]).isRequired,
      startDateTime: PropTypes.string,
      endDateTime: PropTypes.string,
      estimatedHours: PropTypes.number,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
      }),
    }),
  }).isRequired, */
};

TaskTreeItem.defaultProps = {
  showProjectTitle: false,
};

export default withTranslation('common')(TaskTreeItem);
