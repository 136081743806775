import {
  DELETE_PROFILE_LOGO_TENANT,
  DELETE_PROFILE_PICTURE_TENANT,
  UNSUBSCRIBE_TENANT,
  EDIT_PROFILE_LOGO_TENANT, EDIT_PROFILE_PICTURE_TENANT, EDIT_PROFILE_TENANT, GET_PROFILE_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_PROFILE_TENANT:
      return () => makeFetcher('tenants/profile', 'GET');
    case EDIT_PROFILE_TENANT:
      return data => makeFetcher('tenants/profile', 'PUT', data);
    case EDIT_PROFILE_PICTURE_TENANT:
      return data => makeFetcher('tenants/picture', 'POST', data,
        { 'Content-Type': 'multipart/form-data' });
    case EDIT_PROFILE_LOGO_TENANT:
      return data => makeFetcher('tenants/logo', 'POST', data,
        { 'Content-Type': 'multipart/form-data' });
    case DELETE_PROFILE_PICTURE_TENANT:
      return data => makeFetcher('tenants/profile', 'PUT', { picture: null });
    case DELETE_PROFILE_LOGO_TENANT:
      return data => makeFetcher('tenants/profile', 'PUT', { logo: null });
    case UNSUBSCRIBE_TENANT:
      return data => makeFetcher('subscriptions/cancel', 'POST', {});
    default:
      return null;
  }
};
