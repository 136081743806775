import update from 'immutability-helper';
import withFetch from './withFetch';
import {
  CREATE_TASK_TENANT, GET_TASKS_TENANT, UPDATE_TASK_TENANT, RELOAD_TASK_TENANT, DELETE_TASK_TENANT,
  DELETE_TASK_WITH_CHILDREN_TENANT, GET_CASES_TENANT, GET_CURRENT_CASE_TENANT, CREATE_CASE_TENANT, UPDATE_CASE_TENANT,
} from '../fetchTypes';
import {
  EDIT_TASK, LOG_OUT, CHANGE_TASK_FILTER, CLEAR_TASKS, CLEAR_TASK_FILTER, ADD_CASE, EDIT_CASE, REMOVE_NEW_CASE,
} from '../sharedTypes';

const callIfExists = (obj, ifCallback, elseCallback) => {
  if (obj) {
    return ifCallback && ifCallback(obj);
  }
  return elseCallback && elseCallback(obj);
};

const findFirstActiveIndex = (cases) => {
  let firstActiveIndex = cases.length - 1;
  while ((cases[firstActiveIndex - 1] || {}).isActive) {
    firstActiveIndex -= 1;
  }
  return firstActiveIndex;
};

const createInitialState = () => ({
  cases: [],
  firstActiveIndex: 0,
  loading: false,
  loaded: false,
  casesById: {
    123: {
      loading: false,
      loaded: true,
      error: undefined,
      updating: false,
      Error: undefined,
      isEditing: false,
      details: {
        _id: '123',
        caseName: 'test case',
        caseDate: '1/2/2019',
        caseNumber: '2019/123D',
        decisionName: 'test decision',
        decisionDate: '1/2/2020',
        decisionNumber: '2020/123K',
        isActive: true,
        isComplete: true,
        isSuccess: true,
      },
    },
  },
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_CASES_TENANT:
      return update(state, {
        loading: { $set: true },
        error: { $set: undefined },
        loaded: { $set: false },
        cases: { $set: [] },
        firstActiveIndex: { $set: 0 },
      });
    case GET_CURRENT_CASE_TENANT:
      if (!state.casesById[action.data._id]) {
        return update(state, {
          casesById: {
            [action.data._id]: {
              $set: {
                loaded: false,
                loading: true,
                updating: false,
                isEditing: false,
                details: null,
              },
            },
          },
        });
      }
      return update(state, {
        casesById: {
          [action.data._id]: {
            loading: { $set: true },
            error: { $set: undefined },
          },
        },
      });
    case CREATE_CASE_TENANT:
      return update(state, {
        casesById: {
          new: {
            updating: { $set: true },
            updateError: { $set: undefined },
          },
        },
      });
    case UPDATE_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.data._id]: {
            updating: { $set: true },
            updateError: { $set: undefined },
          },
        },
      });
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_CASES_TENANT:
      return update(state, {
        loading: { $set: false },
        loaded: { $set: true },
        firstActiveIndex: { $set: findFirstActiveIndex(action.data.cases) },
        cases: { $set: action.data.cases.map(({ _id }) => _id) },
        casesById: action.data.cases
          .reduce((acc, caseDetails) => ({
            ...acc,
            [caseDetails._id]: {
              $set: {
                loading: false,
                loaded: true,
                updating: false,
                details: caseDetails,
                isEditing: false,
              },
            },
          }), {}),
      });
    case GET_CURRENT_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.reqData._id]: {
            loading: { $set: false },
            loaded: { $set: true },
            error: { $set: undefined },
            details: { $set: action.data.caseObj },
          },
        },
      });
    case CREATE_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.data.caseObj._id]: {
            $set: {
              loading: false,
              loaded: true,
              updating: false,
              isEditing: false,
              details: action.data.caseObj,
            },
          },
          new: {
            $set: undefined,
          },
        },
        cases: {
          $set: state.cases.map(_id => (_id === 'new' ? action.data.caseObj._id : _id)),
        },
      });
    case UPDATE_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.reqData._id]: {
            updating: { $set: false },
            isEditing: { $set: false },
            details: {
              $merge: action.reqData,
            },
          },
        },
      });
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_TASKS_TENANT:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case GET_CURRENT_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.reqData._id]: {
            loading: { $set: false },
            error: { $set: action.error },
          },
        },
      });
    case CREATE_CASE_TENANT:
      return update(state, {
        casesById: {
          new: {
            updating: { $set: false },
            updateError: { $set: action.error },
          },
        },
      });
    case UPDATE_CASE_TENANT:
      return update(state, {
        casesById: {
          [action.reqData._id]: {
            updating: { $set: false },
            updateError: { $set: action.error },
          },
        },
      });
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case ADD_CASE: {
      const nextCases = state.cases.map(id => state.casesById[id].details);
      const firstActiveIndex = findFirstActiveIndex(nextCases);
      if (firstActiveIndex > 0) {
        return update(state, {
          firstActiveIndex: { $set: firstActiveIndex },
          casesById: {
            [state.cases[firstActiveIndex - 1]]: {
              isEditing: { $set: true },
              details: {
                isActive: { $set: true },
              },
            },
          },
        });
      }
      return update(state, {
        firstActiveIndex: { $set: 0 },
        cases: { $apply: cases => ['new', ...cases] },
        casesById: {
          new: {
            $set: {
              loaded: true,
              loading: false,
              updating: false,
              isEditing: true,
              details: {
                _id: 'new',
                caseName: '',
                caseNumber: '',
                caseDate: '',
                isComplete: false,
                isActive: true,
                isSuccess: false,
              },
            },
          },
        },
      });
    }
    case EDIT_CASE:
      return update(state, {
        casesById: {
          [action.data._id]: {
            isEditing: { $set: true },
          },
        },
      });
    case REMOVE_NEW_CASE:
      return update(state, {
        firstActiveIndex: { $set: 1 },
        casesById: {
          new: {
            isActive: {
              $set: false,
            },
          },
        },
      });
    default:
      return state;
  }
};
/*
export const selectTaskById = (state, taskId) => state.tasks.tasksById[taskId] || {
  loading: false,
  loaded: false,
  error: undefined,
  childrenLoading: false,
  childrenLoaded: false,
  childrenLoadError: undefined,
  totalChildrenTasks: 0,
  childrenTasks: [],
  details: null,
}; */

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
