import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import CloseIcon from 'mdi-react/CloseIcon';
import * as SidebarActions from '../../../store/actions/shared/sidebar';
import { SidebarProps } from '../../prop-types/ReducerProps';
import DefaultSidebar from '../../../containers/Tenant/Layout/sidebar/DefaultSidebar';

const FilterSidebar = ({
  changeFilterSidebarVisibility, sidebar, children,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar-filter': true,
    'sidebar--show': false,
    'sidebar--collapse': sidebar.filterCollapse,
  });

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeFilterSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__content sidebar__content-dynamic">
          <button className="sidebar__toggle-collapse-btn" type="button" onClick={changeFilterSidebarVisibility}>
            <CloseIcon />
          </button>
          { children }
        </div>
      </Scrollbar>
    </div>
  );
};

FilterSidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeFilterSidebarVisibility: PropTypes.func.isRequired,
  children: PropTypes.node,
};

FilterSidebar.defaultProps = {
  children: null,
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  changeFilterSidebarVisibility: SidebarActions.changeFilterSidebarVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSidebar);
