/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import CalendarIcon from 'mdi-react/CalendarIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import classNames from 'classnames';
import projectType from '../types/project';
import {
  tenureTypeToLabel, categoryToLabel, billingTypeToLabel, statusToLabel, billingTypeToFeeUnit, defaultCurrencyObject,
} from '../constants/options';
import { displayCurrency } from '../../../../shared/helpers';
import * as ProjectActions from '../../../../store/actions/tenant/projects';
import * as TaskActions from '../../../../store/actions/tenant/tasks';

class ProjectItem extends Component {
  static propTypes = {
    /* actions: PropTypes.shape({
      editprojects-page: PropTypes.func,
      completeprojects-page: PropTypes.func,
      deleteprojects-page: PropTypes.func,
    }).isRequired, */
    project: projectType.isRequired,
    toggleEditProject: PropTypes.func.isRequired,
    toggleProjectStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    editTask: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    const { project } = this.props;

    /*
    this.state = {
      title: projects-page.title,
      completed: projects-page.completed,
      description: projects-page.description,
      priority: projects-page.priority,
    };
    */
  }

  handleAddTask = () => {
    const { project, editTask } = this.props;
    const { _id: projectId, title: projectTitle, customer } = project;
    const { _id: customerId, name: customerName } = customer || {};
    editTask({
      _id: 'new',
      isBillable: true,
      startDateTime: new Date(),
      customerId: { label: customerName, value: customerId },
      projectId: { label: projectTitle, value: projectId },
    });
  }

  handleEditMatter = () => {
    const { project, editProject } = this.props;

    const { customer } = project;
    const { _id: customerId, name: customerName } = customer || {};

    editProject({
      ...project,
      shouldCap: !!project.capHours,
      customerId: { value: customerId, label: customerName },
    });
  }

  handleComplete() {
    const { project } = this.props;
    // const { checked } = this.state;

    /*
    actions.completeprojects-page(projects-page.id);
    this.setState({
      checked: !checked,
    });
     */
  }

  handleDelete() {
    const { project } = this.props;

    // actions.deleteprojects-page(projects-page.id);
  }

  /*
  handleEdit() {
    const { projects-page, actions } = this.props;

    actions.editprojects-page(projects-page.id);
  }

  handleUpdate() {
    const { projects-page, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updateprojects-page(projects-page.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }

 */

  render() {
    /*
    const {
      title, completed, description, priority,
    } = this.state;
    */

    /*
    const priorityColorsClass = classNames({
      'projects-page__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
     */
    const {
      project, toggleEditProject, toggleProjectStatus, promptSoftDelete, t,
    } = this.props;
    const {
      _id, title, customer, category, status, tenureType, legalType,
      billingType, fee, capHours, feeAfterCap, estimatedHrs, estimatePrice,
      totalBilledAmount, totalBilledAmountForTask,
      totalWorkedHrs, totalBilledHrs, totalUnbilledHrs,
      totalExpense, totalExpensePayments, totalExtras,
      recordExpenditure, currency,
    } = project;
    const { name: customerName, _id: customerId } = customer || {};

    return (
      <Card>
        <CardBody className="customers-page__item pt-0">
          <button
            className="btn btn-secondary customers-page__delete-btn align-self-right"
            type="button"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: '30px',
              height: '30px',
              alignItems: 'center',
              padding: 'initial',
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#000',
              color: '#000',
            }}
            onClick={toggleEditProject}
          >
            <EditIcon className="w-100" style={{ color: '#000' }} />
          </button>
          <fieldset style={{ borderWidth: 1, borderColor: '#000', borderStyle: 'solid' }} className="w-100">
            <legend style={{
              width: 'unset',
              whiteSpace: 'nowrap',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
            >
              <Link to={`/matters/${_id}`}>
                {title}
              </Link>
              <Link to={`/customers/${customerId}`} style={{ marginLeft: 8, fontSize: 16 }}>
                {customerName}
              </Link>

            </legend>

            {/* <label htmlFor={`customer_${_id}_is_active_`} className="customers-page__label-checkbox">
              <input
                id={`customer_${_id}_is_active_`}
                type="checkbox"
                className="customers-page__complete-toggle"
                defaultChecked={isActive}
                required
                onClick={toggleCustomerStatus}
              />
              <span className="checkbox-indicator" />
            </label> */}
            <div className="customers-page__info">

              <div className="customers-page__header">
                {/* <h3>{name}</h3> */}
                <div className="customers-page__additional d-block m-auto">

                  <span className="customers-page__priority d-inline-block p-1 m-1 font-weight-bold">
                    {t(categoryToLabel(category))}
                  </span>
                  {/* <span className="customers-page__priority d-inline-block p-1 m-1">
                    {isActive ? 'Active' : 'Not Active'}
                  </span> */}

                  {/* <span className="customers-page__due-date d-inline-block p-1 m-1 font-weight-bold">
                    {t(tenureTypeToLabel(tenureType))}
                  </span> */}

                  <span className="customers-page__due-date d-inline-block p-1 m-1 font-weight-bold">
                    {t(statusToLabel(status))}
                  </span>
                  <span className="customers-page__due-date d-inline-block p-1 m-1 font-weight-bold">
                    {legalType}
                  </span>
                  {/* <div className="w-100 text-right"> */}
                  {/* <span className="customers-page__due-date d-inline-block p-1 m-1 mt-2">
                      ({customer.name})
                    </span> */}
                  {/* <span className="customers-page__due-date d-inline-block p-1 m-1 mt-2 font-weight-bold">
                    {`${moment(proposalDate).fromNow()}`} <CalendarIcon size="18" />
                  </span> */}
                  {/* </div> */}
                </div>

              </div>
              <div className="separator" />
              <div className="customers-page__content">
                {status === 'active' ? (
                  <>
                    <div className="customers-page__description customers-page__details my-2">
                      <div>
                        <span>
                          <Trans i18nKey={
                        `bill_item.billing_profile_explanation.${
                          // eslint-disable-next-line no-nested-ternary
                          capHours
                            ? feeAfterCap
                              ? 'cap_and_fee_after_cap'
                              : 'cap_but_no_fee_after_cap'
                            : 'no_cap'
                        }`
                      }
                          >
                        Billed <strong>{{ fee: displayCurrency(fee, currency) }}</strong>
                            {' '}
                            {{ fee_unit: t((billingTypeToFeeUnit(billingType) || {}).label || '').toLowerCase() }}
                            {capHours ? ', capped at ' : ''}
                            <strong>
                              { capHours ? { cap_hours: capHours } : ''}
                            </strong>
                            {capHours ? ' hours.' : ''}
                            {(capHours && feeAfterCap) ? ' Afterwards ' : ''}
                            <strong>
                              {(capHours && feeAfterCap) ? {
                                fee_after_cap: displayCurrency(feeAfterCap, currency),
                              } : ''}
                            </strong>
                            {(capHours && feeAfterCap) ? ' per hour.' : ''}
                          </Trans>
                        </span>
                      </div>
                    </div>
                    <div className="separator" />
                  </>
                ) : null}
                <div className="customers-page__description customers-page__details my-2">

                  {/* {caseName && (
                  <div>
                    <b>Case Name: </b>
                    <span>{caseName}</span>
                  </div>
                  )
                  }
                  {caseNumber
                  && (
                  <div>
                    <b>Case Number: </b>
                    <span>{caseNumber}</span>
                  </div>
                  )
                  }
                  {meritsNumber
                  && (
                  <div>
                    <b>Merits Number: </b>
                    <span>{meritsNumber}</span>
                  </div>
                  )
                  } */}
                  {/*                  {legalType
                  && (
                  <div>
                    <b>Legal Type: </b>
                    <span>{legalType}</span>
                  </div>
                  )
                  } */}
                  {/* <h4>Addresses</h4> */}
                  <ul>
                    <li>
                      {/* <CashIcon size="18" /> {' '} */}
                      {t('project_item.total_worked')}: {' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        { totalWorkedHrs || 0 } {t('project_item.hours')} ({
                        totalBilledHrs || 0 } {t('project_item.billed')}, { totalUnbilledHrs || 0 }{' '}
                        {t('project_item.unbilled')})
                      </span>
                    </li>
                    { status === 'active' ? (
                      <>
                        <li>
                          {t('project_item.total_billed')} :
                          <span>
                            {' '} {displayCurrency(totalBilledAmount, currency)
                          } ({displayCurrency(totalBilledAmountForTask, currency)} {t('project_item.for_tasks')})
                          </span>
                        </li>
                        <li>
                          {/* <CashIcon size="18" /> {' '} */}
                          {t('project_item.total_estimated')}: {' '}
                          <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {displayCurrency(estimatePrice, currency)}
                          </span>
                          <span> ({estimatedHrs}{' '}{t('project_item.hours')})</span>
                        </li>
                      </>
                    ) : null }
                    <li>
                      {t('project_item.total_extra')} :
                      <span>
                        {' '} {displayCurrency(totalExtras, currency)}
                      </span>
                    </li>
                    <li>
                      {t('project_item.total_expenses')} :
                      <span>
                        {' '}{`${displayCurrency(totalExpense, currency)
                        }
                        ${t('project_item.spent')} /
                        ${displayCurrency(totalExpensePayments, currency)}
                        ${t('project_item.received')}`}
                      </span>
                    </li>

                  </ul>
                </div>
                <div className="separator w-100" />

                <div
                  className={`my-1 ${''
                  }d-flex flex-wrap justify-content-center align-items-center w-100`}
                >
                  <Button
                    type="button"
                    className="icon m-1"
                    outline
                    onClick={toggleEditProject}
                  >
                    <p><SquareEditOutlineIcon /> {t('project_item.edit')}</p>
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    className="icon m-1"
                    onClick={promptSoftDelete}
                  >
                    <p style={{ color: '#fff' }}>
                      <DeleteIcon /> {t('project_item.delete')}
                    </p>
                  </Button>
                  <Button
                    type="button"
                    color="success"
                    onClick={this.handleAddTask}
                    className="icon m-1"
                  >
                    <p style={{ color: '#fff' }}>
                      <AccountPlusIcon /> {t('project_item.add_task')}
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  editTask: TaskActions.editTask,
  editProject: ProjectActions.editProject,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(ProjectItem));
