import PropTypes from 'prop-types';

const extra = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  // projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  description: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isClose: PropTypes.bool.isRequired,
  appliedDate: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
});

export default extra;
