import React from 'react';
import expenseTeamReportType from '../types/expenseTeamReport';

import { useRequest } from '../../../../shared/hooks';


function useExpenseTeamReport(enabled = true) {
  return useRequest(
    ([expenseTeamReport]) => expenseTeamReport,
    enabled ? 'expenses/team-report' : '',
    useRequest.methods.GET,
  );
}

useExpenseTeamReport.propType = useRequest.createPropType(expenseTeamReportType);

export default useExpenseTeamReport;
