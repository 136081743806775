import {
  CREATE_CASE_TENANT,
  GET_CASES_TENANT,
  GET_CURRENT_CASE_TENANT,
  UPDATE_CASE_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_CASES_TENANT:
      return params => makeFetcher(`projects/${params.matterId}/cases`, 'GET');
    case GET_CURRENT_CASE_TENANT:
      return params => makeFetcher(`projects/cases/${params._id}`, 'GET');
    case CREATE_CASE_TENANT:
      return ({ matterId, ...data }) => makeFetcher(`projects/${matterId}/cases`, 'POST', data);
    case UPDATE_CASE_TENANT:
      return ({ _id, ...data }) => makeFetcher(`projects/cases/${_id}`, 'PUT', data);
    default:
      return null;
  }
};
