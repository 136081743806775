import React from 'react';

import { useRequest } from '../../../../shared/hooks';

function useProjectList(customerId) {
  return useRequest(
    undefined,
    `customers/${customerId || '000000000000000000000000'}/projects`,
    useRequest.methods.GET,
  );
}

export default useProjectList;
