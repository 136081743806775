import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col, Card, Row, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import RegisterFormOne from './RegisterFormOne';
import RegisterFormTwo from './RegisterFormTwo';
import RegisterFormThree from './RegisterFormThree';

class RegisterForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  nextPage = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  render() {
    const { onSubmit, loading, t } = this.props;
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <div className="wizard">
            <div className="wizard__steps">
              {/* <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                <p>{t('register.account_info')}</p>
              </div> */}
              {/* <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                <p>{t('register.personal_info')}</p>
              </div> */}
              {/* <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                <p>{t('register.address_info')}</p>
              </div> */}
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <RegisterFormOne onSubmit={onSubmit} />}
              {/* {page === 2 && <RegisterFormTwo previousPage={this.previousPage} onSubmit={onSubmit} /> } */}
              {/* {page === 3
              && (
                <RegisterFormThree
                  previousPage={this.previousPage}
                  onSubmit={onSubmit}
                  loading={loading}
                />
              ) } */}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withTranslation('common')(RegisterForm);
