import withFetch from './withFetch';
import {
  GET_READ_MESSAGES_TENANT, GET_UNREAD_MESSAGES_TENANT, MARK_MESSAGE_TENANT,
} from '../fetchTypes';
import { LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingMessage: null,
  totalMessages: 0,
  messages: [],
  currentMessage: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_READ_MESSAGES_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_UNREAD_MESSAGES_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case MARK_MESSAGE_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_READ_MESSAGES_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        readmessages: action.data.listMessages,
        totalMessages: action.data.countRead,
      };
    case GET_UNREAD_MESSAGES_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        unreadmessages: action.data.listMessages,
        totalMessages: action.data.countUnread,
      };
    // case CREATE_MESSAGE_TENANT:
    case MARK_MESSAGE_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingMessage: null,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_READ_MESSAGES_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_UNREAD_MESSAGES_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    // case CREATE_MESSAGE_TENANT:
    case MARK_MESSAGE_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    // case EDIT_MESSAGE:
    //   return {
    //     ...state,
    //     editingMessage: action.data,
    //   };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
