import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import ExtraForm from './ExtraForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';

class ExtraModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createExtraRequest: PropTypes.func.isRequired,
    updateExtraRequest: PropTypes.func.isRequired,
    editReport: PropTypes.func.isRequired,
    editingReport: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingReport: null,
  };

  handleSubmit = (data) => {
    const payload = { ...data, customerId: undefined };
    const { createExtraRequest, updateExtraRequest, editingReport } = this.props;
    ['customerId', 'projectId', 'taskId', 'status', 'language']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value;
        }
      });
    if (typeof payload.status === 'string') {
      payload.status = parseInt(payload.status, 10);
    }
    // const formData = new FormData();
    // Object.entries(payload)
    //   .forEach(([key, value]) => {
    //     formData.append(key, value);
    //   });
    if (editingReport._id === 'new') {
      createExtraRequest(payload);
    } else {
      payload._id = editingReport._id;
      const {
        appliedDate, authorId, created, isActive, picture, isClose, __v, customerId, ...updatePayload
      } = payload;
      updateExtraRequest(updatePayload);
    }
  };

  render() {
    const {
      updating, editingReport, theme, rtl,
      editReport,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingReport}
        toggle={() => editReport(null)}
        className={modalClasses}
      >
        {editingReport && (
          <ExtraForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editReport(null)}
            initialValues={editingReport}
            isNew={editingReport._id === 'new'}
          />
        )}
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.extras.updating,
  editingReport: state.reports.editingReport,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createExtraRequest: TenantActions.Reports.createReportRequest,
  updateExtraRequest: TenantActions.Reports.updateReportRequest,
  editReport: TenantActions.Reports.editReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraModal);
