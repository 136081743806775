/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, PieChart, Pie, Label, Text,
} from 'recharts';
import { Row, Col } from 'reactstrap';
import durationPriceType from '../types/durationPrice';
import useRefOnce from '../hooks/useRefOnce';
import { getRandomColorFactory } from './colors';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import Loading from '../../../../shared/components/Loading';
import { displayCurrency, getDefaultCurrency } from '../../../../shared/helpers';

const styles = {
  tooltipWrapper: {
    zIndex: 2,
  },
  legendWrapper: {
    padding: '12px 12px 24px 12px',
  },
};

const tooltipFormatter = (currency, t) => (value, name, { dataKey }) => (
  // eslint-disable-next-line no-nested-ternary
  dataKey === 'totalPrice' ? displayCurrency(value, currency)
    // eslint-disable-next-line no-nested-ternary
    : dataKey === 'averagePricePerHour' ? `${displayCurrency(value, currency)}/${t('bar_chart.hour')}`
      : dataKey === 'totalDuration' ? `${value} ${t('bar_chart.hours')}` : ''
);

const StackedGroupedBarChart = ({
  entries,
  includeClosedBillableTasks, includeOpenBillableTasks, includeNonBillableTasks,
  includeClosedExtraPayments, includeOpenExtraPayments,
  startDateRange, endDateRange, currency, noPieChart, t,
}) => {
  const [state, setState] = useState({ extendedEntries: [] });
  useEffect(() => {
    const extendedEntries = entries;

    const maxPrice = extendedEntries.reduce((a, b) => Math.max(a, b.totalPrice), 0);
    const maxDuration = extendedEntries.reduce((a, b) => Math.max(a, b.totalDuration), 0);
    const totalPrice = extendedEntries.reduce((a, b) => a + b.totalPrice, 0);
    const totalDuration = extendedEntries.reduce((a, b) => a + b.totalDuration, 0);
    const avgPricePerHour = totalPrice / totalDuration;
    setState({
      extendedEntries,
      maxPrice,
      maxDuration,
      totalPrice,
      totalDuration,
      avgPricePerHour,
    });
  }, [entries]);

  const getColor = useRefOnce(getRandomColorFactory);
  const {
    extendedEntries, maxPrice, maxDuration, totalPrice, totalDuration, avgPricePerHour,
  } = state;

  const renderLabelPrice = useRefOnce(
    () => renderCustomizedLabelFactory(getColor, value => displayCurrency(value, currency), true),
  );
  const renderLabelDuration = useRefOnce(
    () => renderCustomizedLabelFactory(getColor, t('bar_chart.h'), true),
  );
  const renderLabelAverage = useRefOnce(
    () => renderCustomizedLabelFactory(getColor,
      value => `${displayCurrency(value, currency)}/${t('bar_chart.h')}`, true),
  );

  const priceScaleMax = Math.round(Math.max(maxPrice, maxDuration * avgPricePerHour) * 1.2 / 100).toFixed(2) * 100;
  const durationScaleMax = Math.round(Math.max(maxDuration, maxPrice / avgPricePerHour) * 1.2).toFixed(2);

  if (entries.length === 0) {
    return (
      <div>
        <h4 style={{ textAlign: 'center', marginTop: 24 }}>
          {t('custom_pie_chart.no_data')}
        </h4>
      </div>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <ResponsiveContainer width="100%" aspect={2 - (entries.length) * 0.1}>
          <BarChart
            data={entries}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" />
            <YAxis
              yAxisId="price"
              unit={currency || getDefaultCurrency()}
              name={t('bar_chart.price')}
              domain={[0, priceScaleMax]}
              scale="linear"
            />
            <YAxis
              yAxisId="duration"
              unit=" hours"
              name={t('bar_chart.duration')}
              orientation="right"
              domain={[0, durationScaleMax]}
              scale="linear"
            />

            {includeClosedBillableTasks ? (
              <Bar
                dataKey="closedBillableTasksPrice"
                name={t('bar_chart.closed_tasks_price')}
                unit={currency || getDefaultCurrency()}
                stackId="price"
                yAxisId="price"
                fill="#deb881"
              />
            ) : null}
            {includeClosedExtraPayments ? (
              <Bar
                dataKey="closedExtraPaymentsPrice"
                name={t('bar_chart.closed_extras_price')}
                unit={currency || getDefaultCurrency()}
                stackId="price"
                yAxisId="price"
                fill="#f6cd90"
              />
            ) : null}
            {includeOpenBillableTasks ? (
              <Bar
                dataKey="openBillableTasksPrice"
                name={t('bar_chart.open_tasks_price')}
                unit={currency || getDefaultCurrency()}
                stackId="price"
                yAxisId="price"
                fill="#eadea6"
              />
            ) : null}
            {includeOpenExtraPayments ? (
              <Bar
                dataKey="openExtraPaymentsPrice"
                name={t('bar_chart.open_extras_price')}
                unit={currency || getDefaultCurrency()}
                stackId="price"
                yAxisId="price"
                fill="#efe9cc"
              />
            ) : null}

            {includeClosedBillableTasks ? (
              <Bar
                dataKey="closedBillableTasksDuration"
                name={t('bar_chart.closed_tasks_duration')}
                unit=" hours"
                stackId="duration"
                yAxisId="duration"
                fill="#698474"
              />
            ) : null}
            {includeOpenBillableTasks ? (
              <Bar
                dataKey="openBillableTasksDuration"
                name={t('bar_chart.open_tasks_duration')}
                unit=" hours"
                stackId="duration"
                yAxisId="duration"
                fill="#889e81"
              />
            ) : null}
            {includeNonBillableTasks ? (
              <Bar
                dataKey="nonBillableTasksDuration"
                name={t('bar_chart.non_billable_tasks_duration')}
                unit=" hours"
                stackId="duration"
                yAxisId="duration"
                fill="#bac7a7"
              />
            ) : null}
            <Legend verticalAlign="top" wrapperStyle={styles.legendWrapper} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={styles.tooltipWrapper}
            />
          </BarChart>
        </ResponsiveContainer>
      </Col>
      { noPieChart ? null : (
        <Col xs={12}>
          <div className="small-pie-heading-container">
            <h5 className="small-pie-heading">
              {t('graph_options.modes.total_price')}
            </h5>
            <h5 className="small-pie-heading">
              {t('graph_options.modes.average_price_per_hour')}
            </h5>
            <h5 className="small-pie-heading">
              {t('graph_options.modes.total_duration')}
            </h5>
          </div>
          <ResponsiveContainer width="100%" aspect={3 - (entries.length) * 0.15}>
            <PieChart>
              <Pie
                cx="20%"
                cy="40%"
                data={extendedEntries}
                nameKey="title"
                dataKey="totalPrice"
                label={renderLabelPrice}
                labelLine={false}
                outerRadius="60%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Pie
                cx="50%"
                cy="40%"
                data={extendedEntries}
                nameKey="title"
                dataKey="averagePricePerHour"
                label={renderLabelAverage}
                labelLine={false}
                outerRadius="60%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Pie
                cx="80%"
                cy="40%"
                data={extendedEntries}
                nameKey="title"
                dataKey="totalDuration"
                label={renderLabelDuration}
                labelLine={false}
                outerRadius="60%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Tooltip
                wrapperStyle={styles.tooltipWrapper}
                formatter={tooltipFormatter(currency, t)}
              />
              <Legend
                verticalAlign="bottom"
                payload={extendedEntries.map((entry, index) => ({
                  color: getColor(index),
                  value: entry.title,
                  payload: entry,
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      )}
      <Col xs={12}>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <h5>{t('bar_chart.total_duration')}: {(totalDuration || 0).toFixed(1)} hours</h5>
          <h5>{t('bar_chart.total_price')}: {displayCurrency(totalPrice || 0, currency)}</h5>
          <h5>{t('bar_chart.avrg_per_hour')}: {
            displayCurrency(avgPricePerHour || 0, currency)}/{t('bar_chart.hour')}
          </h5>
        </div>
      </Col>
    </Row>
  );
};

StackedGroupedBarChart.propTypes = {
  entries: PropTypes.arrayOf(
    durationPriceType.isRequired,
  ).isRequired,
  includeClosedBillableTasks: PropTypes.bool,
  includeOpenBillableTasks: PropTypes.bool,
  includeNonBillableTasks: PropTypes.bool,
  includeClosedExtraPayments: PropTypes.bool,
  includeOpenExtraPayments: PropTypes.bool,
  startDateRange: PropTypes.instanceOf(Date).isRequired,
  endDateRange: PropTypes.instanceOf(Date).isRequired,
  currency: PropTypes.string,
  noPieChart: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

StackedGroupedBarChart.defaultProps = {
  includeClosedBillableTasks: true,
  includeOpenBillableTasks: true,
  includeNonBillableTasks: true,
  includeClosedExtraPayments: true,
  includeOpenExtraPayments: true,
  currency: '',
  noPieChart: false,
};

export default withTranslation('common')(StackedGroupedBarChart);
