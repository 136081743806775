import React from 'react';
import PropTypes from 'prop-types';

const SidebarLink = ({
  title, icon, onClick, iconColor,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="topbar__quick-add-action-button"
  >
    <span
      className={`topbar__quick-add-action--icon lnr lnr-${icon}`}
      style={{ color: iconColor }}
    />
    <p className="topbar__quick-add-action--title">
      {title}
    </p>
  </button>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  iconColor: 'red',
  onClick: () => {},
};

export default SidebarLink;
