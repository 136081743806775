/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import RenderTextField from './TextField';
import { isRequired, passwordMatch } from '../../../../lib/FieldWarnings/FieldWarnings';
import Loading from '../../../../shared/components/Loading';

class ProfilePasswordSettings extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  newPasswordRequired = isRequired('profile_password.new_password');

  passwordConfirmRequired = isRequired('profile_password.confirm_password');

  state = {
    showPassword: false,
    showConfirmPassword: false,
  };

  toggleState = name => () => this.setState(prevState => ({ [name]: !prevState[name] }));

  render() {
    const {
      handleSubmit, reset, loading, t,
    } = this.props;
    const { showPassword, showConfirmPassword } = this.state;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label required">{t('profile_password.new_password')}</span>
          <Field
            name="password"
            component={RenderTextField}
            placeholder={t('profile_password.new_password')}
            type={showPassword ? 'text' : 'password'}
            validate={this.newPasswordRequired}
          />
          <button
            type="button"
            className={`material-form__button${showPassword ? ' active' : ''}`}
            onClick={this.toggleState('showPassword')}
          ><EyeIcon />
          </button>
        </div>
        <div>
          <span className="material-form__label required">{t('profile_password.confirm_password')}</span>
          <Field
            name="confirmPassword"
            component={RenderTextField}
            placeholder={t('profile_password.confirm_password')}
            type={showConfirmPassword ? 'text' : 'password'}
            validate={[this.passwordConfirmRequired, passwordMatch]}
          />
          <button
            type="button"
            className={`material-form__button${showConfirmPassword ? ' active' : ''}`}
            onClick={this.toggleState('showConfirmPassword')}
          ><EyeIcon />
          </button>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          {
            loading ? <Loading />
              : <Button color="primary" type="submit">{t('profile_password.update_info')}</Button>
          }
          <Button type="button" onClick={reset} disabled={loading}>
            {t('profile_password.reset')}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'profile_password_settings_form', // a unique identifier for this form
})(withTranslation('common')(ProfilePasswordSettings));
