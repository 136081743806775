export const LOGIN_ADMIN = 'LOGIN_ADMIN';

export const GET_PROFILE_ADMIN = 'GET_PROFILE_ADMIN';
export const EDIT_PROFILE_ADMIN = 'EDIT_PROFILE_ADMIN';
export const EDIT_PROFILE_PICTURE_ADMIN = 'EDIT_PROFILE_PICTURE_ADMIN';


export const LOGIN_TENANT = 'LOGIN_TENANT';
export const REGISTER_TENANT = 'REGISTER_TENANT';
export const VERIFY_EMAIL_TENANT = 'VERIFY_EMAIL_TENANT';
export const RESEND_EMAIL_VERIFICATION_TENANT = 'RESEND_EMAIL_VERIFICATION_TENANT';
export const RESET_PASSWORD_TENANT = 'RESET_PASSWORD_TENANT';
export const RESET_PASSWORD_CONFIRMATION_TENANT = 'RESET_PASSWORD_CONFIRMATION_TENANT';

export const GET_PROFILE_TENANT = 'GET_PROFILE_TENANT';
export const EDIT_PROFILE_TENANT = 'EDIT_PROFILE_TENANT';
export const EDIT_PROFILE_PICTURE_TENANT = 'EDIT_PROFILE_PICTURE_TENANT';
export const DELETE_PROFILE_PICTURE_TENANT = 'DELETE_PROFILE_PICTURE_TENANT';
export const EDIT_PROFILE_LOGO_TENANT = 'EDIT_PROFILE_LOGO_TENANT';
export const DELETE_PROFILE_LOGO_TENANT = 'DELETE_PROFILE_LOGO_TENANT';
export const UNSUBSCRIBE_TENANT = 'UNSUBSCRIBE_TENANT';

export const GET_CUSTOMERS_TENANT = 'GET_CUSTOMERS_TENANT';
export const GET_CURRENT_CUSTOMER_TENANT = 'GET_CURRENT_CUSTOMER_TENANT';
export const CREATE_CUSTOMER_TENANT = 'CREATE_CUSTOMER_TENANT';
export const UPDATE_CUSTOMER_TENANT = 'UPDATE_CUSTOMER_TENANT';
export const SOFT_DELETE_CUSTOMER_TENANT = 'SOFT_DELETE_CUSTOMER_TENANT';

export const GET_PROJECTS_TENANT = 'GET_PROJECTS_TENANT';
export const GET_CURRENT_PROJECT_TENANT = 'GET_CURRENT_PROJECT_TENANT';
export const CREATE_PROJECT_TENANT = 'CREATE_PROJECT_TENANT';
export const UPDATE_PROJECT_TENANT = 'UPDATE_PROJECT_TENANT';
export const SOFT_DELETE_PROJECT_TENANT = 'SOFT_DELETE_PROJECT_TENANT';

export const GET_CASES_TENANT = 'GET_CASES_TENANT';
export const GET_CURRENT_CASE_TENANT = 'GET_CURRENT_CASE_TENANT';
export const CREATE_CASE_TENANT = 'CREATE_CASE_TENANT';
export const UPDATE_CASE_TENANT = 'UPDATE_CASE_TENANT';
export const DELETE_CASE_TENANT = 'DELETE_CASE_TENANT';

export const GET_TASKS_TENANT = 'GET_TASKS_TENANT';
export const RELOAD_TASK_TENANT = 'RELOAD_TASK_TENANT';
export const CREATE_TASK_TENANT = 'CREATE_TASK_TENANT';
export const UPDATE_TASK_TENANT = 'UPDATE_TASK_TENANT';
export const SOFT_DELETE_TASK_TENANT = 'SOFT_DELETE_TASK_TENANT';
export const DELETE_TASK_TENANT = 'DELETE_TASK_TENANT';
export const DELETE_TASK_WITH_CHILDREN_TENANT = 'DELETE_TASK_WITH_CHILDREN_TENANT';

export const GET_EMPLOYEES_TENANT = 'GET_EMPLOYEES_TENANT';
export const GET_CURRENT_EMPLOYEE_TENANT = 'GET_CURRENT_EMPLOYEE_TENANT';
export const RELOAD_EMPLOYEE_TENANT = 'RELOAD_EMPLOYEE_TENANT';
export const CREATE_EMPLOYEE_TENANT = 'CREATE_EMPLOYEE_TENANT';
export const UPDATE_EMPLOYEE_TENANT = 'UPDATE_EMPLOYEE_TENANT';
export const DELETE_EMPLOYEE_TENANT = 'DELETE_EMPLOYEE_TENANT';
export const DELETE_EMPLOYEE_WITH_CHILDREN_TENANT = 'DELETE_EMPLOYEE_WITH_CHILDREN_TENANT';


export const GET_EXPENSES_TENANT = 'GET_EXPENSES_TENANT';
export const RELOAD_EXPENSE_TENANT = 'RELOAD_EXPENSE_TENANT';
export const CREATE_EXPENSE_TENANT = 'CREATE_EXPENSE_TENANT';
export const UPDATE_EXPENSE_TENANT = 'UPDATE_EXPENSE_TENANT';
export const UPDATE_EXPENSE_PHOTO_TENANT = 'UPDATE_EXPENSE_PHOTO_TENANT';
export const DELETE_EXPENSE_PHOTO_TENANT = 'DELETE_EXPENSE_PHOTO_TENANT';
export const SOFT_DELETE_EXPENSE_TENANT = 'SOFT_DELETE_EXPENSE_TENANT';
export const DELETE_EXPENSE_TENANT = 'DELETE_EXPENSE_TENANT';
export const DELETE_EXPENSE_WITH_CHILDREN_TENANT = 'DELETE_EXPENSE_WITH_CHILDREN_TENANT';
export const GET_CURRENT_EXPENSE_TENANT = 'GET_CURRENT_EXPENSE_TENANT';


export const CREATE_EXPENSE_PAYMENT_TENANT = 'CREATE_EXPENSE_PAYMENT_TENANT';
export const GET_EXPENSE_PAYMENTS_TENANT = 'GET_EXPENSE_PAYMENTS_TENANT';
export const UPDATE_EXPENSE_PAYMENT_TENANT = 'UPDATE_EXPENSE_PAYMENT_TENANT';
export const RELOAD_EXPENSE_PAYMENT_TENANT = 'RELOAD_EXPENSE_PAYMENT_TENANT';
export const SOFT_DELETE_EXPENSE_PAYMENT_TENANT = 'SOFT_DELETE_EXPENSE_PAYMENT_TENANT';
export const DELETE_EXPENSE_PAYMENT_TENANT = 'DELETE_EXPENSE_PAYMENT_TENANT';
export const GET_CURRENT_EXPENSE_PAYMENTS_TENANT = 'GET_CURRENT_EXPENSE_PAYMENTS_TENANT';


export const CREATE_EXTRA_TENANT = 'CREATE_EXTRA_TENANT';
export const GET_EXTRAS_TENANT = 'GET_EXTRAS_TENANT';
export const UPDATE_EXTRA_TENANT = 'UPDATE_EXTRA_TENANT';
export const RELOAD_EXTRA_TENANT = 'RELOAD_EXTRA_TENANT';
export const SOFT_DELETE_EXTRA_TENANT = 'SOFT_DELETE_EXTRA_TENANT';
export const DELETE_EXTRA_TENANT = 'DELETE_EXTRA_TENANT';
export const GET_CURRENT_EXTRAS_TENANT = 'GET_CURRENT_EXTRAS_TENANT';

export const GET_REPORTS_TENANT = 'GET_REPORTS_TENANT';
export const RELOAD_REPORT_TENANT = 'RELOAD_REPORT_TENANT';
export const CREATE_REPORT_TENANT = 'CREATE_REPORT_TENANT';
export const UPDATE_REPORT_TENANT = 'UPDATE_REPORT_TENANT';
export const SOFT_DELETE_REPORT_TENANT = 'SOFT_DELETE_REPORT_TENANT';
export const DELETE_REPORT_TENANT = 'DELETE_REPORT_TENANT';
export const DELETE_REPORT_WITH_CHILDREN_TENANT = 'DELETE_REPORT_WITH_CHILDREN_TENANT';
export const GET_CURRENT_REPORT_TENANT = 'GET_CURRENT_REPORT_TENANT';

export const GET_BILLS_TENANT = 'GET_BILLS_TENANT';
export const CREATE_BILL_TENANT = 'CREATE_BILL_TENANT';
export const UPDATE_BILL_TENANT = 'UPDATE_BILL_TENANT';
export const SOFT_DELETE_BILL_TENANT = 'SOFT_DELETE_BILL_TENANT';

export const GET_READ_MESSAGES_TENANT = 'GET_READ_MESSAGES_TENANT';
export const GET_UNREAD_MESSAGES_TENANT = 'GET_UNREAD_MESSAGES_TENANT';
export const MARK_MESSAGE_TENANT = 'MARK_MESSAGE_TENANT';
