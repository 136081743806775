import { EDIT_PROJECT, FETCH_REQUEST } from '../../sharedTypes';
import {
  GET_PROJECTS_TENANT, CREATE_PROJECT_TENANT, UPDATE_PROJECT_TENANT,
  GET_CURRENT_PROJECT_TENANT, SOFT_DELETE_PROJECT_TENANT,
} from '../../fetchTypes';

export const getProjectsRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_PROJECTS_TENANT,
  data,
});

export const getCurrentProjectRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CURRENT_PROJECT_TENANT,
  data,
});

export const createProjectRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_PROJECT_TENANT,
  data,
});

export const updateProjectRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_PROJECT_TENANT,
  data,
});

export const softDeleteProjectRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_PROJECT_TENANT,
  data,
});

export const editProject = data => ({
  type: EDIT_PROJECT,
  data,
});
