import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import classNames from 'classnames';
import renderSwitchInput from '../../../../shared/components/form/Switch';

import EmployeeFormInfo from '../../Customers/components/CustomerFormInfo';
import EmployeeFormTax from '../../Customers/components/CustomerFormTax';
import EmployeeFormAddresses from '../../Customers/components/CustomerFormAddresses';
import EmployeeFormEmails from '../../Customers/components/CustomerFormEmails';
import EmployeeFormPhones from '../../Customers/components/CustomerFormPhones';
import RenderSelectField from '../../../../shared/components/form/Select';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import ProjectFormInfo from '../../Projects/components/ProjectFormInfo';
import ProjectFormTimeBilling from '../../Projects/components/ProjectFormTimeBilling';
import ProjectFormCase from '../../Projects/components/ProjectFormCase';
import Loading from '../../../../shared/components/Loading';
import { subTypes, types } from '../constants/options';
import {
  isRequired, idNumber, taxNumber, phoneNumber,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import renderField from '../../../../shared/components/form/FormField';
import renderMaskedInput from '../../../../shared/components/form/MaskedInput';
import * as Countries from '../../../../lib/Countries';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';

const idMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

class EmployeeForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    tab: 'info',
    defaultPhoneCodeCountry: 'tr',
  };

  constructor(props) {
    super(props);
    this.state = {
      cityList: [],
      defaultPhoneCodeCountry: 'tr',
    };
    this.countryList = Countries.countriesWithCities.map(countryName => ({
      value: countryName,
      label: countryName,
    }));
    this.countryCodeList = Countries.phoneCodeList
      .map(({ countryName, countryPhoneCode }) => ({
        value: countryPhoneCode,
        label: `${countryName} (${countryPhoneCode})`,
      }));
    this.phoneRequired = isRequired('employee_form.phone');
    this.emailRequired = isRequired('employee_form.email');
    this.firstNameRequired = isRequired('employee_form.first_name');
    this.lastNameRequired = isRequired('employee_form.last_name');
    this.passwordRequired = isRequired('employee_form.password');
  }

  setTab = (tab, callback) => {
    this.setState({ tab }, callback);
  };

  selectCountry = (e, { value }) => {
    this.setState({
      cityList: Countries.citiesByCountry(value)
        .map(cityName => ({
          value: cityName,
          label: cityName,
        })),
      defaultPhoneCodeCountry: Countries.countryCodeByCountry(value).toLowerCase(),
    });
  };

  render() {
    const {
      handleSubmit, close, loading, isNew, t,
    } = this.props;
    const { tab, cityList, defaultPhoneCodeCountry } = this.state;

    return (
      <form className="form employees-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="mb-3">
                {isNew ? t('employee_form.add_employee') : t('employee_form.edit_employee')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('employee_form.first_name')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="fname"
                    component={renderField}
                    type="text"
                    placeholder={t('employee_form.first_name')}
                    validate={this.firstNameRequired}
                  />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('employee_form.last_name')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="lname"
                    component={renderField}
                    type="text"
                    placeholder={t('employee_form.last_name')}
                    validate={this.lastNameRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('employee_form.email')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="email"
                    component={renderField}
                    type="text"
                    placeholder={t('employee_form.enter_email')}
                    validate={this.emailRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {isNew ? (
              <>
                <Col xs={12} sm={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label required">{t('employee_form.password')}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                      </div>
                      <Field
                        name="password"
                        component={renderField}
                        type="text"
                        placeholder={t('employee_form.enter_password')}
                        validate={this.passwordRequired}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('employee_form.identity_number')}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                      </div>
                      <Field
                        name="identityNumber"
                        component={renderMaskedInput}
                        type="text"
                        mask={idMask}
                        warn={idNumber}
                        placeholder={t('employee_form.identity_number')}
                      />
                    </div>
                  </div>
                </Col>
              </>
            )
              : (
                <>
                  <Col xs={6} sm={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('employee_form.identity_number')}</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="identityNumber"
                          component={renderMaskedInput}
                          type="text"
                          mask={idMask}
                          warn={idNumber}
                          placeholder={t('employee_form.identity_number')}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('employee_form.is_active')}</span>
                      <div className="form__form-group-field" />
                      <Field
                        name="isActive"
                        component={renderSwitchInput}
                      />

                    </div>
                  </Col>
                </>
              )
            }

          </Row>
          <Row>
            <Col xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('employee_form.phone')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component={renderPhoneInput}
                    type="text"
                    defaultCountry={defaultPhoneCodeCountry}
                    validate={phoneNumber}
                    placeholder={t('employee_form.phone')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('employee_form.is_admin')}</span>
                <div className="form__form-group-field" />
                <Field
                  name="isTenantAdmin"
                  component={renderSwitchInput}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Loading loading={loading}>
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('employee_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew ? t('employee_form.add_employee') : t('employee_form.edit_employee')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}
export default reduxForm({
  form: 'tenant_employee_form',
})(withTranslation('common')(EmployeeForm));
