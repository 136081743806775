import PropTypes from 'prop-types';

const durationPriceDateType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.oneOfType([
      PropTypes.number.isRequired, PropTypes.object.isRequired,
    ]).isRequired,
    closedBillableTasksPrice: PropTypes.number.isRequired,
    openBillableTasksPrice: PropTypes.number.isRequired,
    closedExtraPaymentsPrice: PropTypes.number.isRequired,
    openExtraPaymentsPrice: PropTypes.number.isRequired,
    closedBillableTasksDuration: PropTypes.number.isRequired,
    openBillableTasksDuration: PropTypes.number.isRequired,
    nonBillableTasksDuration: PropTypes.number.isRequired,
  }).isRequired).isRequired,
});

export default durationPriceDateType;
