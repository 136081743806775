/* eslint-disable react/no-children-prop */
import React, { PureComponent, useState } from 'react';
import {
  Row, Col, Button, ButtonToolbar, Modal,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import {
  isRequired, zipCode,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import RenderTextField from './TextField';
import * as Countries from '../../../../lib/Countries';
import RenderSelectField from './SelectField';
import ControlledPanel from '../../../../shared/components/ControlledPanel';
import Loading from '../../../../shared/components/Loading';

export const countryList = Countries.countriesWithCities.map(countryName => ({
  value: countryName,
  label: countryName,
}));

export const countryRequired = isRequired('profile_address.country');

export const cityRequired = isRequired('profile_address.city');

export const addressRequired = isRequired('profile_address.address_upper');

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const MaskedZipcode = (props) => {
  // eslint-disable-next-line react/prop-types
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={zipCodeMask}
      showMask
    />
  );
};

export class AddressList extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.any.isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
    }).isRequired,
    initialAddresses: PropTypes.arrayOf(PropTypes.shape({
      country: PropTypes.string,
    })).isRequired,
    t: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const { initialAddresses } = this.props;
    this.state = {
      addressStates: initialAddresses.map(({ country }) => ({
        collapsed: false,
        cities: country ? Countries.citiesByCountry(country).map(countryName => ({
          label: countryName, value: countryName,
        })) : [],
      })),
    };
  }

  removeAddressState = (index) => {
    this.setState(({ addressStates }) => ({
      addressStates: [
        ...addressStates.slice(0, index),
        ...addressStates.slice(index + 1),
      ],
    }));
  };

  setAddressState = (index, nextState) => {
    this.setState(({ addressStates }) => ({
      addressStates: [
        ...addressStates.slice(0, index),
        { ...addressStates[index], ...nextState },
        ...addressStates.slice(index + 1),
      ],
    }));
  };

  getAddressState = (index) => {
    const { addressStates } = this.state;
    return addressStates[index] || {};
  };

  renderAddress = (address, index, addresses) => {
    const addressState = this.getAddressState(index);
    const { cities = [], collapsed = false } = addressState;
    const { t } = this.props;

    return (
      <ControlledPanel
        key={`${t('profile_address.address')}_${index}`}
        title={`${t('profile_address.address_upper')} #${index + 1}`}
        onToggle={addresses.length > 1 ? () => {
          addresses.remove(index);
          this.removeAddressState(index);
        } : null}
        collapsed={collapsed}
        onCollapse={() => this.setAddressState(index, {
          collapsed: !collapsed,
        })}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <div>
          <span className="material-form__label required">{t('profile_address.country')}</span>
          <Field
            name={`${address}.country`}
            component={RenderSelectField}
            options={countryList}
            onExtraChange={countryName => this.setAddressState(index, {
              cities: Countries.citiesByCountry(countryName)
                .map(cityName => ({ value: cityName, label: cityName })),
            })}
            validate={countryRequired}
          />
        </div>
        <div>
          <span className="material-form__label required">{t('profile_address.city')}</span>
          <Field
            name={`${address}.city`}
            component={RenderSelectField}
            options={cities}
            validate={cityRequired}
            disabled={cities.length === 0}
          />
        </div>
        <div>
          <span className="material-form__label required">{t('profile_address.address_upper')}</span>
          <Field
            name={`${address}.address`}
            component={RenderTextField}
            placeholder={t('profile_address.address_upper')}
            validate={addressRequired}
            multiline
          />
        </div>
        <div>
          <span className="material-form__label required">{t('profile_address.zip_code')}</span>
          <Field
            name={`${address}.zipCode`}
            component={RenderTextField}
            placeholder={t('profile_address.zip_code')}
            validate={zipCode}
            InputProps={{
              inputComponent: MaskedZipcode,
            }}
          />
        </div>
      </ControlledPanel>
    );
  };

  render() {
    const { fields, meta: { error, submitFailed }, t } = this.props;
    return (
      <>
        <Row>

          <Col md={12} lg={12} xl={12} className="text-right">
            <Button
              type="button"
              color="success"
              onClick={() => fields.push({})}
            >
              {t('profile_address.add_address')}
            </Button>
            {submitFailed && error && <span>{t(error)}</span>}
          </Col>
        </Row>
        <Row>
          {
          fields.map(this.renderAddress)
        }
        </Row>
      </>
    );
  }
}

class ProfileAddressSettings extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      addresses: PropTypes.arrayOf(PropTypes.shape({
        country: PropTypes.string,
      })).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const {
      handleSubmit, reset, initialValues, loading, t,
    } = this.props;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <FieldArray
          name="addresses"
          component={AddressList}
          initialAddresses={initialValues.addresses}
          t={t}
        />
        <ButtonToolbar className="form__button-toolbar">
          {
            loading ? <Loading />
              : <Button color="primary" type="submit">{t('profile_address.update_addresses')}</Button>
          }
          <Button type="button" onClick={reset} disabled={loading}>
            {t('profile_address.reset')}
          </Button>
        </ButtonToolbar>
        <Modal />
      </form>
    );
  }
}

export default reduxForm({
  form: 'profile_address_settings_form', // a unique identifier for this form
})(withTranslation('common')(ProfileAddressSettings));
