import moment from 'moment';

const durationPriceDateSumHelper = ({
  entries,
  includeClosedBillableTasks,
  includeOpenBillableTasks,
  includeOpenExtraPayments,
  includeClosedExtraPayments,
  includeNonBillableTasks,
}) => (entries || []).map(entry => ({
  ...entry,
  data: (entry.data || []).map(data => ({
    ...data,
    date: moment(data.date).valueOf(),
    totalTaskPrice: (includeClosedBillableTasks ? data.closedBillableTasksPrice : 0)
      + (includeOpenBillableTasks ? data.openBillableTasksPrice : 0),
    totalExtraPrice: (includeClosedExtraPayments ? data.closedExtraPaymentsPrice : 0)
      + (includeOpenExtraPayments ? data.openExtraPaymentsPrice : 0),
    totalDuration: (includeClosedBillableTasks ? data.closedBillableTasksDuration : 0)
      + (includeOpenBillableTasks ? data.openBillableTasksDuration : 0)
      + (includeNonBillableTasks ? data.nonBillableTasksDuration : 0),
  })).map(data => ({
    ...data,
    totalPrice: data.totalTaskPrice + data.totalExtraPrice,
  })).map(data => ({
    ...data,
    averagePricePerHour: Math.round(data.totalPrice / (data.totalDuration || 1) * 10).toFixed(2) / 10,
  })),
})).map(entry => ({
  ...entry,
  totalTaskPrice: entry.data.reduce((acc, { totalTaskPrice }) => acc + totalTaskPrice, 0),
  totalExtraPrice: entry.data.reduce((acc, { totalExtraPrice }) => acc + totalExtraPrice, 0),
  totalDuration: entry.data.reduce((acc, { totalDuration }) => acc + totalDuration, 0),
  totalPrice: entry.data.reduce((acc, { totalPrice }) => acc + totalPrice, 0),
})).map(entry => ({
  ...entry,
  averagePricePerHour: Math.round(entry.totalPrice / (entry.totalDuration || 1) * 10).toFixed(2) / 10,
}));

export default durationPriceDateSumHelper;
