import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button,
} from 'reactstrap';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import throttle from 'lodash.throttle';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import { ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import SearchBox from '../../../../shared/components/SearchBox';
import { typesFilter } from '../constants/options';

const activeOptions = [
  { value: '', id: 'all-employees-filter', label: 'All Employees' },
  { value: 'actives', id: 'active-employees-filter', label: 'Active Employees' },
  { value: 'inactives', id: 'inactive-employees-filter', label: 'Inactive Employees' },
];

const employeesPageSidebarImg = `${process.env.PUBLIC_URL}/img/sidebar_img.svg`;

class EmployeeSidebar extends Component {
  static propTypes = {
    openNewEmployeeModel: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    activesFilter: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    keyword: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
  };

  constructor(props) {
    super(props);
    this.handleKeywordChange = throttle(this.handleKeywordChange, 400);
  }

  handleFilterChange = (event) => {
    const { updateFilter } = this.props;
    updateFilter(event.target.value, event.target.name);
  };

  handleDateChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  handleTypeChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'type');
  };

  handleKeywordChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  render() {
    const {
      openNewEmployeeModel,
      activesFilter, startDate, endDate, keyword, type, sortBy, dir, theme, t, rtl,
    } = this.props;

    return (
      <div className="employees-page__input-new">
        <div className="employees-page__sidebar">
          <img className="employees-page_sidebar-image" src={employeesPageSidebarImg} alt="sidebar-img" />
          <Button className="employees-page__btn-add-new" onClick={openNewEmployeeModel}>
            <AccountPlusIcon /> {t('employees.add_new')}
          </Button>
          <div className="employees-page__date-filter">
            <p className="title">{t('employees_filter.status')}</p>
            <ul className="employees-page__date-filter-list">
              {activeOptions.map(({ value, label, id }) => (
                <li key={id}>
                  <input
                    className="employees-page__filter-radio"
                    type="radio"
                    id={id}
                    name="activesFilter"
                    value={value}
                    checked={activesFilter === value}
                    onChange={this.handleFilterChange}
                  />
                  <label htmlFor={id}>{label}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="employees-page__date-filter">
            <p className="title">{t('employees_filter.start_date')}</p>
            <div>
              <RenderDatePickerField
                input={{
                  onChange: this.handleDateChange,
                  name: 'startDate',
                }}
                defaultValue={startDate}
              />
            </div>
          </div>
          <div className="employees-page__date-filter">
            <p className="title">{t('employees_filter.end_date')}</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'endDate',
              }}
              defaultValue={endDate}
            />
          </div>
          <div className="employees-page__select-filter">
            <p className="title">{t('employees_filter.type')}</p>
            <Select
              options={typesFilter}
              onChange={this.handleTypeChange}
              defaultValue={type}
            />
          </div>
          <div className="employees-page__search-filter">
            <p className="title">{t('employees_filter.keyword')}</p>
            <SearchBox
              name="keyword"
              defaultValue={keyword}
              onChange={this.handleKeywordChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(EmployeeSidebar);
