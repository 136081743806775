import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class DefaultSidebar extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h4>{t('dashboard.title')}</h4>
        <p>{t('dashboard.p1')}</p>
        <p>{t('dashboard.p2')}</p>
        <p>{t('dashboard.p3')}</p>
        <p>{t('dashboard.p4')}</p>
        <p>{t('dashboard.p5')}</p>
        <p>{t('dashboard.p6')}</p>
      </div>
    );
  }
}

export default withTranslation('common')(DefaultSidebar);
