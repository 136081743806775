import * as Auth from './auth';
import * as Sidebar from './sidebar';
import * as Theme from './theme';
import * as Language from './language';

export default {
  Auth,
  Sidebar,
  Theme,
  Language,
};
