import * as Auth from './auth';
import * as EmailVerification from './emailVerification';
import * as ResetPassword from './resetPassword';
import * as Profile from './profile';
import * as Register from './register';
import * as Customers from './customers';
import * as Projects from './projects';
import * as Tasks from './tasks';
import * as Expenses from './expenses';
import * as ExpensePayments from './expensePayments';
import * as Extras from './extras';
import * as Bills from './bills';
import * as Reports from './reports';
import * as Messages from './messages';
import * as Employees from './employees';


export default {
  Auth,
  EmailVerification,
  ResetPassword,
  Profile,
  Register,
  Customers,
  Projects,
  Tasks,
  Expenses,
  ExpensePayments,
  Extras,
  Bills,
  Reports,
  Messages,
  Employees,
};
