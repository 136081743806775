import withFetch from './withFetch';
import {
  CREATE_CUSTOMER_TENANT, GET_CURRENT_CUSTOMER_TENANT, GET_CUSTOMERS_TENANT,
  SOFT_DELETE_CUSTOMER_TENANT, UPDATE_CUSTOMER_TENANT,
} from '../fetchTypes';
import { EDIT_CUSTOMER, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingCustomer: null,
  totalCustomers: 0,
  customers: [],
  currentCustomer: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_CUSTOMERS_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_CUSTOMER_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_CUSTOMER_TENANT:
    case UPDATE_CUSTOMER_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_CUSTOMERS_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        customers: action.data.customers,
        totalCustomers: action.data.totalCustomers,
      };
    case GET_CURRENT_CUSTOMER_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentCustomer: action.data.customer,
      };
    case CREATE_CUSTOMER_TENANT:
    case UPDATE_CUSTOMER_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingCustomer: null,
      };
    case SOFT_DELETE_CUSTOMER_TENANT:
      return {
        ...state,
        customers: state.customers.filter(({ _id }) => _id !== action.reqData._id),
        totalCustomers: state.totalCustomers - 1,
        currentCustomer: state.currentCustomer && state.currentCustomer._id === action.reqData._id ? {
          ...state.currentCustomer,
          isActive: false,
        } : state.currentCustomer,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_CUSTOMERS_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_CUSTOMER_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_CUSTOMER_TENANT:
    case UPDATE_CUSTOMER_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_CUSTOMER:
      return {
        ...state,
        editingCustomer: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
