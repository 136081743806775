import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequest } from '../../../../shared/hooks';
import taskType from '../../Tasks/types/task';

const createParams = (projectId, periodStart, periodEnd) => ({
  projectId,
  'startDateRange[0]': periodStart,
  'startDateRange[1]': periodEnd,
  start: 0,
  limit: 400,
  open: 1,
  billable: 1,
  actives: '1',
});

function useOpenBillableTasks(projectId, periodStart, periodEnd) {
  const [params, setParams] = useState(createParams(projectId, periodStart, periodEnd));
  useEffect(() => {
    setParams(createParams(projectId, periodStart, periodEnd));
  }, [projectId, periodStart, periodEnd]);
  return useRequest(
    data => data.tasks,
    (projectId && periodStart && periodEnd) ? 'tasks' : '',
    useRequest.methods.GET,
    params,
  );
}

useOpenBillableTasks.propType = useRequest.createPropType(
  PropTypes.arrayOf(taskType.isRequired),
);

export default useOpenBillableTasks;
