import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Switch, Route, Redirect, useParams,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Container from 'reactstrap/es/Container';
import { useHistory } from 'react-router';
import Tasks from '../Tasks';

import Expenses from '../Expenses';

import SecondaryTopbar from '../Layout/topbar_with_navigation/SecondaryTopbar';
import * as ProjectActions from '../../../store/actions/tenant/projects';
import { categories, isCategoryCase, isCategoryNonCase } from './constants/options';
import CaseList from './Cases/CaseList';
import Extras from '../Extras';
import ExpensePayments from '../ExpensePayments';
import Bills from '../Bills';
import Graphs from '../Graphs';
import Reports from '../Reports';

const baseRoutes = [{
  name: 'matter_page.dashboard',
  route: '/passboard',
}, {
  name: 'matter_page.tasks',
  route: '/tasks',
}, {
  name: 'matter_page.expenses',
  route: '/expenses',
}, {
  name: 'matter_page.expense_payments',
  route: '/expense-payments',
}, {
  name: 'matter_page.extra_payments',
  route: '/extra-payments',
}];

const extraRoutes = {
  reports: {
    name: 'matter_page.reports',
    route: '/reports',
  },
  bills: {
    name: 'matter_page.bills',
    route: '/bills',
  },
  cases: {
    name: 'matter_page.cases',
    route: '/cases',
  },
};

const MatterPage = ({
  getCurrentProject, matter, loaded, loading, error, t, isTenantAdmin, isEmployee,
}) => {
  const { matterId } = useParams();
  const [routes, setRoutes] = useState(baseRoutes);

  useEffect(() => {
    if (!loading && !error && (!loaded || (matter || {})._id !== matterId)) {
      getCurrentProject({ _id: matterId });
    }
  }, [matterId, loaded, loading]);

  const {
    category, isActive, status, customerId: c1, customer: c2,
  } = matter || {};

  useEffect(() => {
    const nextRoutes = [...baseRoutes];
    if (isTenantAdmin) {
      nextRoutes.push(extraRoutes.reports);
      if (status === 'active') {
        nextRoutes.push(extraRoutes.bills);
      }
    }
    if (isCategoryCase(category)) {
      /* show case tab only if category is case related (don't show when loading) */
      nextRoutes.push(extraRoutes.cases);
    }
    setRoutes(nextRoutes);
  }, [isTenantAdmin, status, category]);

  const customer = c1 || c2;

  const customerId = typeof customer === 'string' ? customer : (customer || {})._id;

  const history = useHistory();

  if (isActive === false) {
    if (customerId) {
      history.push(`/customers/${customerId}/matters`);
    } else {
      history.push('/matters');
    }
  }

  return (
    <Container>
      <SecondaryTopbar
        baseRoute={`/matters/${matterId}`}
        routes={routes}
      />
      <Switch>
        <Route
          exact
          path="/matters/:matterId/passboard"
          render={() => <Graphs projectId={matterId} />}
        />
        <Route
          exact
          path="/matters/:matterId/tasks"
          render={() => <Tasks projectId={matterId} />}
        />
        {(isCategoryNonCase(category)) ? null : (
          /* make case route available only if the category is noncase related (keep it when loading) */
          <Route
            exact
            path="/matters/:matterId/cases"
            component={CaseList}
          />
        )}
        <Route
          exact
          path="/matters/:matterId/expenses"
          render={() => <Expenses projectId={matterId} />}
        />
        <Route
          exact
          path="/matters/:matterId/expense-payments"
          render={() => <ExpensePayments projectId={matterId} />}
        />
        <Route
          exact
          path="/matters/:matterId/extra-payments"
          render={() => <Extras projectId={matterId} />}
        />
        {(isEmployee || status === 'potential') ? null : (
          <Route
            exact
            path="/matters/:matterId/bills"
            render={() => <Bills projectId={matterId} />}
          />
        )}
        {isEmployee ? null : (
          <Route
            exact
            path="/matters/:matterId/reports"
            render={() => <Reports projectId={matterId} />}
          />
        )}
        <Redirect to={`/matters/${matterId}/passboard`} />
      </Switch>
    </Container>
  );
};

MatterPage.propTypes = {
  getCurrentProject: PropTypes.func.isRequired,
  matter: PropTypes.shape({
    category: PropTypes.oneOf(categories.map(({ value }) => value)).isRequired,
  }),
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
  isTenantAdmin: PropTypes.bool,
  isEmployee: PropTypes.bool.isRequired,
};
MatterPage.defaultProps = {
  matter: null,
  error: '',
  isTenantAdmin: true,
};

const mapStateToProps = state => ({
  loaded: state.projects.loadedCurrent,
  loading: state.projects.loadingCurrent,
  error: state.projects.errorCurrent,
  matter: state.projects.currentProject,
  isTenantAdmin: state.profile.isTenantAdmin,
  isEmployee: state.profile.isEmployee,
});

const mapDispatchToProps = {
  getCurrentProject: ProjectActions.getCurrentProjectRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MatterPage));
