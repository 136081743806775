import React, { useMemo } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Badge from 'reactstrap/es/Badge';
import ControlledPanel from '../../../../shared/components/ControlledPanel';
import { displayFormat } from '../../../../shared/helpers/displayCurrency';

const goals = [1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500,
  5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000];

const PieChartGoalGraph = ({
  t, color, format, /* goals, */current, loading, title, titleIcon, bodyIcon, unit,
}) => {
  const [data, nextGoal] = useMemo(() => {
    console.log('curr', current);
    let g = Math.max(100000, current + 1);
    for (let i = 0; i < goals.length; i += 1) {
      if (goals[i] > current) {
        g = goals[i];
        break;
      }
    }
    return [
      [
        { value: 100 * current / g, fill: color },
        { value: 100 - (100 * current / g), fill: '#eeeeee' },
      ],
      g,
    ];
  }, [goals, current]);
  return (
    <ControlledPanel
      icon={titleIcon}
      title={title}
      /* label={unit} */
      loading={loading}
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={3}
      bodyClassName="dashboard__health-chart-card"
      titleClassName="d-flex justify-content-center align-items-center"
    >
      <div className="dashboard__health-chart">
        <ResponsiveContainer height={180}>
          <PieChart>
            <Pie data={data} dataKey="value" cy={85} innerRadius={80} outerRadius={90} />
          </PieChart>
        </ResponsiveContainer>
        <div className="dashboard__health-chart-info">
          {bodyIcon}
          <p className="dashboard__health-chart-number">{displayFormat(current, format)}</p>
          <Badge
            className="dashboard__health-chart-unit-badge"
            style={{
              backgroundColor: color,
            }}
          >
            {unit}
          </Badge>
        </div>
      </div>
    </ControlledPanel>
  );
};

PieChartGoalGraph.propTypes = {
  t: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  format: PropTypes.string,
  /* goals: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired, */
  current: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  bodyIcon: PropTypes.node,
  unit: PropTypes.string.isRequired,
};

PieChartGoalGraph.defaultProps = {
  format: ',d',
  titleIcon: '',
  bodyIcon: null,
};

export default withTranslation('common')(PieChartGoalGraph);
