import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Container, Row, Col, Card, CardBody, Button,
} from 'reactstrap';
import { useParams, withRouter } from 'react-router-dom';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import { useHistory } from 'react-router';
import TaskTreeItem from './components/TaskTreeItem';
import FilterButton from '../../../shared/components/filter/FilterButton';
import TasksFilterSidebar from './components/TasksFilterSidebar';
import TaskDeletePrompt from './components/TaskDeletePrompt';
import { selectTaskById } from '../../../store/reducers/tasks';


const TaskPage = ({ t }) => {
  const { id } = useParams();

  const task = useSelector(state => selectTaskById(state, id));

  const { isActive, projectId: p1, project: p2 } = task.details || {};

  const project = p1 || p2;

  const projectId = typeof project === 'string' ? project : (project || {})._id;

  const history = useHistory();

  if (isActive === false) {
    if (projectId) {
      history.push(`/matters/${projectId}/tasks`);
    } else {
      history.push('/tasks');
    }
  }

  const [deletingTask, setDeletingTask] = useState(null);

  return (
    <Container className="container__with-filter">
      <Row>
        <Col xs={6}>
          <h3 className="page-title">{t('task.page_title')}</h3>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <FilterButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <TaskTreeItem taskId={id} onDeleteButtonClick={setDeletingTask} />
              <TaskDeletePrompt
                tasks={deletingTask}
                onHideModal={() => setDeletingTask(null)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <TasksFilterSidebar />
    </Container>
  );
};

TaskPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TaskPage);
