const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const withholdingOptions = [
  { value: '0', label: 'bill_options.withholding.no_withholding' },
  { value: '20', label: 'bill_options.withholding.with_withholding' },
];

export const defaultWithholdingOption = withholdingOptions[1];

export const withholdingOptionToLabel = toLabel(withholdingOptions);

export const vatOptions = [
  { value: '1', label: 'bill_options.vat.one_percent' },
  { value: '8', label: 'bill_options.vat.eight_percent' },
  { value: '18', label: 'bill_options.vat.eighteen_percent' },
];

export const defaultVatOption = vatOptions[2];

export const vatOptionToLabel = toLabel(vatOptions);

export const languageOptions = [
  { value: 'tr', label: 'bill_options.language.turkish' },
  { value: 'en', label: 'bill_options.language.english' },
];

export const languageOptionToLabel = toLabel(languageOptions);
