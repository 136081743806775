import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import EmployeeItem from './EmployeeItem';
import employeeType from '../types/employee';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import Loading from '../../../../shared/components/Loading';

class EmployeeList extends PureComponent {
  static propTypes = {
    employees: PropTypes.arrayOf(employeeType.isRequired).isRequired,
    totalEmployees: PropTypes.number.isRequired,
    toggleEditEmployee: PropTypes.func.isRequired,
    toggleEmployeeStatus: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      employees, toggleEditEmployee, toggleEmployeeStatus, totalEmployees, t,
    } = this.props;
    if (totalEmployees === 0) {
      return (
        <Card>
          <CardBody>
            {t('employee_list.filter_error')}.
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        {employees.map(employee => (
          <Col xs={12} md={6} xl={4} key={employee._id}>
            <EmployeeItem
              employee={employee}
              toggleEditEmployee={() => toggleEditEmployee(employee)}
              toggleEmployeeStatus={() => toggleEmployeeStatus(employee)}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
export default withTranslation('common')(EmployeeList);
