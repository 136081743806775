import axios from 'axios';
import withFetch from './withFetch';
import {
  LOGIN_ADMIN, LOGIN_TENANT, VERIFY_EMAIL_TENANT, REGISTER_TENANT,
} from '../fetchTypes';
import { LOG_OUT } from '../sharedTypes';
import { ADMIN_MODE } from '../../constants';

const tokenName = () => (ADMIN_MODE
  ? 'attorney_admin_token' : 'attorney_tenant_token');

const initialState = {
  loading: false,
  error: undefined,
  token: localStorage.getItem(tokenName()) || '',
};

axios.defaults.headers.Authorization = initialState.token;

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case LOGIN_ADMIN:
    case LOGIN_TENANT:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case LOGIN_ADMIN:
    case LOGIN_TENANT:
    case REGISTER_TENANT:
    case VERIFY_EMAIL_TENANT:
      localStorage.setItem(tokenName(), action.data.token);
      axios.defaults.headers.Authorization = action.data.token;
      return {
        ...state,
        loading: false,
        error: undefined,
        token: action.data.token,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case LOGIN_ADMIN:
    case LOGIN_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      if (action.code === 401) {
        localStorage.removeItem(tokenName());
        axios.defaults.headers.Authorization = '';
        return {
          ...state,
          error: action.error,
          token: '',
        };
      }
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      localStorage.removeItem(tokenName());
      axios.defaults.headers.Authorization = '';
      return {
        ...initialState,
        token: '',
      };
    default:
      return state;
  }
};

export default withFetch(initialState, fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
