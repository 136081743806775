import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import RegisterForm from './newComponents/RegisterForm';
import TenantActions from '../../../store/actions/tenant';

const Register = ({
  registerRequest, registerLoading, verifyEmail, t,
}) => {
  if (verifyEmail) {
    return (
      <Redirect to="/login" />
    );
  }
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card account__card--register">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> Advo
                <span className="account__logo-accent">PASS</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">{t('register.create_account')}</h4>
          </div>
          <RegisterForm
            onSubmit={({
              fname, lname, email, password, // phone, identityNumber, chamberRegNo, country, city, // address, zipCode,
            }) => registerRequest({
              fname,
              lname,
              email,
              password,
              // phone: phone.replace(/[^\d+]+/g, ''),
              // identityNumber,
              // chamberRegNo,
              // addresses: [
              //   {
              //     country: country.value,
              //     city: city.value,
              //     //   address,
              //     //  zipCode,
              //   },
              // ],
            })}
            loading={registerLoading}
          />
          <div className="account__have-account">
            <p>{t('register.already_have_an_account')} <Link to="/login">{t('register.login')}</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  registerRequest: PropTypes.func.isRequired,
  registerLoading: PropTypes.bool.isRequired,
  verifyEmail: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  registerLoading: state.register.loading,
  verifyEmail: state.register.verifyEmail,
});

const mapDispatchToProps = { registerRequest: TenantActions.Register.registerRequest };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Register));
