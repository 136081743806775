import { REGISTER_TENANT } from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case REGISTER_TENANT:
      return data => makeFetcher('tenants/', 'POST', data);
    default:
      return null;
  }
};
