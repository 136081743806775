/* eslint-disable react/no-children-prop */
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const RenderTextField = ({
  input, label, meta: { touched, error, warning }, children, onExtraChange, t, ...extra
}) => (
  <TextField
    {...extra}
    className="material-form__field"
    label={label}
    error={Boolean(touched && error)}
    helperText={touched && (/* eslint-disable-next-line no-nested-ternary */
      (error ? t(error) : warning ? t(warning) : ''))}
    children={children}
    {...input}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
      if (onExtraChange) {
        onExtraChange(e.target.value);
      }
    }}
  />
);

RenderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  onExtraChange: PropTypes.func,
};

RenderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  onExtraChange: null,
};

export default withTranslation('common')(RenderTextField);
