import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const trimSlash = path => `${path.slice(
  path.startsWith('/') ? 1 : 0,
  path.length - (path.endsWith('/') ? 1 : 0),
)}`;

const joinPath = (path1, path2) => `/${trimSlash(path1)}/${trimSlash(path2)}/`;

const SecondaryTopbar = ({ baseRoute, routes, t }) => (
  <div className="topbar topbar--navigation topbar--secondary">
    <div className="topbar__wrapper">
      <nav className="topbar__nav">
        {routes.map(({ route, name }) => (
          <NavLink
            className="topbar__nav-link"
            activeClassName="active"
            key={route}
            to={joinPath(baseRoute, route)}
          >
            { t(name) }
          </NavLink>
        ))}
      </nav>
    </div>
  </div>
);

SecondaryTopbar.propTypes = {
  baseRoute: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  t: PropTypes.func.isRequired,
};
SecondaryTopbar.defaultProps = {
  baseRoute: '',
};

export default withTranslation('common')(SecondaryTopbar);
