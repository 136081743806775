import PropTypes from 'prop-types';

export const caseType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  caseName: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseDate: PropTypes.string.isRequired,
  decisionName: PropTypes.string,
  decisionNumber: PropTypes.string,
  decisionDate: PropTypes.string,
  isComplete: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
});
