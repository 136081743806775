import {
  CREATE_CUSTOMER_TENANT, GET_CURRENT_CUSTOMER_TENANT, GET_CUSTOMERS_TENANT,
  SOFT_DELETE_CUSTOMER_TENANT, UPDATE_CUSTOMER_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_CUSTOMERS_TENANT:
      return params => makeFetcher('customers', 'GET', params);
    case GET_CURRENT_CUSTOMER_TENANT:
      return params => makeFetcher(`customers/${params._id}`, 'GET');
    case CREATE_CUSTOMER_TENANT:
      return data => makeFetcher('customers', 'POST', data);
    case UPDATE_CUSTOMER_TENANT:
      return ({ _id, ...data }) => makeFetcher(`customers/${_id}`, 'PUT', data);
    case SOFT_DELETE_CUSTOMER_TENANT:
      return ({ _id }) => makeFetcher(`customers/${_id}`, 'PUT', { isActive: false });
    default:
      return null;
  }
};
