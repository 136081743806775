import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { useProject } from '../../Projects/hooks';
import { extractSelectValue } from '../../../../shared/components/form/Select';

import Loading from '../../../../shared/components/Loading';
import { calculateTotalPrice } from '../../Projects/helpers';
import { useOpenBillableTasks, useOpenExtras } from '../hooks';
import { displayCurrency, displayPercentile } from '../../../../shared/helpers';
import { withholdingOptionToLabel } from '../constants/options';

const BillFormPricing = ({
  projectState, openBillableTaskListState, openExtraListState, tasks, extras,
  billedAmount, extrasTotal: totalExtras, taskCost: totalTaskPrice, isNew,
  language, withholding, vat, t,
}) => {
  const tasksList = openBillableTaskListState.result || [];
  const extrasList = openExtraListState.result || [];

  const {
    fee, billingType, capHours, feeAfterCap, currency,
  } = projectState.result || {};


  if (isNew) {
    // CALCULATE BELOW FIELDS IF NOT CREATED, OTHERWISIE NOT ALLOWED TO CHANGE THEM.
    const totalWorkedHrs = tasksList
      .filter(({ _id }) => tasks.has(_id))
      .map(({ duration }) => (typeof duration === 'string' ? parseFloat(duration) : duration))
      .reduce((sum, duration) => sum + duration, 0);
    // eslint-disable-next-line no-param-reassign
    totalExtras = extrasList
      .filter(({ _id }) => extras.has(_id))
      .map(({ amount }) => (typeof amount === 'string' ? parseFloat(amount) : amount))
      .reduce((sum, amount) => sum + amount, 0);

    // eslint-disable-next-line no-param-reassign
    totalTaskPrice = calculateTotalPrice(billingType, totalWorkedHrs, fee, capHours, feeAfterCap);
    // eslint-disable-next-line no-param-reassign
    billedAmount = totalTaskPrice + totalExtras;
  }

  // eslint-disable-next-line no-param-reassign
  if (typeof vat === 'string') vat = parseFloat(vat);
  // eslint-disable-next-line no-param-reassign
  if (typeof withholding === 'string') withholding = parseFloat(withholding);

  const withholdingAmount = billedAmount * (1 / (1 - (withholding / 100)) - 1);

  const billedGrossAmount = billedAmount * (1 / (1 - (withholding / 100)));

  const vatAmount = billedGrossAmount * (vat / 100);

  const totalDueAmount = billedAmount + vatAmount;

  return (
    <div>
      <div>
        <b>{t('bill_form_pricing.net_amount')}: </b>
        <span>{displayCurrency(totalTaskPrice, currency)}</span>
        <span> + </span>
        <span>{displayCurrency(totalExtras, currency)}</span>
        <span> = </span>
        <b>{ displayCurrency(billedAmount, currency)}</b>
      </div>
      <div>
        <b>{t('bill_form_pricing.withholding')} </b>
        <span>({displayPercentile(withholding, { language, fractionDigits: 0 })})</span>
        <span> = </span>
        <b>{ displayCurrency(withholdingAmount, currency)}</b>
      </div>
      <div>
        <b>{t('bill_form_pricing.gross_amount')}: </b>
        <span>{displayCurrency(billedAmount, currency)}</span>
        <span> + </span>
        <span>{displayCurrency(withholdingAmount, currency)}</span>
        <span> = </span>
        <b>{ displayCurrency(billedGrossAmount, currency)}</b>
      </div>
      <div>
        <b>{t('bill_form_pricing.vat')} </b>
        <span>({displayPercentile(vat, { language, fractionDigits: 0 })})</span>
        <span> = </span>
        <b>{ displayCurrency(vatAmount, currency)}</b>
      </div>
      <div>
        <b>{t('bill_form_pricing.total_due')}: </b>
        <span>{displayCurrency(billedAmount, currency)}</span>
        <span> + </span>
        <span>{displayCurrency(vatAmount, currency)}</span>
        <span> = </span>
        <b>{ displayCurrency(totalDueAmount, currency)}</b>
      </div>
    </div>
  );
};

BillFormPricing.propTypes = {
  projectState: useProject.propType.isRequired,
  openBillableTaskListState: useOpenBillableTasks.propType.isRequired,
  openExtraListState: useOpenExtras.propType.isRequired,
  tasks: PropTypes.instanceOf(Set),
  extras: PropTypes.instanceOf(Set),
  billedAmount: PropTypes.number,
  extrasTotal: PropTypes.number,
  taskCost: PropTypes.number,
  isNew: PropTypes.bool.isRequired,
  language: PropTypes.string,
  withholding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func.isRequired,
};
BillFormPricing.defaultProps = {
  tasks: new Set(),
  extras: new Set(),
  billedAmount: undefined,
  extrasTotal: undefined,
  taskCost: undefined,
  language: 'tr',
  withholding: 20,
  vat: 18,
};

export default withTranslation('common')(BillFormPricing);
