/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import ProjectsTable from './components/ProjectsTable';
import ProjectsTablePagination from './components/ProjectsTablePagination';
import projectType from './types/project';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import * as TaskActions from '../../../store/actions/tenant/tasks';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import ProjectList from './components/ProjectList';
import ProjectsFilterSidebar from './components/ProjectsFilterSidebar';
import FilterButton from '../../../shared/components/filter/FilterButton';
import MatterDeletePrompt from './components/MatterDeletePrompt';

class Projects extends React.PureComponent {
  static propTypes = {
    getProjectsRequest: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    customerId: PropTypes.string,
    projects: PropTypes.arrayOf(projectType).isRequired,
    totalProjects: PropTypes.number.isRequired,
    editingProject: PropTypes.shape(),
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    currentCustomer: PropTypes.shape(),
  };

  static defaultProps = {
    error: '',
    customerId: '',
    editingProject: null,
    currentCustomer: null,
  };

  state = {
    activesFilter: 'actives',
    status: '',
    startDate: null,
    endDate: null,
    keyword: '',
    tenureType: '',
    sortBy: '',
    keywords: '',
    category: '',
    billingType: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
    deletingMatter: null,
  };

  componentDidMount() {
    this.forceReloadProject();
  }
  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = [
      'activesFilter',
      'status',
      'startDate',
      'endDate',
      'keyword',
      'tenureType',
      'sortBy',
      'startDateRangeStart',
      'startDateRangeEnd',
      'createdRangeStart',
      'createdRangeEnd',
      'billingType',
      'keywords',
      'dir',
      'currentPage',
      'category',
      'perPage',
    ].reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadProjects(force);
  }

  toggleProjectStatus = (project) => {
    const { updateProject } = this.props;
    updateProject({
      _id: project._id,
      isActive: !project.isActive,
    });
  };

  promptSoftDelete = (deletingMatter = null) => {
    this.setState({
      deletingMatter,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editProjectModal = (project) => {
    const { editProject } = this.props;
    const { customer } = project;
    const { _id: customerId, name: customerName } = customer || {};
    editProject({
      ...project,
      shouldCap: !!project.capHours,
      customerId: { value: customerId, label: customerName },
    });
  };

  newProjectModal = () => {
    const { editProject, customerId, currentCustomer } = this.props;
    const data = { _id: 'new', status: 'active' };
    const { name: customerName } = currentCustomer || {};
    if (customerId) {
      data.customerId = { value: customerId, label: customerName || customerId };
    }
    editProject(data);
  };

  closeProjectModal = () => {
    const { editProject } = this.props;
    editProject(null);
  };

  forceReloadProject = () => this.maybeReloadProjects(true);

  maybeReloadProjects = (force = false) => {
    const {
      getProjectsRequest, loading, loaded, error,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter,
        status,
        startDate,
        endDate,
        tenureType,
        sortBy,
        category,
        dir,
        keywords,
        currentPage,
        perPage,
        startDateRangeStart,
        startDateRangeEnd,
        createdRangeStart,
        createdRangeEnd,
        billingType,
      } = this.state;
      const { customerId } = this.props;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.actives = '1';
        else if (activesFilter === 'inactives') filter.inactives = '1';
        else {
          console.error(
            'unknown activesFilter filter in projects',
            activesFilter,
          );
        }
      }

      if (status) filter.status = status;
      if (createdRangeStart) filter['createdRange[0]'] = moment(startDate).format('YYYY-MM-DD');
      if (createdRangeEnd) filter['createdRange[1]'] = moment(startDate).format('YYYY-MM-DD');
      if (startDateRangeStart) filter['proposalRange[0]'] = moment(endDate).format('YYYY-MM-DD');
      if (startDateRangeEnd) filter['proposalRange[1]'] = moment(endDate).format('YYYY-MM-DD');
      if (keywords) filter.keywords = keywords;
      if (category) filter.category = category;
      if (tenureType) filter.tenureType = tenureType;
      if (billingType) filter.billingType = billingType;
      if (customerId) filter.customerId = customerId;

      if (sortBy) filter.sortBy = sortBy;
      if (dir) filter.dir = dir;
      getProjectsRequest(filter);
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading,
      loaded,
      error,
      projects,
      totalProjects,
      editingProject,
      t,
      theme,
      rtl,
    } = this.props;

    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      type,
      startDateRangeStart,
      startDateRangeEnd,
      createdRangeStart,
      createdRangeEnd,
      sortBy,
      dir,
      currentPage,
      perPage,
      viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              {/* <ProjectsFilter
                openNewProjectModel={this.newProjectModal}
                updateFilter={this.updateFilter}
                activesFilter={activesFilter}
                startDate={startDate}
                endDate={endDate}
                keyword={keyword}
                type={type}
                theme={theme}
                rtl={rtl.direction}
              /> */}
              <ProjectsTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadProject}
                projects={projects}
                totalProjects={totalProjects}
                toggleEditProject={this.editProjectModal}
                sortBy={sortBy}
                dir={dir}
                // startDateRangeStart={startDateRangeStart}
                // startDateRangeEnd={startDateRangeEnd}
                // createdRangeStart={createdRangeStart}
                // createdRangeEnd={createdRangeEnd}
                updateSort={this.updateSort}
                toggleProjectStatus={this.toggleProjectStatus}
                promptSoftDelete={this.promptSoftDelete}
              />
              <ProjectsTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalProjects={totalProjects}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      loading,
      loaded,
      error,
      projects,
      totalProjects,
      editingProject,
      t,
      theme,
      rtl,
    } = this.props;

    const {
      activesFilter,
      status,
      startDate,
      endDate,
      keyword,
      tenureType,
      sortBy,
      dir,
      billingType,
      category,
      currentPage,
      perPage,
      keywords,
      viewMode,
      deletingMatter,
    } = this.state;

    return (
      <Container className="container__with-filter projects-page customers-page">
        <Row>
          <Col sm={3}>
            <h3 className="page-title">{t('projects.page_title')}</h3>
          </Col>
          <Col sm={9} className="d-flex justify-content-end flex-wrap">
            <Button
              color="success"
              className="icon"
              onClick={this.newProjectModal}
            >
              <p>
                <AccountPlusIcon /> {t('projects.add_new')}
              </p>
            </Button>
            <FilterButton />
          </Col>
        </Row>
        {this.renderTableView()}
        <ProjectsFilterSidebar
          updateFilter={this.updateFilter}
          activesFilter={activesFilter}
          status={status}
          startDate={startDate}
          endDate={endDate}
          billingType={billingType}
          keywords={keywords}
          tenureType={tenureType}
          sortBy={sortBy}
          dir={dir}
          category={category}
          theme={theme}
          rtl={rtl.direction}
        />
        <MatterDeletePrompt
          matter={deletingMatter}
          onHideModal={this.promptSoftDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.projects.loading,
  loaded: state.projects.loaded,
  error: state.projects.error,
  projects: state.projects.projects,
  totalProjects: state.projects.totalProjects,
  editingProject: state.projects.editingProject,
  theme: state.theme,
  rtl: state.rtl,
  currentCustomer: state.customers.currentCustomer,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = {
  getProjectsRequest: TenantActions.Projects.getProjectsRequest,
  updateProject: TenantActions.Projects.updateProjectRequest,
  editProject: TenantActions.Projects.editProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Projects));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
