import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Container, Row, Col, Card, CardBody, Modal, Button,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import LockIcon from 'mdi-react/LockIcon';
import classNames from 'classnames';
import FilterButton from '../../../shared/components/filter/FilterButton';
import FilterSidebar from '../../../shared/components/filter/FilterSidebar';

import MatTableHead from '../../../shared/components/material-table/MatTableHead';
import MatTableToolbar from '../../../shared/components/material-table/MatTableToolbar';

import * as TaskActions from '../../../store/actions/tenant/tasks';

import { selectFilter, filterToParams } from '../../../store/reducers/tasks';

import Loading from '../../../shared/components/Loading';

import TaskTreeItem from './components/TaskTreeItem';
import TasksFilterSidebar from './components/TasksFilterSidebar';
import TaskModal from './components/TaskModal';
import { CheckBoxField } from '../../../shared/components/form/CheckBox';
import ModalComponent from '../../../shared/components/Modal';
import TaskDeletePrompt from './components/TaskDeletePrompt';

const Tasks = ({
  t, projectId, filter, loading, loaded, error, tasks, tasksById, totalTasks, currentProject,
  changeTaskFilter, getTasks, editTask,
}) => {
  // const [taskModal, setTaskModal] = useState(false);

  const loadTasks = () => {
    getTasks(filterToParams(filter, {
      start: tasks.length,
      limit: 50,
    }));
  };

  const addTask = () => {
    const data = {
      _id: 'new', isBillable: true, startDateTime: new Date(),
    };
    if (projectId) {
      const { customerId: customer, title: projectTitle } = currentProject || {};
      const { _id: customerId, name: customerName } = customer || {};
      data.projectId = { label: projectTitle || projectId, value: projectId };
      if (customerId) {
        data.customerId = { label: customerName || customerId, value: customerId };
      }
    }
    editTask(data);
  };

  useEffect(() => {
    if (projectId !== filter.projectId) {
      changeTaskFilter({ projectId });
    } else if (!loaded && !loading && !error) {
      loadTasks();
    }
  });

  const [selectedTasks, setSelectedTasks] = useState(new Set());

  const [openTaskCount, setOpenTaskCount] = useState(0);

  useEffect(() => {
    const nextSelectedTasks = new Set();
    tasks.forEach((taskId) => {
      if (selectedTasks.has(taskId)) {
        nextSelectedTasks.add(taskId);
      }
    });
    setSelectedTasks(nextSelectedTasks);
    setOpenTaskCount(
      tasks.reduce((count, id) => count + (((tasksById[id] || {}).details || {}).isClose ? 0 : 1), 0),
    );
  }, [tasks]);

  const [deletingTask, setDeletingTask] = useState(null);

  const showDeleteTaskModal = (deletingTaskId) => {
    setDeletingTask(deletingTaskId);
  };

  const showDeleteSelectedModal = () => {
    if (selectedTasks.size === 0) {
      return;
    }
    setDeletingTask('selected');
  };

  const hideDeleteModal = () => {
    setDeletingTask(null);
  };

  return (
    <Container className="container__with-filter customers-page">
      <Row>
        <Col sm={3}>
          <h3 className="page-title">{t('tasks.page_title')}</h3>
        </Col>
        <Col sm={9} className="d-flex justify-content-end flex-wrap">
          <Button
            color="success"
            onClick={addTask}
            className="icon"
          >
            <p>
              <AccountPlusIcon /> {t('tasks.add_new')}
            </p>
          </Button>
          <FilterButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <MatTableToolbar
                numSelected={selectedTasks.size}
                handleDeleteSelected={showDeleteSelectedModal}
              />
              <div className="material-table__wrap">
                <Table className="material-table">
                  <MatTableHead
                    numSelected={selectedTasks.size}
                    onSelectAllClick={(event, checked) => {
                      const next = new Set();
                      if (checked) {
                        tasks.forEach((taskId) => {
                          if (!((tasksById[taskId] || {}).details || {}).isClose) {
                            next.add(taskId);
                          }
                        });
                      }
                      setSelectedTasks(next);
                    }}
                    rowCount={openTaskCount}
                    heads={[{
                      id: 'task',
                      label: selectedTasks.size === openTaskCount && openTaskCount
                        ? t('task_index.deselect_all') : t('task_index.select_all'),
                    }]}
                  />
                  <TableBody>
                    {tasks.map(taskId => (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        onClick={() => {
                          if (((tasksById[taskId] || {}).details || {}).isClose) {
                            return;
                          }
                          const next = new Set(selectedTasks);
                          if (next.has(taskId)) {
                            next.delete(taskId);
                          } else {
                            next.add(taskId);
                          }
                          setSelectedTasks(next);
                        }}
                        aria-checked={selectedTasks.has(taskId)}
                        tabIndex={-1}
                        key={taskId}
                        selected={selectedTasks.has(taskId)}
                      >
                        <TableCell className="material-table__cell" padding="checkbox">
                          <Checkbox
                            checked={selectedTasks.has(taskId)}
                            className="material-table__checkbox"
                            indeterminate={
                              (
                                (
                                  tasksById[taskId] || {}
                                ).details || {}
                              ).isClose
                            }
                            indeterminateIcon={(
                              <LockIcon />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >
                          <TaskTreeItem
                            taskId={taskId}
                            showProjectTitle={!projectId}
                            onDeleteButtonClick={showDeleteTaskModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <Loading
                      loading={loading}
                      loaded={loaded}
                      error={error}
                      retry={loadTasks}
                      render={children => ((!loaded || tasks.length === 0 || totalTasks > tasks.length) ? (
                        <TableRow>
                          <TableCell colSpan={2}>
                            {children}
                          </TableCell>
                        </TableRow>
                      ) : null)}
                    >
                      { tasks.length === 0 && <p>{t('task_index.filter_error')}.</p>}
                      { totalTasks > tasks.length && (
                        <div className="d-flex justify-content-center">
                          <Button
                            type="button"
                            color="primary"
                            onClick={loadTasks}
                            className="py-1 px-4 my-0"
                          >
                            {t('task_index.load_more')} ({totalTasks - tasks.length})
                          </Button>
                        </div>
                      )}
                    </Loading>
                  </TableBody>
                </Table>
              </div>
              <TaskDeletePrompt
                tasks={deletingTask === 'selected' ? selectedTasks : deletingTask}
                onHideModal={hideDeleteModal}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <TasksFilterSidebar />
    </Container>
  );
};

Tasks.propTypes = {
  t: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  filter: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  tasksById: PropTypes.shape().isRequired,
  totalTasks: PropTypes.number.isRequired,
  currentProject: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    customerId: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  changeTaskFilter: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  editTask: PropTypes.func.isRequired,
};

Tasks.defaultProps = {
  projectId: '',
  error: '',
  currentProject: null,
};

const mapStateToProps = state => ({
  filter: selectFilter(state),
  loading: state.tasks.loading,
  loaded: state.tasks.loaded,
  error: state.tasks.error,
  tasks: state.tasks.tasks,
  tasksById: state.tasks.tasksById,
  totalTasks: state.tasks.totalTasks,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = {
  changeTaskFilter: TaskActions.changeTaskFilter,
  getTasks: TaskActions.getTasksRequest,
  editTask: TaskActions.editTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Tasks));
