import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import EmailPlusIcon from 'mdi-react/EmailPlusIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import { Button, Row, Col } from 'reactstrap';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import { phoneNumber } from '../../../../lib/FieldWarnings/FieldWarnings';
/*
const RenderMaskedField = ({
  input, meta: { touched, error, warning }, t, ...props
}) => (
  <div className="w-100">
    <MaskedInput {...props} {...input} />
    {touched
        && ((error && <p className="mt-1 text-danger">{t(error)}</p>)
          || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
  </div>
);
RenderMaskedField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,};
*/

const renderPhones = ({ fields, t }) => (
  <>
    {
      fields.map((phone, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="form__form-group" key={`customer_address_${index}`}>
          {/* <span className="form__form-group-label">Phone #{index + 1}</span> */}
          <div className="form__form-group-field">
            <Field
              name={phone}
              component={renderPhoneInput}
              type="text"
              defaultCountry="tr"
              validate={[phoneNumber]}
              placeholder={t('customer_form_phone.phone_number')}
            />
            <button
              className="form__form-group-button"
              type="button"
              onClick={() => fields.remove(index)}
            ><CloseIcon />
            </button>
          </div>
        </div>
      ))
    }
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Button type="button" onClick={() => fields.push('')} outline size="sm">
          <PhonePlusIcon />
          {t('customer_form_phone.add_phone')}
        </Button>
      </div>
    </div>
  </>
);
renderPhones.propTypes = {
  fields: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

const CustomerFormPhones = ({ t }) => (
  <Row>
    <Col xs={12}>
      <FieldArray
        name="phones"
        component={renderPhones}
        t={t}
      />
    </Col>
  </Row>
);

CustomerFormPhones.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'tenant_customer_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(CustomerFormPhones));
