import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { RTLProps } from '../prop-types/ReducerProps';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    toggleBtn: PropTypes.string,
    toggleBtnClass: PropTypes.string,
    showToggleButton: PropTypes.bool,
    showButtons: PropTypes.bool,
    onOk: PropTypes.func,
    onToggle: PropTypes.func,
    showModal: PropTypes.bool,
    rtl: RTLProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    children: null,
    colored: false,
    header: false,
    toggleBtn: 'Default',
    toggleBtnClass: '',
    showToggleButton: true,
    showButtons: true,
    onOk: null,
    onToggle: null,
    showModal: undefined,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  get showModal() {
    const { showModal } = this.props;
    const { modal } = this.state;
    return typeof showModal === 'undefined' ? modal : showModal;
  }

  toggle = (e) => {
    const { onToggle, showModal } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (typeof showModal === 'undefined') {
      this.setState(prevState => ({ modal: !prevState.modal }));
    }
    if (onToggle) {
      onToggle();
    }
  };

  handleOk = (e) => {
    this.toggle(e);
    const { onOk } = this.props;
    if (onOk) {
      onOk();
    }
  };

  render() {
    const {
      color, title, children, colored, header, rtl, t,
      toggleBtn, toggleBtnClass, showToggleButton, showButtons,
    } = this.props;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        { showToggleButton ? (
          <Button className={toggleBtnClass} color={color} onClick={this.toggle}>
            {toggleBtn}
          </Button>
        ) : null}
        <Modal
          isOpen={this.showModal}
          toggle={this.toggle}
          modalClassName={`${rtl.direction}-support`}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            {children}
          </div>
          {showButtons && (
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={this.toggle}>
              {t('card_modal.cancel')}
            </Button>{' '}
            <Button className="modal_ok" outline={colored} color={color} onClick={this.handleOk}>
              {t('card_modal.ok')}
            </Button>
          </ButtonToolbar>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(withTranslation('common')(ModalComponent));
