import React from 'react';
import { useRequest } from '../../../../shared/hooks';

const fixedParams = {
  start: 0,
  limit: 400,
};

function useCustomerList() {
  return useRequest(
    data => data.customers.map(({ _id, name }) => ({ _id, name })),
    'customers',
    useRequest.methods.GET,
  );
}

export default useCustomerList;
