import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line react/prefer-stateless-function
class CustomersLeftSidebar extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props;
    return (

      <div>
        <h4>{t('customers.page_title')}</h4>
        <p>{t('customers.p1')}</p>
        <p>{t('customers.p2')}</p>
        <p>{t('customers.p3')}</p>
        <p>{t('customers.p4')}</p>
      </div>
    );
  }
}

export default withTranslation('common')(CustomersLeftSidebar);
