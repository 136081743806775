import withFetch from './withFetch';
import {
  CREATE_BILL_TENANT,
  CREATE_EXPENSE_PAYMENT_TENANT,
  CREATE_EXPENSE_TENANT, CREATE_EXTRA_TENANT,
  CREATE_PROJECT_TENANT, CREATE_TASK_TENANT, GET_CURRENT_PROJECT_TENANT, GET_PROJECTS_TENANT, SOFT_DELETE_BILL_TENANT,
  SOFT_DELETE_EXPENSE_PAYMENT_TENANT, SOFT_DELETE_EXPENSE_TENANT, SOFT_DELETE_EXTRA_TENANT,
  SOFT_DELETE_PROJECT_TENANT, SOFT_DELETE_TASK_TENANT, UPDATE_EXPENSE_PAYMENT_TENANT,
  UPDATE_EXPENSE_TENANT, UPDATE_EXTRA_TENANT, UPDATE_PROJECT_TENANT, UPDATE_TASK_TENANT,
} from '../fetchTypes';
import { EDIT_PROJECT, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingProject: null,
  totalProjects: 0,
  projects: [],
  currentProject: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROJECTS_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_PROJECT_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_PROJECT_TENANT:
    case UPDATE_PROJECT_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROJECTS_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        projects: action.data.projects,
        totalProjects: action.data.totalProjects,
      };
    case GET_CURRENT_PROJECT_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentProject: action.data.project,
      };
    case CREATE_PROJECT_TENANT:
    case UPDATE_PROJECT_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingProject: null,
        loadedCurrent: false,
      };
    case SOFT_DELETE_PROJECT_TENANT:
      return {
        ...state,
        projects: state.projects.filter(({ _id }) => _id !== action.reqData._id),
        totalProjects: state.totalProjects - 1,
        currentProject: state.currentProject && state.currentProject._id === action.reqData._id ? {
          ...state.currentProject,
          isActive: false,
        } : state.currentProject,
      };
    case SOFT_DELETE_TASK_TENANT:
    case SOFT_DELETE_EXPENSE_TENANT:
    case SOFT_DELETE_EXPENSE_PAYMENT_TENANT:
    case SOFT_DELETE_EXTRA_TENANT:
    case SOFT_DELETE_BILL_TENANT:
    case UPDATE_TASK_TENANT:
    case UPDATE_EXPENSE_TENANT:
    case UPDATE_EXPENSE_PAYMENT_TENANT:
    case UPDATE_EXTRA_TENANT:
    case CREATE_TASK_TENANT:
    case CREATE_EXPENSE_TENANT:
    case CREATE_EXPENSE_PAYMENT_TENANT:
    case CREATE_EXTRA_TENANT:
    case CREATE_BILL_TENANT:
      return {
        ...state,
        loadedCurrent: false,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_PROJECTS_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_PROJECT_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_PROJECT_TENANT:
    case UPDATE_PROJECT_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_PROJECT:
      return {
        ...state,
        editingProject: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
