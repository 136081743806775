import {
  ADD_CASE, FETCH_REQUEST, EDIT_CASE, REMOVE_NEW_CASE,
} from '../../sharedTypes';
import {
  GET_CASES_TENANT, CREATE_CASE_TENANT, UPDATE_CASE_TENANT, GET_CURRENT_CASE_TENANT, DELETE_CASE_TENANT,
} from '../../fetchTypes';

export const getCasesRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CASES_TENANT,
  data,
});

export const getCurrentCaseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CURRENT_CASE_TENANT,
  data,
});

export const createCaseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_CASE_TENANT,
  data,
});

export const updateCaseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_CASE_TENANT,
  data,
});

export const deleteCaseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_CASE_TENANT,
  data,
});

export const addCase = data => ({
  type: ADD_CASE,
  data,
});

export const editCase = data => ({
  type: EDIT_CASE,
  data,
});

export const removeNewCase = data => ({
  type: REMOVE_NEW_CASE,
  data,
});
