import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-react/DeleteIcon';
import MatTableFilterButton from './MatTableFilterButton';

const MatTableToolbar = ({
  numSelected, handleDeleteSelected, onRequestSort, t,
}) => (
  <div className="material-table__toolbar-wrap">
    <Toolbar className="material-table__toolbar">
      <div>
        {numSelected > 0 && (
        <h5 className="material-table__toolbar-selected">{numSelected} <span>{t('mat_table.selected')}</span></h5>
        )}
      </div>
      <div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(numSelected > 0 && handleDeleteSelected) ? (
          <IconButton
            onClick={handleDeleteSelected}
            className="material-table__toolbar-button"
          >
            <DeleteIcon />
          </IconButton>
        ) : onRequestSort ? (
          <MatTableFilterButton onRequestSort={onRequestSort} />
        ) : null}
      </div>
    </Toolbar>
  </div>
);

MatTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func,
  onRequestSort: PropTypes.func,
  t: PropTypes.func.isRequired,
};

MatTableToolbar.defaultProps = {
  handleDeleteSelected: null,
  onRequestSort: null,
};

export default withTranslation('common')(MatTableToolbar);
