import React from 'react';
import customerType from '../types/customer';

import { useRequest } from '../../../../shared/hooks';


function useCustomer(customerId) {
  return useRequest(
    ({ customer }) => customer,
    customerId ? `customers/${customerId}` : '',
    useRequest.methods.GET,
  );
}

useCustomer.propType = useRequest.createPropType(customerType);

export default useCustomer;
