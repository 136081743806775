import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

const DefaultTopbarHeader = ({ profile: { profile } }) => (
  <div className="topbar__header-group">
    <span className="topbar__header-title">
      { profile ? `${profile.fname} ${profile.lname}` : 'AdvoPASS' }
    </span>
  </div>
);

DefaultTopbarHeader.propTypes = {
  profile: PropTypes.shape({
    profile: PropTypes.shape({
      fname: PropTypes.string.isRequired,
      lname: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(DefaultTopbarHeader);
