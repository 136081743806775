import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginAdmin from './LoginAdmin';
import Layout from './Layout';

import ExamplePageTwo from '../ExampleTwo';
import Profile from './Profile';

const AdminRouter = ({ token }) => (token ? (
  <>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/" component={ExamplePageTwo} />
        <Route exact path="/profile" component={Profile} />
        <Redirect to="/" />
      </Switch>
    </div>
  </>
) : (
  <Switch>
    <Route exact path="/login" component={LoginAdmin} />
    <Redirect to="/login" />
  </Switch>
));

AdminRouter.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ token: state.auth.token });

export default connect(mapStateToProps)(AdminRouter);
