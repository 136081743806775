import {
  EDIT_EXPENSE_PAYMENT,
  FETCH_REQUEST, CHANGE_EXPENSE_PAYMENT_FILTER, CLEAR_EXPENSE_PAYMENT, CLEAR_EXPENSE_PAYMENT_FILTER,
} from '../../sharedTypes';
import {
  GET_EXPENSE_PAYMENTS_TENANT, CREATE_EXPENSE_PAYMENT_TENANT, UPDATE_EXPENSE_PAYMENT_TENANT,
  RELOAD_EXPENSE_PAYMENT_TENANT, DELETE_EXPENSE_PAYMENT_TENANT, SOFT_DELETE_EXPENSE_PAYMENT_TENANT,
} from '../../fetchTypes';

export const changeExpensePaymentFilter = data => ({
  type: CHANGE_EXPENSE_PAYMENT_FILTER,
  data,
});

export const clearExpensePaymentFilter = () => ({
  type: CLEAR_EXPENSE_PAYMENT_FILTER,
});

export const clearExpensePayments = () => ({
  type: CLEAR_EXPENSE_PAYMENT,
});

export const getExpensePaymentsRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_EXPENSE_PAYMENTS_TENANT,
  data,
});

export const reloadExpensePaymentRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: RELOAD_EXPENSE_PAYMENT_TENANT,
  data,
});

export const editExpensePayment = data => ({
  type: EDIT_EXPENSE_PAYMENT,
  data,
});

export const createExpensePaymentRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_EXPENSE_PAYMENT_TENANT,
  data,
});

export const updateExpensePaymentRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_EXPENSE_PAYMENT_TENANT,
  data,
});

export const softDeleteExpensePaymentRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_EXPENSE_PAYMENT_TENANT,
  data,
});

export const deleteExpensePaymentRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_EXPENSE_PAYMENT_TENANT,
  data,
});
