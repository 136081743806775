/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button, Card, CardBody, Col,
} from 'reactstrap';
import moment from 'moment';

import EditIcon from 'mdi-react/EditIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import DashboardIcon from 'mdi-react/ClipboardAccountIcon';
import CashIcon from 'mdi-react/CashIcon';
import classNames from 'classnames';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import customerType from '../types/customer';
import { subTypeToLabel, typeToLabel } from '../constants/options';
import * as ProjectActions from '../../../../store/actions/tenant/projects';
import * as CustomerActions from '../../../../store/actions/tenant/customers';
import { defaultCurrencyObject } from '../../Projects/constants/options';

class CustomerItem extends Component {
  static propTypes = {
    /* actions: PropTypes.shape({
      editcustomers-page: PropTypes.func,
      completecustomers-page: PropTypes.func,
      deletecustomers-page: PropTypes.func,
    }).isRequired, */
    customer: customerType.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    toggleCustomerStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    editCustomer: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    const { customer } = this.props;

    /*
    this.state = {
      title: customers-page.title,
      completed: customers-page.completed,
      description: customers-page.description,
      priority: customers-page.priority,
    };
    */
  }

  handleAddMatter = () => {
    const { customer, editProject } = this.props;
    const { _id, name } = customer;
    editProject({
      _id: 'new',
      currency: defaultCurrencyObject,
      customerId: { value: _id, label: name },
      status: 'active',
    });
  }

  handleEditCustomer = () => {
    const { customer, editCustomer } = this.props;
    editCustomer(customer);
  }

  handleComplete() {
    const { customer } = this.props;
    // const { checked } = this.state;

    /*
    actions.completecustomers-page(customers-page.id);
    this.setState({
      checked: !checked,
    });
     */
  }

  handleDelete() {
    const { customer } = this.props;

    // actions.deletecustomers-page(customers-page.id);
  }


  /*
  handleEdit() {
    const { customers-page, actions } = this.props;

    actions.editcustomers-page(customers-page.id);
  }

  handleUpdate() {
    const { customers-page, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updatecustomers-page(customers-page.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }

 */

  render() {
    /*
    const {
      title, completed, description, priority,
    } = this.state;
    */

    /*
    const priorityColorsClass = classNames({
      'customers-page__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
     */
    const {
      customer, toggleEditCustomer, toggleCustomerStatus, promptSoftDelete, t,
    } = this.props;
    const {
      _id, name, sector, peopleInCharge, type, subtype, taxNumber, taxOffice, addresses, emails, phones,
      isActive, created,
    } = customer;

    return (
      <Card>
        <CardBody className="customers-page__item pt-0 ">
          <button
            className="btn btn-secondary customers-page__delete-btn align-self-right"
            type="button"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: '30px',
              height: '30px',
              alignItems: 'center',
              padding: 'initial',
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#000',
              color: '#000',
            }}
            onClick={toggleEditCustomer}
          >
            <EditIcon className="w-100" style={{ color: '#000' }} />
          </button>
          <Link
            to={`/customers/${_id}/passboard/`}

          >
            <button
              className="btn btn-secondary customers-page__delete-btn align-self-right"
              type="button"
              style={{
                position: 'absolute',
                right: 65,
                top: 10,
                width: '30px',
                height: '30px',
                alignItems: 'center',
                padding: 'initial',
                backgroundColor: '#fff',
                borderWidth: 1,
                borderColor: '#000',
                color: '#000',
              }}
            >
              <DashboardIcon className="w-100" style={{ color: '#000' }} />
            </button>
          </Link>
          <fieldset style={{
            borderWidth: 1, borderColor: '#000', borderStyle: 'solid', width: '100%',
          }}
          >
            <legend style={{
              width: 'unset',
              whiteSpace: 'nowrap',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
            >
              <Link to={`/customers/${_id}/matters/`}>
                {name}
              </Link>
            </legend>

            {/* <label htmlFor={`customer_${_id}_is_active_`} className="customers-page__label-checkbox">
              <input
                id={`customer_${_id}_is_active_`}
                type="checkbox"
                className="customers-page__complete-toggle"
                defaultChecked={isActive}
                required
                onClick={toggleCustomerStatus}
              />
              <span className="checkbox-indicator" />
            </label> */}
            <div className="customers-page__info">

              <div className="customers-page__header">
                {/* <h3>{name}</h3> */}
                <div className="customers-page__additional">
                  <p className="customers-page__due-date">
                    {t('customer_item.created')} {`${moment(created).fromNow()}`}
                  </p>
                  <span className="customers-page__priority">{t(typeToLabel(type))}</span>
                  <span className="customers-page__priority">{t(subTypeToLabel(subtype))}</span>
                </div>
              </div>
              <div className="separator" />
              <div className="customers-page__content">
                <div className="customers-page__description customers-page__details my-2">
                  <div>
                    <b>{t('customer_item.people_in_charge')}: </b>
                    <span>{peopleInCharge}</span>
                  </div>
                  <div>
                    <b>{t('customer_item.sector')}: </b>
                    <span>{ sector }</span>
                  </div>
                  <div>
                    <b>{t('customer_item.tax_number')}: </b>
                    <span>{ taxNumber }</span>
                  </div>
                  <div>
                    <b>{t('customer_item.tax_office')}: </b>
                    <span>{ taxOffice }</span>
                  </div>
                </div>
                <div className="separator w-100" />

                { addresses.length ? (
                  <>
                    <div className="customers-page__description my-2">
                      {/* <h4>Addresses</h4> */}
                      <ul>
                        {addresses.map((address, index) => (
                          <li key={`customer_${_id}_address_${index}`}>
                            <MapMarkerIcon size="15" />
                            {address}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="separator w-100" />
                  </>
                ) : null}
                { phones.length || emails.length ? (
                  <>
                    <div className="customers-page__description my-2">

                      {/* <h4>Phones</h4> */}
                      <ul>
                        {phones.map((phone, index) => (
                          <li key={`customer_${_id}_phone_${index}`}>
                            <PhoneIcon size="15" /> {' '}
                            <a href={`tel:${phone}`}>
                              {phone}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="customers-page__description my-2">
                      {/* <h4>E-Mails</h4> */}
                      <ul>
                        {emails.map((email, index) => (
                          <li key={`customer_${_id}_email_${index}`}>
                            <EmailIcon size="15" /> {' '}
                            <a href={`mailto:${email}`}>
                              {email}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="customers-page__description my-2" />
                  </>
                ) : null}
                {/*
                <div className="customers-page__description my-2">
                  <ul>

                    <li>
                      <CashIcon size="18" />{' '}
                        Average Fee: <span style={{ fontSize: '14px', textDecorationLine: 'underline' }}>35₺/Hour</span>
                    </li>
                    <li>
                      <CashIcon size="18" /> {' '}
                      Estimated Total: <span style={{ fontSize: '14px', textDecorationLine: 'underline' }}>35000₺</span>
                    </li>


                  </ul>
                </div>
                */}
              </div>
            </div>
            <div
              className={`my-1 ${''
              }d-flex flex-wrap justify-content-center align-items-center w-100`}
            >
              <Button
                type="button"
                className="icon m-1"
                outline
                onClick={toggleEditCustomer}
              >
                <p><SquareEditOutlineIcon /> {t('customer_item.edit')}</p>
              </Button>
              <Button
                type="button"
                color="danger"
                className="icon m-1"
                onClick={promptSoftDelete}
              >
                <p style={{ color: '#fff' }}>
                  <DeleteIcon /> {t('customer_item.delete')}
                </p>
              </Button>
              <Button
                type="button"
                color="success"
                onClick={this.handleAddMatter}
                className="icon m-1"
              >
                <p style={{ color: '#fff' }}>
                  <AccountPlusIcon /> {t('customer_item.add_matter')}
                </p>
              </Button>
            </div>
          </fieldset>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  editProject: ProjectActions.editProject,
  editCustomer: CustomerActions.editCustomer,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(CustomerItem));
