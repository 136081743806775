const digitRegExp = /\d/;
const slashRegExp = /\//;
const prefix = [/[1-9]/, /\d/, /\d/, /\d/, ' ', '/', ' ', /[1-9]/];

const createCaseNumberMask = (suffixMask = []) => (rawValue = '') => {
  const matchedDigits = rawValue.split('')
    .reduce((acc, x) => acc + (digitRegExp.test(x) ? 1 : 0), 0);
  const middle = [];
  for (let i = 6; i <= matchedDigits; i += 1) {
    middle.push(digitRegExp);
  }
  return [...prefix, ...middle, ...suffixMask];
};

export const caseNumberMask = createCaseNumberMask();
export const decisionNumberMask = createCaseNumberMask('K');
