import {
  CREATE_PROJECT_TENANT, GET_CURRENT_PROJECT_TENANT, GET_PROJECTS_TENANT,
  SOFT_DELETE_PROJECT_TENANT,
  UPDATE_PROJECT_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_PROJECTS_TENANT:
      return params => makeFetcher('projects', 'GET', params);
    case GET_CURRENT_PROJECT_TENANT:
      return params => makeFetcher(`projects/${params._id}`, 'GET');
    case CREATE_PROJECT_TENANT:
      return data => makeFetcher('projects', 'POST', data);
    case UPDATE_PROJECT_TENANT:
      return ({ _id, ...data }) => makeFetcher(`projects/${_id}`, 'PUT', data);
    case SOFT_DELETE_PROJECT_TENANT:
      return ({ _id }) => makeFetcher(`projects/${_id}`, 'PUT', { isActive: false });
    default:
      return null;
  }
};
