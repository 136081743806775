/* eslint-disable react/sort-comp */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Switch from 'react-ios-switch';
import moment from 'moment';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import Button from 'reactstrap/es/Button';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import projectType from '../types/project';
import Loading from '../../../../shared/components/Loading';
import { categoryToLabel, statusToLabel, tenureTypeToLabel } from '../constants/options';
import * as TaskActions from '../../../../store/actions/tenant/tasks';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class ProjectsTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(projectType.isRequired).isRequired,
    totalProjects: PropTypes.number.isRequired,
    toggleEditProject: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleProjectStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    editTask: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  get heads() {
    const { t } = this.props;
    return [
      // { key: 'caseName', name: 'Name', sortable: true },
      {
        key: 'title',
        name: `${t('project_form.title')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            <Link to={`/matters/${row._id}`}>
              {row.title}
            </Link>
          </div>
        ),
      },
      {
        key: 'customer.name',
        name: `${t('project_form.customer')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {row.customer.name}
          </div>
        ),
      },
      {
        key: 'category',
        name: `${t('project_form.category')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {t(categoryToLabel(row.category))}
          </div>
        ),
      },
      {
        key: 'status',
        name: `${t('project_form.status')}`,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {t(statusToLabel(row.status))}
          </div>
        )
        ,
      },
      {
        key: 'legalType',
        name: `${t('project_form.legal_type')}`,
        formatter: ({ row, value }) => (
          <div>
            {value}
          </div>
        ),
      },
      // {
      //   key: 'created',
      //   name: 'Created At',
      //   sortable: true,
      //   formatter: ({ value }) => (
      //     <div>
      //       {`${moment(value).fromNow()}`}
      //     </div>
      //   ),
      // },
      /*      {
        key: 'tenureType',
        name: `${t('project_form.tenure_type')}`,
        formatter: ({ row }) => (
          <div>
            {tenureTypeToLabel(row.tenureType)}
          </div>
        ),
      }, */
      /*      {
        key: 'proposalDate',
        name: `${t('project_form.proposal_date')}`,
        sortable: true,
        formatter: ({ value }) => (
          <div>
            {`${moment(value).fromNow()}`}
          </div>
        ),
      }, */
      // {
      //   key: 'billingType',
      //   name: `${t('project_form.billing_type')}`,
      //   formatter: ({ row, value }) => (
      //     <div>
      //       {row.status === 'potential' ? '-' : value || ''}
      //     </div>
      //   ),
      // },
      // { key: 'peopleInCharge', name: 'People In Charge', formatter: simpleFormatter },

      // { key: 'taxOffice', name: 'Tax Office', formatter: simpleFormatter },
      {
        key: 'actions',
        name: `${t('customer_form.actions')}`,
        width: 430,
        /* cellClass: 'projects-page__details-cell', */
        formatter: ({ row }) => (
          <div>
            <Button
              type="button"
              size="sm"
              className="icon"
              outline
              onClick={() => this.toggleEditProject(row)}
            >
              <p><SquareEditOutlineIcon />{t('project_item.edit')}</p>
            </Button>
            <Button
              type="button"
              size="sm"
              className="icon"
              color="danger"
              onClick={() => this.promptSoftDelete(row)}
            >
              <p><DeleteIcon />{t('project_item.delete')}</p>
            </Button>
            <Button
              type="button"
              size="sm"
              className="icon"
              color="success"
              onClick={() => this.handleAddTask(row)}
            >
              <p><AccountPlusIcon /> {t('project_item.add_task')}</p>
            </Button>
            <Link to={`/matters/${row._id}/reports`} className="icon btn btn-outline-secondary btn-sm">
              <p className="font-weight-normal"><FileDocumentIcon />{t('matter_page.reports')}</p>
            </Link>
          </div>
        ),
      },
    ];
  }

  handleAddTask = (project) => {
    const { editTask } = this.props;
    const { _id: projectId, title: projectTitle, customer } = project;
    const { _id: customerId, name: customerName } = customer || {};
    editTask({
      _id: 'new',
      isBillable: true,
      startDateTime: new Date(),
      customerId: { label: customerName, value: customerId },
      projectId: { label: projectTitle, value: projectId },
    });
  }

  toggleEditProject = (project) => {
    const { toggleEditProject } = this.props;
    toggleEditProject(project);
  };

  promptSoftDelete = (project) => {
    const { promptSoftDelete } = this.props;
    promptSoftDelete(project);
  };

  toggleProjectStatus = (project) => {
    const { toggleProjectStatus } = this.props;
    toggleProjectStatus(project);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, projects, totalProjects, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={projects}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalProjects === 0 ? (
              <div>
                {t('project_list.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

const mapDispatchToProps = {
  editTask: TaskActions.editTask,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(ProjectsTable));
