import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardHeader, CardTitle, TabContent, TabPane,
  Container, Row, Col,
  Nav, NavItem, NavLink, ButtonToolbar, Button,
} from 'reactstrap';
import classNames from 'classnames';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

// DELETE WHEN A SEPARATE CUSTOMERS LIST CAN BE FETCHED MOVE IT TO REDUX
import axios from 'axios';
import { TENANT_API_URL } from '../../../../constants';
// DELETE ^^^

import RenderSelectField from '../../../../shared/components/form/Select';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderField from '../../../../shared/components/form/FormField';
import Loading from '../../../../shared/components/Loading';
import ProjectFormInfo from './ProjectFormInfo';
import ProjectFormCase from './ProjectFormCase';
import ProjectFormTimeBilling from './ProjectFormTimeBilling';
import {
  billingTypes, categories, statuses, tenureTypes, currencies, defaultCurrencyObject, statusToLabel,
} from '../constants/options';
import * as ProjectHelpers from '../helpers';
import renderSwitchInput from '../../../../shared/components/form/Switch';
import { isRequired, minValue0 } from '../../../../lib/FieldWarnings/FieldWarnings';
import { getCustomersList, getLegalTypes } from '../../../../store/api/lookup';


class ProjectForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    selectedCustomer: PropTypes.objectOf(PropTypes.object),
    billingType: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired,
    shouldCap: PropTypes.bool.isRequired,
    currency: PropTypes.shape({ value: PropTypes.string.isRequired }),
    status: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedCustomer: null,
    currency: null,
  }

  state = {
    tab: 'info',
    customers: [],
    customersOptions: [],
    legalTypes: [],
  };

  customerRequired = isRequired('project_form.customer');

  titleRequired = isRequired('project_form.title');

  categoryRequired = isRequired('project_form.category');

  statusRequired = isRequired('project_form.status');

  tenureTypeRequired = isRequired('project_form.tenure_type');

  legalTypeRequired = isRequired('project_form.legal_type');

  billingTypeRequired = isRequired('project_form.billing_type');

  componentDidMount() {
    getCustomersList()
      .then((customers) => {
        if (customers) {
          this.setState({
            customersOptions: customers.map(({ _id, name, subtype }) => ({ label: name, value: _id, subtype })),
            customers,

          });
        }
      });
    getLegalTypes()
      .then((legalTypes) => {
        this.setState({
          legalTypes: legalTypes.map(({ _id, name }) => ({ label: name, value: name })),
        });
      });
  }

  render() {
    const {
      handleSubmit, close, loading, isNew, selectedCustomer,
      billingType, totalPrice, shouldCap, currency, status, t,
    } = this.props;

    const {
      tab, customers, customersOptions, legalTypes,
    } = this.state;

    return (
      <form className="form projects-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row className="align-items-center">
            <Col xs={8} sm={10}>
              <h3 className="mb-3">
                {isNew ? t('project_form.add_matter') : t('project_form.edit_matter')}
              </h3>
            </Col>
            <Col xs={4} sm={2}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t(statusToLabel(status ? 'active' : 'potential'))}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSwitchInput}
                  // validate={this.statusRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.customer')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="customerId"
                    component={RenderSelectField}
                    placeholder={t('project_form.customer')}
                    options={customersOptions}
                    validate={this.customerRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.category')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="category"
                    component={RenderSelectField}
                    placeholder={t('project_form.category')}
                    options={categories}
                    validate={this.categoryRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.title')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    placeholder={t('project_form.title')}
                    validate={this.titleRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/*            <Col xs={12} sm={4}>

              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.status')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="status"
                    component={RenderSelectField}
                    placeholder={t('project_form.status')}
                    options={statuses}
                    validate={this.statusRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col> */}
            {/*            <Col xs={12} sm={5}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.tenure_type')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="tenureType"
                    component={RenderSelectField}
                    placeholder={t('project_form.tenure_type')}
                    options={tenureTypes}
                    validate={this.tenureTypeRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col> */}
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.legal_type')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="legalType"
                    component={RenderSelectField}
                    placeholder={t('project_form.legal_type')}
                    options={legalTypes}
                    isCreatable
                    validate={this.legalTypeRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
          </Row>
          {status && (
            <Row>
              <Col xs={12} sm={5}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('project_form.currency')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="currency"
                      component={RenderSelectField}
                      placeholder={t('project_form.currency')}
                      options={currencies}
                      defaultValue={defaultCurrencyObject}
                      type="String"
                      isTranslated
                    />
                  </div>
                </div>
              </Col>
              <Col xs={8} sm={5}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('project_form.fee')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="fee"
                      component={renderField}
                      placeholder={t('project_form.fee')}
                      type="number"
                      validate={minValue0}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={4} sm={2}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('project_form.cap')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="shouldCap"
                      component={renderSwitchInput}
                    />
                  </div>
                </div>
              </Col>
              { shouldCap && (
                <>
                  <Col xs={12} sm={5}>
                    <div className="form__form-group">
                      <span className="form__form-group-label required">{t('project_form.cap_hours')}</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon form__form-group-icon--select">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="capHours"
                          component="input"
                          placeholder={t('project_form.cap_hours')}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={7}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">{t('project_form.fee_after')}</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon form__form-group-icon--select">
                          <AccountOutlineIcon />
                        </div>
                        <Field
                          name="feeAfterCap"
                          component="input"
                          placeholder={t('project_form.fee_after_cap')}
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              )}
              <Col xs={12} sm={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('project_form.estimate_time')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="estimatedHrs"
                      component={renderField}
                      placeholder={t('project_form.time')}
                      type="number"
                      validate={minValue0}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label required">{t('project_form.billing_type')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="billingType"
                      component={RenderSelectField}
                      placeholder={t('project_form.billing_type')}
                      options={billingTypes}
                      validate={this.billingTypeRequired}
                      isTranslated
                    />
                  </div>
                </div>
              </Col>
              {/* <Col xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h5> {t('project_form.total_price')}: {totalPrice} {(currency || {}).value || ''} </h5>
            </Col> */}
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Loading loading={loading}>
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('project_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew ? t('project_form.add_matter') : t('project_form.update_matter')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const selector = formValueSelector('tenant_project_form');
const mapStateToProps = (state) => {
  let status = selector(state, 'status');
  if (typeof status === 'string') {
    status = status === 'active';
  }
  const selectedCustomer = selector(state, 'customerId');
  const capHours = selector(state, 'capHours');
  const feeAfterCap = selector(state, 'feeAfterCap');
  const estimatedHours = selector(state, 'estimatedHrs');
  let billingType = selector(state, 'billingType') || '';
  if (typeof billingType === 'object') billingType = billingType.value || '';
  const shouldCap = selector(state, 'shouldCap');
  const fee = selector(state, 'fee');
  const currency = selector(state, 'currency');
  /* const advancePayment = selector(state, 'advancePayment'); */
  const totalPrice = ProjectHelpers.calculateTotalPrice(billingType, estimatedHours, fee, capHours, feeAfterCap);
  return {
    shouldCap,
    billingType,
    totalPrice,
    selectedCustomer,
    currency,
    status,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'tenant_project_form',
  })(withTranslation('common')(ProjectForm)),
);
