import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as CustomerActions from '../../../store/actions/tenant/customers';
import Loading from '../../../shared/components/Loading';

const CustomerTopbarHeader = ({
  loading, loaded, error, customer, getCurrentCustomer,
}) => {
  const { customerId } = useParams();

  const loadCurrentCustomer = () => {
    getCurrentCustomer({ _id: customerId });
  };

  useEffect(() => {
    loadCurrentCustomer();
  }, [customerId]);

  const { name: customerName } = customer || {};

  return (
    <Loading
      loading={loading}
      loaded={!loading}
    >
      <div className="topbar__header-group">
        <Link to={`/customers/${customerId}`}>
          <span className="topbar__header-title">
            { customerName || 'AdvoPASS' }
          </span>
        </Link>
      </div>
    </Loading>
  );
};

CustomerTopbarHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  getCurrentCustomer: PropTypes.func.isRequired,
};

CustomerTopbarHeader.defaultProps = {
  error: '',
  customer: null,
};

const mapStateToProps = state => ({
  loading: state.customers.loadingCurrent,
  loaded: state.customers.loadedCurrent,
  error: state.customers.errorCurrent,
  customer: state.customers.currentCustomer,
});

const mapDispatchToProps = {
  getCurrentCustomer: CustomerActions.getCurrentCustomerRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTopbarHeader);
