import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as ExtraActions from '../../../../store/actions/tenant/extras';

const ExtraDeletePrompt = ({
  extra, onHideModal, softDeleteExtra,
}) => {
  const { _id } = extra || {};
  let { title } = extra || {};
  title = title || _id;
  const handleDeleteConfirm = () => {
    softDeleteExtra(extra);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('extra_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!extra}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('extra_delete_prompt.message')}
      </h4>
      <p>{title}</p>
    </ModalComponent>
  );
};

ExtraDeletePrompt.propTypes = {
  extra: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteExtra: PropTypes.func.isRequired,
};

ExtraDeletePrompt.defaultProps = {
  extra: null,
};

const mapDispatchToProps = {
  softDeleteExtra: ExtraActions.softDeleteExtraRequest,
};

export default connect(null, mapDispatchToProps)(ExtraDeletePrompt);
