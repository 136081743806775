import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import TenantActions from '../../../store/actions/tenant';

import ResendEmailVerificationForm from './components/ResendEmailVerificationForm';

const useQuery = () => new URLSearchParams(useLocation().search);

const EmailVerification = ({
  t, loading, error, loaded, resendEmailVerification,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const emailQuery = query.get('email');

  const handleSubmit = ({ email }) => {
    resendEmailVerification({ email });
    setSubmitted(true);
  };

  useEffect(() => {
    if (!loading && !error && loaded && submitted) {
      history.push('/email_verification');
    }
  });

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">{t('resend_email_verification_form.welcome')}
              <span className="account__logo"> {t('resend_email_verification_form.advo')}
                <span className="account__logo-accent">{t('resend_email_verification_form.pass')}</span>
              </span>
            </h3>
            <h4 className="account__subhead">{t('resend_email_verification_form.verify_email')}</h4>
          </div>
          <ResendEmailVerificationForm
            loading={loading}
            initialValues={{ email: emailQuery }}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

EmailVerification.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  resendEmailVerification: PropTypes.func.isRequired,
};

EmailVerification.defaultProps = {
  error: '',
};

const mapStateToProps = state => ({
  loading: state.emailVerification.resendLoading,
  error: state.emailVerification.resendError,
  loaded: state.emailVerification.resendLoaded,
});

const mapDispatchToProps = {
  resendEmailVerification: TenantActions.EmailVerification.resendEmailVerification,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EmailVerification));
