import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import RenderDateTimePickerField from './DateTimePicker';


class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    // eslint-disable-next-line react/forbid-prop-types
    defaultValue: PropTypes.any,
    dateFormat: PropTypes.string.isRequired,
    isClearable: PropTypes.bool,
    forceValue: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: 'unnamed-date-field',
    defaultValue: null,
    value: '',
    isClearable: true,
    forceValue: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      startDate: defaultValue,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  get dateValue() {
    const { value, forceValue } = this.props;
    const { startDate } = this.state;
    if (value instanceof Date) {
      return value;
    }
    if (value) {
      return new Date(value);
    }
    return forceValue ? undefined : startDate;
  }

  handleChange(date) {
    const { onChange, name } = this.props;
    this.setState({
      startDate: date,
    });
    onChange(date, name);
  }

  render() {
    const { isClearable, disabled, dateFormat } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          className="form__form-group-datepicker"
          selected={this.dateValue}
          onChange={this.handleChange}
          dateFormat={dateFormat}
          dropDownMode="select"
          isClearable={isClearable}
          disabled={disabled}
          /* locale="tr" */
        />
      </div>
    );
  }
}

const RenderDatePickerField = (props) => {
  const {
    input, meta, isClearable, defaultValue, forceValue, t, disabled,
  } = props;
  const { touched, error, warning } = meta || {};
  return (
    <div className="form__form-group-input-wrap">
      <DatePickerField
        {...input}
        isClearable={isClearable}
        defaultValue={defaultValue}
        forceValue={forceValue}
        disabled={disabled}
        dateFormat={t('date_picker.date_format.full_date')}
      />
      {touched
      && ((error && <span className="form__form-group-error">{t(error)}</span>)
        || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
    </div>
  );
};

RenderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }).isRequired,
  meta: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  forceValue: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};
RenderDatePickerField.defaultProps = {
  meta: {},
  isClearable: true,
  forceValue: false,
  defaultValue: null,
  disabled: false,
};

export const extractDateString = obj => (obj instanceof Date ? obj.toISOString() : obj);

export default withTranslation('common')(RenderDatePickerField);
