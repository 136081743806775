/* eslint-disable react/no-array-index-key */

import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import { connect } from 'react-redux';
import { Link, useParams, NavLink } from 'react-router-dom';
import {
  Collapse,
} from 'reactstrap';
import MailIcon from 'mdi-react/MailIcon';
import moment from 'moment';
import * as MessageActions from '../../../../store/actions/tenant/messages';


const concatMsg = (readmessages, unreadmessages) => {
  let tempread = [];
  let tempunread = [];

  tempread = readmessages;
  if (tempread) {
    tempread.forEach((v) => {
      // eslint-disable-next-line no-param-reassign
      v.isRead = 'Read';
    });
  }
  tempunread = unreadmessages;
  if (tempunread) {
    tempunread.forEach((v) => {
      // eslint-disable-next-line no-param-reassign
      v.isRead = 'Unread';
    });
  }

  if (tempunread) {
    const allmessages = tempunread.concat(tempread);
    return allmessages;
  }

  if (tempread) {
    const allmessages = tempread.concat(tempunread);
    return allmessages;
  }

  const allmessages = [];
  return allmessages;
};


const TopbarMail = ({
  getReadMessages, getUnreadMessages, readmessages, unreadmessages,
}) => {
  const { customerId } = useParams();

  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    getUnreadMessages({ _id: customerId });
  }, [customerId]);


  useEffect(() => {
    getReadMessages({ _id: customerId });
  }, [customerId]);

  const allmessages = concatMsg(readmessages, unreadmessages).slice(0, 4);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (

    <div className="topbar__collapse">
      <button className="topbar__btn topbar__btn--mail topbar__btn--new" type="button" onClick={toggle}>
        <MailIcon />
        <div className="topbar__btn-new-label">
          <div />
        </div>
      </button>
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse
        isOpen={collapse}
        className="topbar__collapse-content"
      >
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Unread messages</p>
        </div>
        {allmessages.map((msg, index) => (
          <div className="topbar__collapse-item" key={index}>
            <div className="topbar__collapse-img-wrap">
              <img className="topbar__collapse-img" src="/img/topbar/ava.png" alt="" />
            </div>
            <p className="topbar__collapse-name mail-title">{msg.subject}</p>
            <Link to="/profile/info" onClick={toggle}>
              <p
                className="topbar__collapse-message topbar__collapse-message--mail mar-top add-cursor"
              >
                {msg.messageText}
              </p>
            </Link>
            <p className="topbar__collapse-date">{moment(msg.created).startOf('hour').fromNow()}</p>
          </div>
        ))}
        <Link className="topbar__collapse-link" to="/profile/info" onClick={toggle}>
            See all messages
        </Link>
      </Collapse>
    </div>
  );
};

TopbarMail.propTypes = {
  getReadMessages: PropTypes.func.isRequired,
  getUnreadMessages: PropTypes.func.isRequired,
  readmessages: PropTypes.shape(),
  unreadmessages: PropTypes.shape(),
};
TopbarMail.defaultProps = {
  readmessages: [],
  unreadmessages: [],
};


const mapStateToProps = state => ({
  loaded: state.customers.loadedCurrent,
  loading: state.customers.loadingCurrent,
  readmessages: state.messages.readmessages,
  unreadmessages: state.messages.unreadmessages,
  imageEditModal: false,

});


const mapDispatchToProps = {
  getReadMessages: MessageActions.getReadMessages,
  getUnreadMessages: MessageActions.getUnreadMessages,
  markReadMessage: MessageActions.markReadMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopbarMail);
