import withFetch from './withFetch';
import {
  CREATE_EXPENSE_TENANT, DELETE_EXPENSE_PHOTO_TENANT,
  GET_CURRENT_EXPENSE_TENANT, GET_EXPENSES_TENANT, SOFT_DELETE_EXPENSE_TENANT,
  UPDATE_EXPENSE_PHOTO_TENANT, UPDATE_EXPENSE_TENANT,
} from '../fetchTypes';
import { EDIT_EXPENSE, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingExpense: null,
  totalExpenses: 0,
  expenses: [],
  currentExpense: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
  updatingPhoto: false,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSES_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_EXPENSE_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_EXPENSE_TENANT:
    case UPDATE_EXPENSE_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    case UPDATE_EXPENSE_PHOTO_TENANT:
    case DELETE_EXPENSE_PHOTO_TENANT:
      return {
        ...state,
        updatingPhoto: true,
        updatePhotoError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSES_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        expenses: action.data.expenses,
        totalExpenses: action.data.expenses.length,
      };
    case GET_CURRENT_EXPENSE_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentExpense: action.data.expense,
      };
    case CREATE_EXPENSE_TENANT:
    case UPDATE_EXPENSE_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingExpense: null,
      };
    case UPDATE_EXPENSE_PHOTO_TENANT:
      return {
        ...state,
        updatingPhoto: false,
        expenses: state.expenses.map(expense => (expense._id === action.reqData._id ? ({
          ...expense,
          picture: action.data.picture,
        }) : expense)),
        currentExpense: {
          ...state.currentExpense || {},
          picture: action.data.picture,
        },
      };
    case DELETE_EXPENSE_PHOTO_TENANT:
      return {
        ...state,
        updatingPhoto: false,
        expenses: state.expenses.map(expense => (expense._id === action.reqData._id ? ({
          ...expense,
          picture: null,
        }) : expense)),
        currentExpense: {
          ...state.currentExpense || {},
          picture: null,
        },
      };
    case SOFT_DELETE_EXPENSE_TENANT:
      return {
        ...state,
        expenses: state.expenses.filter(({ _id }) => _id !== action.reqData._id),
        totalExpenses: state.totalExpenses - 1,
        currentExpense: state.currentExpense && state.currentExpense._id === action.reqData._id ? {
          ...state.currentExpense,
          isActive: false,
        } : state.currentExpense,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSES_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_EXPENSE_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_EXPENSE_TENANT:
    case UPDATE_EXPENSE_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case UPDATE_EXPENSE_PHOTO_TENANT:
    case DELETE_EXPENSE_PHOTO_TENANT:
      return {
        ...state,
        updatingPhoto: false,
        updatePhotoError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_EXPENSE:
      return {
        ...state,
        editingExpense: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
