import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const renderMaskedInput = ({
  input, meta: { touched, error, warning }, placeholder, type, mask, className, keepCharPositions, t,
}) => (
  <div className="form__form-group-input-wrap">
    <MaskedInput
      {...input}
      placeholder={placeholder}
      type={type}
      mask={mask}
      className={className}
      keepCharPositions={keepCharPositions}
    />
    {touched
    && ((error && <span className="form__form-group-error">{t(error)}</span>)
      || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
  </div>
);

renderMaskedInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any).isRequired, PropTypes.func.isRequired]).isRequired,
  className: PropTypes.string,
  keepCharPositions: PropTypes.bool,
};

renderMaskedInput.defaultProps = {
  meta: null,
  placeholder: '',
  type: 'text',
  className: '',
  keepCharPositions: false,
};

export default withTranslation('common')(renderMaskedInput);
