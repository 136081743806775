import React, { useRef, useEffect } from 'react';

const useRefMemo = (effect, dependencies) => {
  const prevDeps = useRef(dependencies);
  const result = useRef(effect());

  if (prevDeps.current.reduce((acc, d, i) => acc || d !== dependencies[i], false)) {
    result.current = effect(result.current);
  }

  return result.current;
};

export default useRefMemo;
