import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { selectLanguage } from '../../store/reducers/language';

const languageToCountryCode = (() => {
  const lanToCount = {
    en: 'gb',
  };
  return lng => lanToCount[lng] || lng;
})();

const languages = [
  'tr', 'en',
];

class TopbarLanguage extends PureComponent {
  static propTypes = {
    language: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      currentLanguage: 'en',
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  changeLanguage = (lng) => {
    // make sure to set it on html before any render so that
    // css text-uppercase will work with correct locale
    // doing with helmet is not fast enough so did it as below
    const html = document.documentElement;
    html.setAttribute('lang', lng);
    setTimeout(() => {
      i18n.changeLanguage(lng);
      this.setState({
        collapse: false,
      });
    }, 0);
    /* const { i18n } = this.props; */
  };

  render() {
    const { language } = this.props;
    const { currentLanguage, collapse } = this.state;

    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          <span className="topbar__language-btn-title">
            <span className={`flag-icon flag-icon-${languageToCountryCode(language)}`} />
            <span>{ language.toUpperCase() }</span>
          </span>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          {languages.map(lng => (
            <button
              key={lng}
              className="topbar__language-btn"
              type="button"
              onClick={() => this.changeLanguage(lng)}
            >
              <span className="topbar__language-btn-title">
                <span className={`flag-icon flag-icon-${languageToCountryCode(lng)}`} />
                <span>{lng.toUpperCase()}</span>
              </span>
            </button>
          ))}
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: selectLanguage(state),
});

export default withTranslation('common')(
  connect(mapStateToProps)(TopbarLanguage),
);
