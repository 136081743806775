import {
  EDIT_EXPENSE, FETCH_REQUEST, CHANGE_EXPENSE_FILTER, CLEAR_EXPENSE, CLEAR_EXPENSE_FILTER,
} from '../../sharedTypes';
import {
  GET_EXPENSES_TENANT, CREATE_EXPENSE_TENANT, UPDATE_EXPENSE_TENANT,
  RELOAD_EXPENSE_TENANT, DELETE_EXPENSE_TENANT, DELETE_EXPENSE_WITH_CHILDREN_TENANT,
  UPDATE_EXPENSE_PHOTO_TENANT, DELETE_EXPENSE_PHOTO_TENANT,
  SOFT_DELETE_EXPENSE_TENANT,
} from '../../fetchTypes';

export const changeExpenseFilter = data => ({
  type: CHANGE_EXPENSE_FILTER,
  data,
});

export const clearExpenseFilter = () => ({
  type: CLEAR_EXPENSE_FILTER,
});

export const clearExpenses = () => ({
  type: CLEAR_EXPENSE,
});

export const getExpensesRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_EXPENSES_TENANT,
  data,
});

export const reloadExpenseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: RELOAD_EXPENSE_TENANT,
  data,
});

export const editExpense = data => ({
  type: EDIT_EXPENSE,
  data,
});

export const createExpenseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_EXPENSE_TENANT,
  data,
});

export const updateExpenseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_EXPENSE_TENANT,
  data,
});

export const updateExpensePhotoRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_EXPENSE_PHOTO_TENANT,
  data,
});

export const deleteExpensePhotoRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_EXPENSE_PHOTO_TENANT,
  data,
});

export const softDeleteExpenseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_EXPENSE_TENANT,
  data,
});

export const deleteExpenseRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_EXPENSE_TENANT,
  data,
});

export const deleteExpenseWithChildrenRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_EXPENSE_WITH_CHILDREN_TENANT,
  data,
});
