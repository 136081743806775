import { FETCH_REQUEST } from '../../sharedTypes';
import {
  DELETE_PROFILE_LOGO_TENANT,
  DELETE_PROFILE_PICTURE_TENANT,
  EDIT_PROFILE_LOGO_TENANT, EDIT_PROFILE_PICTURE_TENANT, EDIT_PROFILE_TENANT, GET_PROFILE_TENANT, UNSUBSCRIBE_TENANT,
} from '../../fetchTypes';

export const profileRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_PROFILE_TENANT,
  data,
});

export const updateProfile = data => ({
  type: FETCH_REQUEST,
  fetchType: EDIT_PROFILE_TENANT,
  data,
});

export const updateProfilePictureRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: EDIT_PROFILE_PICTURE_TENANT,
  data,
});

export const deleteProfilePictureRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_PROFILE_PICTURE_TENANT,
  data,
});

export const updateProfileLogoRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: EDIT_PROFILE_LOGO_TENANT,
  data,
});

export const deleteProfileLogoRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_PROFILE_LOGO_TENANT,
  data,
});

export const unsubscribeRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UNSUBSCRIBE_TENANT,
  data,
});
