import {
  CREATE_TASK_TENANT, GET_TASKS_TENANT,
  UPDATE_TASK_TENANT, RELOAD_TASK_TENANT,
  DELETE_TASK_TENANT, DELETE_TASK_WITH_CHILDREN_TENANT, SOFT_DELETE_TASK_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_TASKS_TENANT:
      return params => makeFetcher('tasks', 'GET', params);
    case RELOAD_TASK_TENANT:
      return data => makeFetcher(`tasks/${data._id}`, 'GET');
    case CREATE_TASK_TENANT:
      return ({ _id, ...data }) => makeFetcher('tasks', 'POST', data);
    case UPDATE_TASK_TENANT:
      return ({ _id, ...data }) => makeFetcher(`tasks/${_id}`, 'PUT', data);
    case SOFT_DELETE_TASK_TENANT:
      return _id => makeFetcher(`tasks/${_id}`, 'PUT', { isActive: false });
    case DELETE_TASK_TENANT:
      return data => makeFetcher(`tasks/${data._id}`, 'DELETE');
    case DELETE_TASK_WITH_CHILDREN_TENANT:
      return data => makeFetcher(`tasks/${data._id}/delete-child`, 'DELETE');
    default:
      return null;
  }
};
