import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    dateFormat: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    timeCaption: PropTypes.string.isRequired,
  };

  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      startDate: value || null,
    };
  }

  get dateValue() {
    const { value } = this.props;
    const { startDate } = this.state;
    if (value instanceof Date) {
      return value;
    }
    return value ? new Date(value) : startDate;
  }

  handleChange = (date) => {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
    });
    onChange(date);
  };

  render() {
    const { dateFormat, timeFormat, timeCaption } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          timeFormat={timeFormat}
          timeCaption={timeCaption}
          className="form__form-group-datepicker"
          selected={this.dateValue}
          onChange={this.handleChange}
          showTimeSelect
          dateFormat={dateFormat}
          dropDownMode="select"
        />
      </div>
    );
  }
}

const RenderDateTimePickerField = (props) => {
  const { input, meta, t } = props;
  const { touched, error, warning } = meta || {};
  return (
    <div className="form__form-group-input-wrap">
      <DateTimePickerField
        {...input}
        dateFormat={t('date_picker.date_format.full_date_time')}
        timeFormat={t('date_picker.date_format.time')}
        timeCaption={t('date_picker.time_caption')}
      />
      {touched
      && ((error && <span className="form__form-group-error">{t(error)}</span>)
        || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
    </div>
  );
};

RenderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }).isRequired,
  meta: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func.isRequired,
};
RenderDateTimePickerField.defaultProps = {
  meta: {},
};

export default withTranslation('common')(RenderDateTimePickerField);
