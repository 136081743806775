import React from 'react';
import expensePaymentTeamReportType from '../types/expensePaymentTeamReport';

import { useRequest } from '../../../../shared/hooks';

function useExpensePaymentTeamReport(enabled = true) {
  return useRequest(
    ([expensePaymentTeamReport]) => expensePaymentTeamReport,
    enabled ? 'expense-payments/team-report' : '',
    useRequest.methods.GET,
  );
}

useExpensePaymentTeamReport.propType = useRequest.createPropType(expensePaymentTeamReportType);

export default useExpensePaymentTeamReport;
