import {
  CHANGE_DIRECTION_TO_LTR, CHANGE_DIRECTION_TO_RTL,
} from '../../sharedTypes';

export function changeDirectionToLTR() {
  return {
    type: CHANGE_DIRECTION_TO_LTR,
  };
}

export function changeDirectionToRTL() {
  return {
    type: CHANGE_DIRECTION_TO_RTL,
  };
}
