import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

class ToggleRTL extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    changeToRTL: PropTypes.func.isRequired,
    changeToLTR: PropTypes.func.isRequired,
  };

  render() {
    const {
      t, rtl, changeToLTR, changeToRTL,
    } = this.props;

    return (
      <label
        className="toggle-btn customizer__toggle"
        htmlFor="direction_toggle"
      >
        <input
          className="toggle-btn__input"
          type="checkbox"
          name="direction_toggle"
          id="direction_toggle"
          checked={rtl.direction === 'rtl'}
          onChange={rtl.direction === 'rtl' ? changeToLTR : changeToRTL}
        />
        <span className="toggle-btn__input-label" />
        <span>{t('toggle_rtl.mode')}</span>
      </label>
    );
  }
}

export default withTranslation('common')(ToggleRTL);
