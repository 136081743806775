import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSpeedometer from 'react-d3-speedometer';
import { formatDefaultLocale } from 'd3';
import { withContentRect } from 'react-measure';
import { getDefaultCurrency, displayCurrency } from '../../../../shared/helpers';


const ExpenseGaugeChart = ({
  totalExpense, totalExpensePayments, measureRef, contentRect, currency,
}) => {
  formatDefaultLocale({
    currency: ['', currency || getDefaultCurrency()],
    thousands: '.',
    grouping: [3],
    decimal: ',',
  });
  const prevWidth = useRef(200);
  const maxNum = Math.max(totalExpense, totalExpensePayments, 100);
  const currNum = totalExpensePayments - totalExpense;
  const { client } = contentRect || {};
  let { width } = client || {};
  if (!width) width = 200;
  const forceRender = prevWidth.current && prevWidth.current !== width;
  prevWidth.current = width;
  return (
    <div ref={measureRef}>
      {
        <ReactSpeedometer
          value={currNum}
          forceRender={forceRender}
          width={width}
          height={(width / 2) + 42}
          minValue={-maxNum}
          maxValue={maxNum}
          valueFormat="$,.2f"
          currentValueText={`${displayCurrency(totalExpensePayments, currency)
          } - ${displayCurrency(totalExpense, currency)} = \${value}`}
        />
      }
    </div>
  );
};

ExpenseGaugeChart.propTypes = {
  totalExpense: PropTypes.number.isRequired,
  totalExpensePayments: PropTypes.number.isRequired,
  measureRef: PropTypes.func.isRequired,
  contentRect: PropTypes.shape({
    client: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  currency: PropTypes.string,
};

ExpenseGaugeChart.defaultProps = {
  contentRect: {},
  currency: '',
};

export default withContentRect('client')(ExpenseGaugeChart);
