import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as TaskActions from '../../../../store/actions/tenant/tasks';

const TaskDeletePrompt = ({
  tasks: tasksRaw, tasksById, onHideModal, softDeleteTask,
}) => {
  let tasks = tasksRaw;
  if (tasks) {
    if (tasks instanceof Set) {
      tasks = Array.from(tasksRaw);
    }
    if (!Array.isArray(tasks)) {
      tasks = [tasks];
    }
  } else {
    tasks = [];
  }
  const handleDeleteConfirm = () => {
    tasks.forEach(softDeleteTask);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('task_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={tasks.length > 0}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('task_delete_prompt.message', { count: tasks.length })}
      </h4>
      <ul>
        {
          tasks.map(taskId => (
            <li key={taskId}>
              {`"${((tasksById[taskId] || {}).details || {}).description || taskId}"`}
            </li>
          ))
        }
      </ul>
    </ModalComponent>
  );
};

TaskDeletePrompt.propTypes = {
  tasks: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
    ),
    PropTypes.instanceOf(Set),
  ]),
  onHideModal: PropTypes.func.isRequired,

  tasksById: PropTypes.shape().isRequired,
  softDeleteTask: PropTypes.func.isRequired,
};

TaskDeletePrompt.defaultProps = {
  tasks: null,
};

const mapStateToProps = state => ({
  tasksById: state.tasks.tasksById,
});

const mapDispatchToProps = {
  softDeleteTask: TaskActions.softDeleteTaskRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDeletePrompt);
