import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import ExtraForm from './ExtraForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';

class ExtraModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createExtraRequest: PropTypes.func.isRequired,
    updateExtraRequest: PropTypes.func.isRequired,
    editExtra: PropTypes.func.isRequired,
    editingExtra: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingExtra: null,
  };

  handleSubmit = (data) => {
    const payload = { };
    const { createExtraRequest, updateExtraRequest, editingExtra } = this.props;
    ['projectId', 'currency']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value;
        }
      });

    ['description', 'amount', 'appliedDate', 'description']
      .forEach((key) => {
        payload[key] = data[key];
      });

    if (payload.description) {
      payload.title = payload.description;
      if (payload.title.length > 40) {
        payload.title = `${payload.title.substr(0, 36)} ...`;
      }
    }

    if (editingExtra._id === 'new') {
      createExtraRequest(payload);
    } else {
      payload._id = editingExtra._id;
      updateExtraRequest(payload);
    }
  };

  render() {
    const {
      updating, editingExtra, theme, rtl,
      editExtra,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingExtra}
        toggle={() => editExtra(null)}
        className={modalClasses}
      >
        {editingExtra && (
          <ExtraForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editExtra(null)}
            initialValues={editingExtra}
            isNew={editingExtra._id === 'new'}
          />
        )}
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.extras.updating,
  editingExtra: state.extras.editingExtra,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createExtraRequest: TenantActions.Extras.createExtraRequest,
  updateExtraRequest: TenantActions.Extras.updateExtraRequest,
  editExtra: TenantActions.Extras.editExtra,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraModal);
