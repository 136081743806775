/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import { SidebarProps } from '../../prop-types/ReducerProps';

class DataPaginationTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      editable: PropTypes.bool,
      sortable: PropTypes.bool,
      formatter: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSorting: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    rowKey: PropTypes.string,

    sidebar: SidebarProps.isRequired,
  };

  static defaultProps = {
    sortColumn: undefined,
    sortDirection: undefined,
    rowKey: undefined,
  };

  componentDidUpdate(prevProps) {
    const { sidebar } = this.props;
    if (prevProps.sidebar !== sidebar) {
      this._triggerReloadForDataGrid();
    }
  }

  rowGetter = (i) => {
    const { rows } = this.props;
    return rows[i];
  };

  /* hack to fix datapaginationtable size */
  _triggerReloadForDataGrid = () => {
    const resizeEvent = document.createEvent('HTMLEvents');
    resizeEvent.initEvent('resize', true, false);
    window.dispatchEvent(resizeEvent);
    // https://github.com/adazzle/react-data-grid/issues/916#issuecomment-337187269
    // process.nextTick(() => { window.dispatchEvent(resizeEvent); });
    // setTimeout(() => { window.dispatchEvent(resizeEvent); }, 0);
  };

  render() {
    const {
      heads, rows, onSorting, sortColumn, sortDirection, rowKey, sidebar,
    } = this.props;

    return (
      <div className="table">
        <ReactDataGrid
          columns={heads}
          rowGetter={this.rowGetter}
          rowsCount={rows.length}
          rowHeight={44}
          minHeight={64}
          minColumnWidth={100}
          onGridSort={onSorting}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          rowKey={rowKey}
          dummy={sidebar}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

/* below is to trigger re-render on sidebar changes */

export default connect(mapStateToProps)(DataPaginationTable);
