import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequest } from '../../../../shared/hooks';
import extraType from '../../Extras/types/project';

const createParams = (projectId, periodStart, periodEnd) => ({
  projectId,
  'appliedDateRange[0]': periodStart,
  'appliedDateRange[1]': periodEnd,
  start: 0,
  limit: 400,
  open: 1,
  actives: '1',
});

function useOpenExtras(projectId, periodStart, periodEnd) {
  const [params, setParams] = useState(createParams(projectId, periodStart, periodEnd));
  useEffect(() => {
    setParams(createParams(projectId, periodStart, periodEnd));
  }, [projectId, periodStart, periodEnd]);
  return useRequest(
    data => data.extras,
    (projectId && periodStart && periodEnd) ? 'extras' : '',
    useRequest.methods.GET,
    params,
  );
}

useOpenExtras.propType = useRequest.createPropType(
  PropTypes.arrayOf(extraType.isRequired),
);

export default useOpenExtras;
