import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import ExpensesTable from './components/ExpensesTable';
import ExpensesTablePagination from './components/ExpensesTablePagination';
import expenseType from './types/project';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import ExpenseList from './components/ExpensesList';
import FilterButton from '../../../shared/components/filter/FilterButton';
import ReportDeletePrompt from './components/ReportDeletePrompt';
import ProjectList from '../Projects/components/ProjectList';

class Expenses extends React.PureComponent {
  static propTypes = {
    getExpensesRequest: PropTypes.func.isRequired,
    editExpense: PropTypes.func.isRequired,
    updateExpense: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    expenses: PropTypes.arrayOf(expenseType).isRequired,
    totalExpenses: PropTypes.number.isRequired,
    editingExpense: PropTypes.shape(),
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    projectId: PropTypes.string,
    customerId: PropTypes.string,
    currentProject: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  static defaultProps = {
    error: '',
    editingExpense: null,
    projectId: '',
    customerId: '',
    currentProject: null,
  };

  state = {
    activesFilter: 'actives',
    startDate: null,
    endDate: null,
    keyword: '',
    tenureType: '',
    sortBy: '',
    keywords: '',
    category: '',
    billingType: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
    deletingReport: null,
  };

  componentDidMount() {
    this.forceReloadExpense();
    this._reloadProcessingIterval = setInterval(this.maybeReloadProcessing, 1000);
  }

  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = [
      'activesFilter',
      'startDate',
      'endDate',
      'keyword',
      'tenureType',
      'sortBy',
      'startDateRangeStart',
      'startDateRangeEnd',
      'createdRangeStart',
      'createdRangeEnd',
      'billingType',
      'keywords',
      'dir',
      'currentPage',
      'category',
      'perPage',
    ].reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadExpenses(force);
  }

  componentWillUnmount() {
    clearInterval(this._reloadProcessingIterval);
  }

  maybeReloadProcessing = () => {
    const {
      expenses, loading, loaded, error,
    } = this.props;
    if (loaded && !loading && !error && expenses.reduce((acc, x) => acc || x.status === 'Processing', false)) {
      this.maybeReloadExpenses(true);
    }
  };

  toggleExpenseStatus = (expense) => {
    const { updateExpense } = this.props;
    updateExpense({
      _id: expense._id,
      isActive: !expense.isActive,
    });
  };

  promptSoftDelete = (deletingReport = null) => {
    this.setState({
      deletingReport,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editExpenseModal = (expense) => {
    const { editExpense } = this.props;
    // editExpense(expense);
    const { projectId } = expense;
    const customerId = projectId && projectId.customerId ? projectId.customerId._id : null;
    const customerLabel = projectId && projectId.customerId ? projectId.customerId.name : null;
    const id = projectId ? projectId._id : null;

    editExpense({
      ...expense,
      projectId: { value: id, label: projectId.title },
      customerId: { value: customerId, label: customerLabel },
    });
  };

  newExpenseModal = () => {
    const { editExpense, projectId, currentProject } = this.props;
    const data = {
      _id: 'new', taskId: { label: 'None', value: null },
    };
    if (projectId) {
      const { customerId: customer, title: projectTitle } = currentProject || {};
      const { _id: customerId, name: customerName } = customer || {};
      data.projectId = { label: projectTitle || projectId, value: projectId };
      if (customerId) {
        data.customerId = { label: customerName || customerId, value: customerId };
      }
    }
    data.includeNonBillableTasks = true;
    data.includeBillableTasks = true;
    data.includeExtraPayments = true;
    data.includeExpenses = true;
    data.includeExpensePayments = true;
    editExpense(data);
  };

  closeExpenseModal = () => {
    const { editExpense } = this.props;
    editExpense(null);
  };

  forceReloadExpense = () => this.maybeReloadExpenses(true);

  maybeReloadExpenses = (force = false) => {
    const {
      getExpensesRequest, loading, loaded, error, projectId, customerId,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter,
        startDate,
        endDate,
        tenureType,
        sortBy,
        category,
        dir,
        keywords,
        currentPage,
        perPage,
        startDateRangeStart,
        startDateRangeEnd,
        createdRangeStart,
        createdRangeEnd,
        billingType,
      } = this.state;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.active = '1';
        else if (activesFilter === 'inactives') filter.inactive = '1';
        else {
          console.error(
            'unknown activesFilter filter in expenses',
            activesFilter,
          );
        }
      }
      if (projectId) {
        filter.projectId = projectId;
      }
      if (customerId) {
        filter.customerId = customerId;
      }
      getExpensesRequest(filter);
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading,
      loaded,
      error,
      expenses,
      totalExpenses,
      editingExpense,
      t,
      theme,
      rtl,
    } = this.props;

    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      type,
      startDateRangeStart,
      startDateRangeEnd,
      createdRangeStart,
      createdRangeEnd,
      sortBy,
      dir,
      currentPage,
      perPage,
      viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              {/* <ExpensesFilter
                openNewExpenseModel={this.newExpenseModal}
                updateFilter={this.updateFilter}
                activesFilter={activesFilter}
                startDate={startDate}
                endDate={endDate}
                keyword={keyword}
                type={type}
                theme={theme}
                rtl={rtl.direction}
              /> */}
              <ExpensesTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadExpense}
                expenses={expenses}
                totalExpenses={totalExpenses}
                toggleEditExpense={this.editExpenseModal}
                sortBy={sortBy}
                dir={dir}
                // startDateRangeStart={startDateRangeStart}
                // startDateRangeEnd={startDateRangeEnd}
                // createdRangeStart={createdRangeStart}
                // createdRangeEnd={createdRangeEnd}
                updateSort={this.updateSort}
                toggleExpenseStatus={this.toggleExpenseStatus}
                promptSoftDelete={this.promptSoftDelete}
              />
              <ExpensesTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalExpenses={totalExpenses}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  renderCardView = () => {
    const {
      loading,
      loaded,
      error,
      expenses,
      totalExpenses,
      editingExpense,
      t,
      theme,
      rtl,
    } = this.props;

    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      type,
      sortBy,
      dir,
      currentPage,
      perPage,
      viewMode,
    } = this.state;

    if (viewMode !== 'card') {
      return null;
    }

    return (
      <Row>
        <Col xs={12}>
          <Loading
            loading={loading}
            loaded={loaded}
            error={error}
            retry={this.forceReloadExpense}
          >
            <ExpenseList
              expenses={expenses}
              totalExpenses={totalExpenses}
              toggleEditExpense={this.editExpenseModal}
              toggleExpenseStatus={this.toggleExpenseStatus}
              promptSoftDelete={this.promptSoftDelete}
            />
          </Loading>
        </Col>
        {/* <Col md={3} xl={2}>
          <ExpenseSidebar
            openNewExpenseModel={this.newExpenseModal}
            updateFilter={this.updateFilter}
            activesFilter={activesFilter}
            startDate={startDate}
            endDate={endDate}
            keyword={keyword}
            type={type}
            sortBy={sortBy}
            dir={dir}
            theme={theme}
            rtl={rtl.direction}
          />
        </Col> */}
      </Row>
    );
  };

  render() {
    const {
      loading,
      loaded,
      error,
      expenses,
      totalExpenses,
      editingExpense,
      t,
      theme,
      rtl,
    } = this.props;
    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      tenureType,
      sortBy,
      dir,
      billingType,
      category,
      currentPage,
      perPage,
      keywords,
      viewMode,
      deletingReport,
    } = this.state;

    return (
      <Container className="container__with-filter projects-page">
        <Row>
          <Col sm={6}>
            <h3 className="page-title">{t('report_index.reports')}</h3>
          </Col>
          <Col sm={6} className="d-flex justify-content-end">
            <Button
              color="success"
              className="icon"
              onClick={this.newExpenseModal}
            >
              <p>
                <AccountPlusIcon /> {t('report_index.new_report')}
              </p>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="projects-page__view-mode-switch">
            {/* <ButtonToolbar>
              <ButtonGroup className="btn-group--icons" dir="ltr">
                <Button
                  type="button"
                  color="secondary"
                  active={viewMode === 'table'}
                  onClick={() => this.switchViewMode('table')}
                >
                  <FormatListBulletedIcon />
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  active={viewMode === 'card'}
                  onClick={() => this.switchViewMode('card')}
                >
                  <ViewGridIcon />
                </Button>
              </ButtonGroup>
            </ButtonToolbar> */}
          </Col>
        </Row>
        {this.renderTableView()}
        {/* {this.renderCardView()} */}
        <ReportDeletePrompt
          report={deletingReport}
          onHideModal={this.promptSoftDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.reports.loading,
  loaded: state.reports.loaded,
  error: state.reports.error,
  expenses: state.reports.reports,
  totalExpenses: state.reports.totalReports,
  editingExpense: state.reports.editingReport,
  theme: state.theme,
  rtl: state.rtl,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = {
  getExpensesRequest: TenantActions.Reports.getReportsRequest,
  updateExpense: TenantActions.Reports.updateReportRequest,
  editExpense: TenantActions.Reports.editReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Expenses));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
