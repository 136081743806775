import React, { useEffect, useState } from 'react';

const useEmailTemplate = (user, subject, htmlBody) => {
  const [emlFile, setEmlFile] = useState();
  useEffect(() => {
    const text = `To: ${user}
Subject: ${subject}
X-Unsent: 1
Content-Type: text/html

${htmlBody}`;
    const data = new Blob([text], { type: 'text/plain' });
    const nextEmlFile = window.URL.createObjectURL(data);
    setEmlFile(nextEmlFile);

    return () => {
      window.URL.revokeObjectURL(nextEmlFile);
    };
  }, [user, subject, htmlBody]);
  return emlFile || '';
};

export default useEmailTemplate;
