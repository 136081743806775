import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const renderTextArea = ({
  input,
  meta: { touched, error, warning },
  placeholder,
  t,
}) => (

  <div className="form__form-group-input-wrap">
    <textarea {...input} placeholder={placeholder} />
    {touched
    && ((error && <span className="form__form-group-error">{t(error)}</span>)
      || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
  </div>
);
renderTextArea.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
renderTextArea.defaultProps = {
  placeholder: '',
};

export default withTranslation('common')(renderTextArea);
