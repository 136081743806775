import PropTypes from 'prop-types';

const durationPriceType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  closedBillableTasksPrice: PropTypes.number.isRequired,
  openBillableTasksPrice: PropTypes.number.isRequired,
  closedExtraPaymentsPrice: PropTypes.number.isRequired,
  openExtraPaymentsPrice: PropTypes.number.isRequired,
  closedBillableTasksDuration: PropTypes.number.isRequired,
  openBillableTasksDuration: PropTypes.number.isRequired,
  nonBillableTasksDuration: PropTypes.number.isRequired,
  closedBillableTasksCount: PropTypes.number.isRequired,
  openBillableTasksCount: PropTypes.number.isRequired,
  nonBillableTasksCount: PropTypes.number.isRequired,
});

export default durationPriceType;
