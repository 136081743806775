/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';
import {
  Button, Card, CardBody, Col,
} from 'reactstrap';
import moment from 'moment';

import EditIcon from 'mdi-react/EditIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import DashboardIcon from 'mdi-react/ClipboardAccountIcon';
import CashIcon from 'mdi-react/CashIcon';
import classNames from 'classnames';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import employeeType from '../types/employee';
import { subTypeToLabel, typeToLabel } from '../constants/options';
import * as ProjectActions from '../../../../store/actions/tenant/projects';
import * as EmployeeActions from '../../../../store/actions/tenant/employees';

class EmployeeItem extends Component {
  static propTypes = {
    /* actions: PropTypes.shape({
      editemployees-page: PropTypes.func,
      completeemployees-page: PropTypes.func,
      deleteemployees-page: PropTypes.func,
    }).isRequired, */
    employee: employeeType.isRequired,
    toggleEditEmployee: PropTypes.func.isRequired,
    toggleEmployeeStatus: PropTypes.func.isRequired,
    editEmployee: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    const { employee } = this.props;

    /*
    this.state = {
      title: employees-page.title,
      completed: employees-page.completed,
      description: employees-page.description,
      priority: employees-page.priority,
    };
    */
  }

  handleAddMatter = () => {
    /* const { employee, editProject } = this.props;
    const { _id, name } = employee;
    editProject({
      _id: 'new',
      employeeId: { value: _id, label: name },
    }); */
  }

  handleEditEmployee = () => {
    const { employee, editEmployee } = this.props;
    editEmployee(employee);
  }

  handleComplete() {
    const { employee } = this.props;
    // const { checked } = this.state;

    /*
    actions.completeemployees-page(employees-page.id);
    this.setState({
      checked: !checked,
    });
     */
  }

  handleDelete() {
    const { employee } = this.props;

    // actions.deleteemployees-page(employees-page.id);
  }


  /*
  handleEdit() {
    const { employees-page, actions } = this.props;

    actions.editemployees-page(employees-page.id);
  }

  handleUpdate() {
    const { employees-page, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updateemployees-page(employees-page.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }

 */

  render() {
    /*
    const {
      title, completed, description, priority,
    } = this.state;
    */

    /*
    const priorityColorsClass = classNames({
      'employees-page__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
     */
    const {
      employee, toggleEditEmployee, toggleEmployeeStatus, t,
    } = this.props;
    const {
      _id, name, sector, peopleInCharge, type, subtype, taxNumber, taxOffice, addresses, emails, phones,
      isActive, created, fname, lname,
    } = employee;
    const fullname = `${fname} ${lname}`;

    return (
      <Card>
        <CardBody className="employees-page__item pt-0 ">
          <button
            className="btn btn-secondary employees-page__delete-btn align-self-right"
            type="button"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: '30px',
              height: '30px',
              alignItems: 'center',
              padding: 'initial',
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#000',
              color: '#000',
            }}
            onClick={toggleEditEmployee}
          >
            <EditIcon className="w-100" style={{ color: '#000' }} />
          </button>
          <fieldset style={{ borderWidth: 1, borderColor: '#000', borderStyle: 'solid' }}>
            <legend style={{
              width: 'unset',
              whiteSpace: 'nowrap',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
            >
              {fullname}
              {/* <Link to={`/employees/${_id}/matters/`}>
                {fullname}
              </Link> */}
            </legend>

            {/* <label htmlFor={`employee_${_id}_is_active_`} className="employees-page__label-checkbox">
              <input
                id={`employee_${_id}_is_active_`}
                type="checkbox"
                className="employees-page__complete-toggle"
                defaultChecked={isActive}
                required
                onClick={toggleEmployeeStatus}
              />
              <span className="checkbox-indicator" />
            </label> */}
            <div className="employees-page__info">

              <div className="employees-page__header">
                {/* <h3>{name}</h3> */}
                <div className="employees-page__additional">
                  <p className="employees-page__due-date">
                    {t('employee_item.created')} {`${moment(created).fromNow()}`}
                  </p>
                  <span className="employees-page__priority">{typeToLabel(type)}</span>
                  <span className="employees-page__priority">{subTypeToLabel(subtype)}</span>
                </div>
              </div>
              <div className="separator" />
              <div className="employees-page__content">

                <div className="customers-page__description my-2">
                  {/* <h4>Addresses</h4> */}
                  <ul>
                    {employee.addresses.map(({
                      address, country, city, zipCode,
                    }, index) => (
                      <li key={`customer_${_id}_address_${index}`}>
                        <MapMarkerIcon size="15" />
                        {`${address} ${city} ${country} ${zipCode}`}
                      </li>
                    ))}
                  </ul>

                </div>
                <div className="separator w-100" />
                <div className="customers-page__description my-2">

                  {/* <h4>Phones</h4> */}
                  <ul>

                    <PhoneIcon size="15" /> {' '}
                    <a href={`tel:${employee.phone}`}>
                      {employee.phone}
                    </a>

                  </ul>
                </div>
                <div className="customers-page__description my-2">
                  {/* <h4>E-Mails</h4> */}
                  <ul>

                    <EmailIcon size="15" /> {' '}
                    <a href={`mailto:${employee.email}`}>
                      {employee.email}
                    </a>
                  </ul>
                </div>
                <div className="customers-page__description my-2" />
                <div className="separator w-100" />
                {/*
                <div className="employees-page__description my-2">
                  <ul>

                    <li>
                      <CashIcon size="18" />{' '}
                        Average Fee: <span style={{ fontSize: '14px', textDecorationLine: 'underline' }}>35₺/Hour</span>
                    </li>
                    <li>
                      <CashIcon size="18" /> {' '}
                      Estimated Total: <span style={{ fontSize: '14px', textDecorationLine: 'underline' }}>35000₺</span>
                    </li>


                  </ul>
                </div>
                */}
              </div>
            </div>
            <div
              className={`my-2 ${''
              }d-flex flex-wrap justify-content-center align-items-center w-100`}
            >
              <Button
                type="button"
                className="icon m-0 mx-1"
                outline
                onClick={this.handleEditEmployee}
              >
                <p><SquareEditOutlineIcon /> {t('employee_item.edit')}</p>
              </Button>
              {/*              <Button
                type="button"
                color="success"
                onClick={this.handleAddMatter}
                className="icon m-0 mx-1"
              >
                <p style={{ color: '#fff' }}>
                  <AccountPlusIcon /> Add Matter
                </p>
              </Button> */}
            </div>
          </fieldset>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  editProject: ProjectActions.editProject,
  editEmployee: EmployeeActions.editEmployee,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(EmployeeItem));
