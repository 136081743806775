import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Field, reduxForm, change, formValueSelector,
} from 'redux-form';
import {
  Container, Row, Col,
  Nav, NavItem, NavLink, ButtonToolbar, Button,
} from 'reactstrap';
import classNames from 'classnames';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import RenderSelectField from '../../../../shared/components/form/Select';
import renderField from '../../../../shared/components/form/FormField';
import RenderDateTimePickerField from '../../../../shared/components/form/DateTimePicker';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import Loading from '../../../../shared/components/Loading';

import * as ExpenseActions from '../../../../store/actions/tenant/expenses';

import { feeUnits, currencies, defaultCurrencyObject } from '../../Projects/constants/options';
import UploadableImage from '../../../../shared/components/UploadableImage/UploadableImage';

import {
  getCustomersList, getProjectsList, getTasksList, getExpenseTypes,
} from '../../../../store/api/lookup';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderTextArea from '../../../../shared/components/form/TextArea';


const baseTasksOptions = [
  { label: 'None', value: null },
];

class ExpenseForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      customerId: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      selectedProject: PropTypes.shape(),
    }).isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    changeFormField: PropTypes.func.isRequired,
    updateExpensePhoto: PropTypes.func.isRequired,
    deleteExpensePhoto: PropTypes.func.isRequired,
    description: PropTypes.string,
    taskId: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    description: '',
    taskId: '',
  };

  state = {
    customers: [],
    projects: [],
    projectsById: {},
    tasks: [...baseTasksOptions],
    isSubExpense: true,
  };

  customerRequired = isRequired('expense_form.customer');

  matterRequired = isRequired('expense_form.matter');

  /* titleRequired = isRequired('Title'); */

  expenseTypeRequired = isRequired('expense_form.expense_type');

  amountRequired = isRequired('expense_form.amount');

  currencyRequired = isRequired('expense_form.currency');

  descriptionRequired = isRequired('expense_form.description');

  componentDidMount() {
    const { initialValues } = this.props;
    const {
      customerId, projectId, isSubExpense, selectedProject, taskId,
    } = initialValues;
    if (projectId) {
      this.handleProjectSelect(projectId, selectedProject);
    }
    if (customerId) {
      this.handleCustomerSelect(customerId);
    }
    if (isSubExpense === false) {
      this.setState({ isSubExpense });
    }
    // if (taskId) {
    //   this.handleTaskSelect(taskId , projectId);
    // }
    getCustomersList()
      .then((customers) => {
        if (customers) {
          this.setState({
            customers: customers.map(({ _id, name }) => ({ label: name, value: _id })),
          });
        }
      });
    getExpenseTypes().then((expenseTypes) => {
      if (expenseTypes) {
        this.setState({
          expenseTypes: expenseTypes.map(({ _id, name }) => ({ label: name, value: name })),
        });
      }
    });
  }

  handleCustomerSelect = (customer) => {
    const { value } = customer;
    getProjectsList(value)
      .then((projects) => {
        if (projects) {
          this.setState({
            projects: projects.map(({ _id, title }) => ({ label: title, value: _id })),
            projectsById: projects.reduce((acc, project) => ({ ...acc, [project._id]: project }), {}),
          });
        }
      });
  };

  handleTaskSelect = (task) => {
    const { value } = task || {};
    if (!value) {
      return;
    }
    const { changeFormField, description, taskId: prevTaskId } = this.props;
    const { tasksById } = this.state;
    const { startDateTime, description: taskDescription } = (tasksById || {})[value] || {};
    const { description: prevTaskDescription } = (tasksById || {})[prevTaskId] || {};
    changeFormField('appliedDate', new Date(startDateTime));
    if (!(description || '').trim()
      || (description || '').trim() === (prevTaskDescription || '').trim()) {
      changeFormField('description', taskDescription);
    }
    /* getTasksList(value)
      .then((tasks) => {
        if (tasks) {
          this.setState({
            tasks: tasks.map(({ _id, title }) => ({ label: title, value: _id })),
          });
        }
      }); */
  };

  handleProjectSelect = (project, selectedProject) => {
    const { changeFormField } = this.props;
    const { projectsById } = this.state;
    const { value } = project;
    // eslint-disable-next-line no-param-reassign
    selectedProject = selectedProject || projectsById[value] || {};

    getTasksList(value)
      .then((tasks) => {
        if (tasks) {
          this.setState({
            tasks: [...baseTasksOptions, ...tasks.map(({ _id, title }) => ({ label: title, value: _id }))],
            tasksById: tasks.reduce((acc, task) => ({ ...acc, [task._id]: task }), {}),
          });
        }
      });
  };

  uploadableImageField = (props) => {
    const {
      input, uploadable, clearable, className, title, placeholderImage, placeholderImageElement, shape,
    } = props;

    const {
      isNew, updateExpensePhoto, deleteExpensePhoto, initialValues, t,
    } = this.props;
    const { _id } = initialValues;
    const {
      onChange, value, name, meta,
    } = input;
    return (
      <div className={`form__form-group-input-wrap ${className || ''}`}>
        <UploadableImage
          uploadable={uploadable}
          clearable={clearable}
          onChange={(imageFile) => {
            if (isNew) {
              onChange(imageFile);
              // don't set it on form on update, instead update it directly
            } else {
              const formData = new FormData();
              formData.append('picture', imageFile);
              updateExpensePhoto({ _id, formData });
            }
          }}
          onClear={() => {
            if (!isNew) {
              deleteExpensePhoto({ _id });
            }
            onChange(null);
          }}
          imageSource={value}
          title={title}
          name={name}
          placeholderImage={placeholderImage}
          placeholderImageElement={placeholderImageElement}
          shape={shape}

        />
        {meta && meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
      </div>
    );
    /**
     * uploadable: bool (default: false)
     * placeholderImage: string (url/src to default image)
     * placeholderImageElement: react element (default null)
     * imageSource: string (url/src to current image)
     * onChange: function(imageFile):void
     * title: string (default: 'image')
     * shape: 'circle' | 'rounded' | 'square' (default: rounded)
     */
  }

  render() {
    const {
      handleSubmit, close, loading, isNew, t,
    } = this.props;
    const {
      customers, projects, tasks, isSubExpense,
      expenseTypes,
    } = this.state;
    return (
      <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="mb-3">
                {isNew ? t('expense_form.add') : t('expense_form.edit')} {t('expense_form.expense')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_form.customer')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="customerId"
                    component={RenderSelectField}
                    placeholder={t('expense_form.customer')}
                    options={customers}
                    extraOnChange={this.handleCustomerSelect}
                    validate={this.customerRequired}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_form.matter')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="projectId"
                    component={RenderSelectField}
                    placeholder={t('expense_form.matter')}
                    options={projects}
                    extraOnChange={this.handleProjectSelect}
                    validate={this.matterRequired}
                  />
                </div>
              </div>
            </Col>
            {isSubExpense && (
              <Col xs={12} sm={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('expense_form.task')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon form__form-group-icon--select">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      name="taskId"
                      component={RenderSelectField}
                      placeholder={t('expense_form.task')}
                      options={tasks}
                      extraOnChange={this.handleTaskSelect}
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_form.expense_type')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="expenseType"
                    component={RenderSelectField}
                    placeholder={t('expense_form.expense_type')}
                    options={expenseTypes}
                    isCreatable
                    validate={this.expenseTypeRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/*          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">Title</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    placeholder="Title"
                    validate={this.titleRequired}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_form.amount')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="amount"
                    component={renderField}
                    type="number"
                    placeholder={t('expense_form.amount')}
                    validate={this.amountRequired}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('project_form.currency')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="currency"
                    component={RenderSelectField}
                    placeholder={t('project_form.currency')}
                    options={currencies}
                    defaultValue={defaultCurrencyObject}
                    type="String"
                    validate={this.currencyRequired}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('expense_form.applied_date')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="appliedDate"
                    component={RenderDatePickerField}
                    forceValue
                  />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('expense_form.description')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="description"
                    component={renderTextArea}
                    type="text"
                    placeholder={t('expense_form.description')}
                    validate={this.descriptionRequired}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('expense_form.image')}</span>
                <div>
                  <Field
                    name="picture"
                    component={this.uploadableImageField}
                    type="file"
                    title="Image"
                    uploadable
                    clearable
                    placeholderImageElement={<span className="display-3">+</span>}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Loading loading={loading}>
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('expense_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew ? t('expense_form.add_expense') : t('expense_form.update_expense')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const selector = formValueSelector('tenant_expense_form');

const mapStateToProps = state => ({
  description: selector(state, 'description') || '',
  taskId: (selector(state, 'taskId') || {}).value || '',
});

const mapDispatchToProps = {
  changeFormField: (field, value) => change('tenant_expense_form', field, value),
  updateExpensePhoto: ExpenseActions.updateExpensePhotoRequest,
  deleteExpensePhoto: ExpenseActions.deleteExpensePhotoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'tenant_expense_form',
  })(withTranslation('common')(ExpenseForm)),
);
