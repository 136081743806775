import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import DatePicker from '../../../../shared/components/form/DatePicker';

const defaultEnd = new Date();
const defaultStart = new Date(defaultEnd.getTime() - 3 * 30 * 24 * 60 * 60 * 1000);

const DateFilter = ({ state, onChange }) => (
  <Row className="form">
    <Col xs={12} sm={6}>
      <DatePicker
        input={{
          onChange,
          name: 'start',
          value: state.start,
        }}
        isClearable={false}
        defaultValue={defaultStart}
      />
    </Col>
    <Col xs={12} sm={6}>
      <DatePicker
        input={{
          onChange,
          name: 'end',
          value: state.end,
        }}
        isClearable={false}
        defaultValue={defaultStart}
      />
    </Col>
  </Row>
);

DateFilter.propTypes = {
  state: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const initialState = {
  start: defaultStart,
  end: defaultEnd,
};

export default DateFilter;
