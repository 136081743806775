import update, { extend } from 'immutability-helper';
import withFetch from './withFetch';
import {
  CREATE_TASK_TENANT, GET_TASKS_TENANT, UPDATE_TASK_TENANT, RELOAD_TASK_TENANT,
  SOFT_DELETE_TASK_TENANT,
  DELETE_TASK_TENANT,
  DELETE_TASK_WITH_CHILDREN_TENANT,
} from '../fetchTypes';
import {
  EDIT_TASK, LOG_OUT, CHANGE_TASK_FILTER, CLEAR_TASKS, CLEAR_TASK_FILTER, CLEAR_SOFT_DELETE_STATUS,
} from '../sharedTypes';

extend('$filterRemove', (unwanted, original) => original.filter(x => !unwanted.includes(x)));
extend('$dec', (y, x) => x - y);
extend('$inc', (y, x) => x + y);

const callIfExists = (obj, ifCallback, elseCallback) => {
  if (obj) {
    return ifCallback && ifCallback(obj);
  }
  return elseCallback && elseCallback(obj);
};

const createInitialState = () => ({
  filters: {
    'createdRange[0]': undefined,
    'createdRange[1]': undefined,
    'startDateRange[0]': undefined,
    'startDateRange[1]': undefined,
    actives: '1',
    projectId: '',
    parent: '1',
    keywords: '',
    status: '',
    sortBy: 'startDateTime',
    dir: 'asc',
  },
  loading: false,
  loaded: false,
  error: undefined,
  updating: false,
  updateError: undefined,
  editingTask: null,
  totalTasks: 0,
  tasks: [],
  softDeleteHelper: {
    deleting: new Set(),
    deleted: new Set(),
    failed: new Map(),
  },
  tasksById: {
    123: {
      loading: false,
      loaded: true,
      error: undefined,
      childrenLoading: false,
      childrenLoaded: false,
      childrenLoadError: undefined,
      totalChildrenTasks: 0,
      childrenTasks: [],
      details: {
        _id: '123',
        parentId: null,
        description: 't123 description',
        hasChild: true,
        isActive: true,
        title: 't123',
        status: 100,
        startDateTime: '2019-10-12T00:00:00.000Z',
        endDateTime: '2019-10-14T00:00:00.000Z',
        estimatedHrs: 48,
        isClose: false,
        project: {
          id: 'abcd',
          title: 'xyz',
        },
      },
    },
  },
});

// WHEN FETCHING A TASK FROM ITS OWN PAGE FOR THE FIRST TIME,
// CALL RELOAD FIRST, THEN CALL GET_TASKS_TENANT TO FETCH ITS CHILDREN

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_TASKS_TENANT:
      if (action.data.parentId) {
        return update(state, {
          tasksById: {
            [action.data.parentId]: {
              childrenLoading: { $set: true },
              childrenLoadError: { $set: undefined },
            },
          },
        });
      }
      return update(state, {
        loading: { $set: true },
        error: { $set: undefined },
      });
    case RELOAD_TASK_TENANT:
      if (!state.tasksById[action.data._id]) {
        // initialize the parent task if arrived at /tasks/:id page from outside
        return update(state, {
          tasksById: {
            [action.data._id]: {
              $set: {
                loaded: false,
                loading: true,
                error: undefined,
                childrenLoading: false,
                childrenLoaded: false,
                childrenLoadError: undefined,
                totalChildrenTasks: 0,
                childrenTasks: [],
                details: null,
              },
            },
          },
        });
      }
      return update(state, {
        tasksById: {
          [action.data._id]: {
            loading: { $set: true },
            error: { $set: undefined },
          },
        },
      });
    case SOFT_DELETE_TASK_TENANT:
      return update(state, {
        softDeleteHelper: {
          deleting: {
            $add: [action.data], // _id is given directly
          },
          deleted: {
            $remove: [action.data],
          },
          failed: {
            $remove: [action.data],
          },
        },
      });
    case CREATE_TASK_TENANT:
    case UPDATE_TASK_TENANT:
      return update(state, {
        updating: { $set: true },
        updateError: { $set: undefined },
      });
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  let nextState = state;
  switch (action.fetchType) {
    case GET_TASKS_TENANT:
      if (action.reqData.parentId) {
        nextState = update(nextState, {
          tasksById: {
            [action.reqData.parentId]: {
              childrenLoading: { $set: false },
              childrenLoaded: { $set: true },
              childrenLoadError: { $set: undefined },
              totalChildrenTasks: { $set: action.data.totalTasks },
              childrenTasks: { $push: action.data.tasks.map(({ _id }) => _id) },
            },
          },
        });
      } else {
        nextState = update(nextState, {
          loading: { $set: false },
          loaded: { $set: true },
          tasks: { $push: action.data.tasks.map(({ _id }) => _id) },
          totalTasks: { $set: action.data.totalTasks },
        });
      }
      return update(nextState, {
        tasksById: action.data.tasks.reduce((acc, task) => ({
          ...acc,
          [task._id]: {
            $set: {
              loading: false,
              loaded: true,
              error: undefined,
              childrenLoading: false,
              childrenLoaded: !task.hasChild,
              childrenLoadError: undefined,
              totalChildrenTasks: 0,
              childrenTasks: [],
              details: task,
            },
          },
        }), {}),
      });
    case RELOAD_TASK_TENANT:
      if (action.data.task) {
        return update(state, {
          tasksById: {
            [action.reqData._id]: {
              loading: { $set: false },
              loaded: { $set: true },
              error: { $set: undefined },
              details: {
                $set: {
                  project: action.data.task.projectId,
                  ...action.data.task,
                },
              },
            },
          },
        });
      }
      return update(state, {
        tasksById: {
          [action.reqData._id]: {
            loading: { $set: false },
            loaded: { $set: true },
            error: { $set: 'Could not find Task!' },
          },
        },
      });

    case SOFT_DELETE_TASK_TENANT:
      callIfExists(state.tasksById[action.reqData], (oldTask) => {
        nextState = update(state, {
          tasksById: {
            [action.reqData]: {
              details: {
                isActive: { $set: false },
              },
            },
          },
        });
        // eslint-disable-next-line eqeqeq
        if (state.filters.actives == 1 && oldTask.parentId) {
          callIfExists(state.tasksById[oldTask.parentId], (parentTask) => {
            if (parentTask.childrenTasks.includes(action.reqData)) {
              nextState = update(nextState, {
                tasksById: {
                  [oldTask.parentId]: {
                    childrenTasks: { $filterRemove: [action.reqData] },
                    totalChildrenTasks: { $dec: 1 },
                  },
                },
              });
            }
          });
        }
      });
      // eslint-disable-next-line eqeqeq
      if (state.filters.actives == 1) {
        if (state.tasks.includes(action.reqData)) {
          nextState = update(nextState, {
            tasks: { $filterRemove: [action.reqData] },
            totalTasks: { $dec: 1 },
          });
        }
      }
      return update(nextState, {
        softDeleteHelper: {
          deleting: {
            $remove: [action.reqData],
          },
          deleted: {
            $add: [action.reqData],
          },
        },
      });
    case UPDATE_TASK_TENANT:
    case CREATE_TASK_TENANT:
      callIfExists(state.tasksById[action.reqData._id], (oldTask) => {
        // THE TASK WAS LOADED BEFORE
        nextState = update(nextState, {
          tasksById: {
            [action.reqData._id]: {
              loaded: { $set: false },
            },
          },
        });
        if (action.reqData.parentId !== oldTask.details.parentId) {
          // TASK'S PARENT IS CHANGED OR (NULL !== UNDEFINED OR '' !== NULL OR '' !== UNDEFINED)
          if (action.reqData.parentId || oldTask.details.parentId) {
            // TASK'S PARENT IS CHANGED
            callIfExists(state.tasksById[action.reqData._id].details.parentId, (parentId) => {
              // TASK HAD A PARENT
              if (state.tasksById[parentId]) {
                // THAT PARENT WAS LOADED SO REMOVE THE CURRENT TASK FROM THE PARENT
                nextState = update(nextState, {
                  tasksById: {
                    [parentId]: {
                      totalChildrenTasks: { $apply: x => x - 1 },
                      childrenTasks: { $filterRemove: [action.reqData._id] },
                    },
                  },
                });
              }
            }, () => {
              // TASK DID NOT HAD A PARENT
              if (state.tasks.includes(action.reqData._id)) {
                // TASK WAS LOADED ON TASKS LIST IN PROJECT
                nextState = update(nextState, {
                  totalTasks: { $apply: x => x - 1 },
                  tasks: { $filterRemove: [action.reqData._id] },
                });
              }
            });
          }
        }
      }, () => {
        // THE TASK WAS NOT LOADED BEFORE
        callIfExists(action.reqData.parentId, (parentId) => {
          // THE TASK'S PARENT MAY HAVE BEEN CHANGED (including new creation)
          callIfExists(state.tasksById[parentId], (parentTask) => {
            // THE TASKS'S NEW PARENT WAS LOADED BEFORE
            if (!(parentTask.childrenTasks || []).includes(action.reqData._id)) {
              // THE TASK IS NOT INCLUDED IN ITS NEW PARENT, SO AN UPDATE IS REQUIRED
              if (action.reqData._id.toLowerCase() === 'new') {
                // TASK IS NEWLY CREATED, PUSH THE NEW TASK TO PARENT.
                // ALSO, ADD THE NEW TASK TO STATE
                // WE GET EVERYTHING ABOUT THE TASK EXCEPT PROJECT NAME,
                // SO JUST COPY IT FROM THE PARENT INSTEAD OF TRIGGERING A RELOAD
                nextState = update(nextState, {
                  tasksById: {
                    [parentId]: {
                      totalChildrenTasks: { $apply: x => x + 1 },
                      childrenTasks: { $push: [action.data.task._id] },
                    },
                    [action.data.task._id]: {
                      $set: {
                        loading: false,
                        loaded: true,
                        error: undefined,
                        childrenLoading: false,
                        childrenLoaded: !action.data.task.hasChild,
                        childrenLoadError: undefined,
                        totalChildrenTasks: 0,
                        childrenTasks: [],
                        details: {
                          project: ((state.tasksById[parentId].details || {}).project || {
                            projectId: action.data.task.projectId,
                          }),
                          ...action.data.task,
                        },
                      },
                    },
                  },
                });
              } else {
                // TASK WAS EXISTING BUT UPDATED, JUST PUSH THIS TASK TO IT
                nextState = update(nextState, {
                  tasksById: {
                    [parentId]: {
                      totalChildrenTasks: { $apply: x => x + 1 },
                      childrenTasks: { $push: [action.reqData._id] },
                    },
                  },
                });
              }
            }
          });
        }, () => {
          // THE TASK IS CREATED / UPDATED TO NOT HAVE A PARENT
          if (!state.tasks.includes(action.reqData._id)) {
            // THE TASK IS NOT INCLUDED IN LISTED TASKS, SO AN UPDATE IS REQUIRED
            if (action.reqData._id.toLowerCase() === 'new') {
              // TASK IS NEWLY CREATED, PUSH THE TASK TO THE LIST DESPITE FILTERS
              // ALSO COPY THE CURRENT LOADED INFO AS A NEW TASK TO STATE, BUT
              // DO NOT MAKE IT LOADED SO THAT IT'S PROJECT'S NAME CAN BE LOADED
              // (NOT INCLUDED IN RESPONSE AS OF NOW)
              nextState = update(nextState, {
                totalTasks: { $apply: x => x + 1 },
                tasks: { $push: [action.data.task._id] },
                tasksById: {
                  [action.data.task._id]: {
                    $set: {
                      loading: false,
                      loaded: false,
                      error: undefined,
                      childrenLoading: false,
                      childrenLoaded: !action.data.task.hasChild,
                      childrenLoadError: undefined,
                      totalChildrenTasks: 0,
                      childrenTasks: [],
                      details: {
                        project: {
                          projectId: action.data.task.projectId,
                        },
                        ...action.data.task,
                      },
                    },
                  },
                },
              });
            } else {
              // TASK WAS EXISTING BUT UPDATED, JUST PUSH THIS TASK TO THE LIST DESPITE FILTERS
              nextState = update(nextState, {
                totalTasks: { $apply: x => x + 1 },
                tasks: { $push: [action.reqData._id] },
              });
            }
          }
        });
      });
      return update(nextState, {
        updating: { $set: false },
        editingTask: { $set: null }, // ??
      });
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_TASKS_TENANT:
      if (action.data.parentId) {
        return update(state, {
          tasksById: {
            [action.data.parentId]: {
              childrenLoading: { $set: false },
              childrenLoadError: { $set: action.error },
            },
          },
        });
      }
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case RELOAD_TASK_TENANT:
      return update(state, {
        tasksById: {
          [action.data._id]: {
            loading: { $set: false },
            error: { $set: action.error },
          },
        },
      });
    case SOFT_DELETE_TASK_TENANT:
      return update(state, {
        softDeleteHelper: {
          deleting: {
            $remove: [action.data],
          },
          failed: {
            $add: [[action.data, {
              task: ((state.tasksById[action.data] || {}).details || {}).title || `Task #${action.data}`,
              error: action.error,
            }]],
          },
        },
      });
    case CREATE_TASK_TENANT:
    case UPDATE_TASK_TENANT:
      return update(state, {
        updating: { $set: false },
        updateError: { $set: action.error },
      });
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
    case CLEAR_TASK_FILTER:
      return createInitialState();
    case CHANGE_TASK_FILTER:
      return update(createInitialState(), {
        filters: {
          $merge: {
            ...state.filters,
            ...action.data,
          },
        },
      });
    case CLEAR_SOFT_DELETE_STATUS:
      return update(state, {
        softDeleteHelper: {
          $set: {
            deleting: new Set(),
            deleted: new Set(),
            failed: new Map(),
          },
        },
      });
    case EDIT_TASK:
    // TODO NOT DONE
      return {
        ...state,
        editingTask: action.data,
      };
    // TODO NOT DONE FINISHED
    default:
      return state;
  }
};

export const selectTaskById = (state, taskId) => state.tasks.tasksById[taskId] || {
  loading: false,
  loaded: false,
  error: undefined,
  childrenLoading: false,
  childrenLoaded: false,
  childrenLoadError: undefined,
  totalChildrenTasks: 0,
  childrenTasks: [],
  details: null,
};

export const selectFilter = state => state.tasks.filters;

export const filterToParams = ({ actives, billableStatus, ...filter }, extra = {}) => {
  const params = Object.entries(filter)
    .filter(([key, value]) => value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  // eslint-disable-next-line eqeqeq
  if (actives == 1) {
    params.actives = 1;
  }
  // eslint-disable-next-line eqeqeq
  if (actives == -1) {
    params.inactives = 1;
  }

  if ((billableStatus || '').startsWith('billable')) {
    params.billable = 1;
  }
  switch (billableStatus) {
    case 'billableClosed':
      params.closed = 1;
      break;
    case 'billableOpen':
      params.open = 1;
      break;
    case 'nonBillable':
      params.nonbillable = 1;
      break;
    case 'billable':
    default:
  }
  return {
    ...params,
    ...extra,
  };
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
