import { EDIT_PROFILE_ADMIN, EDIT_PROFILE_PICTURE_ADMIN, GET_PROFILE_ADMIN } from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_PROFILE_ADMIN:
      return () => makeFetcher('admins/profile', 'GET');
    case EDIT_PROFILE_ADMIN:
      return data => makeFetcher('admins/profile', 'PUT', data);
    case EDIT_PROFILE_PICTURE_ADMIN:
      return data => makeFetcher('admins/picture', 'POST', data,
        { 'Content-Type': 'multipart/form-data' });
    default:
      return null;
  }
};
