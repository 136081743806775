import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Row, Col } from 'reactstrap';
import normalizeNum from '../../../../lib/Normalize/NormalizeNum';
import { taxNumber } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderField from '../../../../shared/components/form/FormField';


const CustomerFormTax = ({ t }) => (
  <Row>
    <Col xs={12}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('customer_form_tax.tax_office')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="taxOffice"
            component="input"
            type="text"
            placeholder="Tax Office"
          />
        </div>
      </div>
    </Col>
    <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">Tax Number</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="taxNumber"
            component={renderField}
            type="text"
            normalize={(v, p) => normalizeNum(v, p, 10)}
            warn={taxNumber}
            placeholder="Tax Number"
          />
        </div>
      </div>
    </Col>
  </Row>
);

CustomerFormTax.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'tenant_customer_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(CustomerFormTax));
