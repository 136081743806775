import React, { PureComponent, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Field, FieldArray, formValueSelector, reduxForm,
} from 'redux-form';
import {
  Button, ButtonToolbar, Col, Container, Modal, Row,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import Loading from '../../../../shared/components/Loading';
import {
  addressRequired,
  cityRequired,
  countryList,
  countryRequired, MaskedZipcode,
} from '../../Profile/components/ProfileAddressSettings';
import RenderTextField from '../../Profile/components/TextField';
import {
  chamberRegistrationNumber, idNumber, idNumberRequired, phoneNumber, isRequired, zipCode, taxNumber,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import RenderSelectField from '../../../../shared/components/form/Select';
import * as Countries from '../../../../lib/Countries';
import ControlledPanel from '../../../../shared/components/ControlledPanel';
import useRefMemo from '../../../../shared/hooks/useRefMemo';
import renderField from '../../../../shared/components/form/FormField';
import renderMaskedInput from '../../../../shared/components/form/MaskedInput';
import { idMask } from '../../Customers/components/CustomerFormInfo';

const billingAddressRequired = isRequired('profile_subs.billing_address');
const shippingAddressRequired = isRequired('profile_subs.shipping_address');

const formSelector = formValueSelector('pre_subscription_form');
const addressSelector = state => formSelector(state, 'addresses');

const firstNameRequired = isRequired('profile_info.first_name');
const lastNameRequired = isRequired('profile_info.last_name');

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export class AddressList extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.any.isRequired,
    addressList: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
    }).isRequired,
    initialAddresses: PropTypes.arrayOf(PropTypes.shape({
      country: PropTypes.string,
    })).isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { initialAddresses } = this.props;

    this.state = {
      addressStates: initialAddresses.map(({ country }) => ({
        collapsed: false,
        cities: country ? Countries.citiesByCountry(country).map(countryName => ({
          label: countryName, value: countryName,
        })) : [],
      })),
    };
  }

  removeAddressState = (index) => {
    this.setState(({ addressStates }) => ({
      addressStates: [
        ...addressStates.slice(0, index),
        ...addressStates.slice(index + 1),
      ],
    }));
  };

  setAddressState = (index, nextState) => {
    this.setState(({ addressStates }) => ({
      addressStates: [
        ...addressStates.slice(0, index),
        { ...addressStates[index], ...nextState },
        ...addressStates.slice(index + 1),
      ],
    }));
  };

  getAddressState = (index) => {
    const { addressStates } = this.state;
    return addressStates[index] || {};
  };

  renderAddress = (address, index, addresses) => {
    const addressState = this.getAddressState(index);
    const { cities = [], collapsed = false } = addressState;
    const { t, addressList } = this.props;

    const subhead = (addressList[index] || {}).label || '';

    return (
      <ControlledPanel
        key={`${t('profile_address.address')}_${index}`}
        title={`${t('profile_address.address_upper')} #${index + 1}`}
        subhead={subhead}
        onToggle={addresses.length > 1 ? () => {
          addresses.remove(index);
          this.removeAddressState(index);
        } : null}
        collapsed={collapsed}
        onCollapse={() => this.setAddressState(index, {
          collapsed: !collapsed,
        })}
        bodyClassName="pt-4 pb-2"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_address.country')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name={`${address}.country`}
                  component={RenderSelectField}
                  setValueOnly
                  options={countryList}
                  extraOnChange={countryName => this.setAddressState(index, {
                    cities: Countries.citiesByCountry(countryName)
                      .map(cityName => ({ value: cityName, label: cityName })),
                  })}
                  validate={countryRequired}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_address.city')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name={`${address}.city`}
                  component={RenderSelectField}
                  setValueOnly
                  options={cities}
                  validate={cityRequired}
                  disabled={cities.length === 0}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_address.address_upper')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name={`${address}.address`}
                  component={renderField}
                  type="text"
                  placeholder={t('profile_address.address_upper')}
                  validate={addressRequired}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_address.zip_code')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`${address}.zipCode`}
                  component={renderMaskedInput}
                  type="text"
                  placeholder={t('profile_address.zip_code')}
                  validate={zipCode}
                  mask={zipCodeMask}
                />
              </div>
            </div>
          </Col>
        </Row>
      </ControlledPanel>
    );
  };

  render() {
    const { fields, meta: { error, submitFailed }, t } = this.props;
    return (
      <Row className="add-addresses-section">
        {
          fields.map(this.renderAddress)
        }
        <Col md={12} lg={12} xl={12} className="text-right">
          <Button
            type="button"
            color="success"
            onClick={() => fields.push({})}
          >
            {t('profile_address.add_address')}
          </Button>
          {submitFailed && error && <span>{t(error)}</span>}
        </Col>
      </Row>
    );
  }
}

const PreSubscriptionForm = ({
  handleSubmit, pristine, parentPristine, setParentPristine, close, reset, initialValues, loading, t,
}) => {
  const addresses = useSelector(addressSelector);

  const addressList = useRefMemo(() => addresses.map(({ address }, index) => ({
    label: address,
    value: `${index}`,
  })), [addresses]);

  useEffect(() => {
    if (pristine !== parentPristine) {
      setParentPristine(pristine);
    }
  }, [pristine, parentPristine, setParentPristine]);

  /*
    pristine olayini yap

  */

  return (
    <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className="mb-3">
              {t('pre_subscription_form.title')}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('profile_info.first_name')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="fname"
                  component={renderField}
                  type="text"
                  placeholder={t('profile_info.first_name')}
                  validate={firstNameRequired}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('profile_info.last_name')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="lname"
                  component={renderField}
                  type="text"
                  placeholder={t('profile_info.last_name')}
                  validate={lastNameRequired}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_info.identity_number')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="identityNumber"
                  component={renderMaskedInput}
                  type="text"
                  mask={idMask}
                  validate={idNumberRequired}
                  placeholder={t('profile_info.identity_holder')}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">{t('profile_info.phone')}</span>
              <div className="form__form-group-field">
                <Field
                  name="phone"
                  component={renderPhoneInput}
                  defaultCountry="tr"
                  type="text"
                  placeholder={t('profile_info.phone')}
                  validate={phoneNumber}
                />
              </div>
            </div>
          </Col>
        </Row>
        <FieldArray
          name="addresses"
          component={AddressList}
          initialAddresses={initialValues.addresses}
          addressList={addressList}
          t={t}
        />
        <Row>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_subs.billing_address')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="billingAddress"
                  component={RenderSelectField}
                  setValueOnly
                  options={addressList}
                  validate={billingAddressRequired}
                  disabled={addressList.length === 0}
                  placeholder={t('profile_subs.billing_address')}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form__form-group">
              <span className="form__form-group-label required">
                {t('profile_subs.shipping_address')}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="shippingAddress"
                  component={RenderSelectField}
                  setValueOnly
                  options={addressList}
                  validate={shippingAddressRequired}
                  disabled={addressList.length === 0}
                  placeholder={t('profile_subs.shipping_address')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Loading loading={loading}>
              <ButtonToolbar className="form__button-toolbar mt-4">
                <Button type="button" onClick={close}>{t('customer_form.close')}</Button>
                <div className="flex-grow-1" />
                <Button type="button" onClick={reset} disabled={loading}>
                  {t('profile_address.reset')}
                </Button>
                <Button color="primary" type="submit" className="next">
                  {t('pre_subscription_form.continue')}
                </Button>
              </ButtonToolbar>
            </Loading>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

PreSubscriptionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  parentPristine: PropTypes.bool.isRequired,
  setParentPristine: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    addresses: PropTypes.arrayOf(PropTypes.shape({
      country: PropTypes.string,
    })).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'pre_subscription_form', // a unique identifier for this form
})(withTranslation('common')(PreSubscriptionForm));
