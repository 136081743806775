import React, { useState, useEffect } from 'react';

const useStateMemo = (effect, dependencies) => {
  const [result, setResult] = useState(effect());

  useEffect(() => {
    setResult(effect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return result;
};

export default useStateMemo;
