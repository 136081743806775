import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Col, Container, Row, ButtonToolbar, ButtonGroup, Button, Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { } from 'react-router-dom';
import CardSpecial from './components/CardSpecial';
import CardPro from './components/CardPro';
import CardPremium from './components/CardPremium';
import ModalComponent from '../../../shared/components/Modal';
import { getSubscriptionOptions } from '../../../store/api/lookup';
import { requestSubscription } from '../../../store/api/tenant/subscription';
import * as ProfileActions from '../../../store/actions/tenant/profile';
import PreSubscription from './components/PreSubscription';

class Subscription extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    reloadProfile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: null,
      selectedPlan: 0,
      showSubscriptionModal: false,
      checkoutFormContent: '',
      pricingPlanReferenceCode: '',
      showPreSubscriptionModal: false,
    };
  }


  componentDidMount() {
    getSubscriptionOptions().then((response) => {
      const options = response.map(option => ({
        ...option,
        pricingPlanByInterval: (option.pricingPlans || [])
          .reduce((acc, plan) => ({
            ...acc,
            [plan.paymentInterval]: plan,
          }), {}),
      })).sort((a, b) => a.pricingPlanByInterval.MONTHLY.price - b.pricingPlanByInterval.MONTHLY.price);
      this.setState({ options });
    });
    window.addEventListener('message', this.isSubscriptionSuccess, false);
  }

  isSubscriptionSuccess=(data) => {
  //  console.log('windowData', data);
    if (data.data.success) {
      const { t, history, reloadProfile } = this.props;
      reloadProfile();
      history.push('/profile/info', { newlySubscribed: true });
    }
    // else {
    //   console.log('ERROR data', data.data);
    // }
  }

 onselectPlan= (pricingPlanReferenceCode) => {
   //  iframe.src = `/subs.html?referenceCode="${pricingPlanReferenceCode}`;
   this.setState({
     showPreSubscriptionModal: true,
     //  checkoutFormContent: response.checkoutFormContent,
     pricingPlanReferenceCode,
   }, () => {
     //  const { iframe } = this;
     //  console.log(iframe);
     //  iframe.addEventListener('onLoad', () => {
     //    console.log('dasda');
     //    console.log(this.iframe);
     //  });
   });

   //  requestSubscription({
   //    pricingPlanReferenceCode,
   //    callbackUrl: 'http://52.212.186.123:8080/tenant/api/0.0/subscriptions',
   //    // 'http://52.212.186.123:8080/tenant/api/0.0/subscriptions/checkout-form-result',
   //    // 'http://localhost:3000/subscriptionStatus',
   //  }).then((response) => {


   //  let script = response.checkoutFormContent.split(/<\/?script[^>]*?>/g).join('');
   //  script = `window.iyziInit = undefined; window.iyziSubscriptionInit = undefined; ${script}`;
   //  script = script.replace(/iyziInit/g, 'window.iyziInit');
   //  script = script.replace(/iyziSubscriptionInit/g, 'window.iyziSubscriptionInit');
   //  script = script.replace(/var window/g, 'window');
   //  script = `${script}if(typeof window.webpackJsonp !== 'function'){${
   //    ''}window.webpackJsonpOld = window.webpackJsonp;window.webpackJsonp = undefined;}`;
   //  script = `${script}console.log('calistim');`;
   //  const document = iframe.contentDocument;
   //  document.body.innerHTML = `  <div id="iyzipay-checkout-form" className="responsive" ></div>
   //  <script async >${script}</script>`;
   //  const document = iframe.contentDocument;
   //  iframe.src = `localhost:3000/subs.html?referenceCode=${pricingPlanReferenceCode}`;

   //  script = script.replace(/https:\/\/sandbox-static.iyzipay.com\/checkoutform\/v2\/bundle.js/g,
   // 'https://corupta.net/fu.js');
   // console.log('script', script);
   // eslint-disable-next-line no-eval
   // eval(script);
   // document.head.innerHTML += response.checkoutFormContent;
   //  const script = document.createElement('div');

   //  script.innerHTML = response.checkoutFormContent;
   //  script.async = true;

   //  document.body.appendChild(script);
   //  });
 }

 toggle = () => {
   this.setState(prevState => (
     { showSubscriptionModal: !prevState.showSubscriptionModal, showPreSubscriptionModal: false }
   ));
 }

  togglePreSubscriptionModal = () => {
    this.setState(({ showPreSubscriptionModal }) => ({ showPreSubscriptionModal: !showPreSubscriptionModal }));
  }


  render() {
    const {
      options, selectedPlan, showSubscriptionModal,
      showPreSubscriptionModal, checkoutFormContent, pricingPlanReferenceCode,
    } = this.state;
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('subscription_index.price')}</h3>
            <h3 className="page-subhead subhead">{t('subscription_index.element')}</h3>
          </Col>
        </Row>
        <Row className="center mb-4">
          <Col lg={3} sm={12} className="m-auto p-auto">
            <ButtonToolbar>
              <ButtonGroup className="btn-group--justified" dir="ltr">
                <Button
                  color={selectedPlan === 0 ? 'primary' : 'secondary'}
                  onClick={() => this.setState({ selectedPlan: 0 })}
                >
                  {t('subscription_index.monthly')}
                </Button>
                <Button
                  color={selectedPlan === 1 ? 'primary' : 'secondary'}
                  onClick={() => this.setState({ selectedPlan: 1 })}
                >
                  {t('subscription_index.yearly')}
                </Button>

              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row className="justify-content-center" dir="auto">
          {options && options.map(option => (
            <><CardPro
              isEnterprise={option.isEnterprise}
              name={option.name}
              description={option.description}
              /* plan={option.pricingPlans.find(x => x.paymentInterval
              === (selectedPlan === 0 ? 'MONTHLY' : 'YEARLY'))} */
              plan={option.pricingPlanByInterval[selectedPlan === 0 ? 'MONTHLY' : 'YEARLY']}
              onSelect={this.onselectPlan}
            />
            </>
          ))}

        </Row>

        <Modal
          isOpen={showSubscriptionModal}
          toggle={this.toggle}
          className="modal-dialog--primary w-100"
          color="primary"
          size="md"
        >
          { pricingPlanReferenceCode ? (
            <iframe
              onChange={(e) => {
              }}
              frameBorder="0"
              title="uniq"
              height="600px"
              ref={(iframe) => { this.iframe = iframe; }}
              src={`/subs.html?referenceCode=${pricingPlanReferenceCode}`}
            />
          ) : null }
          {/* <div id="iyzipay-checkout-forms" className="responsive" /> */}
          {/* <div dangerouslySetInnerHTML={{
           __html: checkoutFormContent,
         }}
         /> */}
        </Modal>

        <PreSubscription
          showPreSubscriptionModal={showPreSubscriptionModal}
          toggle={this.togglePreSubscriptionModal}
          onSuccess={this.toggle}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  reloadProfile: ProfileActions.profileRequest,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(Subscription));
