import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge, Button } from 'reactstrap';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import { useHistory } from 'react-router';
import Loading from '../../../shared/components/Loading';

import * as TaskActions from '../../../store/actions/tenant/tasks';
import { selectTaskById } from '../../../store/reducers/tasks';

import TaskStatusBadge from './components/TaskStatusBadge';
import { feeUnitToLabel } from '../Projects/constants/options';
import { mapDurationValue } from '../../../shared/components/form/HourDuration';
import { statusToColor, statusToLabel } from './constants/options';
import TaskDeletePrompt from './components/TaskDeletePrompt';

const TaskLeftSidebar = ({
  task: { loading, details }, parent, reloadTask, editTask, t,
}) => {
  const {
    project = {}, _id: taskId, parentId, description, title,
    status, startDateTime, duration, estimatedHrs, fee, feeUnit,
    type: taskType, isClose, isActive,
  } = details || {};
  const projectId = project.id || project._id || '';
  const projectTitle = project.title || '';
  const customer = project.customer || project.customerId || {};
  const customerId = customer.id || customer._id || '';
  const customerName = customer.name || '';

  const reloadParent = () => {
    reloadTask({ _id: parentId });
  };

  const editSelf = () => {
    editTask({
      ...(details || {}),
      duration: mapDurationValue((details || {}).duration || '0:00'),
      status: `${status}`,
      customerId: { value: customerId, label: customerName },
      projectId: { value: projectId, label: projectTitle },
      parentId: { value: parentId || '', label: ((parent || {}).details || {}).title || parentId || 'None' },
    });
  };

  useEffect(() => {
    if (parentId && !parent.loaded && !parent.loading && !parent.error) {
      reloadParent();
    }
  });

  const [deletingTask, setDeletingTask] = useState(null);

  return (
    <Loading loading={loading} loaded={!loading}>
      <div className="sidebar-details">
        <Link to={`/tasks/${taskId}`}>
          <h4>
            {t('task_sidebar.task')}: { title }
          </h4>
        </Link>
        {
          parentId && (

            <Link to={`/tasks/${parentId}`}>
              <h5>
                <Loading
                  loading={parent.loading}
                  loaded={parent.loaded}
                  error={parent.error}
                  retry={reloadParent}
                  size="sm"
                >
                  {t('task_sidebar.parent')}: {
                    parent.details && parent.details.title
                  }
                </Loading>
              </h5>
            </Link>
          )
        }
        <Link to={`/matters/${projectId}`}>
          <h4 className="subhead">
            {t('task_sidebar.matter')}: { projectTitle || 'AdvoPASS' }
          </h4>
        </Link>
        <Link to={`/customers/${customerId}`}>
          <h5 className="subhead">
            {t('task_sidebar.customer')}: { customerName || 'John Doe' }
          </h5>
        </Link>
        {/*        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
           Status: <TaskStatusBadge status={status} />
          </h6>
        </div> */}
        { taskType && (
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('task_sidebar.type')}:
            <Badge color="info" className="mx-1" pill>
              { taskType }
            </Badge>
          </h6>
        </div>
        ) }
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('task_sidebar.description')}
          </h6>
          <p className="sidebar-details__block-content">
            { description || '' }
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('task_sidebar.start_date')}
          </h6>
          <p className="sidebar-details__block-content">
            { startDateTime ? moment(startDateTime).format('LL') : ''}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('task_sidebar.duration')}
          </h6>
          <p className="sidebar-details__block-content">
            { duration ? `${duration} ${t('task_sidebar.hours')}` : ''}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('task_sidebar.total_hours')}
          </h6>
          <p className="sidebar-details__block-content">
            { estimatedHrs } {t('task_sidebar.hours')}
          </p>
        </div>
        { isClose ? null : (
          <>
            <div className="d-flex justify-content-center">
              <Button
                color="secondary"
                onClick={editSelf}
                outline
                className="icon mb-1"
              >
                <p><PencilIcon /> {t('task_sidebar.edit_task')}</p>
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                color="danger"
                onClick={() => setDeletingTask(taskId)}
                outline
                className="icon"
              >
                <p><DeleteIcon /> {t('task_sidebar.delete_task')}</p>
              </Button>
            </div>
            <TaskDeletePrompt
              tasks={deletingTask}
              onHideModal={() => setDeletingTask(null)}
            />
          </>
        )}
      </div>
    </Loading>
  );
};

TaskLeftSidebar.propTypes = {
  task: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    details: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      status: PropTypes.oneOf([100, 101, 102, 200]),
      startDateTime: PropTypes.string,
      duration: PropTypes.number,
      estimatedHrs: PropTypes.number,
      project: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      }),
      fee: PropTypes.number,
      feeUnit: PropTypes.string,
    }),
  }).isRequired,
  parent: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    details: PropTypes.shape(),
  }).isRequired,
  reloadTask: PropTypes.func.isRequired,
  editTask: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { match: { params: { id } } }) => {
  const task = selectTaskById(state, id);
  const { parentId } = task.details || {};
  const parent = selectTaskById(state, parentId);
  return {
    task,
    parent,
  };
};

const mapDispatchToProps = {
  reloadTask: TaskActions.reloadTaskRequest,
  editTask: TaskActions.editTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TaskLeftSidebar));
