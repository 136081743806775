import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import CustomPieChart from './components/CustomPieChart';
import useTaskTypeReport from './hooks/useTaskTypeReport';
import CheckBoxFilter from './components/CheckboxFilter';

const TaskTypePanel = ({
  title, projectId, customerId, t,
}) => {
  const [date, setDate] = useState(initialState);
  const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  const [collapsed, setCollapsed] = useState(false);
  const [{
    loading, loaded, error, result,
  }, reload] = useTaskTypeReport(
    date,
    projectId,
    customerId,
  );

  const [includeClosedBillableTasks, setIncludeClosedBillableTasks] = useState(false);
  const [includeOpenBillableTasks, setIncludeOpenBillableTasks] = useState(false);
  const [includeNonBillableTasks, setIncludeNonBillableTasks] = useState(false);

  const getTotalDuration = entry => (includeClosedBillableTasks ? entry.closedBillableTotalDuration : 0)
    + (includeOpenBillableTasks ? entry.openBillableTotalDuration : 0)
    + (includeNonBillableTasks ? entry.nonBillableTotalDuration : 0);

  return (
    <ControlledPanel
      title={title}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      md={12}
      lg={6}
    >
      <DateFilter
        onChange={onDateChange}
        state={date}
      />
      <Row className="mt-2 form">
        <CheckBoxFilter
          name="task-type-closed-billable-tasks"
          label={t('duration_panel.inc_close_task')}
          setState={setIncludeClosedBillableTasks}
          state={includeClosedBillableTasks}
        />
        <CheckBoxFilter
          name="task-type-open-billable-tasks"
          label={t('duration_panel.inc_open_task')}
          setState={setIncludeOpenBillableTasks}
          state={includeOpenBillableTasks}
        />
        <CheckBoxFilter
          name="task-type-non-billable-tasks"
          label={t('duration_panel.inc_non_task')}
          setState={setIncludeNonBillableTasks}
          state={includeNonBillableTasks}
        />
      </Row>
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {
          loaded ? (
            <CustomPieChart
              entries={result}
              dataKey={getTotalDuration}
              unit=" hours"
            />
          ) : null
        }
      </Loading>
    </ControlledPanel>
  );
};

TaskTypePanel.propTypes = {
  title: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

TaskTypePanel.defaultProps = {
  projectId: '',
  customerId: '',
};

export default withTranslation('common')(TaskTypePanel);
