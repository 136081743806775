import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

const renderField = ({
  input,
  type,
  meta: { touched, error, warning },
  placeholder,
  t,
}) => (

  <div className="form__form-group-input-wrap">
    <input {...input} type={type} placeholder={placeholder} />
    {touched
          && ((error && <span className="form__form-group-error">{t(error)}</span>)
            || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
  </div>
);
renderField.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
renderField.defaultProps = {
  placeholder: '',
};

export default withTranslation('common')(renderField);
