import withFetch from './withFetch';
import { REGISTER_TENANT } from '../fetchTypes';
import { VERIFY_EMAIL_VIEWED } from '../sharedTypes';

const initialState = {
  loading: false,
  error: undefined,
  verifyEmail: false,
};

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case REGISTER_TENANT:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case REGISTER_TENANT:
      return {
        ...state,
        error: undefined,
        loading: false,
        verifyEmail: false,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case REGISTER_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case VERIFY_EMAIL_VIEWED:
      return {
        ...state,
        verifyEmail: false,
      };
    default:
      return state;
  }
};

export default withFetch(initialState, fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
