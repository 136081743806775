import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Field, FieldArray, reduxForm, change,
} from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Row, Col, Button, CardBody,
} from 'reactstrap';

import extraType from '../../Extras/types/project';

import MatTableHead from '../../../../shared/components/material-table/MatTableHead';
import MatTableToolbar from '../../../../shared/components/material-table/MatTableToolbar';
import { displayCurrency, getDefaultCurrency } from '../../../../shared/helpers';

const changeBillForm = (field, value) => change('tenant_bill_form', field, value);

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const SET_ORDER = 'SET_ORDER';

const rowHeadsBase = [
  {
    id: 'title', label: 'Title',
  },
  {
    id: 'appliedDate', label: 'Date',
  },
];

const BillFormExtras = ({
  extraList, selectedExtras, currency, t,
}) => {
  const dispatch = useDispatch();
  const [order, dispatchOrder] = useReducer((state, action) => {
    switch (action.type) {
      case SET_ORDER:
        return action.data;
      default:
        return state;
    }
  }, { dir: 'desc', by: 'startDateTime' });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleRequestSort = (event, by) => {
    const dir = (order.by === by && order.dir === 'desc') ? 'asc' : 'desc';

    dispatchOrder({ type: SET_ORDER, data: { by, dir } });
  };

  const handleSelectAllClick = (event, checked) => {
    let nextExtras = new Set();
    if (checked) {
      nextExtras = new Set(extraList.map(({ _id }) => _id));
    }
    dispatch(changeBillForm('extras', nextExtras));
  };
  const handleClick = (event, id) => {
    const nextExtras = new Set(selectedExtras);
    if (nextExtras.has(id)) {
      nextExtras.delete(id);
    } else {
      nextExtras.add(id);
    }
    dispatch(changeBillForm('extras', nextExtras));
  };
  const handleChangePage = (event, nextPage) => {
    setPage(nextPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    // select all initially
    handleSelectAllClick(undefined, true);
  }, [extraList]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, extraList.length - (page * rowsPerPage));

  const rowHeads = [
    ...rowHeadsBase,
    {
      id: 'amount', label: `Amount (${currency || getDefaultCurrency()})`,
    },
  ];

  return (
    <Row>
      <Col md={12} lg={12}>
        <div className="card__title">
          <h5 className="bold-text">{t('bill_form.select_extra')}</h5>
        </div>
        <MatTableToolbar
          numSelected={selectedExtras.size}
          /* onRequestSort={handleRequestSort} */
        />
        <div className="material-table__wrap">
          {
            extraList.length === 0 ? (
              <span>
                {t('bill_form.no_extra')}
              </span>
            ) : null
          }
          <Table className="material-table">
            <MatTableHead
              numSelected={selectedExtras.size}
              order={order.dir}
              orderBy={order.by}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={extraList.length}
              heads={rowHeads}
            />
            <TableBody>
              {extraList
                .sort(getSorting(order.dir, order.by))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((d) => {
                  const isSelected = selectedExtras.has(d._id);
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => handleClick(event, d._id)}
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={d._id}
                      selected={isSelected}
                    >
                      <TableCell className="material-table__cell" padding="checkbox">
                        <Checkbox checked={isSelected} className="material-table__checkbox" />
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {d.title}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >
                        {moment(d.appliedDate).format('ll')}
                      </TableCell>
                      <TableCell
                        className="material-table__cell material-table__cell-right"
                      >{displayCurrency(d.amount, currency)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/*              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          className="material-table__pagination"
          count={extraList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 40]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </Col>
    </Row>
  );
};

BillFormExtras.propTypes = {
  extraList: PropTypes.arrayOf(extraType.isRequired),
  selectedExtras: PropTypes.instanceOf(Set),
  currency: PropTypes.string,
  t: PropTypes.func.isRequired,
};
BillFormExtras.defaultProps = {
  extraList: [],
  selectedExtras: new Set(),
  currency: '',
};


export default reduxForm({
  form: 'tenant_bill_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(BillFormExtras));
