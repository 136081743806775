import { EDIT_EMPLOYEE, FETCH_REQUEST } from '../../sharedTypes';
import {
  GET_EMPLOYEES_TENANT, CREATE_EMPLOYEE_TENANT, UPDATE_EMPLOYEE_TENANT, GET_CURRENT_EMPLOYEE_TENANT,
} from '../../fetchTypes';

export const getEmployeesRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_EMPLOYEES_TENANT,
  data,
});

export const getCurrentEmployeeRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_CURRENT_EMPLOYEE_TENANT,
  data,
});

export const createEmployeeRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_EMPLOYEE_TENANT,
  data,
});

export const updateEmployeeRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_EMPLOYEE_TENANT,
  data,
});

export const editEmployee = data => ({
  type: EDIT_EMPLOYEE,
  data,
});
