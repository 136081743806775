import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import BillForm from './BillForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import { extractDateString } from '../../../../shared/components/form/DatePicker';
import { extractSelectValue } from '../../../../shared/components/form/Select';

class BillModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createBillRequest: PropTypes.func.isRequired,
    updateBillRequest: PropTypes.func.isRequired,
    editBill: PropTypes.func.isRequired,
    editingBill: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingBill: null,
  };

  handleSubmit = (data) => {
    const { editingBill, createBillRequest, updateBillRequest } = this.props;
    let payload = {
      language: extractSelectValue(data.language),
      withholding: extractSelectValue(data.withholding),
      vat: extractSelectValue(data.vat),
    };

    if (editingBill._id === 'new') {
      payload = {
        ...payload,
        projectId: extractSelectValue(data.projectId),
        periodStart: extractDateString(data.periodStart),
        periodEnd: extractDateString(data.periodEnd),
        tasks: Array.from(data.tasks || []),
        extras: Array.from(data.extras || []),
      };
      createBillRequest(payload);
    } else {
      payload = {
        ...payload,
        _id: editingBill._id,
      };
      updateBillRequest(payload);
    }
  };

  render() {
    const {
      updating, editingBill, theme, rtl, editBill,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingBill}
        toggle={() => editBill(null)}
        className={modalClasses}
        size="lg"
      >
        {editingBill && (
          <BillForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editBill(null)}
            initialValues={editingBill}
            isNew={editingBill._id === 'new'}
          />
        )}
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.bills.updating,
  editingBill: state.bills.editingBill,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createBillRequest: TenantActions.Bills.createBillRequest,
  updateBillRequest: TenantActions.Bills.updateBillRequest,
  editBill: TenantActions.Bills.editBill,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillModal);
