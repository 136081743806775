/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  BarChart, Line, LineChart, Bar, Cell, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, ComposedChart,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import moment from 'moment';
import { Row, Col, Table } from 'reactstrap';
import durationPriceDateType from '../types/durationPriceDate';
import useRefOnce from '../hooks/useRefOnce';
import { getRandomColorFactory } from './colors';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import Loading from '../../../../shared/components/Loading';
import { getDefaultCurrency, displayCurrency } from '../../../../shared/helpers';
import useRefMemo from '../../../../shared/hooks/useRefMemo';
import CustomTooltipContent from './CustomTooltipContent';
import useStateMemo from '../../../../shared/hooks/useStateMemo';
import durationPriceDateSumHelper from '../helpers/durationPriceDateSumHelper';

const styles = {
  tooltipWrapper: {
    zIndex: 2,
  },
  legendWrapper: {
    padding: '12px 12px 24px 12px',
  },
};

const extractLabel = (payload, label) => {
  if (!payload) return label;
  const { periodStart, periodEnd } = payload.payload;
  return `${moment(periodStart).format('DD/MM/YYYY')} - ${moment(periodEnd).format('DD/MM/YYYY')}`;
};

const tooltipFormatter = (currency, t) => (value, name, { dataKey }) => (
  // eslint-disable-next-line no-nested-ternary
  dataKey.endsWith('Price') ? displayCurrency(value, currency)
    // eslint-disable-next-line no-nested-ternary
    : dataKey === 'averagePricePerHour' ? `${displayCurrency(value, currency)}/${t('bar_chart.h')}`
      : dataKey === 'totalDuration' ? `${value} ${t('custom_pie_chart.hours')}` : ''
);

const renderColorfulLegendText = (value, entry) => {
  let { color } = entry;
  if (entry.dataKey === 'totalPrice') {
    color = '#361eae';
  }

  return <span style={{ color }}>{value}</span>;
};

const ShortDurationPriceDateLineChart = ({
  entries,
  includeClosedBillableTasks, includeOpenBillableTasks, includeNonBillableTasks,
  includeClosedExtraPayments, includeOpenExtraPayments,
  startDateRange, endDateRange, currency, t,
}) => {
  const state = useStateMemo(() => {
    const extendedEntries = durationPriceDateSumHelper({
      entries,
      includeClosedBillableTasks,
      includeOpenBillableTasks,
      includeClosedExtraPayments,
      includeOpenExtraPayments,
      includeNonBillableTasks,
    });

    const allDataList = extendedEntries.reduce((acc, b) => ([...acc, ...b.data]), []);

    const maxPrice = allDataList.reduce((a, b) => Math.max(a, b.totalPrice), 0);
    const maxDuration = allDataList.reduce((a, b) => Math.max(a, b.totalDuration), 0);
    const totalPrice = allDataList.reduce((a, b) => a + b.totalPrice, 0);
    const totalDuration = allDataList.reduce((a, b) => a + b.totalDuration, 0);
    const avgPricePerHour = totalPrice / totalDuration;
    return {
      extendedEntries,
      maxPrice,
      maxDuration,
      totalPrice,
      totalDuration,
      avgPricePerHour,
    };
  }, [entries, includeClosedBillableTasks, includeOpenBillableTasks, includeNonBillableTasks,
    includeClosedExtraPayments, includeOpenExtraPayments]);
  const getColor = useRefOnce(getRandomColorFactory);
  const {
    extendedEntries, maxPrice, maxDuration, /* totalPrice, totalDuration, */ avgPricePerHour,
  } = state;

  const priceScaleMax = Math.round(Math.max(maxPrice, maxDuration * avgPricePerHour) * 1.2 / 100).toFixed(2) * 100;
  const durationScaleMax = Math.round(Math.max(maxDuration, maxPrice / avgPricePerHour) * 1.2).toFixed(2);

  if (entries.length === 0 || extendedEntries.length === 0) {
    return (
      <div>
        <h4 style={{ textAlign: 'center', marginTop: 24 }}>
          {t('custom_pie_chart.no_data')}
        </h4>
      </div>
    );
  }

  const { data } = extendedEntries[0];

  return (
    <Row>
      <Col xs={12} className="tooltip-first-entry-dark-gray">
        <ResponsiveContainer height={260}>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={[moment(startDateRange).valueOf(), 'dataMax + 1000000000']}
              name={t('custom_pie_chart.date')}
              type="number"
              scale="linear"
              interval="preserveStartEnd"
              tickFormatter={date => moment(date).format('DD/MM/YYYY')}
            />
            <YAxis
              dataKey="totalPrice"
              yAxisId="totalPrice"
              unit={currency || getDefaultCurrency()}
              name={t('custom_pie_chart.price')}
              domain={[0, priceScaleMax]}
              scale="linear"
            />
            <YAxis
              dataKey="totalDuration"
              yAxisId="totalDuration"
              unit=" hours"
              name={t('custom_pie_chart.duration')}
              orientation="right"
              domain={[0, durationScaleMax]}
              scale="linear"
            />
            <YAxis
              dataKey="averagePricePerHour"
              yAxisId="averagePricePerHour"
              unit={` ${currency || getDefaultCurrency()}/${t('custom_pie_chart.hour')}`}
              name={t('custom_pie_chart.avrg_per_hour')}
              domain={[0, 'auto']}
              scale="linear"
              hide
            />
            <Bar
              dataKey="totalPrice"
              name={t('graphs_index.last_month_goal.total_gain')}
              yAxisId="totalPrice"
              fill="#f0eefc"
              barSize={20}
            />
            <Line
              dataKey="averagePricePerHour"
              name={t('graphs_index.last_month_goal.hourly_task_fee')}
              yAxisId="averagePricePerHour"
              stroke="#1eae4e"
            />
            <Line
              dataKey="totalTaskPrice"
              name={t('graphs_index.last_month_goal.task_gain')}
              yAxisId="totalPrice"
              stroke="#d8b00e"
            />
            <Line
              dataKey="totalDuration"
              name={t('graphs_index.last_month_goal.worked_hours')}
              yAxisId="totalDuration"
              stroke="#ff4861"
            />
            <Line
              dataKey="totalExtraPrice"
              name={t('graphs_index.last_month_goal.extra_gain')}
              yAxisId="totalPrice"
              stroke="darkturquoise"
            />
            <Legend
              verticalAlign="top"
              wrapperStyle={styles.legendWrapper}
              formatter={renderColorfulLegendText}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={styles.tooltipWrapper}
              formatter={tooltipFormatter(currency, t)}
              content={
                /* eslint-disable-next-line react/prop-types */
                props => (
                  <DefaultTooltipContent
                    {...props}
                    label={
                      /* eslint-disable-next-line react/prop-types */
                      extractLabel((props.payload || [])[0], props.label)
                    }
                  />
                )
              }
              labelFormatter={label => (typeof label === 'number' ? moment(label).format('DD/MM/YYYY') : label)}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <div>
          <Table responsive className="table dashboard__occupancy-table">
            <thead>
              <tr>
                <td style={{ fontWeight: 500, color: '#000000' }}>
                  {t('bar_chart.date')}
                </td>
                {data.map(({ date }, index) => (
                  <td key={`date-${index}`} style={{ fontWeight: 500, color: '#000000' }}>
                    {moment(date).format(t('graph_options.date_label_format'))}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: 500, color: '#1eae4e' }}>
                  {t('graphs_index.last_month_goal.hourly_task_fee')}
                </td>
                {data.map(({ averagePricePerHour }, index) => (
                  <td key={`hourly-task-fee-${index}`} style={{ fontWeight: 500, color: '#1eae4e' }}>
                    {`${displayCurrency(averagePricePerHour, currency)}/${t('bar_chart.h')}`}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ fontWeight: 500, color: '#d8b00e' }}>
                  {t('graphs_index.last_month_goal.task_gain')}
                </td>
                {data.map(({ totalTaskPrice }, index) => (
                  <td key={`task-price-${index}`} style={{ fontWeight: 500, color: '#d8b00e' }}>
                    {displayCurrency(totalTaskPrice, currency)}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ fontWeight: 500, color: '#ff4861' }}>
                  {t('graphs_index.last_month_goal.worked_hours')}
                </td>
                {data.map(({ totalDuration }, index) => (
                  <td key={`total-duration-${index}`} style={{ fontWeight: 500, color: '#ff4861' }}>
                    {`${totalDuration} ${t('custom_pie_chart.hours')}`}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ fontWeight: 500, color: 'darkturquoise' }}>
                  {t('graphs_index.last_month_goal.extra_gain')}
                </td>
                {data.map(({ totalExtraPrice }, index) => (
                  <td key={`extra-price-${index}`} style={{ fontWeight: 500, color: 'darkturquoise' }}>
                    {displayCurrency(totalExtraPrice, currency)}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={{ fontWeight: 500, color: '#361eae' }}>
                  {t('graphs_index.last_month_goal.total_gain')}
                </td>
                {data.map(({ totalPrice }, index) => (
                  <td key={`total-price-${index}`} style={{ fontWeight: 500, color: '#361eae' }}>
                    {displayCurrency(totalPrice, currency)}
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

ShortDurationPriceDateLineChart.propTypes = {
  entries: PropTypes.arrayOf(
    durationPriceDateType.isRequired,
  ).isRequired,
  includeClosedBillableTasks: PropTypes.bool,
  includeOpenBillableTasks: PropTypes.bool,
  includeNonBillableTasks: PropTypes.bool,
  includeClosedExtraPayments: PropTypes.bool,
  includeOpenExtraPayments: PropTypes.bool,
  startDateRange: PropTypes.instanceOf(Date).isRequired,
  endDateRange: PropTypes.instanceOf(Date).isRequired,
  currency: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ShortDurationPriceDateLineChart.defaultProps = {
  includeClosedBillableTasks: true,
  includeOpenBillableTasks: true,
  includeNonBillableTasks: true,
  includeClosedExtraPayments: true,
  includeOpenExtraPayments: true,
  currency: '',
};

export default withTranslation('common')(ShortDurationPriceDateLineChart);
