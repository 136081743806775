import {
  all, takeEvery, call, put, take, cancel, fork, spawn,
} from 'redux-saga/effects';
import {
  FETCH_ERROR, FETCH_REQUEST, FETCH_SUCCESS,
} from './sharedTypes';
import { handleFetch } from './api';
import { fetchErrorToasts, fetchSuccessToasts } from './toasts';
import subscriptions from './subscriptions';
import * as FETCH_TYPES from './fetchTypes';

function* fetchRequest(action) {
  const { data: reqData, fetchType } = action;
  try {
    const { data: resData, message } = yield call(handleFetch, fetchType, reqData);
    yield put({
      type: FETCH_SUCCESS, fetchType, data: resData, reqData, success: message,
    });
  } catch (e) {
    yield put({
      type: FETCH_ERROR, fetchType, error: e.message, data: reqData, code: e.code, errorType: e.type,
    });
  }
}

const takeLatestActions = [
  FETCH_TYPES.GET_CUSTOMERS_TENANT,
  FETCH_TYPES.GET_PROJECTS_TENANT,
];

// prevent simultaneous requests
const takeLeadingActions = [
  FETCH_TYPES.GET_CURRENT_PROJECT_TENANT,
  FETCH_TYPES.GET_CURRENT_CUSTOMER_TENANT,
  /* FETCH_TYPES.GET_PROFILE_TENANT, */
];

function* watchFetchRequests() {
  const tasks = {};
  while (true) {
    const action = yield take(FETCH_REQUEST);
    if (takeLatestActions.includes(action.fetchType)) {
      if (tasks[action.fetchType]) {
        yield cancel(tasks[action.fetchType]);
      }
      tasks[action.fetchType] = yield fork(fetchRequest, action);
    } else if (takeLeadingActions.includes(action.fetchType)) {
      if (!tasks[action.fetchType] || !tasks[action.fetchType].isRunning()) {
        tasks[action.fetchType] = yield fork(fetchRequest, action);
      }
    } else {
      yield spawn(fetchRequest, action);
    }
  }
  // yield takeEvery(ACTION_TYPES.FETCH_REQUEST, fetchRequest);
}

function* fetchError(action) {
  yield call(fetchErrorToasts, action);
}

function* watchFetchErrors() {
  yield takeEvery(FETCH_ERROR, fetchError);
}

function* fetchSuccess(action) {
  yield call(fetchSuccessToasts, action);
}

function* watchFetchSuccesses() {
  yield takeEvery(FETCH_SUCCESS, fetchSuccess);
}

export default function* rootSaga() {
  yield fork(subscriptions);
  try {
    yield all([watchFetchRequests(), watchFetchErrors(), watchFetchSuccesses()]);
  } catch (error) {
    console.error('Error at root saga:', error.message);
  }
}
