import {
  CHANGE_MOBILE_SIDEBAR_VISIBILITY, CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_FILTER_SIDEBAR_VISIBILITY,
} from '../sharedTypes';

const initialState = {
  show: false,
  collapse: false,
  filterCollapse: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case CHANGE_FILTER_SIDEBAR_VISIBILITY:
      return { ...state, filterCollapse: !state.filterCollapse };
    default:
      return state;
  }
}
