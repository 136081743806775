import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LoginForm from '../../../shared/components/form/LoginForm';
import AdminActions from '../../../store/actions/admin';

const LoginAdmin = ({ loginRequest, authLoading }) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> Attorney </span>
            <br />
            <span className="account__logo-accent">Admin Dashboard</span>
          </h3>
          <h4 className="account__subhead subhead">Start your business easily</h4>
        </div>
        <LoginForm
          onSubmit={({ email, password }) => loginRequest({ email, password })}
          loading={authLoading}
        />
        <div className="account__or">
          <p>Or Easily Using</p>
        </div>
        <div className="account__social">
          <Link
            className="account__social-btn account__social-btn--facebook"
            to="/pages/one"
          ><FacebookIcon />
          </Link>
          <Link
            className="account__social-btn account__social-btn--google"
            to="/pages/one"
          ><GooglePlusIcon />
          </Link>
        </div>
      </div>
    </div>
  </div>
);

LoginAdmin.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  authLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  authLoading: state.auth.loading,
});

const mapDispatchToProps = { loginRequest: AdminActions.Auth.loginRequest };

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);
