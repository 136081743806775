import withFetch from './withFetch';
import {
  CREATE_EMPLOYEE_TENANT, GET_CURRENT_EMPLOYEE_TENANT, GET_EMPLOYEES_TENANT, UPDATE_EMPLOYEE_TENANT,
} from '../fetchTypes';
import { EDIT_EMPLOYEE, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingEmployee: null,
  totalEmployees: 0,
  employees: [],
  currentEmployee: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EMPLOYEES_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_EMPLOYEE_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_EMPLOYEE_TENANT:
    case UPDATE_EMPLOYEE_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EMPLOYEES_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        employees: action.data.users.users,
        totalEmployees: action.data.users.totalUsers,
      };
    case GET_CURRENT_EMPLOYEE_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentCustomer: action.data.customer,
      };
    case CREATE_EMPLOYEE_TENANT:
    case UPDATE_EMPLOYEE_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingEmployee: null,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EMPLOYEES_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_EMPLOYEE_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_EMPLOYEE_TENANT:
    case UPDATE_EMPLOYEE_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_EMPLOYEE:
      return {
        ...state,
        editingEmployee: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
