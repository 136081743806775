import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import EmailPlusIcon from 'mdi-react/EmailPlusIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import {
  Button, ButtonToolbar, Row, Col,
} from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Loading from '../../../../shared/components/Loading';
import RenderSelectField from '../../../../shared/components/form/Select';

const caseNumberMask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', '/', ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
const meritsNumberMask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', '/', ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
const ProjectFormCase = ({ t }) => (
  <Row>
    <Col xs={12}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('case_card.case_name')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="caseName"
            component="input"
            type="text"
            placeholder={t('case_card.case_name')}
          />
        </div>
      </div>
    </Col>
    <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('case_card.case_number')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <MaskedInput
            mask={caseNumberMask}
            name="caseNumber"
            component="input"
            type="text"
            placeholder={t('case_card.case_number')}
          />
          <Field
            name="select"
            component={RenderSelectField}
            className="form__form-group-input-wrap--error-above"
            type="text"
            defaultValue="e"
            options={[
              { value: 'e', label: 'E' },
              { value: 'd', label: 'D' },
              { value: 't', label: 'T' },
            ]}
          />
        </div>
      </div>
    </Col>
    <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('case_card.decision_number')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>

          <MaskedInput
            mask={meritsNumberMask}
            name="meritsNumber"
            component="input"
            type="text"
            placeholder={t('case_card.merits_number')}
          />
        </div>
      </div>
    </Col>
  </Row>
);

ProjectFormCase.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'tenant_project_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withTranslation('common')(ProjectFormCase));
