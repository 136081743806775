import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { withTranslation } from 'react-i18next';
import {
  emailAddress,
  isRequired,
  passwordMatch,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import renderField from '../../../../shared/components/form/FormField';
import RenderCheckBoxField from '../../../../shared/components/form/CheckBox';

class RegisterFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  passwordRequired = isRequired('register.password');

  // confirmPasswordRequired = isRequired('register.confirm_password');

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  showConfirmPassword = (e) => {
    e.preventDefault();
    this.setState(({ showConfirmPassword }) => ({
      showConfirmPassword: !showConfirmPassword,
    }));
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { showPassword, showConfirmPassword } = this.state;

    return (
      <form className="form wizard__form mb-0" onSubmit={handleSubmit}>
        <div className="form__form-group form__form-group--two-input">
          <span className="form__form-group-label required">{t('register.name')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="fname"
              component={renderField}
              type="text"
              placeholder={t('register.first_name')}
              validate={this.fnameRequired}
            />
            <Field
              name="lname"
              component={renderField}
              type="text"
              placeholder={t('register.last_name')}
              validate={this.lnameRequired}
            />
          </div>

        </div>
        <div className="form__form-group">
          <span className="form__form-group-label required">
            {t('register.email')}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name="email"
              component={renderField}
              type="email"
              placeholder="example@mail.com"
              validate={emailAddress}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label required">
            {t('register.password')}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={renderField}
              type={showPassword ? 'text' : 'password'}
              placeholder={t('register.password')}
              validate={this.passwordRequired}
            />
            <button
              type="button"
              className={`form__form-group-button${
                showPassword ? ' active' : ''
              }`}
              onClick={e => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__have-account">

            <Field
              name="clarification"
              validate={value => (value
                ? undefined
                : t('field_warnings.is_required'))}
              label={(
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://advopass.com/wp-content/uploads/2020/06/advopass-clarification-text.pdf"
                  >
Aydınlatma metnini
                  </a>{' '} okudum, anladım.
                </p>
)}
              component={RenderCheckBoxField}
              className="colored-click"
            />

            {/* <p>{t('register.already_have_an_account')} <Link to="/login">{t('register.login')}</Link></p> */}
          </div>
        </div>


        <ButtonToolbar className="form__button-toolbar wizard__toolbar text-right mr-0">
          {/* <Button color="primary" type="button" disabled className="previous">
            {t('register.previous')}
          </Button> */}
          <Button color="primary" type="submit" className="next">
            {t('register.submit')}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'register_tenant_form', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(RegisterFormOne));
