/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  BarChart, Line, LineChart, Bar, Cell, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import durationPriceDateType from '../types/durationPriceDate';
import useRefOnce from '../hooks/useRefOnce';
import { getRandomColorFactory } from './colors';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import Loading from '../../../../shared/components/Loading';
import { getDefaultCurrency, displayCurrency } from '../../../../shared/helpers';

const styles = {
  tooltipWrapper: {
    zIndex: 2,
  },
  legendWrapper: {
    padding: '12px 12px 24px 12px',
  },
};

const extractLabel = (payload, label) => {
  if (!payload) return label;
  const { periodStart, periodEnd } = payload.payload;
  return `${moment(periodStart).format('DD/MM/YYYY')} - ${moment(periodEnd).format('DD/MM/YYYY')}`;
};

// eslint-disable-next-line no-nested-ternary
const getTooltipValueColor = key => (key === 'totalPrice' ? '#361eae'
  // eslint-disable-next-line no-nested-ternary
  : key === 'averagePricePerHour' ? '#1eae4e'
    : key === 'totalDuration' ? '#ff4861' : '#000000');

const tooltipFormatter = (currency, t) => (value, name, { dataKey }) => (
  <span style={{ color: getTooltipValueColor(dataKey) }}>
    {// eslint-disable-next-line no-nested-ternary
      dataKey === 'totalPrice' ? displayCurrency(value, currency)
        // eslint-disable-next-line no-nested-ternary
        : dataKey === 'averagePricePerHour' ? `${displayCurrency(value, currency)}/${t('custom_pie_chart.hour')}`
          : dataKey === 'totalDuration' ? `${value} ${t('custom_pie_chart.hours')}` : ''
    }
  </span>
);

const FullDurationPriceDateChart = ({
  filterNode, entries, mode,
  includeClosedBillableTasks, includeOpenBillableTasks, includeNonBillableTasks,
  includeClosedExtraPayments, includeOpenExtraPayments,
  startDateRange, endDateRange, currency, noPieChart, t,
}) => {
  const [state, setState] = useState({ extendedEntries: [] });
  useEffect(() => {
    const extendedEntries = entries;

    const allDataList = extendedEntries.reduce((acc, b) => ([...acc, ...b.data]), []);

    const maxPrice = allDataList.reduce((a, b) => Math.max(a, b.totalPrice), 0);
    const maxDuration = allDataList.reduce((a, b) => Math.max(a, b.totalDuration), 0);
    const totalPrice = allDataList.reduce((a, b) => a + b.totalPrice, 0);
    const totalDuration = allDataList.reduce((a, b) => a + b.totalDuration, 0);
    const avgPricePerHour = totalPrice / totalDuration;
    setState({
      extendedEntries,
      maxPrice,
      maxDuration,
      totalPrice,
      totalDuration,
      avgPricePerHour,
    });
  }, [entries]);

  const getColor = useRefOnce(getRandomColorFactory);
  const {
    extendedEntries, maxPrice, maxDuration, totalPrice, totalDuration, avgPricePerHour,
  } = state;

  const renderLabelPrice = useRefOnce(
    () => renderCustomizedLabelFactory(getColor, value => displayCurrency(value, currency), true),
  );
  const renderLabelDuration = useRefOnce(
    () => renderCustomizedLabelFactory(getColor, t('bar_chart.h'), true),
  );
  const renderLabelAverage = useRefOnce(
    () => renderCustomizedLabelFactory(getColor,
      value => `${displayCurrency(value, currency)}/${t('bar_chart.h')}`, true),
  );

  const priceScaleMax = Math.round(Math.max(maxPrice, maxDuration * avgPricePerHour) * 1.2 / 100).toFixed(2) * 100;
  const durationScaleMax = Math.round(Math.max(maxDuration, maxPrice / avgPricePerHour) * 1.2).toFixed(2);

  if (entries.length === 0) {
    return (
      <Row>
        { filterNode}
        <Col md={{ size: 12, order: 2 }} lg={{ size: 6, order: 2 }}>
          <h4 style={{ textAlign: 'center', marginTop: 24 }}>
            {t('custom_pie_chart.no_data')}
          </h4>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        {filterNode}
        <Col md={{ size: 12, order: 2 }} lg={{ size: 6, order: 2 }}>
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            <h5 style={{ color: '#1eae4e' }}>
              {t('bar_chart.avrg_per_hour')}:{'  '}
              <span className="bold-text">
                {displayCurrency(avgPricePerHour || 0, currency)}/{t('custom_pie_chart.hour')}
              </span>
            </h5>
            <h5 style={{ color: '#ff4861' }}>
              {t('bar_chart.total_duration')}:{'  '}
              <span className="bold-text">
                {(totalDuration || 0).toFixed(1)}{' '}{t('custom_pie_chart.hour')}
              </span>
            </h5>
            <h5 style={{ color: '#361eae' }}>
              {t('bar_chart.total_price')}:{'  '}
              <span className="bold-text">
                {displayCurrency(totalPrice || 0, currency)}
              </span>
            </h5>
          </div>
          <div className="small-pie-heading-container">
            <h5 className="small-pie-heading" style={{ color: '#1eae4e' }}>
              {t('graph_options.modes.average_price_per_hour')}
            </h5>
            <h5 className="small-pie-heading" style={{ color: '#ff4861' }}>
              {t('graph_options.modes.total_duration')}
            </h5>
            <h5 className="small-pie-heading" style={{ color: '#361eae' }}>
              {t('graph_options.modes.total_price')}
            </h5>
          </div>
          <ResponsiveContainer width="100%" aspect={4}>
            <PieChart>
              <Pie
                cx="20%"
                cy="50%"
                data={extendedEntries}
                nameKey="title"
                dataKey="averagePricePerHour"
                label={renderLabelAverage}
                labelLine={false}
                outerRadius="75%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Pie
                cx="50%"
                cy="50%"
                data={extendedEntries}
                nameKey="title"
                dataKey="totalDuration"
                label={renderLabelDuration}
                labelLine={false}
                outerRadius="75%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Pie
                cx="80%"
                cy="50%"
                data={extendedEntries}
                nameKey="title"
                dataKey="totalPrice"
                label={renderLabelPrice}
                labelLine={false}
                outerRadius="75%"
              >
                {
                  entries.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.title}-${index}`}
                      fill={getColor(index)}
                    />
                  ))
                }
              </Pie>
              <Tooltip
                wrapperStyle={styles.tooltipWrapper}
                formatter={tooltipFormatter(currency, t)}
              />
              {/* <Legend
                verticalAlign="bottom"
                payload={extendedEntries.map((entry, index) => ({
                  color: getColor(index),
                  value: entry.title,
                  payload: entry,
                }))}
              /> */}
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 4 }} lg={{ size: 6, order: 3 }}>
          <ResponsiveContainer width="100%" aspect={2 - (entries.length) * 0.1}>
            <BarChart
              data={entries}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="title" />
              <YAxis
                yAxisId="price"
                unit={currency || getDefaultCurrency()}
                name={t('bar_chart.price')}
                domain={[0, priceScaleMax]}
                scale="linear"
              />
              <YAxis
                yAxisId="duration"
                unit=" hours"
                name={t('bar_chart.duration')}
                orientation="right"
                domain={[0, durationScaleMax]}
                scale="linear"
              />

              {includeClosedBillableTasks ? (
                <Bar
                  dataKey="closedBillableTasksPrice"
                  name={t('bar_chart.closed_tasks_price')}
                  unit={currency || getDefaultCurrency()}
                  stackId="price"
                  yAxisId="price"
                  fill="#deb881"
                />
              ) : null}
              {includeClosedExtraPayments ? (
                <Bar
                  dataKey="closedExtraPaymentsPrice"
                  name={t('bar_chart.closed_extras_price')}
                  unit={currency || getDefaultCurrency()}
                  stackId="price"
                  yAxisId="price"
                  fill="#f6cd90"
                />
              ) : null}
              {includeOpenBillableTasks ? (
                <Bar
                  dataKey="openBillableTasksPrice"
                  name={t('bar_chart.open_tasks_price')}
                  unit={currency || getDefaultCurrency()}
                  stackId="price"
                  yAxisId="price"
                  fill="#eadea6"
                />
              ) : null}
              {includeOpenExtraPayments ? (
                <Bar
                  dataKey="openExtraPaymentsPrice"
                  name={t('bar_chart.open_extras_price')}
                  unit={currency || getDefaultCurrency()}
                  stackId="price"
                  yAxisId="price"
                  fill="#efe9cc"
                />
              ) : null}

              {includeClosedBillableTasks ? (
                <Bar
                  dataKey="closedBillableTasksDuration"
                  name={t('bar_chart.closed_tasks_duration')}
                  unit=" hours"
                  stackId="duration"
                  yAxisId="duration"
                  fill="#698474"
                />
              ) : null}
              {includeOpenBillableTasks ? (
                <Bar
                  dataKey="openBillableTasksDuration"
                  name={t('bar_chart.open_tasks_duration')}
                  unit=" hours"
                  stackId="duration"
                  yAxisId="duration"
                  fill="#889e81"
                />
              ) : null}
              {includeNonBillableTasks ? (
                <Bar
                  dataKey="nonBillableTasksDuration"
                  name={t('bar_chart.non_billable_tasks_duration')}
                  unit=" hours"
                  stackId="duration"
                  yAxisId="duration"
                  fill="#bac7a7"
                />
              ) : null}
              <Legend verticalAlign="top" wrapperStyle={styles.legendWrapper} />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                wrapperStyle={styles.tooltipWrapper}
              />
            </BarChart>
          </ResponsiveContainer>
          <div className="graph-title-container">
            <h5 className="text-center bold-text">
              {t('graph_options.graph_titles.total_gain_and_worked_hours')}
            </h5>
          </div>
        </Col>

        <Col md={{ size: 12, order: 3 }} lg={{ size: 6, order: 4 }}>
          <ResponsiveContainer width="100%" aspect={2 - (entries.length) * 0.1}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                domain={[moment(startDateRange).valueOf(), 'dataMax + 1000000000']}
                name={t('custom_pie_chart.date')}
                type="number"
                scale="linear"
                interval="preserveStartEnd"
                tickFormatter={date => moment(date).format('DD/MM/YYYY')}
              />
              <YAxis
                dataKey="totalPrice"
                yAxisId="totalPrice"
                unit={currency || getDefaultCurrency()}
                name={t('custom_pie_chart.price')}
                domain={[0, priceScaleMax]}
                scale="linear"
                hide={mode !== 'totalPrice'}
              />
              <YAxis
                dataKey="totalDuration"
                yAxisId="totalDuration"
                unit=" hours"
                name={t('custom_pie_chart.duration')}
                domain={[0, durationScaleMax]}
                scale="linear"
                hide={mode !== 'totalDuration'}
              />
              <YAxis
                dataKey="averagePricePerHour"
                yAxisId="averagePricePerHour"
                unit={` ${currency || getDefaultCurrency()}/${t('custom_pie_chart.hour')}`}
                name={t('custom_pie_chart.avrg_per_hour')}
                domain={[0, 'auto']}
                scale="linear"
                hide={mode !== 'averagePricePerHour'}
              />
              {
                extendedEntries.map(({ title, data }, index) => (
                  <Line
                    dataKey={mode}
                    data={data}
                    name={`${title}`}
                    key={`entry-${index}`}
                    yAxisId={mode}
                    stroke={getColor(index)}
                  />
                ))
              }
              <Legend verticalAlign="top" wrapperStyle={styles.legendWrapper} />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                wrapperStyle={styles.tooltipWrapper}
                formatter={tooltipFormatter(currency, t)}
                content={
                  /* eslint-disable-next-line react/prop-types */
                  props => (
                    <DefaultTooltipContent
                      {...props}
                      label={
                        /* eslint-disable-next-line react/prop-types */
                        extractLabel((props.payload || [])[0], props.label)
                      }
                    />
                  )
                }
                labelFormatter={label => (typeof label === 'number' ? moment(label).format('DD/MM/YYYY') : label)}
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="graph-title-container">
            <h5 className="text-center bold-text">
              {/* eslint-disable-next-line no-nested-ternary */
                mode === 'totalPrice' ? t('graph_options.graph_titles.total_gain_by_date')
                  // eslint-disable-next-line no-nested-ternary
                  : mode === 'totalDuration' ? t('graph_options.graph_titles.worked_hours_by_date')
                    : mode === 'averagePricePerHour' ? t('graph_options.graph_titles.hourly_task_fee_by_date')
                      : ''}
            </h5>
          </div>
        </Col>
      </Row>
    </>
  );
};

FullDurationPriceDateChart.propTypes = {
  filterNode: PropTypes.node,
  entries: PropTypes.arrayOf(
    durationPriceDateType.isRequired,
  ).isRequired,
  includeClosedBillableTasks: PropTypes.bool,
  includeOpenBillableTasks: PropTypes.bool,
  includeNonBillableTasks: PropTypes.bool,
  includeClosedExtraPayments: PropTypes.bool,
  includeOpenExtraPayments: PropTypes.bool,
  startDateRange: PropTypes.instanceOf(Date).isRequired,
  endDateRange: PropTypes.instanceOf(Date).isRequired,
  currency: PropTypes.string,
  noPieChart: PropTypes.bool,
  mode: PropTypes.oneOf(['totalPrice', 'totalDuration', 'averagePricePerHour']).isRequired,
  t: PropTypes.func.isRequired,
};

FullDurationPriceDateChart.defaultProps = {
  filterNode: null,
  includeClosedBillableTasks: true,
  includeOpenBillableTasks: true,
  includeNonBillableTasks: true,
  includeClosedExtraPayments: true,
  includeOpenExtraPayments: true,
  currency: '',
  noPieChart: false,
};

export default withTranslation('common')(FullDurationPriceDateChart);
