import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
/* import { selectLanguage } from '../../store/reducers/language'; */

const Headers = () => {
  const theme = useSelector(state => state.theme);
  /* const language = useSelector(selectLanguage); */
  return (
    <Helmet>
      {/* <html lang={language} /> instead set lang
      manually before changing lang in language switcher */}
      {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
      <html className={theme.className === 'theme-dark' ? theme.className : ''} />
    </Helmet>
  );
};
export default Headers;
