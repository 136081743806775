/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import { chamberRegistrationNumber, idNumber, isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import RenderTextField from './TextField';
import Loading from '../../../../shared/components/Loading';
import RenderSelectField from './SelectField';

class ProfileSubscriptionAddressSettings extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    addressList: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  billingAddressRequired = isRequired('profile_subs.billing_address');

  shippingAddressRequired = isRequired('profile_subs.shipping_address');

  render() {
    const {
      handleSubmit, reset, addressList, loading, t,
    } = this.props;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label required">{t('profile_subs.billing_address')}</span>
          <Field
            name="billingAddress"
            component={RenderSelectField}
            options={addressList}
            validate={this.billingAddressRequired}
            disabled={addressList.length === 0}
          />
        </div>
        <div>
          <span className="material-form__label required">{t('profile_subs.shipping_address')}</span>
          <Field
            name="shippingAddress"
            component={RenderSelectField}
            options={addressList}
            validate={this.shippingAddressRequired}
            disabled={addressList.length === 0}
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          {
            loading ? <Loading />
              : <Button color="primary" type="submit">{t('profile_subs.update_addresses')}</Button>
          }
          <Button type="button" onClick={reset} disabled={loading}>
            {t('profile_subs.reset')}
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'profile_subscription_address_settings_form', // a unique identifier for this form
})(withTranslation('common')(ProfileSubscriptionAddressSettings));
