import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Container, Row, Col, Card, CardBody, Button,
} from 'reactstrap';
import { useParams, withRouter } from 'react-router-dom';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import EmployeeTreeItem from './components/EmployeeTreeItem';
import FilterButton from '../../../shared/components/filter/FilterButton';
import EmployeesFilterSidebar from './components/EmployeesFilterSidebar';


const EmployeePage = ({ t }) => {
  const { id } = useParams();
  return (
    <Container className="container__with-filter">
      <Row>
        <Col xs={6}>
          <h3 className="page-title">{t('employee.page_title')}</h3>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <FilterButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <EmployeeTreeItem employeeId={id} showProjectTitle />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <EmployeesFilterSidebar />
    </Container>
  );
};

EmployeePage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(EmployeePage);
