import React, { PureComponent } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import diacritics from 'diacritics';
import { withTranslation } from 'react-i18next';

const filterOption = ({ label, value }, rawInput) => {
  const input = diacritics.remove(rawInput.trim()).toLowerCase();
  const candidate = diacritics.remove(`${label} ${value}`.trim()).toLowerCase();
  return candidate.indexOf(input) > -1;
};

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    extraOnChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    clearable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    isCreatable: PropTypes.bool,
    isLoading: PropTypes.bool,
    isTranslated: PropTypes.bool,
    setValueOnly: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    extraOnChange: undefined,
    placeholder: '',
    options: [],
    value: undefined,
    clearable: false,
    defaultValue: undefined,
    touched: true,
    disabled: false,
    isCreatable: false,
    isLoading: false,
    isTranslated: false,
    setValueOnly: false,
  };

  componentDidMount() {
    const { onChange, defaultValue } = this.props;
    if (defaultValue) {
      onChange(this.defaultValue);
    }
  }

  get currentValue() {
    const { options, defaultValue, touched } = this.props;
    let { value } = this.props;
    if (typeof value === 'string') {
      value = options.reduce((acc, option) => (option.value === value ? option : acc), value);
    }
    if (!value) {
      value = touched ? undefined : this.defaultValue;
    }
    if (typeof value === 'string') {
      value = { value, label: value };
    }
    return value;
  }

  get defaultValue() {
    const { options } = this.props;
    let { defaultValue } = this.props;
    if (typeof defaultValue === 'string') {
      defaultValue = options.reduce(
        (acc, option) => (option.value === defaultValue ? option : acc),
        defaultValue,
      );
    }
    if (!defaultValue) {
      defaultValue = undefined;
    }
    if (typeof defaultValue === 'string') {
      defaultValue = { value: defaultValue, label: defaultValue };
    }
    return defaultValue;
  }

  noOptionsMessage = () => {
    const { t } = this.props;
    return t('form_fields.select.no_options');
  }

  getOptionLabel = ({ label, value }) => {
    const { t, isTranslated } = this.props;
    if (isTranslated) {
      return t(label || value) || label || value;
    }
    return label || value;
  };

  handleChange = (selectedOption) => {
    const { onChange, extraOnChange, setValueOnly } = this.props;
    let option = selectedOption;
    if (setValueOnly && option && typeof option === 'object') {
      option = option.value;
    }
    if (extraOnChange) {
      extraOnChange(option);
    }
    onChange(option);
  };

  handleCreatableBlur = (e) => {
    const { value } = e.target;
    if (value) {
      this.handleChange({
        label: value,
        value,
        __isNew__: true,
      });
    }
  };

  render() {
    const {
      name, placeholder, options, clearable, disabled, isCreatable, isLoading, t,
    } = this.props;


    return (
      <>{
        isCreatable

          ? (
            <CreatableSelect
              name={name}
              value={this.currentValue}
              onChange={this.handleChange}
              onBlur={this.handleCreatableBlur}
              filterOption={filterOption}
              options={options}
              isClearable={clearable}
              className="react-select"
              placeholder={placeholder}
              classNamePrefix="react-select"
              defaultValue={this.defaultValue}
              isDisabled={disabled}
              isLoading={isLoading}
              getOptionLabel={this.getOptionLabel}
              noOptionsMessage={this.noOptionsMessage}
            />
          ) : (
            <Select
              name={name}
              value={this.currentValue}
              onChange={this.handleChange}
              filterOption={filterOption}
              options={options}
              isClearable={clearable}
              className="react-select"
              placeholder={placeholder}
              classNamePrefix="react-select"
              defaultValue={this.defaultValue}
              isDisabled={disabled}
              isLoading={isLoading}
              getOptionLabel={this.getOptionLabel}
              noOptionsMessage={this.noOptionsMessage}
            />
          )
      }
      </>
    );
  }
}

const RenderSelectField = (props) => {
  const {
    input, meta, options, placeholder, clearable, defaultValue, className, extraOnChange,
    disabled, isCreatable, isLoading, isTranslated, t, setValueOnly,
  } = props;
  return (
    <div className={`form__form-group-input-wrap ${className || ''}`}>
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
        clearable={clearable}
        defaultValue={defaultValue}
        touched={meta && meta.touched}
        extraOnChange={extraOnChange}
        disabled={disabled}
        isCreatable={isCreatable}
        isLoading={isLoading}
        isTranslated={isTranslated}
        setValueOnly={setValueOnly}
        t={t}
      />
      {meta && meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
    </div>
  );
};

RenderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }), PropTypes.string,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }), PropTypes.string,
  ]),
  className: PropTypes.string,
  extraOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTranslated: PropTypes.bool,
  setValueOnly: PropTypes.bool,
};

RenderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  clearable: false,
  className: '',
  defaultValue: undefined,
  extraOnChange: undefined,
  disabled: false,
  isCreatable: false,
  isLoading: false,
  isTranslated: false,
  setValueOnly: false,
};

export const extractSelectValue = obj => (obj && typeof obj === 'object' ? obj.value : obj) || '';

export default withTranslation('common')(RenderSelectField);
