import { useRef } from 'react';

const useRefOnce = (value) => {
  const reffed = useRef();
  let retValue = reffed.current;
  if (!retValue) {
    if (typeof value === 'function') {
      retValue = value();
    } else {
      retValue = value;
    }
    reffed.current = retValue;
  }
  return retValue;
};

export default useRefOnce;
