import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, Container } from 'reactstrap';
import classNames from 'classnames';
import ProjectFormCombined from './ProjectFormCombined';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import * as ProjectHelpers from '../helpers';

class ProjectModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createProjectRequest: PropTypes.func.isRequired,
    updateProjectRequest: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    editingProject: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingProject: null,
  };

  handleSubmit = (data) => {
    const payload = {};
    const { createProjectRequest, updateProjectRequest, editingProject } = this.props;
    ['customerId', 'category', 'status', 'tenureType', 'billingType', 'currency', 'legalType']
      .forEach((key) => {
        if (data[key]) {
          if (typeof data[key] === 'object') {
            payload[key] = data[key].value;
          } else {
            payload[key] = data[key];
          }
        }
      });
    if (typeof data.status === 'boolean') {
      payload.status = data.status ? 'active' : 'potential';
    } else if (typeof data.status === 'string' && data.status) {
      payload.status = data.status;
    } else if (!data.status) {
      payload.status = 'potential';
    }
    ['estimatedHrs', 'title', 'fee']
      .forEach((key) => {
        payload[key] = data[key];
      });

    let { shouldCap, capHours, feeAfterCap } = data;
    if (payload.status === 'potential') {
      // clear billingType, fee, etc.
      payload.billingType = 'fixed';
      payload.fee = 0;
      payload.estimatedHrs = 0;
      shouldCap = false;
    }
    const {
      billingType, estimatedHrs, fee,
    } = payload;
    /* if (billingType === 'hourly') {
      shouldCap = false;
    } */
    if (!shouldCap) {
      capHours = 0;
      feeAfterCap = 0;
    } else {
      capHours = parseFloat(capHours) || 0;
      feeAfterCap = parseFloat(feeAfterCap) || 0;
    }
    payload.capHours = capHours;
    payload.feeAfterCap = feeAfterCap;
    payload.estimatePrice = ProjectHelpers.calculateTotalPrice(billingType, estimatedHrs, fee, capHours, feeAfterCap);

    if (editingProject._id === 'new') {
      createProjectRequest(payload);
    } else {
      payload._id = editingProject._id;

      updateProjectRequest(payload);
    }
  };

  render() {
    const {
      updating, editProject, theme, rtl, editingProject,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingProject}
        toggle={() => editProject(null)}
        className={modalClasses}
      >
        { editingProject && (
          <ProjectFormCombined
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editProject(null)}
            initialValues={editingProject}
            isNew={editingProject._id === 'new'}
          />
        ) }
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.projects.updating,
  editingProject: state.projects.editingProject,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createProjectRequest: TenantActions.Projects.createProjectRequest,
  updateProjectRequest: TenantActions.Projects.updateProjectRequest,
  editProject: TenantActions.Projects.editProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);
