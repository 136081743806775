/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  BarChart, Line, LineChart, Bar, Cell, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import durationPriceDateType from '../types/durationPriceDate';
import useRefOnce from '../hooks/useRefOnce';
import { getRandomColorFactory } from './colors';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import Loading from '../../../../shared/components/Loading';
import { getDefaultCurrency, displayCurrency } from '../../../../shared/helpers';

const styles = {
  tooltipWrapper: {
    zIndex: 2,
  },
  legendWrapper: {
    padding: '12px 12px 24px 12px',
  },
};

const extractLabel = (payload, label) => {
  if (!payload) return label;
  const { periodStart, periodEnd } = payload.payload;
  return `${moment(periodStart).format('DD/MM/YYYY')} - ${moment(periodEnd).format('DD/MM/YYYY')}`;
};

const tooltipFormatter = (currency, t) => (value, name, { dataKey }) => (
  // eslint-disable-next-line no-nested-ternary
  dataKey === 'totalPrice' ? displayCurrency(value, currency)
    // eslint-disable-next-line no-nested-ternary
    : dataKey === 'averagePricePerHour' ? `${displayCurrency(value, currency)}/${t('custom_pie_chart.hour')}`
      : dataKey === 'totalDuration' ? `${value} ${t('custom_pie_chart.hours')}` : ''
);

const DurationPriceDateLineChart = ({
  entries, mode,
  startDateRange, endDateRange, currency, t,
}) => {
  const [state, setState] = useState({ extendedEntries: [] });
  useEffect(() => {
    const extendedEntries = entries;

    const allDataList = extendedEntries.reduce((acc, b) => ([...acc, ...b.data]), []);

    const maxPrice = allDataList.reduce((a, b) => Math.max(a, b.totalPrice), 0);
    const maxDuration = allDataList.reduce((a, b) => Math.max(a, b.totalDuration), 0);
    const totalPrice = allDataList.reduce((a, b) => a + b.totalPrice, 0);
    const totalDuration = allDataList.reduce((a, b) => a + b.totalDuration, 0);
    const avgPricePerHour = totalPrice / totalDuration;
    setState({
      extendedEntries,
      maxPrice,
      maxDuration,
      totalPrice,
      totalDuration,
      avgPricePerHour,
    });
  }, [entries]);

  const getColor = useRefOnce(getRandomColorFactory);
  const {
    extendedEntries, maxPrice, maxDuration, totalPrice, totalDuration, avgPricePerHour,
  } = state;

  const priceScaleMax = Math.round(Math.max(maxPrice, maxDuration * avgPricePerHour) * 1.2 / 100).toFixed(2) * 100;
  const durationScaleMax = Math.round(Math.max(maxDuration, maxPrice / avgPricePerHour) * 1.2).toFixed(2);

  if (entries.length === 0) {
    return (
      <div>
        <h4 style={{ textAlign: 'center', marginTop: 24 }}>
          {t('custom_pie_chart.no_data')}
        </h4>
      </div>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <ResponsiveContainer width="100%" aspect={2 - (entries.length) * 0.1}>
          <LineChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              domain={[moment(startDateRange).valueOf(), 'dataMax + 1000000000']}
              name={t('custom_pie_chart.date')}
              type="number"
              scale="linear"
              interval="preserveStartEnd"
              tickFormatter={date => moment(date).format('DD/MM/YYYY')}
            />
            <YAxis
              dataKey="totalPrice"
              yAxisId="totalPrice"
              unit={currency || getDefaultCurrency()}
              name={t('custom_pie_chart.price')}
              domain={[0, priceScaleMax]}
              scale="linear"
              hide={mode !== 'totalPrice'}
            />
            <YAxis
              dataKey="totalDuration"
              yAxisId="totalDuration"
              unit=" hours"
              name={t('custom_pie_chart.duration')}
              domain={[0, durationScaleMax]}
              scale="linear"
              hide={mode !== 'totalDuration'}
            />
            <YAxis
              dataKey="averagePricePerHour"
              yAxisId="averagePricePerHour"
              unit={` ${currency || getDefaultCurrency()}/${t('custom_pie_chart.hour')}`}
              name={t('custom_pie_chart.avrg_per_hour')}
              domain={[0, 'auto']}
              scale="linear"
              hide={mode !== 'averagePricePerHour'}
            />
            {
              extendedEntries.map(({ title, data }, index) => (
                <Line
                  dataKey={mode}
                  data={data}
                  name={`${title}`}
                  key={`entry-${index}`}
                  yAxisId={mode}
                  stroke={getColor(index)}
                />
              ))
            }
            <Legend verticalAlign="top" wrapperStyle={styles.legendWrapper} />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={styles.tooltipWrapper}
              formatter={tooltipFormatter(currency, t)}
              content={
                /* eslint-disable-next-line react/prop-types */
                props => (
                  <DefaultTooltipContent
                    {...props}
                    label={
                      /* eslint-disable-next-line react/prop-types */
                      extractLabel((props.payload || [])[0], props.label)
                    }
                  />
                )
              }
              labelFormatter={label => (typeof label === 'number' ? moment(label).format('DD/MM/YYYY') : label)}
            />
          </LineChart>
        </ResponsiveContainer>
      </Col>

      <Col xs={12}>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <h5>{t('custom_pie_chart.total_duration')}:
            {(totalDuration || 0).toFixed(1)} {t('custom_pie_chart.hours')}
          </h5>
          <h5>{t('custom_pie_chart.total_price')}: {displayCurrency(totalPrice || 0, currency)}</h5>
          <h5>{t('custom_pie_chart.avrg_per_hour')}:
            {displayCurrency(avgPricePerHour || 0, currency)}/{t('custom_pie_chart.hour')}
          </h5>
        </div>
      </Col>
    </Row>
  );
};

DurationPriceDateLineChart.propTypes = {
  entries: PropTypes.arrayOf(
    durationPriceDateType.isRequired,
  ).isRequired,
  startDateRange: PropTypes.instanceOf(Date).isRequired,
  endDateRange: PropTypes.instanceOf(Date).isRequired,
  currency: PropTypes.string,
  mode: PropTypes.oneOf(['totalPrice', 'totalDuration', 'averagePricePerHour']).isRequired,
  t: PropTypes.func.isRequired,
};

DurationPriceDateLineChart.defaultProps = {
  currency: '',
};

export default withTranslation('common')(DurationPriceDateLineChart);
