import {
  EDIT_REPORT, FETCH_REQUEST, CHANGE_REPORT_FILTER, CLEAR_REPORT, CLEAR_REPORT_FILTER,
} from '../../sharedTypes';
import {
  GET_REPORTS_TENANT, CREATE_REPORT_TENANT, UPDATE_REPORT_TENANT,
  RELOAD_REPORT_TENANT, DELETE_REPORT_TENANT, DELETE_REPORT_WITH_CHILDREN_TENANT, SOFT_DELETE_REPORT_TENANT,
} from '../../fetchTypes';

export const changeReportFilter = data => ({
  type: CHANGE_REPORT_FILTER,
  data,
});

export const clearReportFilter = () => ({
  type: CLEAR_REPORT_FILTER,
});

export const clearReports = () => ({
  type: CLEAR_REPORT,
});

export const getReportsRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_REPORTS_TENANT,
  data,
});

export const reloadReportRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: RELOAD_REPORT_TENANT,
  data,
});

export const editReport = data => ({
  type: EDIT_REPORT,
  data,
});

export const createReportRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_REPORT_TENANT,
  data,
});

export const updateReportRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_REPORT_TENANT,
  data,
});

export const softDeleteReportRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_REPORT_TENANT,
  data,
});

export const deleteReportRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_REPORT_TENANT,
  data,
});

export const deleteReportWithChildrenRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_REPORT_WITH_CHILDREN_TENANT,
  data,
});
