import PropTypes from 'prop-types';

const project = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  tenureType: PropTypes.string.isRequired,
  legalType: PropTypes.string.isRequired,
  billingType: PropTypes.string.isRequired,
  fee: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  currency: PropTypes.string.isRequired,
  capHours: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  feeAfterCap: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  estimatedHrs: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  estimatedPrice: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  totalBilledAmount: PropTypes.number.isRequired,
  closedBillableTasksPrice: PropTypes.number.isRequired,
  openBillableTasksPrice: PropTypes.number.isRequired,
  totalWorkedHrs: PropTypes.number.isRequired,
  closedBillableTasksDuration: PropTypes.number.isRequired,
  openBillableTasksDuration: PropTypes.number.isRequired,
  totalTasksCount: PropTypes.number.isRequired,
  closedBillableTasksCount: PropTypes.number.isRequired,
  openBillableTasksCount: PropTypes.number.isRequired,
  totalExpense: PropTypes.number.isRequired,
  totalExpensePayments: PropTypes.number.isRequired,
  totalExtras: PropTypes.number.isRequired,
  openExtraPaymentsPrice: PropTypes.number.isRequired,
  closedExtraPaymentsPrice: PropTypes.number.isRequired,
  advancePayment: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  recordExpenditure: PropTypes.bool.isRequired,
});

export default project;
