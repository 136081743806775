import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Row, Col } from 'reactstrap';

import renderMaskedInput from '../../../../shared/components/form/MaskedInput';
import { subTypes, types } from '../constants/options';
import renderField from '../../../../shared/components/form/FormField';
import { idNumber, isRequired, taxNumber } from '../../../../lib/FieldWarnings/FieldWarnings';

const taxOfficeRequired = isRequired('customer_form_info.tax_office');
// const taxNumberRequired = isRequired('customer_form_info.tax_number');

export const idMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const taxNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const CustomerFormInfo = ({ type, t }) => (
  <>
    <Row>
      <Col xs={12} sm={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t('customer_form_info.tax_office')}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="taxOffice"
              component={renderField}
              type="text"
              placeholder={t('customer_form_info.tax_office')}
              /* validate={type === 'corporate' ? taxOfficeRequired : undefined} */
            />
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {`${(type === 'personal' || type === 'individual')
              ? t('customer_form_info.id_number') : t('customer_form_info.tax_number')}`}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="taxNumber"
              component={renderMaskedInput}
              type="text"
              mask={(type === 'personal' || type === 'individual') ? idMask : taxNumberMask}
              warn={(type === 'personal' || type === 'individual') ? idNumber : taxNumber}
              placeholder={`${(type === 'personal' || type === 'individual')
                ? t('customer_form_info.id_number') : t('customer_form_info.tax_number')}`}
              /* validate={type === 'corporate' ? taxNumber : undefined} */
            />
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('customer_form_info.sector')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="sector"
              component="input"
              type="text"
              placeholder={t('customer_form_info.sector')}
            />
          </div>
        </div>
      </Col>
    </Row>
  </>
);

CustomerFormInfo.propTypes = {
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const selector = formValueSelector('tenant_customer_form');
const mapStateToProps = (state) => {
  let type = selector(state, 'type') || '';
  if (typeof type === 'object') type = type.value || '';
  return {
    type,
  };
};

export default connect(
  mapStateToProps,
)(
  reduxForm({
    form: 'tenant_customer_form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(withTranslation('common')(CustomerFormInfo)),
);
