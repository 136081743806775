const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const types = [
  { value: 'corporate', label: 'customer_options.types.corporate' },
  { value: 'personal', label: 'customer_options.types.individual' },
];

export const typesFilter = [
  { value: '', label: 'customer_options.types_filter.any_type' },
  ...types,
];

export const typeToLabel = toLabel(types);

export const subTypes = [
  { value: 'lead', label: 'customer_options.sub_types.lead' },
  { value: 'working', label: 'customer_options.sub_types.working' },
];

export const subTypeToLabel = toLabel(subTypes);

export const statusFilters = [
  { value: '', label: 'customer_options.status_filter.all_customers' },
  { value: 'actives', label: 'customer_options.status_filter.active_customers' },
  { value: 'inactives', label: 'customer_options.status_filter.inactive_customers' },
];
