/* eslint-disable react/sort-comp */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Switch from 'react-ios-switch';
import moment from 'moment';
import MailIcon from 'mdi-react/MailIcon';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Button from 'reactstrap/es/Button';

import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import projectType from '../types/project';
import Loading from '../../../../shared/components/Loading';
import EmailButton from '../../../../shared/components/EmailButton';
import { languageOptionToLabel } from '../../Bills/constants/options';
import { statusToLabel } from '../constants/options';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class ExpensesTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    expenses: PropTypes.arrayOf(projectType.isRequired).isRequired,
    totalExpenses: PropTypes.number.isRequired,
    toggleEditExpense: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleExpenseStatus: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  get heads() {
    const { t } = this.props;
    return [
      // { key: 'caseName', name: 'Name', sortable: true },

      { key: 'title', name: 'Title', sortable: true },
      {
        key: 'period',
        name: `${t('extra_form.period')}`,
        width: 216,
        formatter: ({ row }) => (
          <div>
            {moment(row.periodStart).format('LL')} - {moment(row.periodEnd).format('LL')}
          </div>
        ),
      },
      // {
      //   key: 'projectId.customerId.name',
      //   name: 'Customer',
      //   sortable: true,
      //   formatter: ({ row }) => (
      //     <div>
      //       {row.projectId.customerId.name}
      //     </div>
      //   ),
      // },
      // {
      //   key: 'projectId.title',
      //   name: 'Matter',
      //   sortable: true,
      //   formatter: ({ row }) => (
      //     <div>
      //       {row.projectId.title}
      //     </div>
      //   ),
      // },
      {
        key: 'created',
        name: `${t('extra_form.created_at')}`,
        width: 96,
        sortable: true,
        formatter: ({ row }) => (
          <div>
            {moment(row.created).fromNow()}
          </div>
        ),
      },
      {
        key: 'language',
        name: `${t('extra_form.language')}`,
        width: 80,
        formatter: ({ row }) => (
          <div>
            {t(languageOptionToLabel(row.language))}
          </div>
        ),
      },
      {
        key: 'status',
        name: `${t('extra_form.status')}`,
        width: 96,
        formatter: ({ value }) => (
          <div>
            {t(statusToLabel(value))}
          </div>
        ),
      },


      // {
      //   key: 'created',
      //   name: 'Created At',
      //   sortable: true,
      //   formatter: ({ value }) => (
      //     <div>
      //       {`${moment(value).fromNow()}`}
      //     </div>
      //   ),
      // },
      // { key: 'peopleInCharge', name: 'People In Charge', formatter: simpleFormatter },

      // { key: 'taxOffice', name: 'Tax Office', formatter: simpleFormatter },
      {
        key: 'actions',
        name: `${t('extra_form.actions')}`,
        width: 336,
        /* cellClass: 'Expenses-page__details-cell', */
        formatter: ({ row }) => (
          <div>
            {row.pdfUrl ? (
              <Button
                tag="a"
                href={row.pdfUrl}
                type="button"
                size="sm"
                className="icon"
                outline
              >
                <p><FileDownloadIcon /> {t('extra_form.download')}</p>
              </Button>
            ) : null}
            <EmailButton
              subject={row.title}
              htmlBody={row.htmlOutput}
              type="button"
              size="sm"
              className="icon"
              outline
            >
              <p><MailIcon /> {t('extra_form.send_mail')}</p>
            </EmailButton>
            <Button
              type="button"
              size="sm"
              className="icon"
              color="danger"
              onClick={() => this.promptSoftDelete(row)}
            >
              <p><DeleteIcon />{t('extra_form.delete')}</p>
            </Button>
            {/*          <Button
            type="button"
            size="sm"
            className="icon"
            outline
            onClick={() => {
              const win = window.open('mailto:?subject=Report &html-body=<b>ME</b>');
              win.focus();
            }}
          >
            <p><MailIcon /> Send Mail</p>
          </Button> */}
          </div>
        ),
      },

    ];
  }

  toggleEditExpense = (Expense) => {
    const { toggleEditExpense } = this.props;
    toggleEditExpense(Expense);
  };

  promptSoftDelete = (report) => {
    const { promptSoftDelete } = this.props;
    promptSoftDelete(report);
  };

  toggleExpenseStatus = (Expense) => {
    const { toggleExpenseStatus } = this.props;
    toggleExpenseStatus(Expense);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, expenses, totalExpenses, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={expenses}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalExpenses === 0 ? (
              <div>
                {t('extra_form.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

export default withTranslation('common')(ExpensesTable);
