import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'reactstrap';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import Loading from '../../../shared/components/Loading';

import * as ProjectActions from '../../../store/actions/tenant/projects';
import {
  billingTypeToFeeUnit,
  billingTypeToLabel,
  categoryToLabel, feeUnitToLabel, statusToLabel, tenureTypeToLabel,
} from './constants/options';
import { displayCurrency } from '../../../shared/helpers';
import MatterDeletePrompt from './components/MatterDeletePrompt';

const MatterLeftSidebar = ({
  loading, loaded, error, project, getCurrentProject, editProject, t,
}) => {
  const { matterId: projectId } = useParams();

  const loadCurrentProject = () => {
    getCurrentProject({ _id: projectId });
  };

  useEffect(() => {
    loadCurrentProject();
  }, [projectId]);

  const {
    title, customerId: customer, category, status, tenureType, legalType,
    billingType, fee, capHours, feeAfterCap, estimatedHrs, estimatePrice,
    totalBilledAmount, totalBilledAmountForTask,
    totalWorkedHrs, totalBilledHrs, totalUnbilledHrs,
    totalExpense, totalExpensePayments, totalExtras,
    recordExpenditure, currency,
  } = project || {};

  const { _id: customerId, name: customerName } = customer || {};

  const editSelf = () => {
    editProject({
      ...project,
      shouldCap: !!project.capHours,
      customerId: { value: customerId, label: customerName },
    });
  };

  const [deletingMatter, setDeletingMatter] = useState(null);

  return (
    <Loading
      loading={loading}
      loaded={loaded}
      error={error}
      retry={loadCurrentProject}
    >
      <div className="sidebar-details">
        <Link to={`/matters/${projectId}`}>
          <h4>
            {t('project_form.matter')}: { title }
          </h4>
        </Link>
        <Link to={`/customers/${customerId}`}>
          <h4 className="subhead">
            { customerName || 'John Doe' }
          </h4>
        </Link>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_form.category')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(categoryToLabel(category)) || ''}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_form.status')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(statusToLabel(status)) || ''}
          </p>
        </div>
        {/*        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_form.tenure_type')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(tenureTypeToLabel(tenureType)) || ''}
          </p>
        </div> */}
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_form.legal_type')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(legalType) || ''}
          </p>
        </div>
        {/*        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            Billing Type
          </h6>
          <p className="sidebar-details__block-content">
            { billingTypeToLabel(billingType) || ''}
          </p>
        </div> */}
        { status === 'active' ? (
          <>
            <div className="sidebar-details__block">
              <h6 className="sidebar-details__block-title">
                {t('project_form.fee')}
              </h6>
              <p className="sidebar-details__block-content">
                {t(displayCurrency(fee || 0, currency))} { (t(billingTypeToFeeUnit(billingType)) || {}).label || '' }
              </p>
            </div>
            { capHours ? (
              <>
                <div className="sidebar-details__block">
                  <h6 className="sidebar-details__block-title">
                    {t('project_form.cap_hours')}
                  </h6>
                  <p className="sidebar-details__block-content">
                    { capHours || ''} {t('project_form.hours')}
                  </p>
                </div>
                <div className="sidebar-details__block">
                  <h6 className="sidebar-details__block-title">
                    {t('project_form.fee_cap')}
                  </h6>
                  <p className="sidebar-details__block-content">
                    { displayCurrency(feeAfterCap || 0, currency)} {t('project_form.per_hour')}
                  </p>
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_item.total_worked')}
          </h6>
          <p className="sidebar-details__block-content">
            { totalWorkedHrs || 0 } {t('project_item.hours')} ({ totalBilledHrs || 0 } {t('project_item.billed')},
            { totalUnbilledHrs || 0 }{' '}{t('project_item.unbilled')})
          </p>
        </div>
        { status === 'active' ? (
          <>
            <div className="sidebar-details__block">
              <h6 className="sidebar-details__block-title">
                {t('project_item.total_estimated_time')}
              </h6>
              <p className="sidebar-details__block-content">
                {estimatedHrs} {t('project_item.hours')}
              </p>
            </div>
            <div className="sidebar-details__block">
              <h6 className="sidebar-details__block-title">
                {t('project_item.total_estimated_price')}
              </h6>
              <p className="sidebar-details__block-content">
                {displayCurrency(estimatePrice, currency)}
              </p>
            </div>
            <div className="sidebar-details__block">
              <h6 className="sidebar-details__block-title">
                {t('project_item.total_billed')}
              </h6>
              <p className="sidebar-details__block-content">
                {displayCurrency(totalBilledAmount, currency)
                } ({displayCurrency(totalBilledAmountForTask, currency)} {t('project_item.for_tasks')})
              </p>
            </div>
          </>
        ) : null}
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_item.total_extra')}
          </h6>
          <p className="sidebar-details__block-content">
            {t(displayCurrency(totalExtras, currency))}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('project_item.total_expenses')}
          </h6>
          <p className="sidebar-details__block-content">
            { `${displayCurrency(totalExpense, currency)
            } spent / ${displayCurrency(totalExpensePayments, currency)} received`}
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="secondary"
            onClick={editSelf}
            outline
            className="icon mb-1"
          >
            <p><PencilIcon /> {t('project_item.edit_matter')}</p>
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="danger"
            onClick={() => setDeletingMatter(project || { _id: projectId, title })}
            outline
            className="icon"
          >
            <p><DeleteIcon /> {t('project_item.delete_matter')}</p>
          </Button>
        </div>
        <MatterDeletePrompt
          matter={deletingMatter}
          onHideModal={() => setDeletingMatter(null)}
        />
      </div>
    </Loading>
  );
};


MatterLeftSidebar.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  project: PropTypes.shape({
    customerId: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }),
  getCurrentProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MatterLeftSidebar.defaultProps = {
  error: '',
  project: null,
};

const mapStateToProps = state => ({
  loading: state.projects.loadingCurrent,
  loaded: state.projects.loadedCurrent,
  error: state.projects.errorCurrent,
  project: state.projects.currentProject,
});

const mapDispatchToProps = {
  getCurrentProject: ProjectActions.getCurrentProjectRequest,
  editProject: ProjectActions.editProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MatterLeftSidebar));
