const normalizeNum = (value, previousValue, maxLength) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    return `${onlyNums.slice(0, 10)}`;
  }
  return `${onlyNums.slice(0, 10)}`;
};

export default normalizeNum;
