import PropTypes from 'prop-types';

const employee = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  parentId: PropTypes.string,
  startDateTime: PropTypes.string,
  duration: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isClose: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
});

export default employee;
