import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import {
  Container, Row, Col,
  Nav, NavItem, NavLink, ButtonToolbar, Button,
} from 'reactstrap';
import classNames from 'classnames';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import RenderSelectField from '../../../../shared/components/form/Select';
import renderField from '../../../../shared/components/form/FormField';
import RenderDateTimePickerField from '../../../../shared/components/form/DateTimePicker';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import Loading from '../../../../shared/components/Loading';

import { feeUnits } from '../../Projects/constants/options';
import UploadableImage from '../../../../shared/components/UploadableImage/UploadableImage';
import { getCustomersList, getProjectsList } from '../../../../store/api/lookup';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderSwitchInput from '../../../../shared/components/form/Switch';

import * as ProfileActions from '../../../../store/actions/tenant/profile';
import { languageOptions } from '../../Bills/constants/options';


const baseTasksOptions = [
  { label: 'None', value: null },
];

class ExtraForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      projectId: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      selectedProject: PropTypes.shape(),
    }).isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      loaded: PropTypes.bool,
      loading: PropTypes.bool,
      updatingLogo: PropTypes.bool,
      error: PropTypes.string,
      profile: PropTypes.shape().isRequired,
    }).isRequired,
    changeFormField: PropTypes.func.isRequired,
    updateProfileLogo: PropTypes.func.isRequired,
    deleteProfileLogo: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    customers: [],
    projects: [],
    projectsById: {},
  };

  customerRequired = isRequired('extra_form.customer');

  matterRequired = isRequired('Matter');

  titleRequired = isRequired('extra_form.title');

  /* amountRequired = isRequired('Amount'); */

  periodStartRequired = isRequired('extra_form.period_start');

  periodEndRequired = isRequired('extra_form.period_end');

  languageRequired = isRequired('extra_form.language');

  componentDidMount() {
    const { initialValues } = this.props;
    const {
      customerId, projectId, selectedProject,
    } = initialValues;
    if (customerId) {
      this.handleCustomerSelect(customerId);
    }
    if (projectId) {
      this.handleProjectSelect(projectId, selectedProject);
    }


    getCustomersList()
      .then((customers) => {
        if (customers) {
          this.setState({
            customers: customers.map(({ _id, name }) => ({ label: name, value: _id })),
          });
        }
      });
  }

  handleCustomerSelect = (customer) => {
    const { value } = customer;
    getProjectsList(value)
      .then((projects) => {
        if (projects) {
          this.setState({
            projects: projects.map(({ _id, title }) => ({ label: title, value: _id })),
            projectsById: projects.reduce((acc, project) => ({ ...acc, [project._id]: project }), {}),
          });
        }
      });
  };

  handleProjectSelect = (project, selectedProject) => {
    const { changeFormField } = this.props;
    const { projectsById } = this.state;
    const { value } = project;
    // eslint-disable-next-line no-param-reassign
    selectedProject = selectedProject || projectsById[value] || {};
  };

  uploadableImageField = (props) => {
    const { t } = this.props;
    const {
      input, uploadable, className, title, placeholderImage, shape,
    } = props;
    const {
      onChange, value, name, meta,
    } = input;
    return (
      <div className={`form__form-group-input-wrap ${className || ''}`}>
        <UploadableImage
          uploadable={uploadable}
          onChange={onChange}
          imageSource={value}
          title={title}
          name={name}
          placeholderImage={placeholderImage}
          shape={shape}

        />
        {meta && meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
      </div>
    );
    /**
     * uploadable: bool (default: false)
     * placeholderImage: string (url/src to default image)
     * placeholderImageElement: react element (default null)
     * imageSource: string (url/src to current image)
     * onChange: function(imageFile):void
     * title: string (default: 'image')
     * shape: 'circle' | 'rounded' | 'square' (default: rounded)
     */
  }

  render() {
    const {
      handleSubmit, close, loading, isNew, profile, updateProfileLogo, deleteProfileLogo, initialValues,
      language: defaultLanguage, t,
    } = this.props;
    const { projectId } = initialValues;
    const {
      customers, projects,
    } = this.state;
    return (
      <form className="form customers-page__form mb-0" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="mb-3">
                {isNew ? t('extra_form.add') : t('extra_form.edit')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('extra_form.customer')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="customerId"
                    component={RenderSelectField}
                    placeholder={t('extra_form.customer')}
                    options={customers}
                    extraOnChange={this.handleCustomerSelect}
                    validate={this.customerRequired}
                    disabled={!!projectId}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.matter')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="projectId"
                    component={RenderSelectField}
                    placeholder={t('extra_form.matter')}
                    options={projects}
                    extraOnChange={this.handleProjectSelect}
                    disabled={!!projectId}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('extra_form.title')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    placeholder={t('extra_form.title')}
                    validate={this.titleRequired}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('extra_form.period_start')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="periodStart"
                    component={RenderDatePickerField}
                    validate={this.periodStartRequired}
                  />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('extra_form.period_end')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="periodEnd"
                    component={RenderDatePickerField}
                    validate={this.periodEndRequired}
                  />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="form__form-group">
                <span className="form__form-group-label required">{t('extra_form.language')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon form__form-group-icon--select">
                    <AccountOutlineIcon />
                  </div>
                  <Field
                    name="language"
                    component={RenderSelectField}
                    placeholder={t('extra_form.language')}
                    options={languageOptions}
                    validate={this.languageRequired}
                    defaultValue={defaultLanguage}
                    isTranslated
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>

            <Col xs={6} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.billable_tasks')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="includeBillableTasks"
                    component={renderSwitchInput}
                  />
                </div>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.non_tasks')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="includeNonBillableTasks"
                    component={renderSwitchInput}
                  />
                </div>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.extra_payments')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="includeExtraPayments"
                    component={renderSwitchInput}
                  />
                </div>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.expenses')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="includeExpenses"
                    component={renderSwitchInput}
                  />
                </div>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.expense_payments')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="includeExpensePayments"
                    component={renderSwitchInput}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('extra_form.logo')}</span>
                <div className="form__form-group-field">
                  <UploadableImage
                    size={128}
                    name="logo"
                    title="logo"
                    shape="rounded"
                    uploadable
                    clearable
                    imageSource={(profile.profile || {}).logo}
                    placeholderImageElement={<PlusIcon />}
                    onChange={(imageFile) => {
                      const data = new FormData();
                      data.append('logo', imageFile);
                      updateProfileLogo(data);
                    }}
                    onClear={() => {
                      deleteProfileLogo();
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Loading
                loading={loading || profile.loading || profile.updatingLogo}
                loaded={profile.loaded}
                error={profile.error}
              >
                <ButtonToolbar className="form__button-toolbar mt-4 justify-content-between">
                  <Button type="button" onClick={close}>{t('extra_form.close')}</Button>
                  <Button color="primary" type="submit" className="next">
                    {isNew ? t('extra_form.create_report') : t('extra_form.update_report')}
                  </Button>
                </ButtonToolbar>
              </Loading>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  language: state.language.language,
});

const mapDispatchToProps = {
  changeFormField: (field, value) => change('tenant_report_form', field, value),
  updateProfileLogo: ProfileActions.updateProfileLogoRequest,
  deleteProfileLogo: ProfileActions.deleteProfileLogoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'tenant_report_form',
  })(withTranslation('common')(ExtraForm)),
);
