/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import CalendarIcon from 'mdi-react/CalendarIcon';
import EditIcon from 'mdi-react/EditIcon';
import classNames from 'classnames';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import FileDownloadIcon from 'mdi-react/FileDownloadIcon';
import MailIcon from 'mdi-react/MailIcon';
import EmailButton from '../../../../shared/components/EmailButton';
import { billingTypeToFeeUnit } from '../../Projects/constants/options';
import billTypes from '../types/bill';
import { displayCurrency, displayPercentile } from '../../../../shared/helpers';
import { calculateTotalPrice } from '../../Projects/helpers';
import { languageOptionToLabel } from '../constants/options';
// import { tenureTypeToLabel, categoryToLabel, billingTypeToLabel } from '../constants/options';

class BillItem extends Component {
  static propTypes = {
    /* actions: PropTypes.shape({
      editbills-page: PropTypes.func,
      completebills-page: PropTypes.func,
      deletebills-page: PropTypes.func,
    }).isRequired, */
    bill: billTypes.isRequired,
    toggleEditBill: PropTypes.func.isRequired,
    toggleBillStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    currency: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currency: '',
  };

  constructor(props) {
    super(props);

    const { bill } = this.props;

    /*
    this.state = {
      title: bills-page.title,
      completed: bills-page.completed,
      description: bills-page.description,
      priority: bills-page.priority,
    };
    */
  }

  handleComplete() {
    const { bill } = this.props;
    // const { checked } = this.state;

    /*
    actions.completebills-page(bills-page.id);
    this.setState({
      checked: !checked,
    });
     */
  }

  handleDelete() {
    const { bill } = this.props;

    // actions.deletebills-page(bills-page.id);
  }

  /*
  handleEdit() {
    const { bills-page, actions } = this.props;

    actions.editbills-page(bills-page.id);
  }

  handleUpdate() {
    const { bills-page, actions } = this.props;
    const { title } = this.state;

    if (title !== '') {
      actions.updatebills-page(bills-page.id,
        title);
    }
  }

  handleTitleChange(event) {
    this.setState({
      title: event.target.value,
    });
  }

 */

  render() {
    /*
    const {
      title, completed, description, priority,
    } = this.state;
    */

    /*
    const priorityColorsClass = classNames({
      'bills-page__priority-indicator': true,
      low: priority === 'low',
      medium: priority === 'medium',
      high: priority === 'high',
    });
     */
    const {
      bill, toggleEditBill, toggleBillStatus, promptSoftDelete, currency, t,
    } = this.props;
    const {
      _id, billedAmount, billingProfile, totalTaskHrs, extrasTotal, taskCost, periodStart, periodEnd, created,
      pdfUrl, htmlOutput, status, language,
    } = bill;
    let { vat = 0, withholding = 0 } = bill;
    const {
      billingType, fee, capHours, feeAfterCap,
    } = billingProfile;


    if (typeof vat === 'string') vat = parseFloat(vat);

    if (typeof withholding === 'string') withholding = parseFloat(withholding);

    const withholdingAmount = billedAmount * (1 / (1 - (withholding / 100)) - 1);

    const billedGrossAmount = billedAmount * (1 / (1 - (withholding / 100)));

    const vatAmount = billedGrossAmount * (vat / 100);

    const totalDueAmount = billedAmount + vatAmount;


    return (
      <Card>
        <CardBody className="customers-page__item pt-0">
          <button
            className="btn btn-secondary customers-page__delete-btn align-self-right"
            type="button"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: '30px',
              height: '30px',
              alignItems: 'center',
              padding: 'initial',
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#000',
              color: '#000',
            }}
            onClick={toggleEditBill}
          >
            <EditIcon className="w-100" style={{ color: '#000' }} />
          </button>
          <fieldset style={{ borderWidth: 1, borderColor: '#000', borderStyle: 'solid' }} className="w-100">
            <legend style={{
              width: 'unset',
              whiteSpace: 'nowrap',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
            >
              {moment(periodStart).format('l')} - {moment(periodEnd).format('l')}

            </legend>

            {/* <label htmlFor={`customer_${_id}_is_active_`} className="customers-page__label-checkbox">
              <input
                id={`customer_${_id}_is_active_`}
                type="checkbox"
                className="customers-page__complete-toggle"
                defaultChecked={isActive}
                required
                onClick={toggleCustomerStatus}
              />
              <span className="checkbox-indicator" />
            </label> */}
            <div className="customers-page__info">

              <div className="customers-page__header">
                <h3>{t('bill_item.billed_total')} {displayCurrency(billedAmount, currency)}</h3>
                <div className="customers-page__additional">

                  <span className="customers-page__due-date">
                    {`${moment(created).fromNow()}`} <CalendarIcon size="18" />
                  </span>
                </div>

              </div>
              <div className="separator" />
              <div className="customers-page__content">
                <div className="customers-page__description customers-page__details my-2">
                  <div>
                    <span>
                      <Trans i18nKey={
                        `bill_item.billing_profile_explanation.${
                          // eslint-disable-next-line no-nested-ternary
                          capHours
                            ? feeAfterCap
                              ? 'cap_and_fee_after_cap'
                              : 'cap_but_no_fee_after_cap'
                            : 'no_cap'
                        }`
                      }
                      >
                        Billed <strong>{{ fee: displayCurrency(fee, currency) }}</strong>
                        {' '}
                        {{ fee_unit: t((billingTypeToFeeUnit(billingType) || {}).label || '').toLowerCase() }}
                        {capHours ? ', capped at ' : ''}
                        <strong>
                          { capHours ? { cap_hours: capHours } : ''}
                        </strong>
                        {capHours ? ' hours.' : ''}
                        {(capHours && feeAfterCap) ? ' Afterwards ' : ''}
                        <strong>
                          {(capHours && feeAfterCap) ? { fee_after_cap: displayCurrency(feeAfterCap, currency) } : ''}
                        </strong>
                        {(capHours && feeAfterCap) ? ' per hour.' : ''}
                      </Trans>
                    </span>
                    {/* {

                    }
                    20 saate kadar Sabit 1000TL sonrasinda saatlik 40TL
                    20 saate kadar Sabit 1000TL
                    Sabit 1000TL
                    <span>
                      {t('bill_item.billed')} <b>{displayCurrency(fee, currency) }</b>
                      { t((billingTypeToFeeUnit(billingType) || {}).label || '') }
                    </span>
                    {
                      capHours ? (
                        <span>{t('bill_item.capped_at')} <b>{capHours}</b>{t('bill_item.hours')}. {
                          feeAfterCap ? (
                            <span>
                              {t('bill_item.afterwards')} <b>${displayCurrency(feeAfterCap, currency)}</b>
                              {t('bill_item.per_hour')}.
                            </span>
                          ) : ''
                        }
                        </span>
                      ) : null
                    } */}
                  </div>

                  {/*
                  {tenureType
                    && (
                      <div>
                        <b>Tenure Type: </b>

                      </div>
                    )
                  } */}
                </div>
                <div className="separator" />
                <div className="customers-page__description my-2">
                  {/* <h4>Addresses</h4> */}
                  <ul>

                    <li>
                      {/* <CashIcon size="18" />{' '} */}
                      {t('bill_item.total_task_hours')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {totalTaskHrs} {t('bill_item.hours')}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.total_task_cost')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(taskCost, currency)}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.total_extras_cost')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(extrasTotal, currency)}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="separator" />
                <div className="customers-page__description my-2">
                  {/* <h4>Addresses</h4> */}
                  <ul>
                    <li>
                      {t('bill_item.language')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {t(languageOptionToLabel(language))}
                      </span>
                    </li>
                    <li>
                      {/* <CashIcon size="18" />{' '} */}
                      {t('bill_item.net_amount')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(billedAmount, currency)}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.withholding')}{' '}
                      ({displayPercentile(withholding, { language, fractionDigits: 0 })}):{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(withholdingAmount, currency)}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.gross_amount')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(billedGrossAmount, currency)}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.vat')}{' '}
                      ({displayPercentile(vat, { language, fractionDigits: 0 })}):{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(vatAmount, currency)}
                      </span>
                    </li>
                    <li>
                      {t('bill_item.total_due')}:{' '}
                      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {displayCurrency(totalDueAmount, currency)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`my-1 ${''
              }d-flex flex-wrap justify-content-center align-items-center w-100`}
            >

              { status === 'Finished' ? (
                <>
                  <Button
                    tag="a"
                    href={pdfUrl}
                    type="button"
                    className="icon m-1"
                    outline
                  >
                    <p><FileDownloadIcon /> {t('bill_item.download')}</p>
                  </Button>
                  <EmailButton
                    subject={`Bill for ${moment(periodStart).format('DD/MM/YYYY')
                    } - ${moment(periodEnd).format('DD/MM/YYYY')}`}
                    htmlBody={htmlOutput}
                    type="button"
                    color="success"
                    className="icon m-1"
                  >
                    <p style={{ color: '#fff' }}><MailIcon /> {t('bill_item.send_mail')}</p>
                  </EmailButton>
                  <Button
                    type="button"
                    color="danger"
                    className="icon m-1"
                    onClick={promptSoftDelete}
                  >
                    <p style={{ color: '#fff' }}>
                      <DeleteIcon /> {t('bill_item.delete')}
                    </p>
                  </Button>
                </>
              ) : status}
            </div>
          </fieldset>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(BillItem);
