import cities from './cities.min';
import countryCodes from './country-codes.min';
import phones from './phones.min';

export const phoneCodeList = Object.entries(phones)
  .map(([key, value]) => ({ countryName: key, countryPhoneCode: value }));

const { ...otherCountries } = cities;

export const countriesWithCities = [...Object.keys(otherCountries)];

export const citiesByCountry = countryName => cities[countryName] || [];

export const phoneCodeByCountry = countryName => phones[countryName] || '';

export const countryCodeByCountry = countryName => countryCodes[countryName] || '';
