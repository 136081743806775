/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Button, Table, CardHeader, Popover, PopoverBody, PopoverHeader,
} from 'reactstrap';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import { NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import UploadableImage from '../../../../shared/components/UploadableImage/UploadableImage';
import * as MessageActions from '../../../../store/actions/tenant/messages';
import UnsubscribePrompt from '../../Subscription/components/UnsubscribePrompt';


const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const onChangePage = (pageOfItems) => {
  this.setState({ pageOfItems });
};

const onChangeSelect = () => {
  const { isChecked } = this.state;
  this.setState({ isChecked: !isChecked });
};


const concatMsg = (readmessages, unreadmessages) => {
  let tempread = [];
  let tempunread = [];

  tempread = readmessages;
  if (tempread) {
    tempread.forEach((v) => {
      v.isRead = 'Read';
    });
  }
  tempunread = unreadmessages;
  if (tempunread) {
    tempunread.forEach((v) => {
      v.isRead = 'Unread';
    });
  }

  if (tempunread) {
    const allmessages = tempunread.concat(tempread);
    return allmessages;
  }

  if (tempread) {
    const allmessages = tempread.concat(tempunread);
    return allmessages;
  }

  const allmessages = [];
  return allmessages;
};


const ProfileMain = ({
  getReadMessages, getUnreadMessages, fname, lname, picture, logo, email, phone, subscriptionReferenceCode, metadata,
  updateProfilePicture, updateProfileLogo, deleteProfilePicture, deleteProfileLogo, onLetter,
  readmessages, unreadmessages, markReadMessage, dir, t, parentId,
}) => {
  const { customerId } = useParams();


  useEffect(() => {
    getUnreadMessages({ _id: customerId });
  }, [customerId]);


  const [bookingListModal, setBookingListModal] = useState(false);
  const [tempText, setTempText] = useState(null);
  const [tempSubject, setTempSubject] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverClass, setPopoverClass] = useState(null);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const toggleUnsubscribeModal = useCallback(() => {
    setShowUnsubscribeModal(prevShowUnsubscribeModal => !prevShowUnsubscribeModal);
  }, [setShowUnsubscribeModal]);

  useEffect(() => {
    getReadMessages({ _id: customerId });
  }, [customerId]);

  const allmessages = concatMsg(readmessages, unreadmessages);

  const handleChangePage = (event, temppage) => {
    setPage(temppage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const openPopover = (msg, key) => {
    setPopoverOpen(msg._id);
    setTempText(msg.messageText);
    setTempSubject(msg.subject);
    setPopoverClass(key % 10);
    if (msg.isRead === 'Unread') {
      markReadMessage({ messageId: msg._id });
    }
  };

  const closePopover = () => {
    setPopoverOpen(false);
    setTempText(null);
    setTempSubject(null);
  };


  return (
    <>


      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div>
                <h4 className="text-center mb-2">{t('profile_main.profile_photo')}</h4>
                <UploadableImage
                  size={128}
                  name="picture"
                  title="avatar"
                  shape="circle"
                  uploadable
                  clearable
                  imageSource={picture}
                  placeholderImage={Ava}
                  placeholderImageElement={t('profile_main.upload_photo')}
                  onChange={(imageFile) => {
                    const data = new FormData();
                    data.append('picture', imageFile);
                    updateProfilePicture(data);
                  }}
                  onClear={deleteProfilePicture}
                />
              </div>
              <div className="ml-4">
                <h4 className="text-center mb-2">{t('profile_main.company_logo')}</h4>
                <UploadableImage
                  size={128}
                  name="logo"
                  title="logo"
                  shape="rounded"
                  uploadable
                  clearable
                  imageSource={logo}
                  placeholderImageElement={t('profile_main.upload_logo')}
                  onChange={(imageFile) => {
                    const data = new FormData();
                    data.append('logo', imageFile);
                    updateProfileLogo(data);
                  }}
                  onClear={deleteProfileLogo}
                />
              </div>

              <div className="profile__data">
                <p className="profile__name">{fname} {lname}</p>
                {/* <p className="profile__work">Senior Account Manager</p> */}
                <p className="profile__contact">{email}</p>
                <p className="profile__contact">{phone}</p>
                {/*
            <Button color="primary" className="icon profile__btn"><p><MessageTextOutlineIcon /> Message</p></Button>
*/}
                {metadata.isSubscribed ? (
                  <>
                    <p className="profile__contact">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {metadata.isEnterpriseSubscribed ? t('profile_main.premium_enterprise')
                        : metadata.isIndividualSubscribed ? t('profile_main.premium_individual')
                          : t('profile_main.premium')}
                    </p>
                    <Button
                      type="button"
                      color="danger"
                      className="profile__btn"
                      onClick={toggleUnsubscribeModal}
                    >
                      <p className="">
                        {t('unsubscribe.button_title')}
                      </p>
                    </Button>
                  </>
                ) : (
                  !parentId
                    && (
                      <Button
                        type="button"
                        color="success"
                        className="icon profile__btn"
                      >
                        <NavLink
                          to="/profile/subscription"
                          color="#fff"
                        >
                          <p className="">
                            {t('profile_main.get_premium')}
                          </p>
                        </NavLink>
                      </Button>
                    )
                )}
              </div>
            </div>
            {/*        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__stat-number">05</p>
            <p className="profile__stat-title">Projects</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">24</p>
            <p className="profile__stat-title">Tasks</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">12</p>
            <p className="profile__stat-title">Reports</p>
          </div>
        </div> */}

          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardHeader className="custom-header"> {t('profile_main.system_messages')}</CardHeader>
          <CardBody className="profile__card-v2">
            <Table responsive className="table--bordered">
              <thead>
                <tr className="m-0">
                  <th className="">#</th>
                  <th className="">{t('profile_main.subject')}</th>
                  <th className=""> {t('profile_main.message')}</th>
                  <th className="">{t('profile_main.type')}</th>
                  <th className="">{t('profile_main.status')}</th>
                </tr>
              </thead>
              <tbody>
                {allmessages.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((msg, key) => (
                    <tr className="m-0" key={key}>
                      {<th className="">{(key + (page * 10)) + 1}</th>}
                      <td className="">{`${msg.subject.substring(0, 9)}...`}</td>
                      <div>
                        <td id={`popover${msg._id}`} className="add-cursor" onClick={() => openPopover(msg, key)}>
                          {`${msg.messageText.substring(0, 9)}...`}
                        </td>
                        <Popover
                          // className={`fix-class${popoverClass} pop-fix`}
                          isOpen={popoverOpen === msg._id}
                          target={`popover${msg._id}`}
                          toggle={openPopover}
                          dir={dir}
                        >
                          <PopoverHeader>{tempSubject}</PopoverHeader>
                          <PopoverBody>{tempText}
                          </PopoverBody>
                        </Popover>
                      </div>
                      <td className="">{msg.messageType}</td>
                      <td className="">{msg.isRead === 'Read' ? 'Okundu' : 'Okunmadı'}</td>


                    </tr>
                  ))}
              </tbody>


            </Table>


            <TablePagination
              component="div"
              className="material-table__pagination"
              count={allmessages.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': t('profile_main.previous_page') }}
              nextIconButtonProps={{ 'aria-label': t('profile_main.next_page') }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': t('profile_main.rows') },
                native: true,
              }}
            />
            <UnsubscribePrompt
              modalVisible={showUnsubscribeModal}
              onHideModal={toggleUnsubscribeModal}
            />
          </CardBody>


        </Card>

      </Col>
    </>
  );
};

ProfileMain.propTypes = {
  getReadMessages: PropTypes.func.isRequired,
  getUnreadMessages: PropTypes.func.isRequired,
  markReadMessage: PropTypes.func.isRequired,
  fname: PropTypes.string.isRequired,
  lname: PropTypes.string.isRequired,
  picture: PropTypes.string,
  logo: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  subscriptionReferenceCode: PropTypes.string,
  metadata: PropTypes.shape({
    isEmployee: PropTypes.bool.isRequired,
    isTenantAdmin: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    isNotSubscribed: PropTypes.bool.isRequired,
    isEnterpriseSubscribed: PropTypes.bool.isRequired,
    isIndividualSubscribed: PropTypes.bool.isRequired,
  }).isRequired,
  updateProfilePicture: PropTypes.func.isRequired,
  updateProfileLogo: PropTypes.func.isRequired,
  deleteProfilePicture: PropTypes.func.isRequired,
  deleteProfileLogo: PropTypes.func.isRequired,
  onLetter: PropTypes.func.isRequired,
  dir: PropTypes.string.isRequired,
  readmessages: PropTypes.shape(),
  unreadmessages: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};
ProfileMain.defaultProps = {
  picture: null,
  logo: null,
  readmessages: [],
  unreadmessages: [],
  subscriptionReferenceCode: '',
  parentId: null,
};

const mapStateToProps = state => ({
  loaded: state.customers.loadedCurrent,
  loading: state.customers.loadingCurrent,
  readmessages: state.messages.readmessages,
  unreadmessages: state.messages.unreadmessages,

});


const mapDispatchToProps = {
  getReadMessages: MessageActions.getReadMessages,
  getUnreadMessages: MessageActions.getUnreadMessages,
  markReadMessage: MessageActions.markReadMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ProfileMain));
