/* eslint-disable no-useless-escape */
export const isRequired = label => value => (value
  ? undefined
  : `$t(${label}) $t(field_warnings.is_required)`
);

export const alphaNumeric = value => (!(value && /^[a-zA-Z0-9]$/g.test(value))
  ? 'field_warnings.only_alphanumeric'
  : undefined);

export const phoneNumber = value => (!(value && /^\+[1-9]\d{10,12}$/g.test(value.replace(/[^\d+]+/g, '')))
  ? 'field_warnings.invalid_phone_number'
  : undefined);

export const minValue0 = value => (value && value < 0 ? 'field_warnings.invalid_min_value0' : undefined);

export const idNumber = value => ((value && !/^([1-9][0-9]{10})$/i.test(value))
  ? 'field_warnings.invalid_id_number'
  : undefined);

export const idNumberRequired = value => idNumber(value || 'b');

export const taxNumber = value => (!(value && /^([0-9]{10})$/i.test(value))
  ? 'field_warnings.invalid_tax_number'
  : undefined);

export const zipCode = value => (!(value && /^([0-9]{5})$/i.test(value))
  ? 'field_warnings.invalid_zipcode'
  : undefined);

export const emailAddress = value => (!(value
  && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(value)) ? 'field_warnings.invalid_email' : undefined);

export const chamberRegistrationNumber = value => ((value && !/^([1-9][0-9]{0,5})$/i.test(value))
  ? 'field_warnings.invalid_chamber_registration_number'
  : undefined);

export const passwordMatch = (value, allValues) => (value === allValues.password ? undefined
  : 'field_warnings.passwords_dont_match');

export default alphaNumeric;
