import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import { Container } from 'reactstrap';
import CustomerForm from './CustomerForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';

class CustomerModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createCustomerRequest: PropTypes.func.isRequired,
    updateCustomerRequest: PropTypes.func.isRequired,
    editCustomer: PropTypes.func.isRequired,
    editingCustomer: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingCustomer: null,
  };

  handleSubmit = (data) => {
    const payload = {};
    const { createCustomerRequest, updateCustomerRequest, editingCustomer } = this.props;
    if (data.phones) {
      payload.phones = data.phones.map(phone => phone.replace(/[^\d+]+/g, ''));
    }
    ['type', 'subtype']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value;
        }
      });
    ['name', 'peopleInCharge', 'taxOffice', 'taxNumber', 'sector', 'addresses', 'emails']
      .forEach((key) => {
        payload[key] = data[key];
      });
    if (editingCustomer._id === 'new') {
      createCustomerRequest(payload);
    } else {
      payload._id = editingCustomer._id;
      updateCustomerRequest(payload);
    }
  };

  render() {
    const {
      updating, editCustomer, editingCustomer, theme, rtl,
    } = this.props;

    const modalClasses = classNames({
      'todo__add-modal': true,
    }, theme.className, `${rtl.direction}-support`);

    return (

      <Modal
        isOpen={!!editingCustomer}
        toggle={() => editCustomer(null)}
        className={modalClasses}
      >
        { editingCustomer && (
          <CustomerForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editCustomer(null)}
            initialValues={editingCustomer}
            isNew={editingCustomer._id === 'new'}
          />
        ) }
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.customers.updating,
  editingCustomer: state.customers.editingCustomer,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createCustomerRequest: TenantActions.Customers.createCustomerRequest,
  updateCustomerRequest: TenantActions.Customers.updateCustomerRequest,
  editCustomer: TenantActions.Customers.editCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
