import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { billableStatuses, billableStatusToColor, billableStatusToLabel } from '../constants/options';

const TaskBillableStatusBadge = ({ billableStatus, className, t }) => (
  <Badge color={billableStatusToColor(billableStatus)} className={`mx-1 ${className}`} pill>
    {t(billableStatusToLabel(billableStatus))}
  </Badge>
);


TaskBillableStatusBadge.propTypes = {
  billableStatus: PropTypes.oneOf(billableStatuses.map(x => x.value)),
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

TaskBillableStatusBadge.defaultProps = {
  billableStatus: '',
  className: '',
};

export default withTranslation('common')(TaskBillableStatusBadge);
