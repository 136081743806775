import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';

const airplane = `${process.env.PUBLIC_URL}/img/pricing_cards/003-airplane.svg`;
const rocket = `${process.env.PUBLIC_URL}/img/pricing_cards/001-rocket.svg`;

const CardPro = ({
  isEnterprise, name, description, plan, onSelect, t,
}) => (
  plan
    ? (
      <Col md={6} xl={3} sm={12}>
        <Card>
          <CardBody className={`pricing-card ${isEnterprise ? 'pricing-card--danger' : 'pricing-card--warning'}`}>
            <div className="pricing-card__body">
              <img className="pricing-card__img" src={isEnterprise ? rocket : airplane} alt="" />
              <h3 className="pricing-card__plan">{name}</h3>
              <hr />
              <p className="pricing-card__price">
            ₺{plan.price}
                <span>/ {t(`subscription_index.${plan.paymentInterval.toLowerCase()}`)}
                </span>
              </p>
              <p className="pricing-card__feature">{description}</p>
              <p className="pricing-card__feature pricing-card__feature--inactive">{t('card_pro.support')}</p>
              <Button
                className="pricing-card__button"
                color="primary"
                onClick={() => onSelect(plan.pricingPlanReferenceCode)}
              >{t('card_pro.try')}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
    : null

);

CardPro.propTypes = {
  isEnterprise: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  plan: PropTypes.objectOf().isRequired,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CardPro.defaultProps = {

};

export default withTranslation('common')(CardPro);
