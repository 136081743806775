import React from 'react';
import {
  spawn, take, takeLatest, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { CLEAR_SOFT_DELETE_STATUS, FETCH_SUCCESS } from '../sharedTypes';
import { SOFT_DELETE_TASK_TENANT } from '../fetchTypes';
import Translated from '../../shared/components/Translated';
import { dispatch } from '../index';

const SOFT_DELETE_TOAST_ID = 'SOFT_DELETE_TOAST_ID';

const handleToastClose = () => {
  dispatch({
    type: CLEAR_SOFT_DELETE_STATUS,
  });
};

const updateSoftDeleteToast = ({ failed, deleting, deleted }) => {
  let failedTasks = '\n';
  failed.forEach(({ task, error }) => {
    failedTasks = `${failedTasks}${task}: \n\t${error}\n`;
  });

  const toastContent = (
    <Translated>
      {t => (
        <>
          {
            deleting.size
              ? t('toasts.custom.tasks.soft_delete_progress', {
                deletedSize: deleted.size,
                totalSize: deleting.size + deleted.size,
              }) : t('toasts.custom.tasks.soft_delete_success', {
                deletedSize: deleted.size,
              })
          }
          { failed.size ? (
            <div>
              <h4>{t('toasts.custom.tasks.soft_delete_failed', { failedSize: failedTasks.size })}</h4>
              <ul>
                {
                  Array.from(failed).map(([key, { task, error }]) => (
                    <li key={key}>
                      <b>{task}</b>
                      <br />
                      <span>{error}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
          ) : null}
        </>
      )}
    </Translated>
  );
  const options = {
    progress: deleted.size / (deleting.size + deleted.size),
    autoClose: (failed.size || deleting.size) ? false : null,
    closeButton: deleting.size ? false : null, // null resets to default
    onClose: handleToastClose,
  };
  if (!toast.isActive(SOFT_DELETE_TOAST_ID)) {
    toast.info(toastContent, {
      toastId: SOFT_DELETE_TOAST_ID,
      ...options,
    });
  } else {
    toast.update(SOFT_DELETE_TOAST_ID, {
      render: toastContent,
      // eslint-disable-next-line no-nested-ternary
      type: failed.size ? toast.TYPE.ERROR
        : deleting.size ? toast.TYPE.INFO
          : toast.TYPE.SUCCESS,
      ...options,
    });
  }
};

function* onTaskDeleteProgress(action) {
  const softDeleteState = yield select(state => state.tasks.softDeleteHelper);
  yield call(updateSoftDeleteToast, softDeleteState);
}

export default function* watchTaskDeleteProgress() {
  yield takeLatest(
    action => action.fetchType === SOFT_DELETE_TASK_TENANT,
    onTaskDeleteProgress,
  );
}
