import withFetch from './withFetch';
import { RESET_PASSWORD_TENANT, RESET_PASSWORD_CONFIRMATION_TENANT } from '../fetchTypes';

const initialState = {
  loading: false,
  error: undefined,
  loaded: false,
  confirmError: false,
  confirmLoading: false,
  confirmLoaded: false,
};

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case RESET_PASSWORD_TENANT:
      return {
        ...state,
        error: undefined,
        loading: true,
        loaded: false,
      };
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return {
        ...state,
        confirmError: false,
        confirmLoading: true,
        confirmLoaded: false,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case RESET_PASSWORD_TENANT:
      return {
        ...state,
        error: undefined,
        loading: false,
        loaded: true,
      };
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return {
        ...state,
        confirmError: false,
        confirmLoading: false,
        confirmLoaded: true,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case RESET_PASSWORD_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return {
        ...state,
        confirmError: action.error,
        confirmLoading: false,
        confirmLoaded: false,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => state;

export default withFetch(initialState, fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
