import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import useDurationPriceReport from './hooks/useDurationPriceReport';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import CustomPieChart from './components/CustomPieChart';
import useRefOnce from './hooks/useRefOnce';
import { getRandomColorFactory } from './components/colors';
import CheckBoxFilter from './components/CheckboxFilter';
import ShortDurationPriceDateLineChart from './components/ShortDurationPriceDateLineChart';
import timeseriesOptions from './constants/timeseries';
import modeOptions from './constants/modes';
import SelectFilter from './components/SelectFilter';
import durationPriceDateNormalize from './helpers/durationPriceDateNormalizer';
import useRefMemo from '../../../shared/hooks/useRefMemo';
import useStateMemo from '../../../shared/hooks/useStateMemo';

const ShortDurationPriceDatePanel = ({
  title, projectId, customerId, fallbackTitle, t,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const groupBy = projectId ? 'project'
    : customerId ? 'client' : 'team';
  const [date, setDate] = useState(initialState);
  const [timeseries, setTimeseries] = useState('monthly');
  const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  const [collapsed, setCollapsed] = useState(false);
  const [{
    loading, loaded, error, result,
  }, reload] = useDurationPriceReport(
    date,
    groupBy,
    projectId,
    customerId,
    timeseries,
  );
  const entries = useStateMemo(() => {
    if (loaded) {
      return durationPriceDateNormalize({
        date,
        timeseries,
        results: result,
        fallbackTitle: t(fallbackTitle),
      });
    }
    return [];
  }, [result]);

  const [includeClosedBillableTasks, setIncludeClosedBillableTasks] = useState(false);
  const [includeOpenBillableTasks, setIncludeOpenBillableTasks] = useState(false);
  const [includeNonBillableTasks, setIncludeNonBillableTasks] = useState(false);
  const [includeClosedExtraPayments, setIncludeClosedExtraPayments] = useState(false);
  const [includeOpenExtraPayments, setIncludeOpenExtraPayments] = useState(false);

  return (
    <ControlledPanel
      title={title}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      lg={12}
      xl={6}
    >
      <Row className="form">
        <Col sm={12} md={8} className="mt-2">
          <DateFilter
            onChange={onDateChange}
            state={date}
          />
        </Col>
        <SelectFilter
          className="mt-2"
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          name={`${groupBy}-duration-price-date-timeseries`}
          options={timeseriesOptions}
          setState={setTimeseries}
          state={timeseries}
          isTranslated
        />
      </Row>
      <Row className="mt-2 form">
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-billable-tasks`}
          label={t('duration_panel.inc_close_task')}
          setState={setIncludeClosedBillableTasks}
          state={includeClosedBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-billable-tasks`}
          label={t('duration_panel.inc_open_task')}
          setState={setIncludeOpenBillableTasks}
          state={includeOpenBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-non-billable-tasks`}
          label={t('duration_panel.inc_non_task')}
          setState={setIncludeNonBillableTasks}
          state={includeNonBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-extra-payments`}
          label={t('duration_panel.inc_close_extra')}
          setState={setIncludeClosedExtraPayments}
          state={includeClosedExtraPayments}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-extra-payments`}
          label={t('duration_panel.inc_open_extra')}
          setState={setIncludeOpenExtraPayments}
          state={includeOpenExtraPayments}
        />
      </Row>
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {
          loaded ? (
            <ShortDurationPriceDateLineChart
              entries={entries}
              includeClosedBillableTasks={includeClosedBillableTasks}
              includeClosedExtraPayments={includeClosedExtraPayments}
              includeOpenBillableTasks={includeOpenBillableTasks}
              includeOpenExtraPayments={includeOpenExtraPayments}
              includeNonBillableTasks={includeNonBillableTasks}
              startDateRange={date.start}
              endDateRange={date.end}
            />
          ) : null
        }
      </Loading>
    </ControlledPanel>
  );
};

ShortDurationPriceDatePanel.propTypes = {
  title: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  fallbackTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ShortDurationPriceDatePanel.defaultProps = {
  projectId: '',
  customerId: '',
  fallbackTitle: 'duration_price_chart.unknown',
};

export default withTranslation('common')(ShortDurationPriceDatePanel);
