import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import DefaultTopbarHeader from './DefaultTopbarHeader';
import CustomerTopbarHeader from '../../Customers/CustomerTopbarHeader';
import MatterTopbarHeader from '../../Projects/MatterTopbarHeader';
import TaskTopbarHeader from '../../Tasks/TaskTopbarHeader';

const TopbarHeader = () => (
  <div className="topbar__header">
    <Switch>
      <Route path="/customers/:customerId" component={CustomerTopbarHeader} />
      <Route path="/matters/:matterId" component={MatterTopbarHeader} />
      <Route path="/tasks/:id" component={TaskTopbarHeader} />
      <Route component={DefaultTopbarHeader} />
    </Switch>
  </div>
);

export default TopbarHeader;
