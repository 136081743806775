import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import DefaultSidebar from './DefaultSidebar';
import CustomersLeftSidebar from '../../Customers/components/CustomersLeftSidebar';
import CustomerLeftSidebar from '../../Customers/CustomerLeftSidebar';
import ProjectsLeftSidebar from '../../Projects/components/ProjectsLeftSidebar';
import TasksLeftSidebar from '../../Tasks/components/TasksLeftSidebar';
import TaskLeftSidebar from '../../Tasks/TaskLeftSidebar';
import MatterLeftSidebar from '../../Projects/MatterLeftSidebar';
import EmployeesLeftSidebar from '../../Employees/components/EmployeesLeftSidebar';

class SidebarContent extends PureComponent {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight } = this.props;
    return (
      <>
        <div className="sidebar__content sidebar__content-dynamic">
          <Switch>
            <Route path="/customers/:customerId" component={CustomerLeftSidebar} />
            <Route path="/customers" component={CustomersLeftSidebar} />
            <Route path="/matters/:matterId" component={MatterLeftSidebar} />
            <Route path="/matters" component={ProjectsLeftSidebar} />
            <Route path="/tasks/:id" component={TaskLeftSidebar} />
            <Route path="/tasks" component={TasksLeftSidebar} />
            <Route path="/employees" component={EmployeesLeftSidebar} />
            <Route component={DefaultSidebar} />
          </Switch>
        </div>
        <button className="sidebar__toggle-collapse-btn" type="button" onClick={this.hideSidebar}>
          <ChevronUpIcon />
        </button>
      </>
    );
  }
}

export default withRouter(SidebarContent);
