import moment from 'moment';

const dataFields = [
  'closedBillableTasksPrice',
  'openBillableTasksPrice',
  'closedExtraPaymentsPrice',
  'openExtraPaymentsPrice',
  'closedBillableTasksDuration',
  'openBillableTasksDuration',
  'nonBillableTasksDuration',
];

/**
 * @param oldDate {Moment}
 * @param timeseries {String}
 * @param periodCount {Number}
 * @returns date {Moment}
 */
const addPeriod = (oldDate, timeseries, periodCount = 1) => {
  const date = moment(oldDate);
  switch (timeseries) {
    case 'weekly':
      date.add(periodCount, 'weeks');
      break;
    case 'monthly':
      date.add(periodCount, 'months');
      break;
    case 'yearly':
      date.add(periodCount, 'years');
      break;
    default:
      throw new Error(`'Unknown timeseries ${timeseries}`);
  }
  return date;
};

const durationPriceDateNormalize = ({
  date: { start: startDate, end: endDate },
  timeseries,
  results,
  fallbackTitle,
}) => {
  const periods = [];
  const filterRangeStart = moment(startDate);
  const filterRangeEnd = moment(endDate);

  // ================================================================================
  // PICK A SAMPLE DATA WITH PERIOD-START AND PERIOD-END
  // ================================================================================
  let sample = null;
  for (let i = 0; i < results.length && !sample; i += 1) {
    const { data: { taskTimeSeries, extraTimeSeries } } = results[i];
    for (let j = 0; j < taskTimeSeries.length && !sample; j += 1) {
      const { periodStart, periodEnd } = taskTimeSeries[j];
      sample = {
        periodStart: moment(periodStart),
        periodEnd: moment(periodEnd),
      };
    }
    for (let j = 0; j < extraTimeSeries.length && !sample; j += 1) {
      const { periodStart, periodEnd } = extraTimeSeries[j];
      sample = {
        periodStart: moment(periodStart),
        periodEnd: moment(periodEnd),
      };
    }
  }
  if (!sample) {
  // no sample found, let's create one ourself (that means all results are empty)
    const periodStart = moment(filterRangeStart);
    const periodEnd = addPeriod(moment(periodStart), timeseries);
    sample = { periodStart, periodEnd };
  }
  sample = dataFields.reduce((acc, dataField) => ({
    ...acc,
    [dataField]: 0,
  }), sample);

  sample.date = addPeriod(sample.periodStart, timeseries);
  sample.date.add(-sample.date.diff(sample.periodStart) / 2);

  // ================================================================================
  // POPULATING PERIODS ARRAY STARTING WITH SAMPLE in range (filterRangeStart-filterRangeEnd)
  // ================================================================================
  let current = sample;
  while (current.periodStart.isAfter(filterRangeStart, 'day')) {
    current = {
      ...sample,
      periodStart: addPeriod(current.periodStart, timeseries, -1),
      periodEnd: addPeriod(current.periodEnd, timeseries, -1),
      date: addPeriod(current.date, timeseries, -1),
    };
    periods.push(current);
  }
  periods.reverse();
  current = sample;
  periods.push(current);
  while (current.periodEnd.isBefore(filterRangeEnd, 'day')) {
    current = {
      ...sample,
      periodStart: addPeriod(current.periodStart, timeseries),
      periodEnd: addPeriod(current.periodEnd, timeseries),
      date: addPeriod(current.date, timeseries),
    };
    periods.push(current);
  }
  // ================================================================================
  // CONVERT EACH OF THE RESULTS FIT POPULATED PERIODS
  // ================================================================================
  return results.map(({ title, data }) => {
    const { taskTimeSeries, extraTimeSeries } = data;
    const copyData = [...(taskTimeSeries || []), ...(extraTimeSeries || [])].map(item => ({
      ...item,
      periodStart: moment(item.periodStart),
      periodEnd: moment(item.periodEnd),
    })).sort((a, b) => a.periodStart.diff(b.periodStart, 'days'));

    let dataIndex = 0;
    const entry = {
      title: title || fallbackTitle,
      data: periods.map((p) => {
        const nextP = { ...p };
        while (dataIndex < copyData.length) {
          if (copyData[dataIndex].periodStart.isAfter(nextP.periodEnd)) {
            break;
          }
          if (copyData[dataIndex].periodStart.isSameOrAfter(nextP.periodStart)) {
            for (let i = 0; i < dataFields.length; i += 1) {
              nextP[dataFields[i]] += copyData[dataIndex][dataFields[i]] || 0;
            }
          }
          dataIndex += 1;
        }
        return nextP;
      }),
    };
    dataFields.forEach((key) => {
      entry[key] = 0;
    });
    entry.data.forEach((d) => {
      dataFields.forEach((key) => {
        entry[key] += d[key];
      });
    });
    return entry;
  });
};

/*
const durationPriceDateNormalizeOld = ({
  date: { start: startDate, end: endDate },
  timeseries,
  results,
  fallbackTitle,
}) => {
  const periods = [];
  const filterRangeStart = moment(startDate);
  const filterRangeEnd = moment(endDate);
  const currentDate = moment(filterRangeStart);
  while (currentDate.isBefore(filterRangeEnd, 'day')) {
    const nextPeriod = dataFields.reduce((acc, dataField) => ({
      ...acc,
      [dataField]: 0,
    }), {
      periodStart: moment(currentDate),
    });
    switch (timeseries) {
      case 'weekly':
        currentDate.add(1, 'weeks');
        break;
      case 'monthly':
        currentDate.add(1, 'months');
        break;
      case 'yearly':
        currentDate.add(1, 'years');
        break;
      default:
        throw new Error(`'Unknown timeseries ${timeseries}`);
    }
    if (currentDate.isSameOrBefore(filterRangeEnd, 'day')) {
      nextPeriod.periodEnd = moment(currentDate);
    } else {
      nextPeriod.periodEnd = moment(filterRangeEnd);
    }
    let dayDifference = nextPeriod.periodStart.diff(nextPeriod.periodEnd, 'days');
    dayDifference = Math.floor(dayDifference / 2);
    nextPeriod.date = moment(nextPeriod.periodStart);
    nextPeriod.date.add(dayDifference, 'days');
    periods.push(nextPeriod);
  }
  return results.map(({ title, data }) => {
    const copyData = (data || []).map(item => ({
      ...item,
      periodStart: moment(item.periodStart),
      periodEnd: moment(item.periodEnd),
    })).sort((a, b) => a.periodStart.diff(b.periodStart, 'days'));
    let dataIndex = 0;
    return {
      title: title || fallbackTitle,
      data: periods.map((p) => {
        const nextP = { ...p };
        while (dataIndex < copyData.length) {
          if (copyData[dataIndex].periodStart.isAfter(nextP.periodEnd)) {
            break;
          }
          if (copyData[dataIndex].periodStart.isAfter(nextP.periodStart)) {
            for (let i = 0; i < dataFields.length; i += 1) {
              nextP[dataFields[i]] += copyData[dataIndex][dataFields[i]] || 0;
            }
          }
          dataIndex += 1;
        }
        return nextP;
      }),
    };
  });
}; */

export default durationPriceDateNormalize;
