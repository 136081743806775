import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Switch from 'react-ios-switch';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import Button from 'reactstrap/es/Button';

import DataPaginationTable from '../../../../shared/components/table/DataPaginationTable';
import employeeType from '../types/employee';
import Loading from '../../../../shared/components/Loading';
import * as ProjectActions from '../../../../store/actions/tenant/projects';

const simpleFormatter = ({ value }) => (
  <div>{value || ''}</div>
);

simpleFormatter.propTypes = { value: PropTypes.string };
simpleFormatter.defaultProps = { value: '' };

class EmployeesTable extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retry: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(employeeType.isRequired).isRequired,
    totalEmployees: PropTypes.number.isRequired,
    toggleEditEmployee: PropTypes.func.isRequired,
    editMatter: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    toggleEmployeeStatus: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  get heads() {
    const { t } = this.props;
    return [
      { key: 'fname', name: `${t('employee_form.first_name')}`, sortable: true },
      { key: 'lname', name: `${t('employee_form.last_name')}`, sortable: true },
      { key: 'email', name: `${t('employee_form.email')}`, sortable: true },
      { key: 'identityNumber', name: `${t('employee_form.identity_number')}`, formatter: simpleFormatter },
      { key: 'phone', name: `${t('employee_form.phone')}`, formatter: simpleFormatter },
      {
        key: 'actions',
        name: 'Actions',
        width: 176,
        /* cellClass: 'employees-page__details-cell', */
        formatter: ({ row }) => (
          <div>
            <Button
              type="button"
              size="sm"
              className="icon"
              outline
              onClick={() => this.toggleEditEmployee(row)}
            >
              <p><SquareEditOutlineIcon /> {t('employee_form.edit_employee')}</p>
            </Button>
          </div>
        ),
      },
    ];
  }

  toggleEditEmployee = (employee) => {
    const { toggleEditEmployee } = this.props;
    toggleEditEmployee(employee);
  };

  handleAddMatter = (employee) => {
    const { editMatter } = this.props;
    const { _id, name } = employee;
    editMatter({
      _id: 'new',
      employeeId: { value: _id, label: name },
    });
  };

  toggleEmployeeStatus = (employee) => {
    const { toggleEmployeeStatus } = this.props;
    toggleEmployeeStatus(employee);
  };

  onSorting = (sortColumnRaw, sortDirectionRaw) => {
    const { updateSort } = this.props;
    let sortDirection = sortDirectionRaw.toLowerCase();
    let sortColumn = sortColumnRaw;
    if (sortDirection === 'none') {
      sortDirection = '';
      sortColumn = '';
    }
    updateSort(sortColumn, sortDirection);
  };

  render() {
    const {
      loading, loaded, error, retry, employees, totalEmployees, sortBy, dir, t,
    } = this.props;

    return (
      <>
        <Loading loaded={loaded} loading={loading} error={error} retry={retry}>
          <DataPaginationTable
            heads={this.heads}
            rows={employees}
            onSorting={this.onSorting}
            sortColumn={sortBy}
            sortDirection={dir ? dir.toUpperCase() : 'NONE'}
            rowKey="_id"
          />
          {
            totalEmployees === 0 ? (
              <div>
                {t('employee_list.filter_error')}.
              </div>
            ) : null
          }
        </Loading>
      </>
    );
  }
}

const mapDispatchToProps = {
  editMatter: ProjectActions.editProject,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(EmployeesTable));
