import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Field, FieldArray, reduxForm, formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import EmailPlusIcon from 'mdi-react/EmailPlusIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import { Row, Col, Button } from 'reactstrap';

const renderAddresses = ({ fields, t }) => (
  <>
    {
      fields.map((address, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="form__form-group" key={`customer_address_${index}`}>
          {/* <span className="form__form-group-label">Address #{index + 1}</span> */}
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MapMarkerIcon />
            </div>
            <Field
              name={address}
              type="text"
              component="input"
              placeholder={t('customer_form_address.address')}
            />
            <button
              className="form__form-group-button"
              type="button"
              onClick={() => fields.remove(index)}
            ><CloseIcon />
            </button>
          </div>
        </div>
      ))
    }
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Button type="button" onClick={() => fields.push('')} outline size="sm">
          <MapMarkerPlusIcon />
          {t('customer_form_address.add_address')}
        </Button>
      </div>
    </div>
  </>
);
renderAddresses.propTypes = {
  fields: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

const CustomerFormAddresses = ({ fields, t }) => (
  <Row>
    <Col xs={12}>
      <FieldArray
        name="addresses"
        component={renderAddresses}
        t={t}
      />
    </Col>
  </Row>
);

CustomerFormAddresses.propTypes = {
  fields: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

const selector = formValueSelector('tenant_customer_form');
const mapStateToProps = (state) => {
  let type = selector(state, 'type') || '';
  if (typeof type === 'object') type = type.value || '';
  return {
    type,
  };
};

export default connect(
  mapStateToProps,
)(
  reduxForm({
    form: 'tenant_customer_form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(withTranslation('common')(CustomerFormAddresses)),
);
