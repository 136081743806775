import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const renderSwitchInput = ({
  extraOnChange,
  input: {
    onChange, name, value, defaultValue,
  }, meta: { touched, error, warning }, t,
}) => {
  let finalValue = value;
  if (typeof value === 'undefined') {
    finalValue = defaultValue;
  } else if (!value) {
    finalValue = false;
  } else if (typeof value === 'string') {
    if (value === 'active') {
      finalValue = true;
    } else if (value === 'potential') {
      finalValue = false;
    } else {
      throw new Error(`Unknown string option, '${value}' in switch`);
    }
  }
  finalValue = finalValue || false;
  return (
    <div className="form__form-group-input-wrap d-flex align-items-center">
      <Switch
        name={name}
        checked={finalValue}
        onChange={(newValue) => {
          if (extraOnChange) {
            extraOnChange(newValue);
          }
          return onChange(newValue);
        }}
      />
      {touched
    && ((error && <span className="form__form-group-error">{t(error)}</span>)
      || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
    </div>
  );
};

renderSwitchInput.propTypes = {
  extraOnChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.bool,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

renderSwitchInput.defaultProps = {
  extraOnChange: undefined,
  meta: null,
};

export default withTranslation('common')(renderSwitchInput);
