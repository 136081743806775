import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'reactstrap';
import PencilIcon from 'mdi-react/PencilIcon';

import DeleteIcon from 'mdi-react/DeleteIcon';
import Loading from '../../../shared/components/Loading';

import * as CustomerActions from '../../../store/actions/tenant/customers';
import { subTypeToLabel, typeToLabel } from './constants/options';
import CustomerDeletePrompt from './components/CustomerDeletePrompt';

const CustomerLeftSidebar = ({
  loading, loaded, error, customer, getCurrentCustomer, editCustomer, t,
}) => {
  const { customerId } = useParams();

  const loadCurrentCustomer = () => {
    getCurrentCustomer({ _id: customerId });
  };

  useEffect(() => {
    loadCurrentCustomer();
  }, [customerId]);

  const {
    _id, name, sector, peopleInCharge, type, subtype, taxNumber, taxOffice, addresses, emails, phones,
    isActive, created,
  } = customer || {};

  const editSelf = () => {
    editCustomer({
      ...customer,
    });
  };

  const [deletingCustomer, setDeletingCustomer] = useState(null);

  return (
    <Loading
      loading={loading}
      loaded={loaded}
      error={error}
      retry={loadCurrentCustomer}
    >
      <div className="sidebar-details">
        <Link to={`/customers/${customerId}`}>
          <h4>
            {t('customer_left_sidebar.customer')}: { name }
          </h4>
        </Link>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.sector')}
          </h6>
          <p className="sidebar-details__block-content">
            { sector || ''}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.people_in_charge')}
          </h6>
          <p className="sidebar-details__block-content">
            { peopleInCharge || ''}
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.type')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(typeToLabel(type)) }
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.subtype')}
          </h6>
          <p className="sidebar-details__block-content">
            { t(subTypeToLabel(subtype)) }
          </p>
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            { type === 'personal' ? t('customer_left_sidebar.id_number') : t('customer_left_sidebar.tax_number') }
          </h6>
          <p className="sidebar-details__block-content">
            { taxNumber || ''}
          </p>
        </div>
        {
          type === 'corporate' ? (
            <div className="sidebar-details__block">
              <h6 className="sidebar-details__block-title">
                {t('customer_left_sidebar.tax_office')}
              </h6>
              <p className="sidebar-details__block-content">
                { taxOffice || ''}
              </p>
            </div>
          ) : null
        }
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.addresses')}
          </h6>
          {
            (addresses || []).map(address => (
              <p className="sidebar-details__block-content">
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${
                    encodeURIComponent(address || '')}`}
                  rel="noopener noreferrer nofollow"
                >
                  { address || ''}
                </a>
              </p>
            ))
          }
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.emails')}
          </h6>
          {
            (emails || []).map(email => (
              <p className="sidebar-details__block-content">
                <a href={`mailto:${email || ''}`}>
                  { email || ''}
                </a>
              </p>
            ))
          }
        </div>
        <div className="sidebar-details__block">
          <h6 className="sidebar-details__block-title">
            {t('customer_left_sidebar.phones')}
          </h6>
          {
            (phones || []).map(phone => (
              <p className="sidebar-details__block-content">
                <a href={`tel:${phone || ''}`}>
                  {phone || ''}
                </a>
              </p>
            ))
          }
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="secondary"
            onClick={editSelf}
            outline
            className="icon mb-1"
          >
            <p><PencilIcon /> {t('customer_left_sidebar.edit_customer')}</p>
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            color="danger"
            onClick={() => setDeletingCustomer(customer || { _id: customerId, name })}
            outline
            className="icon"
          >
            <p><DeleteIcon /> {t('customer_left_sidebar.delete_customer')}</p>
          </Button>
        </div>
        <CustomerDeletePrompt
          customer={deletingCustomer}
          onHideModal={() => setDeletingCustomer(null)}
        />
      </div>
    </Loading>
  );
};


CustomerLeftSidebar.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  getCurrentCustomer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  editCustomer: PropTypes.func.isRequired,
};

CustomerLeftSidebar.defaultProps = {
  error: '',
  customer: null,
};

const mapStateToProps = state => ({
  loading: state.customers.loadingCurrent,
  loaded: state.customers.loadedCurrent,
  error: state.customers.errorCurrent,
  customer: state.customers.currentCustomer,
});

const mapDispatchToProps = {
  getCurrentCustomer: CustomerActions.getCurrentCustomerRequest,
  editCustomer: CustomerActions.editCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CustomerLeftSidebar));
