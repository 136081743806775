import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/es/Button';
import MailIcon from 'mdi-react/MailIcon';
import { useEmailTemplate } from '../hooks';

const EmailButton = ({
  user, subject, htmlBody, children, ...props
}) => {
  const href = useEmailTemplate(user, subject, htmlBody);
  return (
    <Button
      tag="a"
      href={href}
      download={`${subject || 'email'}.eml`}
      {...props}
    >
      { children }
    </Button>
  );
};

EmailButton.propTypes = {
  user: PropTypes.string,
  subject: PropTypes.string,
  htmlBody: PropTypes.string,
  children: PropTypes.node,
};

EmailButton.defaultProps = {
  user: '',
  subject: '',
  htmlBody: '',
  children: null,
};


export default EmailButton;
