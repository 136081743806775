import { FETCH_REQUEST } from '../../sharedTypes';
import { RESEND_EMAIL_VERIFICATION_TENANT, VERIFY_EMAIL_TENANT } from '../../fetchTypes';

export const verifyEmail = data => ({
  type: FETCH_REQUEST,
  fetchType: VERIFY_EMAIL_TENANT,
  data,
});

export const resendEmailVerification = data => ({
  type: FETCH_REQUEST,
  fetchType: RESEND_EMAIL_VERIFICATION_TENANT,
  data,
});
