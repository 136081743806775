import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

export const mapDurationValue = (rawValue) => {
  const value = `${rawValue}`;
  let h; let
    m;
  if (/[hm]/.test(value)) {
    if (/[h]/.test(value)) {
      h = parseFloat(value.substr(0, value.indexOf('h')));
    }
    if (/[m]/.test(value)) {
      if (/[h]/.test(value)) {
        m = parseFloat(value.substr(value.indexOf('h') + 1, value.indexOf('m')));
      } else {
        m = parseFloat(value.substr(0, value.indexOf('m')));
      }
    }
    m += h - Math.floor(h);
    h = Math.floor(h);
  } else if (/[sd]/.test(value)) {
    if (/[s]/.test(value)) {
      h = parseFloat(value.substr(0, value.indexOf('s')));
    }
    if (/[d]/.test(value)) {
      if (/[s]/.test(value)) {
        m = parseFloat(value.substr(value.indexOf('s') + 1, value.indexOf('d')));
      } else {
        m = parseFloat(value.substr(0, value.indexOf('d')));
      }
    }
  } else if (/[.,]/.test(value)) {
    const [x, y] = value.split(/[.,]/);
    h = parseInt(x, 10);
    m = 60 * parseFloat(`.${y}`);
  } else if (/:/.test(value)) {
    const [x, y] = value.split(':');
    h = parseInt(x, 10);
    m = parseInt(y, 10);
  } else if (value) {
    h = parseInt(value, 10);
  }
  if (!h) { h = 0; }
  if (!m) { m = 0; }
  m = 6 * Math.ceil(m / 6);
  h += Math.floor(m / 60);
  m %= 60;
  m = `${m}`;
  if (m.length === 1) {
    m = `0${m}`;
  }
  return `${h}:${m}`;
};

const renderHourDuration = ({
  input: { name, value, onChange }, meta: { touched, error, warning }, placeholder, type, className, dir, t,
}) => {
  useEffect(() => {
    onChange(mapDurationValue(value));
    // initialize first value
  }, []);
  return (
    <div className="form__form-group-input-wrap">
      <input
        autoComplete="off"
        autoCorrect="off"
        id="duration-input"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={e => onChange(mapDurationValue(e.target.value))}
        placeholder={placeholder}
        type={type}
        className={className}
      />
      <UncontrolledTooltip target="duration-input" dir={dir} placement="bottom">
        {t('form_fields.hour_duration.tooltip')}
      </UncontrolledTooltip>
      {touched
      && ((error && <span className="form__form-group-error">{t(error)}</span>)
        || (warning && <span className="form__form-group-error text-warning">{t(warning)}</span>))}
    </div>
  );
};

renderHourDuration.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  dir: PropTypes.string,
};

renderHourDuration.defaultProps = {
  meta: null,
  placeholder: '',
  type: 'text',
  className: '',
  dir: 'ltr',
};

export default withTranslation('common')(renderHourDuration);
