import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useParams } from 'react-router';
import {
  Button, Card, CardHeader, CardTitle, CardBody, Row, Col, Container,
} from 'reactstrap';
import moment from 'moment';
import Switch from 'react-switch';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import TrashCanIcon from 'mdi-react/TrashCanIcon';
import EditIcon from 'mdi-react/EditIcon';
import { caseType } from './caseType';

import * as CaseActions from '../../../../store/actions/tenant/cases';

import TimeLineItem from '../../../../shared/components/TimeLineItem';
import renderSwitchInput from '../../../../shared/components/form/Switch';
import RenderSelectField from '../../../../shared/components/form/Select';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderMaskedInput from '../../../../shared/components/form/MaskedInput';

import { caseNumberMask, decisionNumberMask } from './caseMasks';
import Loading from '../../../../shared/components/Loading';
import { isRequired } from '../../../../lib/FieldWarnings/FieldWarnings';
import renderField from '../../../../shared/components/form/FormField';
// const caseNumberMask =
// [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', '/', ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
// const decisionNumberMask =
// [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, ' ', '/', ' ', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, 'K'];

const caseNameRequired = isRequired('case_card.case_name');
const caseNumberRequired = isRequired('case_card.case_number');
const caseDateRequired = isRequired('case_card.case_date');
const decisionNameRequired = isRequired('case_card.decision_name');
const decisionNumberRequired = isRequired('case_card.decision_number');
const decisionDateRequired = isRequired('case_card.decision_date');

const CaseCard = ({
  case: {
    loading, loaded, error, updating, updateError, details, isEditing,
  }, handleSubmit, onRemove, isComplete, editedCaseDate, onEdit, t,
}) => {
  const {
    _id, caseName, caseNumber, caseDate,
    decisionName, decisionNumber, decisionDate,
    isActive, isSuccess,
  } = details || {};

  return (
    <Loading loaded={loaded} loading={loading} error={error}>
      <TimeLineItem type="work" title={caseName} date={moment(editedCaseDate || caseDate).fromNow()}>
        <div style={{
          position: 'absolute', top: 0, right: 0, margin: 8,
        }}
        >
          {
            isEditing ? null : (
              <Button
                type="button"
                color="primary"
                className="rounded mx-1"
                style={{
                  width: 32,
                  height: 32,
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                  margin: 0,
                }}
                onClick={onEdit}
                outline
              >
                <EditIcon className="w-100" style={{ color: '#000', margin: 0, height: 24 }} />
              </Button>
            )
          }
          { onRemove && (
            <Button
              type="button"
              color="danger"
              className="rounded mx-1"
              style={{
                width: 32,
                height: 32,
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                margin: 0,
              }}
              onClick={onRemove}
              outline
            >
              <TrashCanIcon className="w-100" style={{ color: '#000', margin: 0, height: 24 }} />
            </Button>
          ) }
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm={10}>
                { isEditing ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label required">{t('case_card.case_name')}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                      </div>
                      <Field
                        name="caseName"
                        component={renderField}
                        type="text"
                        placeholder={t('case_card.case_name')}
                        validate={caseNameRequired}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <b> { `${t('case_card.case_name')}: ` } </b>
                    { caseName }
                  </>
                )}
              </Col>
              <Col sm={2}>
                {isEditing ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('case_card.completion')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="isComplete"
                        component={renderSwitchInput}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <b> { `${t('case_card.completion_status')}: ` } </b>
                    { details.isComplete ? t('case_card.completed') : t('case_card.ongoing') }
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                { isEditing ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label required">{t('case_card.case_number')}</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                      </div>
                      <Field
                        mask={caseNumberMask}
                        name="caseNumber"
                        component={renderMaskedInput}
                        type="text"
                        placeholder={t('case_card.case_number')}
                        validate={caseNumberRequired}
                      />
                      <Field
                        name="caseNumberEnding"
                        component={RenderSelectField}
                        className="form__form-group-input-wrap--error-above"
                        type="text"
                        defaultValue="e"
                        options={[
                          { value: 'e', label: 'E' },
                          { value: 'd', label: 'D' },
                          { value: 't', label: 'T' },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <b> { `${t('case_card.case_number')}: `} </b>
                    { caseNumber }
                  </>
                ) }
              </Col>
              <Col sm={6}>
                { isEditing ? (
                  <div className="form__form-group">
                    <span className="form__form-group-label required">{t('case_card.case_date')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="caseDate"
                        component={RenderDatePickerField}
                        validate={caseDateRequired}
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <b> { `${t('case_card.case_date')}: `} </b>
                    { moment(caseDate).format('LL') }
                  </>
                ) }
              </Col>
            </Row>
            { (isEditing ? isComplete : details.isComplete) ? (
              <>
                <hr />
                <Row>
                  <Col sm={10}>
                    { isEditing ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label required">{t('case_card.decision_name')}</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                          </div>
                          <Field
                            name="decisionName"
                            component={renderField}
                            type="text"
                            placeholder={t('case_card.decision_name')}
                            validate={decisionNameRequired}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <b> { `${t('case_card.decision_name')}: ` } </b>
                        { decisionName }
                      </>
                    )}
                  </Col>
                  <Col sm={2}>
                    {isEditing ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label required">{t('case_card.result')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="isSuccess"
                            component={renderSwitchInput}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <b> { `${t('case_card.result')}: ` } </b>
                        { isSuccess ? t('case_card.successful') : t('case_card.failed') }
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    { isEditing ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label required">{t('case_card.decision_number')}</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                          </div>
                          <Field
                            mask={decisionNumberMask}
                            name="decisionNumber"
                            component={renderMaskedInput}
                            type="text"
                            placeholder={t('case_card.decision_number')}
                            validate={decisionNumberRequired}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <b> { `${t('case_card.decision_number')}: `} </b>
                        { decisionNumber }
                      </>
                    ) }
                  </Col>
                  <Col sm={6}>
                    { isEditing ? (
                      <div className="form__form-group">
                        <span className="form__form-group-label required">{t('case_card.decision_date')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="decisionDate"
                            component={RenderDatePickerField}
                            validate={decisionDateRequired}
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <b> { `${t('case_card.decision')}: `} </b>
                        { moment(decisionDate).format('LL') }
                      </>
                    ) }
                  </Col>
                </Row>
              </>
            ) : null }
            { isEditing ? (
              <Row>
                <Col xs={12}>
                  <Loading loading={updating} error={updateError}>
                    <Button type="submit" color="primary">
                      {t('case_card.save')}
                    </Button>
                  </Loading>
                </Col>
              </Row>
            ) : null }
          </Container>
        </form>
      </TimeLineItem>
    </Loading>
  );
};

CaseCard.propTypes = {
  case: PropTypes.shape({
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    error: PropTypes.string,
    updateError: PropTypes.string,
    details: caseType,
  }).isRequired,
  onRemove: PropTypes.func,
  isComplete: PropTypes.bool,
  editedCaseDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CaseCard.defaultProps = {
  onRemove: undefined,
  isComplete: false,
  editedCaseDate: null,
};

const caseCardFormComponents = {};

const GenericCaseCard = (props) => {
  const {
    id, case: theCase, canRemove, isComplete, editedCaseDate, updateCase, createCase, editCase, removeNewCase, t,
  } = props;
  let { caseNumber = '' } = theCase.details || {};
  const formId = `tenant_case_form-${id}`;
  if (!caseCardFormComponents[formId]) {
    caseCardFormComponents[formId] = reduxForm({
      form: formId,
    })(CaseCard);
  }
  const { matterId } = useParams();
  const caseNumberEnding = caseNumber.substr(-1);
  caseNumber = caseNumber.substr(0, caseNumber.length - 1);
  const CaseCardForm = caseCardFormComponents[formId];
  const handleSubmit = (args) => {
    const payload = {};
    ['caseName', 'caseDate', 'decisionName', 'decisionNumber', 'decisionDate',
      'isActive', 'isComplete', 'isSuccess'].forEach((key) => {
      if (args[key]) {
        payload[key] = args[key];
      }
    });
    ['decisionDate', 'caseDate'].forEach((key) => {
      if (payload[key] instanceof Date) {
        payload[key] = payload[key].toISOString();
      }
    });
    payload.caseNumber = `${args.caseNumber}${
      typeof args.caseNumberEnding === 'object'
        ? args.caseNumberEnding.value : args.caseNumber
    }`;
    if (id === 'new') {
      createCase({ ...payload, matterId });
    } else {
      updateCase({ ...payload, _id: id });
    }
  };
  const handleRemove = () => {
    if (id === 'new') {
      removeNewCase();
    } else {
      updateCase({ isActive: false, _id: id });
    }
  };
  const handleEdit = () => {
    editCase({ _id: id });
  };
  return (
    <CaseCardForm
      initialValues={{
        ...(theCase.details || {}),
        caseNumber,
        caseNumberEnding,
      }}
      case={theCase}
      onSubmit={handleSubmit}
      onRemove={canRemove ? handleRemove : undefined}
      onEdit={handleEdit}
      isComplete={isComplete}
      editedCaseDate={editedCaseDate}
      t={t}
    />
  );
};

GenericCaseCard.propTypes = {
  id: PropTypes.string.isRequired,
  canRemove: PropTypes.bool,
  case: PropTypes.shape({
    isEditing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    error: PropTypes.string,
    updateError: PropTypes.string,
    details: caseType,
  }).isRequired,
  isComplete: PropTypes.bool,
  editedCaseDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  updateCase: PropTypes.func.isRequired,
  createCase: PropTypes.func.isRequired,
  editCase: PropTypes.func.isRequired,
  removeNewCase: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

GenericCaseCard.defaultProps = {
  canRemove: false,
  isComplete: false,
  editedCaseDate: null,
};

const mapStateToProps = (state, {
  id,
}) => {
  const formId = `tenant_case_form-${id}`;
  let isComplete = formValueSelector(formId)(state, 'isComplete');
  const caseDate = formValueSelector(formId)(state, 'caseDate');
  if (typeof isComplete !== 'boolean') {
    isComplete = false;
  }
  return {
    isComplete,
    editedCaseDate: caseDate,
    case: state.cases.casesById[id],
  };
};

const mapDispatchToProps = {
  updateCase: CaseActions.updateCaseRequest,
  createCase: CaseActions.createCaseRequest,
  editCase: CaseActions.editCase,
  removeNewCase: CaseActions.removeNewCase,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(GenericCaseCard));
