import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../shared/components/Modal';

import * as CustomerActions from '../../../../store/actions/tenant/customers';

const CustomerDeletePrompt = ({
  customer, onHideModal, softDeleteCustomer,
}) => {
  const { _id } = customer || {};
  let { name } = customer || {};
  name = name || _id;
  const handleDeleteConfirm = () => {
    softDeleteCustomer(customer);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('customer_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!customer}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('customer_delete_prompt.message')}
      </h4>
      <p>{name}</p>
    </ModalComponent>
  );
};

CustomerDeletePrompt.propTypes = {
  customer: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteCustomer: PropTypes.func.isRequired,
};

CustomerDeletePrompt.defaultProps = {
  customer: null,
};

const mapDispatchToProps = {
  softDeleteCustomer: CustomerActions.softDeleteCustomerRequest,
};

export default connect(null, mapDispatchToProps)(CustomerDeletePrompt);
