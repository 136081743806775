import {
  EDIT_TASK, FETCH_REQUEST, CHANGE_TASK_FILTER, CLEAR_TASKS, CLEAR_TASK_FILTER,
} from '../../sharedTypes';
import {
  GET_TASKS_TENANT, CREATE_TASK_TENANT, UPDATE_TASK_TENANT,
  RELOAD_TASK_TENANT, DELETE_TASK_TENANT, DELETE_TASK_WITH_CHILDREN_TENANT, SOFT_DELETE_TASK_TENANT,
} from '../../fetchTypes';

export const changeTaskFilter = data => ({
  type: CHANGE_TASK_FILTER,
  data,
});

export const clearTaskFilter = () => ({
  type: CLEAR_TASK_FILTER,
});

export const clearTasks = () => ({
  type: CLEAR_TASKS,
});

export const getTasksRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_TASKS_TENANT,
  data,
});

export const reloadTaskRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: RELOAD_TASK_TENANT,
  data,
});

export const editTask = data => ({
  type: EDIT_TASK,
  data,
});

export const createTaskRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_TASK_TENANT,
  data,
});

export const updateTaskRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_TASK_TENANT,
  data,
});

export const softDeleteTaskRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_TASK_TENANT,
  data,
});

export const deleteTaskRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_TASK_TENANT,
  data,
});

export const deleteTaskWithChildrenRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: DELETE_TASK_WITH_CHILDREN_TENANT,
  data,
});
