import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Loading from '../../../shared/components/Loading';

import { selectTaskById } from '../../../store/reducers/tasks';

const TaskTopbarHeader = ({ task: { loading, details } }) => {
  const { id: taskId } = useParams();
  const { project = {}, title: taskTitle } = details || {};
  const projectId = project.id || project._id || '';
  const projectTitle = project.title || '';
  const customer = project.customer || project.customerId || {};
  const customerId = customer.id || customer._id || '';
  const customerName = customer.name || '';

  return (
    <Loading loading={loading} loaded={!loading}>
      <div className="topbar__header-group">
        <Link to={`/tasks/${taskId}`}>
          <span className="topbar__header-title">
            { taskTitle || 'Current Task' }
          </span>
        </Link>
        <Link to={`/matters/${projectId}`}>
          <span className="topbar__header-subtitle">
            { projectTitle || 'AdvoPASS' }
          </span>
        </Link>
        <Link to={`/customers/${customerId}`}>
          <i className="topbar__header-subtitle">
            ({ customerName || 'John Doe' })
          </i>
        </Link>
      </div>
    </Loading>
  );
};

TaskTopbarHeader.propTypes = {
  task: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    details: PropTypes.shape(),
  }).isRequired,
};

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  task: selectTaskById(state, id),
});

export default connect(mapStateToProps)(TaskTopbarHeader);
