import withFetch from './withFetch';
import {
  CREATE_EXTRA_TENANT, GET_CURRENT_EXTRAS_TENANT, GET_EXTRAS_TENANT,
  SOFT_DELETE_EXTRA_TENANT, UPDATE_EXTRA_TENANT,
} from '../fetchTypes';
import { EDIT_EXTRA, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingExtra: null,
  totalExtras: 0,
  extras: [],
  currentExtra: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXTRAS_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_EXTRAS_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_EXTRA_TENANT:
    case UPDATE_EXTRA_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXTRAS_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        extras: action.data.extras,
        totalExtras: action.data.extras.length,
      };
    case GET_CURRENT_EXTRAS_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentExtra: action.data.extra,
      };
    case CREATE_EXTRA_TENANT:
    case UPDATE_EXTRA_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingExtra: null,
      };
    case SOFT_DELETE_EXTRA_TENANT:
      return {
        ...state,
        extras: state.extras.filter(({ _id }) => _id !== action.reqData._id),
        totalExtras: state.totalExtras - 1,
        currentExtra: state.currentExtra && state.currentExtra._id === action.reqData._id ? {
          ...state.currentExtra,
          isActive: false,
        } : state.currentExtra,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXTRAS_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_EXTRAS_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_EXTRA_TENANT:
    case UPDATE_EXTRA_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_EXTRA:
      return {
        ...state,
        editingExtra: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
