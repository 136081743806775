import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import AdminRouter from '../Admin/Router';
import TenantRouter from '../Tenant/Router';
import { ADMIN_MODE } from '../../constants';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/" component={ADMIN_MODE ? AdminRouter : TenantRouter} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
