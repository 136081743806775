import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import SharedActions from '../../../store/actions/shared';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

class Layout extends React.PureComponent {
  static propTypes = {
    sidebar: SidebarProps.isRequired,
    changeThemeToDark: PropTypes.func.isRequired,
    changeThemeToLight: PropTypes.func.isRequired,
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const {
      sidebar,
      changeThemeToDark, changeThemeToLight,
      changeMobileSidebarVisibility, changeSidebarVisibility,
    } = this.props;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Sidebar
          sidebar={sidebar}
          changeToDark={changeThemeToDark}
          changeToLight={changeThemeToLight}
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  changeThemeToDark: SharedActions.Theme.changeThemeToDark,
  changeThemeToLight: SharedActions.Theme.changeThemeToLight,
  changeMobileSidebarVisibility: SharedActions.Sidebar.changeMobileSidebarVisibility,
  changeSidebarVisibility: SharedActions.Sidebar.changeSidebarVisibility,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Layout),
);
