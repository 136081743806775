import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Button } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PencilIcon from 'mdi-react/PencilIcon';

import * as TaskActions from '../../../../store/actions/tenant/tasks';
import { selectTaskById, selectFilter, filterToParams } from '../../../../store/reducers/tasks';

import Loading from '../../../../shared/components/Loading';
import { mapDurationValue } from '../../../../shared/components/form/HourDuration';


const mapStateToProps = (state, { taskId }) => {
  const task = selectTaskById(state, taskId);
  const { parentId } = task.details || {};
  return {
    task,
    parent: selectTaskById(state, parentId),
    filter: selectFilter(state),
  };
};

const mapDispatchToProps = {
  getTasks: TaskActions.getTasksRequest,
  reloadTask: TaskActions.reloadTaskRequest,
  editTask: TaskActions.editTask,
};


const EmployeeTreeItem = connect(mapStateToProps, mapDispatchToProps)(({
  taskId, showProjectTitle, task, parent, filter, getTasks, reloadTask, editTask,
}) => {
  const {
    loading, loaded, error, childrenLoading, childrenLoaded, childrenLoadError,
    totalChildrenTasks, childrenTasks, details,
  } = task;

  const {
    parentId, status, project = {}, startDateTime, duration, estimatedHrs,
  } = details || {};

  let { title } = details || {};
  if (!title) {
    title = '';
  }
  if (title.length > 40) {
    title = `${title.substr(0, 37)}...`;
  }

  const projectId = project.id || project._id;
  const projectTitle = project.title || '';
  const customer = project.customer || project.customerId || {};
  const customerId = customer.id || customer._id || '';
  const customerName = customer.name || '';

  const [childrenVisible, setChildrenVisible] = useState(false);

  const loadChildren = () => {
    getTasks(filterToParams(filter, {
      parentId: taskId,
      start: childrenTasks.length,
      limit: 50,
    }));
  };

  const addSubtask = () => {
    editTask({
      _id: 'new',
      startDateTime: new Date(),
      isBillable: true,
      customerId: { value: customerId, label: customerName },
      projectId: { value: projectId, label: projectTitle },
      selectedProject: project,
      parentId: { value: taskId, label: title },
    });
  };

  const editSelf = () => {
    editTask({
      ...(details || {}),
      duration: mapDurationValue((details || {}).duration || '0:00'),
      customerId: { value: customerId, label: customerName },
      projectId: { value: projectId, label: projectTitle },
      selectedProject: project,
      parentId: { value: parentId || '', label: ((parent || {}).details || {}).title || parentId || 'None' },
    });
  };

  useEffect(() => {
    if (!loaded && !loading && !error) {
      reloadTask({ _id: taskId });
    }
    if (childrenVisible && !childrenLoaded && !childrenLoading && !childrenLoadError) {
      loadChildren();
    }
  });

  const itemHeadClass = classNames({
    'task-tree__item-head': true,
    'task-tree__item-head--open': childrenVisible,
  });

  const date = (startDateTime || duration)
    ? `(${startDateTime ? moment(startDateTime).format('LL') : ''
    }${startDateTime && duration ? ' - ' : ''
    }${duration ? `${duration} hours` : ''}` : '';

  return (
    <Loading
      loading={loading}
      loaded={loaded}
      error={error}
      retry={() => {
        reloadTask({ _id: taskId });
      }}
    >
      <div className={itemHeadClass}>
        <div className="task-tree__item-head-title-block">
          <button
            className="task-tree__item-head-toggle-btn"
            type="button"
            onClick={() => setChildrenVisible(!childrenVisible)}
          >
            <ChevronDownIcon />
          </button>
          <Link to={`/tasks/${taskId}`}>
            <h5>
              { title }
            </h5>
          </Link>
        </div>
        { showProjectTitle && (
          <Link to={`/matters/${project.id || project._id}`}>
            <h5 className="subhead">
              { project.title }
            </h5>
          </Link>
        )}
        <h5 className="subhead mx-1">
          { estimatedHrs ? `${estimatedHrs} hours` : '' }
        </h5>
        <h6 className="subhead mx-1 d-none d-md-block" style={{ marginTop: 1 }}>
          {date}
        </h6>
        <button type="button" onClick={editSelf}>
          <PencilIcon className="mb-1" />
        </button>
      </div>
      {/* <pre>
        { JSON.stringify(details) }
      </pre> */}
      <Collapse isOpen={childrenVisible}>
        <div style={{ marginLeft: 20 }}>
          { childrenTasks.map(childId => childId !== taskId && (
          <EmployeeTreeItem taskId={childId} key={childId} />
          ))}
          <Loading
            loading={childrenLoading}
            loaded={childrenLoaded}
            error={childrenLoadError}
            retry={loadChildren}
          >
            { childrenTasks.length === 0 && (
              <h4 className="subhead ml-3 my-1">No Children</h4>
            )}
            <Button
              type="button"
              color="success"
              onClick={addSubtask}
              className="py-1 px-3 mt-1 ml-2 mb-0"
            >
              Add Subtask
            </Button>
            { totalChildrenTasks > childrenTasks.length ? (
              <div className="d-flex justify-content-center">
                <Button
                  type="button"
                  color="primary"
                  onClick={loadChildren}
                  className="py-1 px-3 mt-1 mb-0"
                >
                  Load More ({ totalChildrenTasks - childrenTasks.length })
                </Button>
              </div>
            ) : null }
          </Loading>
        </div>
      </Collapse>
    </Loading>
  );
});

EmployeeTreeItem.propTypes = {
  taskId: PropTypes.string.isRequired,
  showProjectTitle: PropTypes.bool,
  /* task: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    childrenLoading: PropTypes.bool.isRequired,
    childrenLoaded: PropTypes.bool.isRequired,
    childrenLoadError: PropTypes.string,
    totalChildrenTasks: PropTypes.number.isRequired,
    childrenTasks: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    details: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      hasChild: PropTypes.bool,
      isActive: PropTypes.bool,
      status: PropTypes.oneOf([100, 101, 102, 200]).isRequired,
      startDateTime: PropTypes.string,
      endDateTime: PropTypes.string,
      estimatedHours: PropTypes.number,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
      }),
    }),
  }).isRequired, */
};

EmployeeTreeItem.defaultProps = {
  showProjectTitle: false,
};

export default EmployeeTreeItem;
