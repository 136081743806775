import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Row, Col,
} from 'reactstrap';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import throttle from 'lodash.throttle';
import RenderDatePickerField from '../../../../shared/components/form/DatePicker';
import RenderSelectField from '../../../../shared/components/form/Select';
import { ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import SearchBox from '../../../../shared/components/SearchBox';
import FilterSidebar from '../../../../shared/components/filter/FilterSidebar';
import { statusFilters, typesFilter } from '../constants/options';

const sortByOptions = [
  { value: '', label: 'No sort' },
  { value: 'name', label: 'Name' },
  { value: 'type', label: 'Type' },
  { value: 'created', label: 'Date' },
];

const dirOptions = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

class EmployeesFilter extends Component {
  static propTypes = {
    updateFilter: PropTypes.func.isRequired,
    activesFilter: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    keywords: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
  };

  constructor(props) {
    super(props);
    this.handleKeywordsChange = throttle(this.handleKeywordsChange, 400);
  }

  handleFilterChange = (event) => {
    const { updateFilter } = this.props;
    updateFilter(event.target.value, event.target.name);
  };

  handleDateChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  handleTypeChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'type');
  };

  handleStatusChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'activesFilter');
  };

  handleSortByChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'sortBy');
  }

  handleDirChange = ({ value }) => {
    const { updateFilter } = this.props;
    updateFilter(value, 'dir');
  }

  handleKeywordsChange = (value, name) => {
    const { updateFilter } = this.props;
    updateFilter(value, name);
  };

  render() {
    const {
      activesFilter, startDate, endDate, keywords, type, sortBy, dir, theme, t, rtl,
    } = this.props;

    return (
      <FilterSidebar>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.keyword')}</p>
            <SearchBox
              name="keywords"
              defaultValue={keywords}
              onChange={this.handleKeywordsChange}
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.start_date')}</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'startDate',
              }}
              defaultValue={startDate}
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.end_date')}</p>
            <RenderDatePickerField
              input={{
                onChange: this.handleDateChange,
                name: 'endDate',
              }}
              defaultValue={endDate}
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.status')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleStatusChange,
                name: 'status',
              }}
              options={statusFilters}
              defaultValue={activesFilter || statusFilters[0]}
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.type')}</p>
            <RenderSelectField
              input={{
                onChange: this.handleTypeChange,
                name: 'type',
              }}
              options={typesFilter}
              defaultValue={type || typesFilter[0]}
            />
          </Col>
        </Row>
        <Row className="customers-page__filter-row form">
          <Col>
            <p className="title">{t('employees_filter.sort_by')}</p>
            <div className="d-flex sortInput">
              <RenderSelectField
                input={{
                  onChange: this.handleSortByChange,
                  name: 'sortBy',
                }}
                options={sortByOptions}
                defaultValue={sortBy || sortByOptions[0]}
              />
              <RenderSelectField
                input={{
                  onChange: this.handleDirChange,
                  name: 'dir',
                }}
                options={dirOptions}
                defaultValue={dir || dirOptions[0]}
              />
            </div>
          </Col>
        </Row>
      </FilterSidebar>
    );
  }
}
export default withTranslation('common')(EmployeesFilter);
