import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as ProjectActions from '../../../../store/actions/tenant/projects';

function useCurrentProject() {
  const { matterId: projectId } = useParams();
  const dispatch = useDispatch();
  const reloadProject = () => dispatch(ProjectActions.getCurrentProjectRequest({ _id: projectId }));
  useEffect(() => {
    reloadProject();
  }, [projectId]);
  const loading = useSelector(state => state.projects.loadingCurrent || false);
  const loaded = useSelector(state => state.projects.loadedCurrent || false);
  const error = useSelector(state => state.projects.errorCurrent || '');
  const project = useSelector(state => state.projects.currentProject || null);
  return {
    loading,
    loaded,
    error,
    project,
    reloadProject,
  };
}
export default useCurrentProject;
