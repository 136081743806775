import auth from './auth';
import emailVerification from './emailVerification';
import resetPassword from './resetPassword';
import profile from './profile';
import register from './register';
import customers from './customers';
import projects from './projects';
import tasks from './tasks';
import cases from './cases';
import expenses from './expenses';
import expensePayments from './expensePayments';
import extras from './extras';
import bills from './bills';
import reports from './reports';
import messages from './messages';
import employees from './employees';

const fetcherList = [
  auth,
  emailVerification,
  resetPassword,
  profile,
  register,
  customers,
  projects,
  tasks,
  cases,
  expenses,
  expensePayments,
  extras,
  bills,
  reports,
  messages,
  employees,
];

export default fetchType => fetcherList
  .reduce((currentFetcher, nextFetcher) => currentFetcher || nextFetcher(fetchType), null);
