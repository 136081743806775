import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withTranslation } from 'react-i18next';
import * as Countries from '../../../../lib/Countries';
import RenderSelectField from '../../../../shared/components/form/Select';
import Loading from '../../../../shared/components/Loading';
import renderPhoneInput from '../../../../shared/components/form/PhoneInput';
import renderField from '../../../../shared/components/form/FormField';
import {
  zipCode,
  isRequired,
} from '../../../../lib/FieldWarnings/FieldWarnings';
import renderMaskedInput from '../../../../shared/components/form/MaskedInput';
import RenderCheckBoxField from '../../../../shared/components/form/CheckBox';

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

class RegisterFormThree extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  countryRequired = isRequired('register.country');

  cityRequired = isRequired('register.city');

  // addressRequired = isRequired('register.address');

  constructor(props) {
    super(props);
    this.state = {
      cityList: [],
      defaultPhoneCodeCountry: 'tr',
    };
    this.countryList = Countries.countriesWithCities.map(countryName => ({
      value: countryName,
      label: countryName,
    }));
    this.countryCodeList = Countries.phoneCodeList.map(
      ({ countryName, countryPhoneCode }) => ({
        value: countryPhoneCode,
        label: `${countryName} (${countryPhoneCode})`,
      }),
    );
  }

  selectCountry = (e, { value }) => {
    this.setState({
      cityList: Countries.citiesByCountry(value).map(cityName => ({
        value: cityName,
        label: cityName,
      })),
      defaultPhoneCodeCountry: Countries.countryCodeByCountry(
        value,
      ).toLowerCase(),
    });
  };

  render() {
    const {
      handleSubmit, previousPage, loading, t,
    } = this.props;
    const { cityList, defaultPhoneCodeCountry } = this.state;
    return (
      <form className="form wizard__form" onSubmit={handleSubmit}>
        {/* <h3 className="wizard__title">Please, fill your address details</h3> */}
        <div className="form__form-group">
          <span className="form__form-group-label required">
            {t('register.country')}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--select">
              <AccountOutlineIcon />
            </div>
            <Field
              name="country"
              component={RenderSelectField}
              type="text"
              options={this.countryList}
              onChange={this.selectCountry}
              placeholder={t('register.select_country')}
              validate={this.countryRequired}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label required">
            {t('register.city')}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--select">
              <AccountOutlineIcon />
            </div>
            <Field
              name="city"
              component={RenderSelectField}
              type="text"
              options={cityList}
              placeholder={t('register.select_city')}
              validate={this.cityRequired}
            />
          </div>

          <div className="account__have-account">

            <Field
              name="clarification"
              validate={value => (value
                ? undefined
                : t('field_warnings.is_required'))}
              label={(
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://advopass.com/wp-content/uploads/2020/06/advopass-clarification-text.pdf"
                  >
               Aydınlatma metnini
                  </a>{' '} okudum, anladım.
                </p>
)}
              component={RenderCheckBoxField}
              className="colored-click"
            />

            {/* <p>{t('register.already_have_an_account')} <Link to="/login">{t('register.login')}</Link></p> */}
          </div>
        </div>
        {/* <div className="form__form-group">
          <span className="form__form-group-label required">{t('register.address')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--select">
              <AccountOutlineIcon />
            </div>
            <Field
              name="address"
              component={renderField}
              type="text"
              placeholder={t('register.enter_address')}
              validate={this.addressRequired}
            />
          </div>
        </div> */}
        {/* <div className="form__form-group">
          <span className="form__form-group-label required">{t('register.zipcode')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--select">
              <AccountOutlineIcon />
            </div>
            <Field
              name="zipCode"
              component={renderMaskedInput}
              type="text"
              mask={zipCodeMask}
              validate={zipCode}
              placeholder={t('register.enter_zipcode')}
            />
          </div>
        </div> */}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={previousPage}
          >
            {t('register.previous')}
          </Button>
          {loading ? (
            <Loading />
          ) : (
            <Button color="primary" type="submit" className="next">
              {t('register.submit')}
            </Button>
          )}
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'register_tenant_form', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(RegisterFormThree));
