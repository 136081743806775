/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'reactstrap/es/Modal';
import MaskedInput from 'react-text-mask';
import TaskForm from './TaskForm';
import TenantActions from '../../../../store/actions/tenant';
import { RTLProps, ThemeProps } from '../../../../shared/prop-types/ReducerProps';
import { mapDurationValue } from '../../../../shared/components/form/HourDuration';

class TaskModal extends React.PureComponent {
  static propTypes = {
    updating: PropTypes.bool.isRequired,
    createTaskRequest: PropTypes.func.isRequired,
    updateTaskRequest: PropTypes.func.isRequired,
    editTask: PropTypes.func.isRequired,
    editingTask: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    theme: ThemeProps.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    editingTask: null,
  };

  handleSubmit = (data) => {
    const payload = {};
    const { createTaskRequest, updateTaskRequest, editingTask } = this.props;
    ['projectId', 'parentId', 'status', 'taskType']
      .forEach((key) => {
        if (data[key] && typeof data[key] === 'object') {
          payload[key] = data[key].value;
        }
      });
    ['description', 'isBillable', 'startDateTime', 'duration']
      .forEach((key) => {
        payload[key] = data[key];
      });

    if (payload.duration) {
      payload.duration = mapDurationValue(payload.duration);
      const [h, m] = payload.duration.split(':').map(x => parseInt(x, 10));
      payload.duration = (h + (m / 60)).toFixed(2);
    }
    if (typeof payload.status === 'string') {
      payload.status = parseInt(payload.status, 10);
    }
    if (payload.description) {
      payload.title = payload.description;
      if (payload.title.length > 40) {
        payload.title = `${payload.title.substr(0, 36)} ...`;
      }
    }

    payload._id = editingTask._id;
    if (editingTask._id === 'new') {
      createTaskRequest(payload);
    } else {
      updateTaskRequest(payload);
    }
  };

  render() {
    const {
      updating, editingTask, theme, rtl, editTask,
    } = this.props;

    const modalClasses = classNames(
      {
        'todo__add-modal': true,
      },
      theme.className,
      `${rtl.direction}-support`,
    );

    return (
      <Modal
        isOpen={!!editingTask}
        toggle={() => editTask(null)}
        className={modalClasses}
      >
        { editingTask && (
          <TaskForm
            loading={updating}
            onSubmit={this.handleSubmit}
            close={() => editTask(null)}
            initialValues={editingTask}
            isNew={editingTask._id === 'new'}
          />
        ) }
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  updating: state.tasks.updating,
  editingTask: state.tasks.editingTask,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  createTaskRequest: TenantActions.Tasks.createTaskRequest,
  updateTaskRequest: TenantActions.Tasks.updateTaskRequest,
  editTask: TenantActions.Tasks.editTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);
