import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { statusToColor, statusToLabel } from '../constants/options';

const TaskStatusBadge = ({ status, t }) => (
  <Badge color={statusToColor(status)} className="mx-1" pill>
    {t(statusToLabel(status))}
  </Badge>
);


TaskStatusBadge.propTypes = {
  status: PropTypes.oneOf([100, 101, 102, 200, '']),
  t: PropTypes.func.isRequired,
};

TaskStatusBadge.defaultProps = {
  status: '',
};

export default withTranslation('common')(TaskStatusBadge);
