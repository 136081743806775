import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';

import ExpensesTable from './components/ExpensesTable';
import ExpensesTablePagination from './components/ExpensesTablePagination';
import expenseType from './types/project';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import ExpenseList from './components/ExpensesList';
import FilterButton from '../../../shared/components/filter/FilterButton';
import ExpensePaymentDeletePrompt from './components/ExpensePaymentDeletePrompt';

class Expenses extends React.PureComponent {
  static propTypes = {
    getExpensesRequest: PropTypes.func.isRequired,
    editExpense: PropTypes.func.isRequired,
    updateExpense: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    expenses: PropTypes.arrayOf(expenseType).isRequired,
    totalExpenses: PropTypes.number.isRequired,
    editingExpense: PropTypes.shape(),
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    projectId: PropTypes.string,
    currentProject: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      customerId: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  static defaultProps = {
    error: '',
    editingExpense: null,
    projectId: '',
    currentProject: null,
  };

  state = {
    activesFilter: 'actives',
    startDate: null,
    endDate: null,
    keyword: '',
    tenureType: '',
    sortBy: '',
    keywords: '',
    category: '',
    billingType: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
    deletingExpensePayment: null,
  };

  componentDidMount() {
    this.forceReloadExpense();
  }
  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = [
      'activesFilter',
      'startDate',
      'endDate',
      'keyword',
      'tenureType',
      'sortBy',
      'startDateRangeStart',
      'startDateRangeEnd',
      'createdRangeStart',
      'createdRangeEnd',
      'billingType',
      'keywords',
      'dir',
      'currentPage',
      'category',
      'perPage',
    ].reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadExpenses(force);
  }

  toggleExpenseStatus = (expense) => {
    const { updateExpense } = this.props;
    updateExpense({
      _id: expense._id,
      isActive: !expense.isActive,
    });
  };

  promptSoftDelete = (deletingExpensePayment = null) => {
    this.setState({
      deletingExpensePayment,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editExpenseModal = (expense) => {
    const { editExpense } = this.props;

    const { projectId: project } = expense;
    const { _id: projectId, title: projectTitle, customerId: customer } = project || {};
    const { _id: customerId, name: customerName } = customer || {};

    editExpense({
      ...expense,
      projectId: { value: projectId, label: projectTitle },
      customerId: { value: customerId, label: customerName },
    });
  };

  newExpenseModal = () => {
    const { editExpense, projectId, currentProject } = this.props;
    const data = {
      _id: 'new', taskId: { label: 'None', value: null },
    };
    if (projectId) {
      const { customerId: customer, title: projectTitle } = currentProject || {};
      const { _id: customerId, name: customerName } = customer || {};
      data.projectId = { label: projectTitle || projectId, value: projectId };
      if (customerId) {
        data.customerId = { label: customerName || customerId, value: customerId };
      }
    }
    editExpense(data);
  };

  closeExpenseModal = () => {
    const { editExpense } = this.props;
    editExpense(null);
  };

  forceReloadExpense = () => this.maybeReloadExpenses(true);

  maybeReloadExpenses = (force = false) => {
    const {
      getExpensesRequest, loading, loaded, error, projectId,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter,
        startDate,
        endDate,
        tenureType,
        sortBy,
        category,
        dir,
        keywords,
        currentPage,
        perPage,
        startDateRangeStart,
        startDateRangeEnd,
        createdRangeStart,
        createdRangeEnd,
        billingType,
      } = this.state;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.actives = '1';
        else if (activesFilter === 'inactives') filter.inactives = '1';
        else {
          console.error(
            'unknown activesFilter filter in expenses',
            activesFilter,
          );
        }
      }
      if (projectId) {
        filter.projectId = projectId;
      }
      getExpensesRequest(filter);
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading,
      loaded,
      error,
      expenses,
      totalExpenses,
      editingExpense,
      t,
      theme,
      rtl,
      currentProject,
    } = this.props;

    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      type,
      startDateRangeStart,
      startDateRangeEnd,
      createdRangeStart,
      createdRangeEnd,
      sortBy,
      dir,
      currentPage,
      perPage,
      viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    const { currency } = currentProject || {};

    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              {/* <ExpensesFilter
                openNewExpenseModel={this.newExpenseModal}
                updateFilter={this.updateFilter}
                activesFilter={activesFilter}
                startDate={startDate}
                endDate={endDate}
                keyword={keyword}
                type={type}
                theme={theme}
                rtl={rtl.direction}
              /> */}
              <ExpensesTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadExpense}
                expenses={expenses}
                totalExpenses={totalExpenses}
                toggleEditExpense={this.editExpenseModal}
                sortBy={sortBy}
                dir={dir}
                // startDateRangeStart={startDateRangeStart}
                // startDateRangeEnd={startDateRangeEnd}
                // createdRangeStart={createdRangeStart}
                // createdRangeEnd={createdRangeEnd}
                updateSort={this.updateSort}
                toggleExpenseStatus={this.toggleExpenseStatus}
                promptSoftDelete={this.promptSoftDelete}
                currency={currency}
              />
              <ExpensesTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalExpenses={totalExpenses}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      loading,
      loaded,
      error,
      expenses,
      totalExpenses,
      editingExpense,
      t,
      theme,
      rtl,
    } = this.props;
    const {
      activesFilter,
      startDate,
      endDate,
      keyword,
      tenureType,
      sortBy,
      dir,
      billingType,
      category,
      currentPage,
      perPage,
      keywords,
      viewMode,
      deletingExpensePayment,
    } = this.state;

    return (
      <Container className="container__with-filter projects-page">
        <Row>
          <Col sm={6}>
            <h3 className="page-title">{t('expense_index.expense_payments')}</h3>
          </Col>
          <Col sm={6} className="d-flex justify-content-end">
            <Button
              color="success"
              className="icon"
              onClick={this.newExpenseModal}
            >
              <p>
                <AccountPlusIcon /> {t('expense_index.new_expense_payments')}
              </p>
            </Button>
          </Col>
        </Row>
        {this.renderTableView()}
        <ExpensePaymentDeletePrompt
          expensePayment={deletingExpensePayment}
          onHideModal={this.promptSoftDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.expensePayments.loading,
  loaded: state.expensePayments.loaded,
  error: state.expensePayments.error,
  expenses: state.expensePayments.expensePayments,
  totalExpenses: state.expensePayments.totalExpenses,
  editingExpense: state.expensePayments.editingExpense,
  theme: state.theme,
  rtl: state.rtl,
  currentProject: state.projects.currentProject,
});

const mapDispatchToProps = {
  getExpensesRequest: TenantActions.ExpensePayments.getExpensePaymentsRequest,
  updateExpense: TenantActions.ExpensePayments.updateExpensePaymentRequest,
  editExpense: TenantActions.ExpensePayments.editExpensePayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Expenses));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
