/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import axios from 'axios';

let initialized = false;

if (!initialized) {
  initialized = true;

  toast.configure({
    hideProgressBar: true,
    newestOnTop: true,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
  });
  const requestHandler = (config) => {
    config.uploadToast = { id: null };
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      const prevUploadProgress = config.onUploadProgress;
      config.onUploadProgress = (p) => {
        const progress = p.loaded / (p.total + 1);
        if (config.uploadToast.id === null) {
          config.uploadToast.id = toast.info('Upload in progress', { progress, hideProgressBar: false });
        } else {
          toast.update(config.uploadToast.id, { progress });
        }
        return prevUploadProgress && prevUploadProgress(p);
      };
    }
    return config;
  };
  const errorHandler = (error) => {
    // toast update/done checks performs noop/returns null if toastId is invalid, so no need to null check toastId
    toast.done(error.config.uploadToast.id);
    return Promise.reject(error);
  };
  const responseHandler = (response) => {
    toast.done(response.config.uploadToast.id);
    return response;
  };

  axios.interceptors.request.use(
    request => requestHandler(request),
  );
  axios.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error),
  );
}
