import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'reactstrap/es/Button';
import TuneIcon from 'mdi-react/TuneIcon';
import * as SidebarActions from '../../../store/actions/shared/sidebar';
import { SidebarProps } from '../../prop-types/ReducerProps';

const FilterButton = ({ t, sidebar, changeFilterSidebarVisibility }) => (
  <Button color={sidebar.filterCollapsed ? 'primary' : 'secondary'} onClick={changeFilterSidebarVisibility}>
    <TuneIcon /> {t('filter.filter')}
  </Button>
);

FilterButton.propTypes = {
  t: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  changeFilterSidebarVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = {
  changeFilterSidebarVisibility: SidebarActions.changeFilterSidebarVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(FilterButton));
