// FETCH
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

// AUTH
export const LOG_OUT = 'LOG_OUT';

// REGISTER
export const VERIFY_EMAIL_VIEWED = 'VERIFY_EMAIL_VIEWED';

// CUSTOMER
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';

// EMPLOYEE
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';

// PROJECT
export const EDIT_PROJECT = 'EDIT_PROJECT';

// CASE
export const ADD_CASE = 'ADD_CASE';
export const EDIT_CASE = 'EDIT_CASE';
export const REMOVE_NEW_CASE = 'REMOVE_NEW_CASE';

// TASK
export const EDIT_TASK = 'EDIT_TASK';
export const CHANGE_TASK_FILTER = 'CHANGE_TASK_FILTER';
export const CLEAR_TASK_FILTER = 'CLEAR_TASK_FILTER';
export const CLEAR_SOFT_DELETE_STATUS = 'CLEAR_SOFT_DELETE_STATUS';
export const CLEAR_TASKS = 'CLEAR_TASKS';

// EXPENSE
export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const CHANGE_EXPENSE_FILTER = 'CHANGE_EXPENSE_FILTER';
export const CLEAR_EXPENSE_FILTER = 'CLEAR_EXPENSE_FILTER';
export const CLEAR_EXPENSE = 'CLEAR_EXPENSE';

// EXPENSE
export const EDIT_REPORT = 'EDIT_REPORT';
export const CHANGE_REPORT_FILTER = 'CHANGE_REPORT_FILTER';
export const CLEAR_REPORT_FILTER = 'CLEAR_REPORT_FILTER';
export const CLEAR_REPORT = 'CLEAR_REPORT';

// EXPENSE PAYMENT
export const EDIT_EXPENSE_PAYMENT = 'EDIT_EXPENSE_PAYMENT';
export const CHANGE_EXPENSE_PAYMENT_FILTER = 'CHANGE_EXPENSE_PAYMENT_FILTER';
export const CLEAR_EXPENSE_PAYMENT_FILTER = 'CLEAR_EXPENSE_PAYMENT_FILTER';
export const CLEAR_EXPENSE_PAYMENT = 'CLEAR_EXPENSE_PAYMENT';

// EXTRA
export const EDIT_EXTRA = 'EDIT_EXTRA';
export const CHANGE_EXTRA_FILTER = 'CHANGE_EXTRA_FILTER';
export const CLEAR_EXTRA_FILTER = 'CLEAR_EXTRA_FILTER';
export const CLEAR_EXTRA = 'CLEAR_EXTRA';

// BILL
export const EDIT_BILL = 'EDIT_BILL';

// SIDEBAR
export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const CHANGE_FILTER_SIDEBAR_VISIBILITY = 'CHANGE_FILTER_SIDEBAR_VISIBILITY';

// THEME
export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';

// RTL
export const CHANGE_DIRECTION_TO_LTR = 'CHANGE_DIRECTION_TO_LTR';
export const CHANGE_DIRECTION_TO_RTL = 'CHANGE_DIRECTION_TO_RTL';

// CUSTOMIZER
export const CHANGE_BORDER_RADIUS = 'CHANGE_BORDER_RADIUS';
export const TOGGLE_BOX_SHADOW = 'TOGGLE_BOX_SHADOW';
export const TOGGLE_TOP_NAVIGATION = 'TOGGLE_TOP_NAVIGATION';

// LANGUAGE
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
