import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import ProjectItem from './ProjectItem';
import projectType from '../types/project';

class ProjectList extends PureComponent {
  static propTypes = {
    projects: PropTypes.arrayOf(projectType.isRequired).isRequired,
    totalProjects: PropTypes.number.isRequired,
    toggleEditProject: PropTypes.func.isRequired,
    toggleProjectStatus: PropTypes.func.isRequired,
    promptSoftDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      projects, toggleEditProject, toggleProjectStatus, totalProjects, promptSoftDelete, t,
    } = this.props;

    if (totalProjects === 0) {
      return (
        <Card>
          <CardBody>
            {t('project_list.filter_error')}.
          </CardBody>
        </Card>
      );
    }

    return (
      <Row>
        {projects.map(project => (
          <Col xs={12} md={6} xl={4} key={project._id}>
            <ProjectItem
              project={project}
              toggleEditProject={() => toggleEditProject(project)}
              toggleProjectStatus={() => toggleProjectStatus(project)}
              promptSoftDelete={() => promptSoftDelete(project)}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
export default withTranslation('common')(ProjectList);
