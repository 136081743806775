import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalComponent from '../../../../shared/components/Modal';

import * as BillActions from '../../../../store/actions/tenant/bills';

const BillDeletePrompt = ({
  bill, onHideModal, softDeleteBill,
}) => {
  const { _id } = bill || {};
  const { periodStart, periodEnd } = bill || {};
  let title = _id;
  if (periodStart && periodEnd) {
    title = `${moment(periodStart).format('ll')} - ${moment(periodEnd).format('ll')}`;
  }
  const handleDeleteConfirm = () => {
    softDeleteBill(bill);
    onHideModal();
  };
  const { t } = useTranslation('common');
  return (
    <ModalComponent
      title={t('bill_delete_prompt.title')}
      color="danger"
      showToggleButton={false}
      showButtons
      onToggle={onHideModal}
      showModal={!!bill}
      onOk={handleDeleteConfirm}
    >
      <h4>
        {t('bill_delete_prompt.message')}
      </h4>
      <p>{title}</p>
    </ModalComponent>
  );
};

BillDeletePrompt.propTypes = {
  bill: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
  }),
  onHideModal: PropTypes.func.isRequired,

  softDeleteBill: PropTypes.func.isRequired,
};

BillDeletePrompt.defaultProps = {
  bill: null,
};

const mapDispatchToProps = {
  softDeleteBill: BillActions.softDeleteBillRequest,
};

export default connect(null, mapDispatchToProps)(BillDeletePrompt);
