import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import MapMarkerRadiusIcon from 'mdi-react/MapMarkerRadiusIcon';
import MoneyIcon from 'mdi-react/MoneyIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import CreditCardClockIcon from 'mdi-react/CreditCardClockIcon';
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon';
import { connect } from 'react-redux';
import ControlledPanel from '../../../shared/components/ControlledPanel';
import useDurationPriceReport from './hooks/useDurationPriceReport';
import DateFilter, { initialState } from './components/DateFilter';
import Loading from '../../../shared/components/Loading';
import StackedGroupedBarChart from './components/StackedGroupedBarChart';
import CustomPieChart from './components/CustomPieChart';
import useRefOnce from './hooks/useRefOnce';
import { getRandomColorFactory } from './components/colors';
import CheckBoxFilter from './components/CheckboxFilter';
import DurationPriceDateLineChart from './components/DurationPriceDateLineChart';
import timeseriesOptions from './constants/timeseries';
import modeOptions from './constants/modes';
import SelectFilter from './components/SelectFilter';
import durationPriceDateNormalize from './helpers/durationPriceDateNormalizer';
import PieChartGoalGraph from './components/PieChartGoalGraph';
import { getDefaultCurrency } from '../../../shared/helpers';
import useRefMemo from '../../../shared/hooks/useRefMemo';
import useStateMemo from '../../../shared/hooks/useStateMemo';
import durationPriceDateSumHelper from './helpers/durationPriceDateSumHelper';
import EmployeeContributionList from './components/EmployeeContributionList';
import { useCombinedRequest } from '../../../shared/hooks';
import { useEmployeeList } from '../Employees/hooks';

const initialDate = {
  end: new Date(),
};
initialDate.start = new Date(initialDate.end.getTime() - 30 * 24 * 60 * 60 * 1000);

const groupBy = 'tenant';
const EmployeeContributionPanel = ({
  profile, title, projectId, customerId, fallbackTitle, t,
}) => {
  const [date, setDate] = useState(initialDate);
  const [timeseries, setTimeseries] = useState('monthly');
  const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  const [collapsed, setCollapsed] = useState(false);
  // const onDateChange = (value, name) => setDate({ ...date, [name]: value });
  // const [collapsed, setCollapsed] = useState(false);
  const [{
    loading, loaded, error, result,
  }, reload] = useCombinedRequest([
    useDurationPriceReport(
      date,
      groupBy,
      projectId,
      customerId,
      timeseries,
    ),
    useEmployeeList(),
    useStateMemo(() => ([{
      ...profile,
      result: profile.profile,
    }]), [profile]),
  ], ([entryResults, employees, profileResult]) => {
    const entryList = entryResults || [];
    const employeeList = employees || [];
    employeeList.push(profileResult);
    const employeesById = employeeList.reduce((acc, employee) => ({
      ...acc,
      [employee._id]: employee,
    }), {});
    const employeeSet = new Set();
    (employees || []).forEach(({ _id }) => {
      employeeSet.add(_id);
    });
    entryList.forEach((employee) => {
      if (employeeSet.has(employee.title)) {
        employeeSet.delete(employee.title);
      }
    });
    employeeSet.forEach((_id) => {
      entryList.push({ title: _id, data: { taskTimeSeries: [], extraTimeSeries: [] } });
    });
    const entries = durationPriceDateNormalize({
      date,
      timeseries,
      results: entryList,
      fallbackTitle: t(fallbackTitle),
    }) || [];
    return {
      entries, employeesById,
    };
  });

  const { entries = [], employeesById = {} } = result || {};

  const [includeClosedBillableTasks, setIncludeClosedBillableTasks] = useState(false);
  const [includeOpenBillableTasks, setIncludeOpenBillableTasks] = useState(false);
  const [includeNonBillableTasks, setIncludeNonBillableTasks] = useState(false);
  const [includeClosedExtraPayments, setIncludeClosedExtraPayments] = useState(false);
  const [includeOpenExtraPayments, setIncludeOpenExtraPayments] = useState(false);

  const extendedEntries = useStateMemo(() => {
    const ext = durationPriceDateSumHelper({
      entries,
      includeClosedBillableTasks,
      includeOpenBillableTasks,
      includeClosedExtraPayments,
      includeOpenExtraPayments,
      includeNonBillableTasks,
    });
    ext.sort((a, b) => b.totalPrice - a.totalPrice);
    return ext;
  }, [entries, includeClosedBillableTasks, includeOpenBillableTasks,
    includeClosedExtraPayments, includeOpenExtraPayments, includeNonBillableTasks]);

  const currency = getDefaultCurrency();

  return (
    <ControlledPanel
      title={title}
      loading={loading}
      onRefresh={reload}
      onCollapse={() => setCollapsed(!collapsed)}
      collapsed={collapsed}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={6}
    >
      <DateFilter
        onChange={onDateChange}
        state={date}
      />
      <Row className="mt-2 form">
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-billable-tasks`}
          label={t('duration_panel.inc_close_task')}
          setState={setIncludeClosedBillableTasks}
          state={includeClosedBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-billable-tasks`}
          label={t('duration_panel.inc_open_task')}
          setState={setIncludeOpenBillableTasks}
          state={includeOpenBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-non-billable-tasks`}
          label={t('duration_panel.inc_non_task')}
          setState={setIncludeNonBillableTasks}
          state={includeNonBillableTasks}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-closed-extra-payments`}
          label={t('duration_panel.inc_close_extra')}
          setState={setIncludeClosedExtraPayments}
          state={includeClosedExtraPayments}
        />
        <CheckBoxFilter
          name={`${groupBy}-duration-price-date-open-extra-payments`}
          label={t('duration_panel.inc_open_extra')}
          setState={setIncludeOpenExtraPayments}
          state={includeOpenExtraPayments}
        />
      </Row>
      <Loading
        loading={loading}
        loaded={loaded}
        error={error}
        retry={reload}
      >
        {loaded ? (
          <EmployeeContributionList
            entries={extendedEntries}
            employeesById={employeesById}
            currency={currency}
          />
        ) : null}
      </Loading>
    </ControlledPanel>
  );
};

EmployeeContributionPanel.propTypes = {
  profile: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    profile: PropTypes.shape().isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  customerId: PropTypes.string,
  fallbackTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
};

EmployeeContributionPanel.defaultProps = {
  projectId: '',
  customerId: '',
  fallbackTitle: 'duration_price_chart.unknown',
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(
  withTranslation('common')(EmployeeContributionPanel),
);
