import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route, Redirect, useParams,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Container from 'reactstrap/es/Container';
import { useHistory } from 'react-router';
import Matters from '../Projects';
import Reports from '../Reports';
import SecondaryTopbar from '../Layout/topbar_with_navigation/SecondaryTopbar';
import * as CustomerActions from '../../../store/actions/tenant/customers';
import { types } from './constants/options';
import Graphs from '../Graphs';

const _routes = [{
  name: 'customer_page.dashboard',
  route: '/passboard',
}, {
  name: 'customer_page.matters',
  route: '/matters',
}, {
  name: 'customer_page.reports',
  route: '/reports',
}];

const CustomerPage = ({
  getCurrentCustomer, customer, loaded, loading,
  isTenantAdmin, isEmployee,
}) => {
  const { customerId } = useParams();
  const [routes, setRoutes] = useState(_routes);
  useEffect(() => {
    getCurrentCustomer({ _id: customerId });
  }, [customerId]);
  useEffect(() => {
    if (!isTenantAdmin) setRoutes(_routes.slice(0, 2));
    else setRoutes(_routes);
  }, [isTenantAdmin]);

  const { isActive } = customer || {};

  const history = useHistory();

  if (isActive === false) {
    history.push('/customers');
  }

  return (
    <Container>
      <SecondaryTopbar
        baseRoute={`/customers/${customerId}`}
        routes={routes}
      />
      <Switch>
        <Route
          exact
          path="/customers/:customerId/passboard"
          render={() => <Graphs customerId={customerId} />}
        />
        <Route
          exact
          path="/customers/:customerId/matters"
          render={() => <Matters customerId={customerId} />}
        />
        { !isEmployee ? (
          <Route
            exact
            path="/customers/:customerId/reports"
            render={() => <Reports customerId={customerId} />}
          />
        ) : null }
        <Redirect to={`/customers/${customerId}/passboard/`} />
      </Switch>
    </Container>
  );
};

CustomerPage.propTypes = {
  getCurrentCustomer: PropTypes.func.isRequired,
  customer: PropTypes.shape(),
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isTenantAdmin: PropTypes.bool,
  isEmployee: PropTypes.bool.isRequired,
};
CustomerPage.defaultProps = {
  customer: null,
  isTenantAdmin: true,
};

const mapStateToProps = state => ({
  loaded: state.customers.loadedCurrent,
  loading: state.customers.loadingCurrent,
  customer: state.customers.currentCustomer,
  isTenantAdmin: state.profile.isTenantAdmin,
  isEmployee: state.profile.isEmployee,
});

const mapDispatchToProps = {
  getCurrentCustomer: CustomerActions.getCurrentCustomerRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CustomerPage));
