import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';

const balloon = `${process.env.PUBLIC_URL}/img/pricing_cards/002-hot-air-balloon.svg`;

const CardSpecial = ({ t }) => (
  <Col md={6} xl={3} sm={12}>
    <Card>
      <CardBody className="pricing-card pricing-card--info">
        <div className="pricing-card__body">
          <img className="pricing-card__img" src={balloon} alt="" />
          <h3 className="pricing-card__plan">{t('card_special.special')}</h3>
          <hr />
          <p className="pricing-card__price">${t('card_special.dollar')}<span>{t('card_special./mo')}</span></p>
          <p className="pricing-card__feature">{t('card_special.users')}</p>
          <p className="pricing-card__feature">{t('card_special.storage')}</p>
          <p className="pricing-card__feature">{t('card_special.m_update')}</p>
          <p className="pricing-card__feature pricing-card__feature--inactive">{t('card_special.support')}</p>
          <Button className="pricing-card__button" color="primary">{t('card_special.sign_up')}</Button>
        </div>
      </CardBody>
    </Card>
  </Col>
);

CardSpecial.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation('common')(CardSpecial);
