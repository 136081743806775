/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import { withTranslation } from 'react-i18next';
import TopbarQuickAddActionButton from './TopbarQuickAddActionButton';
import TenantActions from '../../../../store/actions/tenant';
import { defaultCurrencyObject } from '../../Projects/constants/options';

class TopbarQuickAdd extends PureComponent {
  static propTypes = {
    editCustomer: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    editTask: PropTypes.func.isRequired,
    editExpense: PropTypes.func.isRequired,
    editExpensePayment: PropTypes.func.isRequired,
    editExtra: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    collapse: false,
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  handleAction = () => {
    alert('Heyo!');
  };

  allCustomers = () => {

  }

  addCustomer = () => {
    const { editCustomer } = this.props;
    editCustomer({ _id: 'new' });
  };

  addProject = () => {
    const { editProject } = this.props;
    editProject({
      _id: 'new',
      currency: defaultCurrencyObject,
      shouldCap: false,
      status: 'active',
    });
  };

  addTask = () => {
    const { editTask } = this.props;
    editTask({
      _id: 'new', isBillable: true, startDateTime: new Date(),
    });
  };

  addExpenses = () => {
    const { editExpense } = this.props;
    editExpense({ _id: 'new' });
  }

  addExtras = () => {
    const { editExtra } = this.props;
    editExtra({ _id: 'new' });
  }

  addExpensePayments = () => {
    const { editExpensePayment } = this.props;
    editExpensePayment({ _id: 'new' });
  }

  render() {
    const { collapse } = this.state;
    const {
      t,
    } = this.props;
    return (
      <div className="topbar__collapse topbar__collapse--quick-add">
        <button
          className={`topbar__btn topbar__btn--quick-add ${collapse ? 'topbar__btn--quick-add-collapsed' : ''}`}
          type="button"
          onClick={this.toggle}
        >
          <PlusIcon />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--quick-add"
        >
          <div className="topbar__collapse-title-wrap topbar__collapse-title-wrap--quick-add">
            <h6 className="half-bold-text">{t('quick_add_items.quick_add')}</h6>
          </div>
          <div className="topbar__quick-add-actions">
            <TopbarQuickAddActionButton
              icon="users"
              iconColor="blue"
              title={t('quick_add_items.add_customer')}
              onClick={this.addCustomer}
            />
            <TopbarQuickAddActionButton
              icon="briefcase"
              iconColor="orange"
              title={t('quick_add_items.add_matter')}
              onClick={this.addProject}
            />
            <TopbarQuickAddActionButton
              icon="list"
              iconColor="purple"
              title={t('quick_add_items.add_task')}
              onClick={this.addTask}
            />
            <TopbarQuickAddActionButton
              icon="printer"
              iconColor="red"
              title={t('quick_add_items.expenses')}
              onClick={this.addExpenses}
            />
            <TopbarQuickAddActionButton
              icon="gift"
              iconColor="green"
              title={t('quick_add_items.expense_payments')}
              onClick={this.addExpensePayments}
            />
            <TopbarQuickAddActionButton
              icon="diamond"
              iconColor="darkturquoise"
              title={t('quick_add_items.extra_payments')}
              onClick={this.addExtras}
            />

            {/*            <TopbarQuickAddActionButton
              icon="rocket"
              title="Rocket"
            />
            <TopbarQuickAddActionButton
              icon="car"
              iconColor="purple"
              title="Car"
            /> */}
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapDispatchToProps = {
  editCustomer: TenantActions.Customers.editCustomer,
  editProject: TenantActions.Projects.editProject,
  editTask: TenantActions.Tasks.editTask,
  editExpense: TenantActions.Expenses.editExpense,
  editExpensePayment: TenantActions.ExpensePayments.editExpensePayment,
  editExtra: TenantActions.Extras.editExtra,
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(TopbarQuickAdd));
