import withFetch from './withFetch';
import {
  CREATE_EXPENSE_PAYMENT_TENANT, GET_CURRENT_EXPENSE_PAYMENTS_TENANT,
  GET_EXPENSE_PAYMENTS_TENANT, SOFT_DELETE_EXPENSE_PAYMENT_TENANT, UPDATE_EXPENSE_PAYMENT_TENANT,
} from '../fetchTypes';
import { EDIT_EXPENSE_PAYMENT, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingExpensePayment: null,
  totalExpensePayments: 0,
  expensePayments: [],
  currentExpensePayment: null,
  loadingCurrent: false,
  loadedCurrent: false,
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case GET_CURRENT_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loadingCurrent: true,
        errorCurrent: undefined,
      };
    case CREATE_EXPENSE_PAYMENT_TENANT:
    case UPDATE_EXPENSE_PAYMENT_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        expensePayments: action.data.expensePayments,
        totalExpenses: action.data.expensePayments.length,
      };
    case GET_CURRENT_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        currentExpensePayment: action.data.expensePayment,
      };
    case CREATE_EXPENSE_PAYMENT_TENANT:
    case UPDATE_EXPENSE_PAYMENT_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingExpensePayment: null,
      };
    case SOFT_DELETE_EXPENSE_PAYMENT_TENANT:
      return {
        ...state,
        expensePayments: state.expensePayments.filter(({ _id }) => _id !== action.reqData._id),
        totalExpensePayments: state.totalExpensePayments - 1,
        currentExpensePayment: state.currentExpensePayment && state.currentExpensePayment._id === action.reqData._id ? {
          ...state.currentExpensePayment,
          isActive: false,
        } : state.currentExpensePayment,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_CURRENT_EXPENSE_PAYMENTS_TENANT:
      return {
        ...state,
        loadingCurrent: false,
        errorCurrent: action.error,
      };
    case CREATE_EXPENSE_PAYMENT_TENANT:
    case UPDATE_EXPENSE_PAYMENT_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_EXPENSE_PAYMENT:
      return {
        ...state,
        editingExpensePayment: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
