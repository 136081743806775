import {
  CREATE_EXPENSE_TENANT, GET_EXPENSES_TENANT,
  UPDATE_EXPENSE_TENANT, RELOAD_EXPENSE_TENANT,
  DELETE_EXPENSE_TENANT, DELETE_EXPENSE_WITH_CHILDREN_TENANT,
  UPDATE_EXPENSE_PHOTO_TENANT, SOFT_DELETE_EXPENSE_TENANT, DELETE_EXPENSE_PHOTO_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_EXPENSES_TENANT:
      return params => makeFetcher('expenses', 'GET', params);
    case RELOAD_EXPENSE_TENANT:
      return data => makeFetcher(`expenses/${data._id}`, 'GET');
    case CREATE_EXPENSE_TENANT:
      return data => makeFetcher('expenses', 'POST', data);
    case UPDATE_EXPENSE_TENANT:
      return ({ _id, ...data }) => makeFetcher(`expenses/${_id}`, 'PUT', data);
    case UPDATE_EXPENSE_PHOTO_TENANT:
      return ({ _id, formData }) => makeFetcher(`expenses/${_id}/picture`, 'PUT', formData,
        { 'Content-Type': 'multipart/form-data' });
    case SOFT_DELETE_EXPENSE_TENANT:
      return ({ _id }) => makeFetcher(`expenses/${_id}`, 'PUT', { isActive: false, amount: '0' });
    case DELETE_EXPENSE_PHOTO_TENANT:
      return ({ _id }) => makeFetcher(`expenses/${_id}/picture`, 'DELETE');
    case DELETE_EXPENSE_TENANT:
      return data => makeFetcher(`expenses/${data._id}`, 'DELETE');
    case DELETE_EXPENSE_WITH_CHILDREN_TENANT:
      return data => makeFetcher(`expenses/${data._id}/delete-child`, 'DELETE');
    default:
      return null;
  }
};
