import moment from 'moment';

const loadedLocales = {
  en: true,
};
const localizeMoment = (locale) => {
  if (loadedLocales[locale]) {
    moment.locale(locale);
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    /* eslint-disable */
     import(
       /* webpackInclude: /(tr|de|fr).js$/ */
       /* webpackChunkName: "moment-locale-" */
       /* webpackMode: "lazy" */
       /* webpackPrefetch: false */
       /* webpackPreload: false */
       'moment/locale/' + locale + '.js'
     )
    /* eslint-enable */
       .then(() => {
         moment.locale(locale);
         loadedLocales[locale] = true;
         resolve();
       }).catch(err => reject(err));
  });
};

export default localizeMoment;
