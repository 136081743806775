import withFetch from './withFetch';
import {
  CREATE_BILL_TENANT, GET_BILLS_TENANT, SOFT_DELETE_BILL_TENANT, UPDATE_BILL_TENANT,
} from '../fetchTypes';
import { EDIT_BILL, LOG_OUT } from '../sharedTypes';

const createInitialState = () => ({
  loading: false,
  loaded: false,
  updating: false,
  editingBill: null,
  totalBills: 0,
  bills: [],
  errorCurrent: undefined,
});

const fetchRequestReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_BILLS_TENANT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case CREATE_BILL_TENANT:
    case UPDATE_BILL_TENANT:
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    default:
      return state;
  }
};

const fetchSuccessReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_BILLS_TENANT:
      return {
        ...state,
        loading: false,
        loaded: true,
        bills: action.data.bills,
        totalBills: action.data.bills.length,
      };
    case CREATE_BILL_TENANT:
    case UPDATE_BILL_TENANT:
      return {
        ...state,
        loading: false,
        loaded: false,
        updating: false,
        editingBill: null,
      };
    case SOFT_DELETE_BILL_TENANT:
      return {
        ...state,
        bills: state.bills.filter(({ _id }) => _id !== action.reqData._id),
        totalBills: state.totalBills - 1,
      };
    default:
      return state;
  }
};

const fetchErrorReducer = (state, action) => {
  switch (action.fetchType) {
    case GET_BILLS_TENANT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_BILL_TENANT:
    case UPDATE_BILL_TENANT:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => {
  switch (action.type) {
    case LOG_OUT:
      return createInitialState();
    case EDIT_BILL:
      return {
        ...state,
        editingBill: action.data,
      };
    default:
      return state;
  }
};

export default withFetch(createInitialState(),
  fetchRequestReducer, fetchSuccessReducer, fetchErrorReducer, defaultReducer);
