const toLabel = (options, defaultLabel = '') => {
  const obj = options.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
  return value => obj[value] || defaultLabel;
};

export const categories = [
  { value: 'courtCase', label: 'matter_options.court_case' },
  { value: 'enforcement', label: 'matter_options.enforcement' },
  { value: 'consultancy', label: 'matter_options.consultancy' },
  { value: 'legalOpinion', label: 'matter_options.legal_opinion' },
];
export const categoryFilters = [
  { value: '', label: 'matter_options.any_type' },
  ...categories,
];
export const categoryToLabel = toLabel(categories);

export const isCategoryCase = category => ['courtCase', 'enforcement'].includes(category);
export const isCategoryNonCase = category => ['consultancy', 'legalOpinion'].includes(category);

export const statuses = [
  { value: 'potential', label: 'matter_options.potential' },
  { value: 'active', label: 'matter_options.active' },
];
export const statusFilters = [
  { value: '', label: 'matter_options.matters' },
  { value: 'active', label: 'matter_options.active_matters' },
  { value: 'potential', label: 'matter_options.potential_matters' },
];
export const statusToLabel = toLabel(statuses);

export const tenureTypes = [
  { value: 'continuous', label: 'matter_options.continuous' },
  { value: 'onetime', label: 'matter_options.one_time' },
];
export const tenureTypeFilters = [
  { value: '', label: 'matter_options.any_type' },
  ...tenureTypes,
];
export const tenureTypeToLabel = toLabel(tenureTypes);

export const billingTypes = [
  { value: 'fixed', label: 'matter_options.fixed' },
  { value: 'hourly', label: 'matter_options.hourly' },
  { value: 'monthly', label: 'matter_options.monthly' },
  { value: 'annually', label: 'matter_options.annually' },
/*
  { value: 'mixed', label: 'Mixed' },
*/
];
export const billingTypeFilters = [
  { value: '', label: 'matter_options.any_type' },
  ...billingTypes,
];
export const billingTypeToLabel = toLabel(billingTypes);

export const currencies = [
  { value: '€', label: 'matter_options.euro' },
  { value: '₺', label: 'matter_options.try' },
  { value: '$', label: 'matter_options.usd' },
];

export const defaultCurrencyObject = currencies[0];

export const currenciesToLabel = toLabel(currencies);

export const feeUnits = [
  { value: 'hour', label: 'matter_options.per_hour' },
  { value: 'month', label: 'matter_options.per_month' },
  { value: 'annum', label: 'matter_options.per_annum' },
  { value: 'fixed', label: 'matter_options.fixed' },
];
export const feeUnitToLabel = toLabel(feeUnits);
export const billingTypeToFeeUnit = (billingType) => {
  switch (typeof billingType === 'object' ? billingType.value : billingType) {
    case 'fixed':
      return feeUnits[3];
    case 'hourly':
      return feeUnits[0];
    case 'monthly':
      return feeUnits[1];
    case 'annually':
      return feeUnits[2];
    default:
      return undefined;
  }
};

export const estimateTimeUnits = [
  { value: 'hours', label: 'matter_options.hours' },
  { value: 'days', label: 'matter_options.days' },
  { value: 'months', label: 'matter_options.months' },
  { value: 'years', label: 'matter_options.years' },
];
export const estimateTimeUnitToLabel = toLabel(estimateTimeUnits);
