import {
  EDIT_BILL, FETCH_REQUEST,
} from '../../sharedTypes';
import {
  GET_BILLS_TENANT, CREATE_BILL_TENANT, UPDATE_BILL_TENANT, SOFT_DELETE_BILL_TENANT,
} from '../../fetchTypes';

export const getBillsRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_BILLS_TENANT,
  data,
});

export const editBill = data => ({
  type: EDIT_BILL,
  data,
});

export const createBillRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: CREATE_BILL_TENANT,
  data,
});

export const updateBillRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: UPDATE_BILL_TENANT,
  data,
});

export const softDeleteBillRequest = data => ({
  type: FETCH_REQUEST,
  fetchType: SOFT_DELETE_BILL_TENANT,
  data,
});
