import { LOGIN_TENANT } from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case LOGIN_TENANT:
      return data => makeFetcher('tenants/login', 'POST', data);
    default:
      return null;
  }
};
