import React from 'react';
import projectType from '../types/project';

import { useRequest } from '../../../../shared/hooks';


function useProject(projectId) {
  return useRequest(
    ({ project }) => project,
    projectId ? `projects/${projectId}` : '',
    useRequest.methods.GET,
  );
}

useProject.propType = useRequest.createPropType(projectType);

export default useProject;
