import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import EmailIcon from 'mdi-react/EmailIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import { Link } from 'react-router-dom';

const EmailConfirmationCard = ({ t }) => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="email-confirmation">
          <div className="email-confirmation__icon">
            <EmailIcon className="email-confirmation__mail" />
            <CheckboxMarkedCircleIcon className="email-confirmation__check" />
          </div>
          <h3 className="email-confirmation__title">{t('email_confirmation_card.information')}</h3>
          <p className="email-confirmation__sub">{t('email_confirmation_card.advopass')}</p>
          <ButtonToolbar className="email-confirmation__buttons">
            <Link className="btn btn-primary" to="/">{t('email_confirmation_card.email')}</Link>
            <Link className="btn btn-primary" to="/profile">{t('email_confirmation_card.profile')}</Link>
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  </Col>
);

EmailConfirmationCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation('common')(EmailConfirmationCard);
