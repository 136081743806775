import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import MultiSelect from '../../../../shared/components/form/MultiSelect';

const meta = {};

const MultiSelectFilter = ({
  state, setState, name, options, isTranslated,
  xs, sm, md, lg, xl, className,
}) => (
  <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={className}>
    <MultiSelect
      input={{
        value: state,
        name,
        onChange: opts => setState(opts || []),
      }}
      options={options}
      isTranslated={isTranslated}
      meta={meta}
    />
  </Col>
);

MultiSelectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  isTranslated: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

MultiSelectFilter.defaultProps = {
  isTranslated: undefined,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  className: '',
};

export default MultiSelectFilter;
