import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Field, FieldArray, formValueSelector, reduxForm, change,
} from 'redux-form';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import MapMarkerPlusIcon from 'mdi-react/MapMarkerPlusIcon';
import EmailPlusIcon from 'mdi-react/EmailPlusIcon';
import PhonePlusIcon from 'mdi-react/PhonePlusIcon';
import {
  Button, ButtonToolbar, Col, Row,
} from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Switch from 'react-ios-switch';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Loading from '../../../../shared/components/Loading';
import RenderSelectField from '../../../../shared/components/form/Select';
import RenderCheckBoxField from '../../../../shared/components/form/CheckBox';
import * as ProjectHelpers from '../helpers';
import {
  billingTypes, estimateTimeUnits, feeUnits,
} from '../constants/options';
import renderSwitchInput from '../../../../shared/components/form/Switch';

const ProjectFormTimeBilling = ({
  billingType, totalPrice, changeFormField, shouldCap, t,
}) => (
  <Row>
    {/* <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">Advance Payment</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="advancePayment"
            component="input"
            type="number"
            placeholder="Advance Payment"
          />
        </div>
      </div>
    </Col> */}
    <Col xs={12} sm={billingType === 'hourly' ? 6 : 5}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('project_form.billing_type')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon form__form-group-icon--select">
            <AccountOutlineIcon />
          </div>
          <Field
            name="billingType"
            component={RenderSelectField}
            placeholder={t('project_form.billing_type')}
            options={billingTypes}
          />
        </div>
      </div>
    </Col>
    <Col xs={billingType === 'hourly' ? 12 : 8} sm={billingType === 'hourly' ? 6 : 5}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('project_form.fee')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon form__form-group-icon--select">
            <AccountOutlineIcon />
          </div>
          <Field
            name="fee"
            component="input"
            placeholder={t('project_form.billing_fee')}
            type="number"
          />
        </div>
      </div>
    </Col>
    { billingType !== 'hourly' && (
      <Col xs={4} sm={2}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('project_form.billing_cap')}</span>
          <div className="form__form-group-field">
            <Field
              name="shouldCap"
              component={renderSwitchInput}
            />
          </div>
        </div>
      </Col>
    ) }
    { shouldCap && (
      <>
        <Col xs={12} sm={5}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('project_form.cap_hours')}</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon form__form-group-icon--select">
                <AccountOutlineIcon />
              </div>
              <Field
                name="capHours"
                component="input"
                placeholder={t('project_form.cap_hours')}
                type="number"
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={7}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('project_form.fee_after')}</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon form__form-group-icon--select">
                <AccountOutlineIcon />
              </div>
              <Field
                name="feeAfterCap"
                component="input"
                placeholder={t('project_form.fee_after_cap')}
                type="number"
              />
            </div>
          </div>
        </Col>
      </>
    ) }
    <Col xs={12} sm={6}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('project_form.estimate_time')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon form__form-group-icon--select">
            <AccountOutlineIcon />
          </div>
          <Field
            name="estimatedHrs"
            component="input"
            placeholder={t('project_form.time')}
            type="number"
          />
        </div>
      </div>
    </Col>
    {/* <Col xs={12}>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            name="recordExpenditure"
            label="I would like to record expenditure information"
            component={RenderCheckBoxField}
            className="colored-click"
          />
        </div>
      </div>
    </Col> */}
    <Col xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {/*
      <h5> Total Estimated Hours: {totalEstimatedHrs} hours </h5>
*/}
      <h5> {t('project_form.total_price')}: {totalPrice} </h5>
    </Col>
  </Row>
);

ProjectFormTimeBilling.propTypes = {
  billingType: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
  shouldCap: PropTypes.bool.isRequired,
  changeFormField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const selector = formValueSelector('tenant_project_form');
const mapStateToProps = (state) => {
  const capHours = selector(state, 'capHours');
  const feeAfterCap = selector(state, 'feeAfterCap');
  const estimatedHours = selector(state, 'estimatedHrs');
  let billingType = selector(state, 'billingType') || '';
  if (typeof billingType === 'object') billingType = billingType.value || '';
  const shouldCap = selector(state, 'shouldCap') && billingType !== 'hourly';
  const fee = selector(state, 'fee');
  /* const advancePayment = selector(state, 'advancePayment'); */
  const totalPrice = ProjectHelpers.calculateTotalPrice(billingType, estimatedHours, fee, capHours, feeAfterCap);
  return {
    shouldCap,
    billingType,
    totalPrice,
  };
};

const mapDispatchToProps = {
  changeFormField: (field, value) => change('tenant_project_form', field, value),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'tenant_project_form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(withTranslation('common')(ProjectFormTimeBilling)),
);
