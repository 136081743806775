/* eslint-disable react/no-children-prop */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const RenderSelectField = ({
  input, meta: { touched, error }, children, options, onExtraChange, t, ...extra
}) => (
  <FormControl className="material-form__field" error={Boolean(touched && error)}>
    <Select
      {...extra}
      children={children}
      {...input}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
        if (onExtraChange) {
          onExtraChange(e.target.value);
        }
      }}
    >
      {
        options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            { label }
          </MenuItem>
        ))
      }
    </Select>
    { touched && error && (
      <FormHelperText>{t(error)}</FormHelperText>
    )}
  </FormControl>
);

RenderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onExtraChange: PropTypes.func,
};

RenderSelectField.defaultProps = {
  meta: null,
  children: [],
  onExtraChange: null,
};

export default withTranslation('common')(RenderSelectField);
