import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProfileMain = ({
  fname, lname, picture, email, phone,
}) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__avatar">
            <img src={picture || Ava} alt="avatar" />
          </div>
          <div className="profile__data">
            <p className="profile__name">{ fname } { lname }</p>
            {/* <p className="profile__work">Senior Account Manager</p> */}
            <p className="profile__contact">{ email }</p>
            <p className="profile__contact" dir="ltr">{ phone }</p>
            <Button color="primary" className="icon profile__btn"><p><MessageTextOutlineIcon /> Message</p></Button>
          </div>
        </div>
        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__stat-number">05</p>
            <p className="profile__stat-title">Projects</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">24</p>
            <p className="profile__stat-title">Tasks</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">12</p>
            <p className="profile__stat-title">Reports</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

ProfileMain.propTypes = {
  fname: PropTypes.string.isRequired,
  lname: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default ProfileMain;
