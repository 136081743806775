import {
  GET_READ_MESSAGES_TENANT, GET_UNREAD_MESSAGES_TENANT, MARK_MESSAGE_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_READ_MESSAGES_TENANT:
      return params => makeFetcher('messages/listread', 'GET', params);
    case GET_UNREAD_MESSAGES_TENANT:
      return params => makeFetcher('messages/listunread', 'GET', params);
    case MARK_MESSAGE_TENANT:
      return data => makeFetcher('messages/markAsRead', 'PUT', data);
    default:
      return null;
  }
};
