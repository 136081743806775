/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  BarChart, Line, LineChart, Bar, Cell, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import moment from 'moment';
import { Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import durationPriceDateType from '../types/durationPriceDate';
import useRefOnce from '../hooks/useRefOnce';
import { getRandomColorFactory } from './colors';
import { renderCustomizedLabelFactory } from '../helpers/customPieLabel';
import Loading from '../../../../shared/components/Loading';
import { getDefaultCurrency, displayCurrency } from '../../../../shared/helpers';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const Ava1 = `${process.env.PUBLIC_URL}/img/11.png`;

const EmployeeContributionList = ({
  entries, employeesById,
  currency, t,
}) => {
  if (entries.length === 0) {
    return (
      <div>
        <h4 style={{ textAlign: 'center', marginTop: 24 }}>
          {t('custom_pie_chart.no_data')}
        </h4>
      </div>
    );
  }

  return (
    <Table responsive striped className="dashboard__table-orders">
      <thead>
        <tr>
          <th style={{ fontWeight: 500, color: '#000000' }}>
            {t('employee_form.employee')}
          </th>
          <th style={{ fontWeight: 500, color: '#1eae4e' }}>
            {t('graphs_index.last_month_goal.hourly_task_fee')}
          </th>
          <th style={{ fontWeight: 500, color: '#d8b00e' }}>
            {t('graphs_index.last_month_goal.task_gain')}
          </th>
          <th style={{ fontWeight: 500, color: '#ff4861' }}>
            {t('graphs_index.last_month_goal.worked_hours')}
          </th>
          <th style={{ fontWeight: 500, color: 'darkturquoise' }}>
            {t('graphs_index.last_month_goal.extra_gain')}
          </th>
          <th style={{ fontWeight: 500, color: '#361eae' }}>
            {t('graphs_index.last_month_goal.total_gain')}
          </th>
        </tr>
      </thead>
      <tbody>
        {entries.map((entry, index) => {
          const employee = employeesById[entry.title] || {};
          const {
            picture, logo, fname, lname,
          } = employee;
          const {
            averagePricePerHour, totalTaskPrice, totalDuration, totalExtraPrice, totalPrice,
          } = entry;
          let title = `${fname || ''} ${lname || ''}`;
          if (title === ' ') {
            title = t('duration_price_chart.unknown');
          }
          const img = picture || logo || Ava;
          return (
            <tr key={`employee-stats-${index}`}>
              <td className="dashboard__table-orders-title">
                <div className="dashboard__table-orders-img-wrap">
                  <div className="dashboard__table-orders-img" style={{ backgroundImage: `url(${img})` }} />
                </div>
                {title}
              </td>
              <td style={{ fontWeight: 500, color: '#1eae4e' }}>
                {`${displayCurrency(averagePricePerHour, currency)}/${t('bar_chart.h')}`}
              </td>
              <td style={{ fontWeight: 500, color: '#d8b00e' }}>
                {displayCurrency(totalTaskPrice, currency)}
              </td>
              <td style={{ fontWeight: 500, color: '#ff4861' }}>
                {`${totalDuration} ${t('custom_pie_chart.hours')}`}
              </td>
              <td style={{ fontWeight: 500, color: 'darkturquoise' }}>
                {displayCurrency(totalExtraPrice, currency)}
              </td>
              <td style={{ fontWeight: 500, color: '#361eae' }}>
                {displayCurrency(totalPrice, currency)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

EmployeeContributionList.propTypes = {
  entries: PropTypes.arrayOf(
    durationPriceDateType.isRequired,
  ).isRequired,
  employeesById: PropTypes.shape().isRequired,
  currency: PropTypes.string,
  t: PropTypes.func.isRequired,
};

EmployeeContributionList.defaultProps = {
  currency: '',
};

export default withTranslation('common')(EmployeeContributionList);
