import React, { Component } from 'react';
import {
  Col, Container, Row, ButtonToolbar, ButtonGroup, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/es/Modal';
import classNames from 'classnames';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import CardFooter from 'reactstrap/es/CardFooter';
import Switch from 'react-ios-switch';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import CustomersTable from './components/CustomersTable';
import CustomersTablePagination from './components/CustomersTablePagination';
import CustomersFilter from './components/CustomersFilter';
import CustomerModal from './components/CustomerModal';
import customerType from './types/customer';
import { RTLProps, ThemeProps } from '../../../shared/prop-types/ReducerProps';
import TenantActions from '../../../store/actions/tenant';
import Loading from '../../../shared/components/Loading';
import SearchBox from '../../../shared/components/SearchBox';
import CustomerList from './components/CustomerList';
import CustomerSidebar from './components/CustomerSidebar';
import FilterButton from '../../../shared/components/filter/FilterButton';
import FilterSidebar from '../../../shared/components/filter/FilterSidebar';
import CustomersFilterSidebar from './components/CustomersFilterSidebar';
import CustomerDeletePrompt from './components/CustomerDeletePrompt';
import ProjectList from '../Projects/components/ProjectList';

class Customers extends React.PureComponent {
  static propTypes = {
    getCustomersRequest: PropTypes.func.isRequired,
    editCustomer: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
    customers: PropTypes.arrayOf(customerType).isRequired,
    totalCustomers: PropTypes.number.isRequired,
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  state = {
    activesFilter: 'actives',
    startDate: null,
    endDate: null,
    keywords: '',
    type: '',
    sortBy: '',
    dir: '',
    currentPage: 1,
    perPage: 10,
    viewMode: 'table',
    deletingCustomer: null,
  };

  componentDidMount() { this.forceReloadCustomer(); }
  // change it to maybe reload if all filter is kept in redux

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;
    const force = ['activesFilter', 'startDate', 'endDate', 'keywords',
      'type', 'sortBy', 'dir', 'currentPage', 'perPage']
      .reduce((acc, key) => acc || state[key] !== prevState[key], false);
    this.maybeReloadCustomers(force);
  }

  toggleCustomerStatus = (customer) => {
    const { updateCustomer } = this.props;
    updateCustomer({
      _id: customer._id,
      isActive: !customer.isActive,
    });
  };

  promptSoftDelete = (deletingCustomer = null) => {
    this.setState({
      deletingCustomer,
    });
  };

  updateFilter = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  updateSort = (sortColumn, sortDirection) => {
    this.setState({
      dir: sortDirection,
      sortBy: sortColumn,
    });
  };

  updatePagination = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  editCustomerModal = (customer) => {
    const { editCustomer } = this.props;
    editCustomer(customer);
  };

  newCustomerModal = () => {
    const { editCustomer } = this.props;
    editCustomer({
      _id: 'new',
    });
  };

  forceReloadCustomer = () => this.maybeReloadCustomers(true);

  maybeReloadCustomers = (force = false) => {
    const {
      getCustomersRequest, loading, loaded, error,
    } = this.props;
    if (!loading && (force || (!loaded && !error))) {
      const {
        activesFilter, startDate, endDate, keywords, type, sortBy, dir, currentPage, perPage,
      } = this.state;
      const filter = { start: (currentPage - 1) * perPage, limit: perPage };
      if (activesFilter) {
        if (activesFilter === 'actives') filter.actives = '1';
        else if (activesFilter === 'inactives') filter.inactives = '1';
        else console.error('unknown activesFilter filter in customers', activesFilter);
      }
      if (startDate) filter.startDate = moment(startDate).format('YYYY/MM/DD');
      if (endDate) filter.endDate = moment(endDate).format('YYYY/MM/DD');
      if (keywords) filter.keywords = keywords;
      if (type) filter.type = type;
      if (sortBy) filter.sortBy = sortBy;
      if (dir) filter.dir = dir;
      getCustomersRequest(filter);
    }
  };

  switchViewMode = (viewMode) => {
    /*
    this.setState(({ viewMode }) => ({
      viewMode: viewMode === 'table' ? 'card' : 'table',
    }));
    */
    this.setState({ viewMode });
  };

  renderTableView = () => {
    const {
      loading, loaded, error, customers, totalCustomers, t, theme, rtl,
    } = this.props;

    const {
      activesFilter, startDate, endDate, keywords, type, sortBy, dir, currentPage, perPage, viewMode,
    } = this.state;

    if (viewMode !== 'table') {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CustomersTable
                loading={loading}
                loaded={loaded}
                error={error}
                retry={this.forceReloadCustomer}
                customers={customers}
                totalCustomers={totalCustomers}
                toggleEditCustomer={this.editCustomerModal}
                sortBy={sortBy}
                dir={dir}
                updateSort={this.updateSort}
                toggleCustomerStatus={this.toggleCustomerStatus}
                promptSoftDelete={this.promptSoftDelete}
              />
              <CustomersTablePagination
                perPage={perPage}
                updatePagination={this.updatePagination}
                currentPage={currentPage}
                totalCustomers={totalCustomers}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      loading, loaded, error, customers, totalCustomers, t, theme, rtl,
    } = this.props;

    const {
      activesFilter, startDate, endDate, keywords, type, sortBy, dir,
      currentPage, perPage, viewMode, deletingCustomer,
    } = this.state;

    return (
      <Container className="container__with-filter customers-page">
        <Row>
          <Col sm={3}>
            <h3 className="page-title">{t('customers.page_title')}</h3>
          </Col>
          <Col sm={9} className="d-flex justify-content-end flex-wrap">
            <Button
              color="success"
              className="icon"
              onClick={this.newCustomerModal}
            >
              <p>
                <AccountPlusIcon /> {t('customers.add_new')}
              </p>
            </Button>
            <FilterButton />
          </Col>
        </Row>
        { this.renderTableView()}
        <CustomersFilterSidebar
          updateFilter={this.updateFilter}
          activesFilter={activesFilter}
          startDate={startDate}
          endDate={endDate}
          keywords={keywords}
          type={type}
          sortBy={sortBy}
          dir={dir}
          theme={theme}
          rtl={rtl.direction}
        />
        <CustomerDeletePrompt
          customer={deletingCustomer}
          onHideModal={this.promptSoftDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.customers.loading,
  loaded: state.customers.loaded,
  error: state.customers.error,
  customers: state.customers.customers,
  totalCustomers: state.customers.totalCustomers,
  theme: state.theme,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  getCustomersRequest: TenantActions.Customers.getCustomersRequest,
  updateCustomer: TenantActions.Customers.updateCustomerRequest,
  editCustomer: TenantActions.Customers.editCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Customers));

/*
            <h4>
              Table
            </h4>
            <Switch
              checked={viewMode === 'card'}
              onChange={this.switchViewMode}
              offColor="#008"
              onColor="#080"
              pendingOnColor="#2a2"
              pendingOffColor="#44c"
            />
            <h4>
              Card
            </h4> */
