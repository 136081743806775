export const COLORS = [

  '#0a4438',
  '#e0b03e',
  '#e0843e',
  '#0064aa',
  '#FF8C00',
  '#00aaa2',
  '#00aa63',

]; /* length must be prime */

export const getColorFactory = (colorStartIndex, colorIncrementIndex) => index => COLORS[
  (colorStartIndex + index * colorIncrementIndex) % COLORS.length

];

export const getRandomColorFactory = () => getColorFactory(
  Math.floor(COLORS.length),
  Math.floor(1),
);

const maxColor = 0xFFFFFF;

/* export const readableColor = color => `#${
  // eslint-disable-next-line no-bitwise
  ((~((Math.floor(parseInt(color.substr(1), 16) / 0x80) & 0x010101) * 0xFF)) & 0xFFFFFF)
    .toString(16).padStart(6, '0')
}`; */

/* export const readableColor = (hexColor) => {
  // eslint-disable-next-line no-bitwise
  const color = Math.floor(parseInt(hexColor.substr(1), 16) / 0x80) & 0x010101;
  // eslint-disable-next-line no-bitwise
  return (((color >> 16) + (color >> 8) + (color)) & 0xFF) > 1 ? '#000000' : '#FFFFFF';
}; */
export const readableColor = (hexColor) => {
  // eslint-disable-next-line no-bitwise
  const color = Math.floor(parseInt(hexColor.substr(1), 16));
  // eslint-disable-next-line no-bitwise
  return (((color >> 16) & 0xFF) + ((color >> 8) & 0xFF) + (color & 0xFF)) > 0x150
    ? '#000000' : '#FFFFFF';
};

export const reverseColor = color => `#${(maxColor - parseInt(color.substr(1), 16)).toString(16)}`;

export default COLORS;
