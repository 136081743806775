import React from 'react';
import { readableColor } from '../components/colors';

const RADIAN = Math.PI / 180;
export const renderCustomizedLabelFactory = (getColor, unit, hideName = false) => ({
  // eslint-disable-next-line react/prop-types
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value,
}) => {
  if (!value) return null;

  const radiusOutside = outerRadius * 1.3;
  const radiusMiddle = innerRadius + (outerRadius - innerRadius) * 0.55;
  const midX = cx + radiusMiddle * Math.cos(-midAngle * RADIAN);
  const midY = cy + radiusMiddle * Math.sin(-midAngle * RADIAN);
  const outX = cx + radiusOutside * Math.cos(-midAngle * RADIAN);
  const outY = cy + radiusOutside * Math.sin(-midAngle * RADIAN);

  const reversedColor = readableColor(getColor(index));

  const displayValue = typeof unit === 'function' ? unit(value) : `${value}${unit}`;

  return (
    <>
      <text
        x={midX}
        y={midY}
        fill={reversedColor}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={hideName ? 11 : 12}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <text
        x={outX}
        y={hideName ? outY : outY - 8}
        className="themed-label"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={hideName ? 10 : 12}
      >
        {hideName ? displayValue : `${name}`}
      </text>
      { hideName ? null : (
        <text
          x={outX}
          y={outY + 8}
          className="themed-label"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          ({displayValue})
        </text>
      ) }
    </>
  );
};
