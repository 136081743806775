import {
  RESET_PASSWORD_TENANT, RESET_PASSWORD_CONFIRMATION_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case RESET_PASSWORD_TENANT:
      return data => makeFetcher('tenants/password-reset-request', 'POST', data);
    case RESET_PASSWORD_CONFIRMATION_TENANT:
      return data => makeFetcher('tenants/password-reset', 'POST', data);
    default:
      return null;
  }
};
