import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Translated = ({
  value, namespace, children, ...translationOptions
}) => {
  const { t } = useTranslation(namespace);
  if (typeof children === 'function') {
    return children(t);
  }
  return (
    t((value || children || '').trim(), translationOptions) || value || children || ''
  );
};

Translated.propTypes = {
  value: PropTypes.string,
  namespace: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

Translated.defaultProps = {
  value: '',
  children: '',
  namespace: 'common',
};

export default Translated;
