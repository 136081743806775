import {
  spawn, take, takeLatest, call,
} from 'redux-saga/effects';
import { updateDefaultCurrency } from '../../shared/helpers';
import { FETCH_SUCCESS } from '../sharedTypes';
import { GET_CURRENT_PROJECT_TENANT } from '../fetchTypes';

const maybeUpdateCurrency = (action) => {
  const { currency } = (action.data || {}).project || {};
  if (currency) {
    updateDefaultCurrency(currency);
  }
};

function* watchCurrentCurrency(action) {
  yield call(maybeUpdateCurrency, action);
}

export default function* watchCurrentProject() {
  while (true) {
    const action = yield take(FETCH_SUCCESS);
    if (action.fetchType === GET_CURRENT_PROJECT_TENANT) {
      yield spawn(watchCurrentCurrency, action);
    }
  }
}
