import {
  CREATE_BILL_TENANT, GET_BILLS_TENANT, SOFT_DELETE_BILL_TENANT, UPDATE_BILL_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_BILLS_TENANT:
      return params => makeFetcher(`${params.projectId
        ? `projectS/${params.projectId}/` : ''}bills`, 'GET', params);
    case CREATE_BILL_TENANT:
      return data => makeFetcher('bills', 'POST', data);
    case UPDATE_BILL_TENANT:
      return ({ _id, ...data }) => makeFetcher(`bills/update/${_id}`, 'PUT', data);
    case SOFT_DELETE_BILL_TENANT:
      // PUT BILLS/:ID IS DEACTIVATE ENDPOINT IN BACKEND, IT DOESN'T NEED A REQUEST BODY
      return ({ _id }) => makeFetcher(`bills/${_id}`, 'PUT');
    default:
      return null;
  }
};
