import {
  CREATE_EXTRA_TENANT, GET_EXTRAS_TENANT,
  UPDATE_EXTRA_TENANT, RELOAD_EXTRA_TENANT,
  DELETE_EXTRA_TENANT, SOFT_DELETE_EXTRA_TENANT,
} from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case GET_EXTRAS_TENANT:
      return params => makeFetcher('extras', 'GET', params);
    case RELOAD_EXTRA_TENANT:
      return data => makeFetcher(`extras/${data._id}`, 'GET');
    case CREATE_EXTRA_TENANT:
      return data => makeFetcher('extras', 'POST', data);
    case UPDATE_EXTRA_TENANT:
      return ({ _id, ...data }) => makeFetcher(`extras/${_id}`, 'PUT', data);
    case SOFT_DELETE_EXTRA_TENANT:
      return ({ _id }) => makeFetcher(`extras/${_id}`, 'PUT', { isActive: false, amount: '0' });
    case DELETE_EXTRA_TENANT:
      return data => makeFetcher(`extras/${data._id}`, 'DELETE');
    default:
      return null;
  }
};
