import { EDIT_CUSTOMER, FETCH_REQUEST } from '../../sharedTypes';
import {
  GET_READ_MESSAGES_TENANT, GET_UNREAD_MESSAGES_TENANT, MARK_MESSAGE_TENANT,
} from '../../fetchTypes';

export const getReadMessages = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_READ_MESSAGES_TENANT,
  data,
});

export const getUnreadMessages = data => ({
  type: FETCH_REQUEST,
  fetchType: GET_UNREAD_MESSAGES_TENANT,
  data,
});

export const markReadMessages = data => ({
  type: FETCH_REQUEST,
  fetchType: MARK_MESSAGE_TENANT,
  data,
});
