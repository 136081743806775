import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    showEmployeeTab: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,

  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const {
      changeToLight, changeToDark, showEmployeeTab, t,
    } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title={t('main_sidebar.dashboard')}
            icon="pie-chart"
            route="/passboard"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title={t('main_sidebar.all_customers')}
            icon="users"
            route="/customers"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title={t('main_sidebar.all_matters')}
            icon="briefcase"
            newLink
            route="/matters"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title={t('main_sidebar.all_tasks')}
            icon="list"
            newLink
            route="/tasks"
            onClick={this.hideSidebar}
          />
          {
            showEmployeeTab ? (
              <SidebarLink
                title={t('main_sidebar.all_employees')}
                icon="list"
                newLink
                route="/employees"
                onClick={this.hideSidebar}
              />
            ) : null
          }
          {/* <SidebarCategory title="Layout" icon="layers">
            <button
              type="button"
              className="sidebar__link"
              onClick={changeToLight}
            >
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button
              type="button"
              className="sidebar__link"
              onClick={changeToDark}
            >
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory> */}
        </ul>
      </div>
    );
  }
}
export default (withTranslation('common')(SidebarContent));
