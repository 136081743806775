import resources from './resources';
import localizeMoment from './localizeMoment';
import localizeDatePicker from './localizeDatePicker';

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  fallbackLng: 'en',
  load: 'all',
  whitelist: ['en', 'tr'],
  nonExplicitWhitelist: true,
  cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US
  lowerCaseLng: false, // locale will be fully lowercased eg. en-US --> en-us
  resources,
  defaultNS: 'common',
  nsSeparator: '#',
  /* debug: true, */
  detection: {
    // LANGUAGE DETECTION OPTIONS https://github.com/i18next/i18next-browser-languageDetector#detector-options
    order: ['querystring', 'localStorage', 'navigator'],
    // order: [ 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
    // lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    // lookupFromPathIndex: 0,
    // lookupFromSubdomainIndex: 0,

    // caches: ['localStorage', 'cookie'],
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // cookieMinutes: 10,
    // cookieDomain: 'myDomain',
    // cookieOptions: {path:'/'}

    checkWhitelist: true,
  },
};

export { resources, localizeMoment, localizeDatePicker };
