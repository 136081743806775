import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import TenantActions from '../../../store/actions/tenant';

import ResetPasswordForm from './components/ResetPasswordForm';

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPassword = ({
  t, loading, error, loaded, resetPasswordRequest,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const emailQuery = query.get('email');

  const handleSubmit = ({ email }) => {
    resetPasswordRequest({ email });
    setSubmitted(true);
  };

  useEffect(() => {
    if (!loading && !error && loaded && submitted) {
      history.push('/reset_password_confirmation');
    }
  });

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> {t('reset_password_form_index.advo')}
                <span className="account__logo-accent">{t('reset_password_form_index.pass')}</span>
              </span>
            </h3>
            <h4 className="account__subhead">{t('reset_password_form_index.verify_email')}</h4>
          </div>
          <ResetPasswordForm
            loading={loading}
            initialValues={{ email: emailQuery }}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  resetPasswordRequest: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
  error: '',
};

const mapStateToProps = state => ({
  loading: state.resetPassword.loading,
  error: state.resetPassword.error,
  loaded: state.resetPassword.loaded,
});

const mapDispatchToProps = {
  resetPasswordRequest: TenantActions.ResetPassword.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ResetPassword));
