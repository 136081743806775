import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  NavLink as RouterNavLink, Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ButtonToolbar, Button,
} from 'reactstrap';
import classnames from 'classnames';
import ProfileTimeLine from './ProfileTimeLine';
import ProfileActivities from './ProfileActivities';
import showResults from './Show';
import ProfileInfoSettings from './ProfileInfoSettings';
import * as ProfileActions from '../../../../store/actions/tenant/profile';
import ProfilePasswordSettings from './ProfilePasswordSettings';
import ProfileAddressSettings from './ProfileAddressSettings';
import ProfileSubscriptionAddressSettings from './ProfileSubscriptionAddressSettings';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

class ProfileTabs extends PureComponent {
  static propTypes = {
    profile: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      picture: PropTypes.string,
      identityNumber: PropTypes.string.isRequired,
      chamberRegNo: PropTypes.string,
      subscriptionReferenceCode: PropTypes.string,
      fname: PropTypes.string.isRequired,
      lname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      addresses: PropTypes.arrayOf(PropTypes.shape({
        countryName: PropTypes.string.isRequired,
        cityName: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        zipCode: PropTypes.string.isRequired,
      })),
      subscription: PropTypes.shape(),
    }),
    isEmployee: PropTypes.bool.isRequired,
    isTenantAdmin: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    isNotSubscribed: PropTypes.bool.isRequired,
    isEnterpriseSubscribed: PropTypes.bool.isRequired,
    isIndividualSubscribed: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    updateProfile: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    rtl: RTLProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    profile: null,
    updateError: '',
  };

  state = {
    promptVisible: false,
    modalVisible: false,
  };

  componentDidMount() {
    const { location } = this.props;
    const { newlySubscribed } = location.state || {};
    /* if (newlySubscribed) {
      this.togglePrompt();
    } */
  }

  componentDidUpdate(prevProps) {
    const { newlySubscribed: wasNewlySubscribed } = prevProps.location.state || {};
    const { location } = this.props;
    const { newlySubscribed } = location.state || {};
    /* if (wasNewlySubscribed !== newlySubscribed) {
      this.togglePrompt();
    } */
  }

  toggleModal = () => {
    const { updating } = this.props;
    if (updating) return;
    this.setState(({ modalVisible }) => ({ modalVisible: !modalVisible, promptVisible: false }));
  };

  togglePrompt = () => {
    this.setState(({ promptVisible }) => ({ promptVisible: !promptVisible }));
  };

  handleUpdateProfileInfo = (fields) => {
    const { updateProfile, profile } = this.props;

    const { fname, lname } = profile;
    let { addresses } = profile;
    if (!addresses) {
      addresses = [];
    }

    const payload = {};
    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        payload[key] = fields[key];
      }
    });

    ['billingAddress', 'shippingAddress'].forEach((addressKey) => {
      if (payload[addressKey]) {
        const {
          address, city, country, zipCode,
        } = addresses[payload[addressKey]];
        payload[addressKey] = {
          contactName: `${fname} ${lname}`,
          country,
          city,
          address,
          zipCode,
        };
      }
    });

    if (payload.phone) {
      payload.phone = payload.phone.replace(/[^\d+]+/g, '');
    }

    updateProfile(payload);

    if (payload.addresses) {
      this.togglePrompt();
    }
  };

  render() {
    const {
      profile, updating, updateError,
      isEmployee, isTenantAdmin,
      isSubscribed, isNotSubscribed,
      isEnterpriseSubscribed, isIndividualSubscribed,
      rtl, t,
    } = this.props;
    const { modalVisible, promptVisible } = this.state;
    if (!profile) return null;
    const {
      fname, lname, email, phone, identityNumber, chamberRegNo,
      billingAddress, shippingAddress,
    } = profile;
    let { addresses } = profile;
    if (!addresses) addresses = [];
    const ProfileInfoSettingsInitialValues = {
      fname,
      lname,
      email,
      identityNumber,
      chamberRegNo,
      phone,
    };

    const ProfileAddressSettingsInitialValues = {
      addresses,
    };

    let billingAddressIndex = '';
    let shippingAddressIndex = '';
    if (billingAddress) {
      addresses.forEach((address, index) => {
        if (billingAddress.address === address.address) {
          billingAddressIndex = `${index}`;
        }
      });
    }
    if (shippingAddress) {
      addresses.forEach((address, index) => {
        if (shippingAddress.address === address.address) {
          shippingAddressIndex = `${index}`;
        }
      });
    }

    const ProfileSubscriptionAddressSettingsInitialValues = {
      billingAddress: billingAddressIndex,
      shippingAddress: shippingAddressIndex,
    };

    const addressList = addresses.map(({ address }, index) => ({
      label: address,
      value: `${index}`,
    }));

    return (
      <Col md={12} lg={12} xl={6}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav
                tabs
              >
                {/* <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile/activity"
                  >
                    Activity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile/timeline"
                  >
                    TimeLine
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile/info"
                  >
                    {t('profile_tabs.info')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile/change-password"
                  >
                    {t('profile_tabs.change_password')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile/addresses"
                  >
                    {t('profile_tabs.addresses')}
                  </NavLink>
                </NavItem>
                { isSubscribed ? (
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/profile/subscription-address"
                    >
                      {t('profile_tabs.subscription_address')}
                    </NavLink>
                  </NavItem>
                ) : null }
              </Nav>
              <TabContent>
                <TabPane>
                  <Switch>
                    {/* <Route exact path="/profile/activity" component={ProfileActivities} /> */}
                    {/* <Route exact path="/profile/timeline" component={ProfileTimeLine} /> */}
                    <Route
                      exact
                      path="/profile/info"
                      render={() => (
                        <ProfileInfoSettings
                          onSubmit={this.handleUpdateProfileInfo}
                          initialValues={ProfileInfoSettingsInitialValues}
                          loading={updating}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/profile/change-password"
                      render={() => (
                        <ProfilePasswordSettings
                          onSubmit={this.handleUpdateProfileInfo}
                          loading={updating}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/profile/addresses"
                      render={() => (
                        <ProfileAddressSettings
                          onSubmit={this.handleUpdateProfileInfo}
                          initialValues={ProfileAddressSettingsInitialValues}
                          loading={updating}
                        />
                      )}
                    />
                    { !isNotSubscribed ? (
                      <Route
                        exact
                        path="/profile/subscription-address"
                        render={() => (
                          <ProfileSubscriptionAddressSettings
                            onSubmit={this.handleUpdateProfileInfo}
                            initialValues={ProfileSubscriptionAddressSettingsInitialValues}
                            loading={updating}
                            addressList={addressList}
                          />
                        )}
                      />
                    ) : null }
                    <Redirect to="/profile/info" />
                  </Switch>
                </TabPane>
              </TabContent>
              <Modal
                isOpen={isSubscribed && promptVisible && !updateError && !updating}
                toggle={this.togglePrompt}
                modalClassName={`${rtl.direction}-support`}
                className="modal-dialog--primary"
              >
                <div className="modal__header">
                  <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={this.togglePrompt}
                  />
                  <h4 className="text-modal  modal__title">
                    {t('profile_tabs.modal_title')}
                  </h4>
                </div>
                <div className="modal__body" />
                <ButtonToolbar className="modal__footer">
                  <Button
                    className="modal_cancel"
                    color="danger"
                    onClick={this.togglePrompt}
                  >
                    {t('profile_tabs.no')}
                  </Button>
                  <Button
                    className="modal_ok"
                    color="success"
                    onClick={this.toggleModal}
                  >
                    {t('profile_tabs.yes')}
                  </Button>
                </ButtonToolbar>
              </Modal>
              <Modal
                isOpen={modalVisible}
                toggle={this.toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className="modal-diaglog--primary"
              >
                <div className="modal__header">
                  <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={this.toggleModal}
                  />
                  <h4 className="text-modal  modal__title">
                    {t('profile_tabs.update_subs')}
                  </h4>
                </div>
                <div className="modal_body">
                  { modalVisible ? (
                    <ProfileSubscriptionAddressSettings
                      onSubmit={this.handleUpdateProfileInfo}
                      initialValues={ProfileSubscriptionAddressSettingsInitialValues}
                      loading={updating}
                      addressList={addressList}
                    />
                  ) : null }
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button
                    className="modal_cancel"
                    disabled={updating}
                    onClick={this.toggleModal}
                  >
                    {t('profile_tabs.close')}
                  </Button>
                </ButtonToolbar>
              </Modal>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  updating: state.profile.updating,
  updateError: state.profile.updateError,
  isEmployee: state.profile.isEmployee,
  isTenantAdmin: state.profile.isTenantAdmin,
  isSubscribed: state.profile.isSubscribed,
  isNotSubscribed: state.profile.isNotSubscribed,
  isEnterpriseSubscribed: state.profile.isEnterpriseSubscribed,
  isIndividualSubscribed: state.profile.isIndividualSubscribed,
  rtl: state.rtl,
});

const mapDispatchToProps = {
  updateProfile: ProfileActions.updateProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileTabs),
);
