import { VERIFY_EMAIL_TENANT, RESEND_EMAIL_VERIFICATION_TENANT } from '../../fetchTypes';
import { makeFetcher } from './base';

export default (fetchType) => {
  switch (fetchType) {
    case VERIFY_EMAIL_TENANT:
      return data => makeFetcher('tenants/verify-email', 'POST', data);
    case RESEND_EMAIL_VERIFICATION_TENANT:
      return data => makeFetcher('tenants/resend-token', 'POST', ({
        ...data,
        resendType: 'verifyemail',
      }));
    default:
      return null;
  }
};
