const displayPercentile = (rawValue, {
  fractionDigits = 2,
  canBeSmallerThanOne = false,
  language = 'tr',
} = {}) => {
  let value = rawValue;

  if (typeof value === 'string') {
    value = parseFloat(value.replace(/[^\d.,]/g, '').replace(/,/g, '.'));
  }
  if (!value || typeof value !== 'number') {
    value = 0;
  }
  if (!canBeSmallerThanOne && Math.abs(value) < 1) {
    value *= 100;
  }
  const res = (value).toFixed(fractionDigits).replace(/\./g, ',');
  return language.toLowerCase() === 'tr' ? `%${res}` : `${res}%`;
};

export default displayPercentile;
