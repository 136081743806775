import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import EmailIcon from 'mdi-react/EmailIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../../shared/components/Loading';

const ResetPasswordForm = ({ t, handleSubmit, loading }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">E-Mail</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <EmailIcon />
        </div>
        <Field
          name="email"
          component="input"
          type="email"
          placeholder="Email"
        />
      </div>
      <div className="account__forgot-password">
        <Link to="/login">Giriş</Link>
        {' | '}
        <Link to="/register">Kayıt</Link>
      </div>
    </div>
    { loading ? <Loading className="mb-2" />
      : (
        <button
          className="btn btn-primary account__btn account__btn--small mt-2"
          type="submit"
        >Şifre Güncelleme Maili
        </button>
      )}
    <Link
      className="btn btn-outline-primary account__btn account__btn--small"
      to="/reset_password_confirmation"
    >
      Kod İle Güncelle
    </Link>
  </form>
);

ResetPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'reset_password_form',
})(withTranslation('common')(ResetPasswordForm));
