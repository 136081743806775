import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginTenant from './LoginTenant';
import Layout from './Layout';

import ExamplePage from '../Example';
import Profile from './Profile';
import Register from './Register';
import EmailConfirmation from './EmailConfimation';
import EmailVerification from './EmailVerification';
import ResendEmailVerification from './ResendEmailVerification';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import Graphs from './Graphs';
import Customers from './Customers';
import CustomerPage from './Customers/CustomerPage';
import Projects from './Projects';
import MatterPage from './Projects/MatterPage';
import Tasks from './Tasks';
import Employee from './Employees';
import EmployeePage from './Employees/EmployeePage';
import TaskPage from './Tasks/TaskPage';
import Subscription from './Subscription';


const TenantRouter = ({
  token, isTenantAdmin, isEmployee, isSubscribed, isNotSubscribed, isEnterpriseSubscribed, isIndividualSubscribed,
}) => (token ? (
  <>
    <Layout />
    <div className="container__wrap">
      <Switch>
        {/* <Route exact path="/" component={ExamplePage} /> */}
        <Route exact path="/profile/subscription" component={Subscription} />
        <Route path="/profile" component={Profile} />

        <Route exact path="/passboard" component={Graphs} />
        <Route exact path="/customers" component={Customers} />
        <Route path="/customers/:customerId" component={CustomerPage} />
        <Route exact path="/matters" component={Projects} />
        <Route path="/matters/:matterId" component={MatterPage} />
        <Route exact path="/tasks" component={Tasks} />
        <Route exact path="/tasks/:id" component={TaskPage} />
        <Route exact path="/email_verification" component={EmailConfirmation} />
        {
          (!isEmployee) ? (
            <Route exact path="/employees" component={Employee} />
          ) : null
        }
        <Redirect to="/passboard" />
      </Switch>
    </div>
  </>
) : (
  <Switch>
    <Route exact path="/login" component={LoginTenant} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/email_verification" component={EmailVerification} />
    <Route exact path="/resend_email_verification" component={ResendEmailVerification} />
    <Route exact path="/reset_password_request" component={ResetPassword} />
    <Route exact path="/reset_password_confirmation" component={ResetPasswordConfirmation} />
    <Redirect to="/login" />
  </Switch>
));

TenantRouter.propTypes = {
  token: PropTypes.string.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  isTenantAdmin: PropTypes.bool.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  isNotSubscribed: PropTypes.bool.isRequired,
  isEnterpriseSubscribed: PropTypes.bool.isRequired,
  isIndividualSubscribed: PropTypes.bool.isRequired,
};

TenantRouter.defaultProps = {
};

const mapStateToProps = state => ({
  token: state.auth.token,
  isEmployee: state.profile.isEmployee,
  isTenantAdmin: state.profile.isTenantAdmin,
  isSubscribed: state.profile.isSubscribed,
  isNotSubscribed: state.profile.isNotSubscribed,
  isEnterpriseSubscribed: state.profile.isEnterpriseSubscribed,
  isIndividualSubscribed: state.profile.isIndividualSubscribed,
});

export default connect(mapStateToProps)(TenantRouter);
